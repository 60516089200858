import React, { useState, useEffect } from "react";
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';

const RadioButton = (props) => {

    const [selectedItem, setSelectedItem] = useState()
    let item = props.item

    const onChange = (e, item) => {
        setSelectedItem(item);
      };
    
      useEffect(() => {

        if (selectedItem) {
          let objectFinal = {
          ...selectedItem,
          ...{ id: item.key }
          }
          props.actionOnChange(objectFinal);
        }else{
          let objectFinal = {
            ...item,
            ...{ id: item.key, key:item.defaultValue?item.defaultValue:'' }
          }
          props.actionOnChange(objectFinal);
        }
        
      }, [selectedItem,]);


    return (
        <>
            <ChoiceGroup 
            defaultSelectedKey={item.defaultValue?item.defaultValue:''} 
            options={item.options} 
            onChange={onChange} 
            label={item.label} 
            required={true} 
            imageWrapper={true}
            className={item.className}
            />
        </>
    )
};


export default RadioButton;

