import React, { useContext, useEffect, useState } from 'react'
//import {DefaultButton, Panel, PanelType, } from 'office-ui-fabric-react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks';
import { useParams } from 'react-router-dom';
import CookiesJS from "js-cookie";
import { render } from "react-dom";
 
//**Components**//
import SubMenu from '../../../components/navigation/subMenu/subMenu.component'
import ContainerLargeForm from '../../../components/containers/largeForm/containerLargeForm.component'
import ContainerBaseLoading from '../../../components/containers/baseLoading/containerBaseLoading.component'
import ListHeader from '../../../components/lists/listHeader/listHeader.component'
import ListBody from '../../../components/lists/listBody/listBody.component'
import DetailsListBext from '../../../components/lists/DetailsList/detailsList.component';
import ModalFluent from "../../../components/tools/modalFluent/ModalFluent";

//**Methods**//
import Lists from '../../../utils/logicRendering/lists.controller'
import { FetchGet, RequestFetch } from '../../../utils/Requests/requests.Controller'
import CountPages from '../../../utils/logicRendering/countPages.controller'
import ContainerForm from './panelAsociate';
import LenguajeContext from '../../../context/Lenguaje/lenguajeContext';
import { changeLang, changeLangForm } from '../../../utils/logicRendering/changeIdiom';

/**
 *@author Sebastian Ocampo(10-05-2022)
 *@version 1.0
 *@lastCommit 
 */

const Domain = () => {
    /************************
     * @arguments 
     ************************/
    /************************
     * @hooks 
     ************************/
    const [edit, setEdit] = useState(false);
    const [deleteD, setDeleteD] = useState(false);
    const [defaultValueInput, setDefaultValueInput] = useState([]);
    const [associateSchema, setAssociateSchema] = useState(false);
    const [idDomainSelect, setIdDomainSelect] = useState([]);
    const [domainName, setDomainName] = useState("");
    const [currentPages, setCurrentPage] = useState(1);
    const [showElements, setShowElements] = useState(5);
    const [itemsDomain, setItemsDomain] = useState(null);
    const [controlsPage, setControlsPage] = useState({});
    const [controlsPageValue, setControlsPageValue] = useState({});
    const [textOnPages, setTextOnPages] = useState({})
    const { getLenguaje, stateLanguage } = useContext(LenguajeContext);
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
    const { clientName } = useParams();
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [isOpenAssociate, { setTrue: openPanelAssociate, setFalse: dismissPanelAssociate }] = useBoolean(false);
    const [isDisable, setDisable] = useState([
        { disable: false, key: "valueDefault" },
        { disable: true, key: "name" },
    ]);
    const [buttonDisable, setButtonDisable] = useState(true)
    const [buttonDisableAssociate, setButtonDisableAssociate] = useState(true)
    
    const [filter, setFilter] = useState({
        pageSize: 5,
        pageNumber: "0",
        idDomain: "",
        idLanguage: JSON.parse(CookiesJS.get("dataUser")).user.idLanguage
    });
    const [pagination, setPagination] = useState(null);
    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);

    const Requests = new RequestFetch('Domains');
    const RequestsDelete = new RequestFetch('Domains/changeStatus');
    const { idClient } = JSON.parse(CookiesJS.get("dataClient"));
    const [domainMeta, setDomainMeta] = useState(null)
    const RequestsAssociate = new RequestFetch('DomainSchema');
    const RequestsSchemas = new RequestFetch('Schemas');
    const SpaceTitel = ['Usuario', 'Correo', 'Estado']
    const OrdItems = ['alias', 'mailAddress', 'type']
    const [schemas, setSchemas] = useState();


    const [credential, setCredential] = useState(false)

    const [alias, setAlias] = useState(null)
    const [mailHost, setMailHost] = useState(null)
    const [mailAddress, setMailAddress] = useState(null)
    const [mailPassword, setMailPassword] = useState(null)
    const [mailPort, setMailPort] = useState(0)
    const [mailPortValue, setMailPortValue] = useState(0)
    const [mailSSL, setMailSSL] = useState(false)
    const [defaultCredentials, setDefaultCredentials] = useState(false)
    const [requestSecurityQuestions, setRequestSecurityQuestions] = useState(null)
    const [requestSecurityQuestionsValue, setRequestSecurityQuestionsValue] = useState(0)
    const [numberSecurityQuestions, setNumberSecurityQuestions] = useState(null)
    const [numberSecurityQuestionsValue, setnumberSecurityQuestionsValue] = useState(0)
    const [sessionTime, setSessionTime] = useState(null)
    const [sessionTimeValue, setSessionTimeValue] = useState(0)
    const [dailyUserRecoveryLimit, setDailyUserRecoveryLimit] = useState(null)
    const [dailyUserRecoveryLimitValue, setDailyUserRecoveryLimitValue] = useState(0)
    const [idSchema, setIdSchema] = useState(0)
    const [defoultOu, setDefoultOu] = useState("")
    const [serviceUser, setServiceUser] = useState("")
    const [isPrimary, setIsPrimary] = useState(false)
    const [servicePassword, setServicePassword] = useState(null)
    const [groupToEnableMFA, setGroupToEnableMFA] = useState("")
    const [groupToDisableMFAPerDays, setGroupToDisableMFAPerDays] = useState("")
    const [isEnable, setisEnable] = useState(true)
    const [validator, setValidator] = useState(true)

    const [editSchema, setEditSchema] = useState(null)

    const [formCred, setFormCred] = useState(null)

    const COLUMN_TITLES = [{
        alias: {
            name: "Dominio",
            minWidth: 30,
            isResizable: true,
            idHtml: "coluDomain",
            maxWidth: 100
        },
        mailAddress: {
            name: "Correo",
            minWidth: 50,
            isResizable: true,
            idHtml: "coluEmail",
            maxWidth: 150
        },
        stateEnable: {
            name: "Estado",
            minWidth: 30,
            isResizable: true,
            idHtml: "coluState",
            maxWidth: 100
        },
        creatingDate: {
            name: "Fecha de creación",
            minWidth: 100,
            isResizable: true,
            maxWidth: 200,
            idHtml: "coluCreationDate",
        },
        creatorUser: {
          name: "Creado por",
          maxWidth: 150,
          isResizable: true,
          idHtml: "coluCreatedBy",
        },
        updatingDate: {
            name: "Última Modificación",
            minWidth: 100,
            isResizable: true,
            maxWidth: 200,
            idHtml: "coluLastModification",
        },
        updateUser: {
          name: "Modificado por",
          maxWidth: 150,
          isResizable: true,
          idHtml: "lblModifiedBy"
        },
    }];
    const [columnTitles, setColumnTitles] = useState(COLUMN_TITLES)

    const [campForm, setCampForm] = useState([
        {
            id: 0,
            label: 'Nombre del dominio',
            key: 'alias',
            type: 'text2',
            placeholder: '',
            //max: 30,
            max: controlsPageValue?.txtDomainName?.maxlength,
            defaultValue: '',
            disabled: false,
            multiline: false,
            resizable: false,
            typeInput: "text",
            description: "",
            onRenderLabel: null,
            isRequired: true,
            defaultValu: null,
            className: 'select2',
            idHtml: "lblDomainName"
        },
        {
            id: 1,
            label: 'Mail host',
            //max: 100,
            max: controlsPageValue?.txtMailHost?.maxlength,
            key: 'mailHost',
            type: 'text2',
            placeholder: '',
            defaultValue: '',
            disabled: false,
            multiline: false,
            resizable: false,
            typeInput: "text",
            description: "",
            onRenderLabel: null,
            isRequired: true,
            defaultValu: null,
            className: 'select2',
            idHtml: "lblMailHost"
        },
        {
            id: 2,
            label: 'Mail address',
            max: controlsPageValue?.txtMailAddress?.maxlength,
            key: 'mailAddress',
            type: 'text2',
            placeholder: '',
            defaultValue: '',
            disable: credential,
            multiline: false,
            resizable: false,
            typeInput: "text",
            description: "",
            onRenderLabel: null,
            isRequired: false,
            defaultValu: null,
            className: 'select2',
            idHtml: "lblMailAddress"
        },
        {
            id: 3,
            label: 'Mail password',
            max: controlsPageValue?.txtMailPasword?.maxlength,
            key: 'mailPassword',
            type: 'text2',
            placeholder: '',
            disable: credential,
            multiline: false,
            resizable: false,
            typeInput: "password",
            autoComplete:"new-password",
            description: "",
            onRenderLabel: null,
            isRequired: false,
            defaultValu: "",
            canRevealPassword: true,
            className: 'select2',
            idHtml: "lblMailPasword"
        },
        {
            id: 4,
            label: 'Mail port',
            type: 'text2',
            key: 'mailPort',
            max: 50,
            placeholder: '',
            defaultValue: mailPortValue,
            value: mailPortValue,
            disabled: false,
            multiline: false,
            resizable: false,
            isNumeric: true,
            typeInput: "text",
            description: "",
            onRenderLabel: null,
            isRequired: true,
            defaultValu: null,
            className: 'select2',
            idHtml: "lblMailPort"
        },
        {
            id: 5,
            label: 'Mail SSL',
            type: 'toggle',
            placeholder: 'Selecciona',
            disabled: false,
            required: true,
            key: 'mailSSL',
            defaultValue: mailSSL,
            checked: mailSSL,
            idHtml: "lblMailSSL"
        },
        {
            id: 6,
            label: 'Default credentials',
            type: 'toggle',
            placeholder: 'Selecciona',
            disabled: false,
            required: true,
            key: 'defaultCredentials',
            defaultValu: 0,
            defaultValue: credential,
            checked: credential,
            idHtml: "lblDefauldCredentials"
        },
        {
            id: 7,
            typeInput: "text",
            label: 'Total de preguntas de seguridad por usuario.',
            type: 'text2',
            key: 'requestSecurityQuestions',
            defaultValue: requestSecurityQuestionsValue,
            value: requestSecurityQuestionsValue,
            oninput: "validity.valid||(value='');",
            min: 0,
            disabled: false,
            multiline: false,
            resizable: false,
            typeInput: "text",
            isNumeric: true,
            description: "",
            onRenderLabel: null,
            isRequired: true,
            defaultValu: null,
            className: 'select2',
            idHtml: "lblTotalUserSecurityQuestions"
        },
        {
            id: 8,
            label: 'Número de preguntas para el desafío de usuario.',
            type: 'text2',
            placeholder: '',
            defaultValue: '',
            key: 'numberSecurityQuestions',
            defaultValue: numberSecurityQuestionsValue,
            value: numberSecurityQuestionsValue,
            min: 2,
            disabled: false,
            multiline: false,
            resizable: false,
            typeInput: "text",
            isNumeric: true,
            description: "",
            onRenderLabel: null,
            isRequired: true,
            defaultValu: null,
            className: 'select2',
            idHtml: "lblNumberQuestionsChallengeTheUser"
        },
        {
            id: 9,
            label: 'Tiempo de sesión.',
            type: 'text2',
            placeholder: '',
            defaultValue: '',
            key: 'sessionTime',
            value: sessionTimeValue,
            defaultValue: sessionTimeValue,
            disabled: false,
            multiline: false,
            resizable: false,
            typeInput: "text",
            isNumeric: true,
            description: "",
            onRenderLabel: null,
            isRequired: false,
            defaultValu: null,
            className: 'select2',
            idHtml: "lblSessionTime"
        },
        {
            id: 10,
            label: 'Numero de accesos.',
            type: 'text2',
            placeholder: '',
            defaultValue: '',
            key: 'dailyUserRecoveryLimit',
            value: dailyUserRecoveryLimitValue,
            defaultValue: dailyUserRecoveryLimitValue,
            disabled: false,
            multiline: false,
            resizable: false,
            typeInput: "text",
            description: "",
            onRenderLabel: null,
            isRequired: true,
            defaultValu: null,
            className: 'select2',
            idHtml: "lblAccessNumber"
        }

    ]);
    const [arraySchema, setArraySchema] = useState([])

    const [campFormAssociate, setCampFormAssociate] = useState([
        {
            id: 0,
            label: 'Dominio',
            key: 'aliasAssociate',
            type: 'text2',
            placeholder: '',
            defaultValue: domainName,
            disabled: isDisable,
            multiline: false,
            resizable: false,
            typeInput: "text",
            onRenderLabel: null,
            isRequired: true,
            readOnly: true,
            idHtml: "lblDomainName",
            className: 'w10',
        },
        {
            id: 1,
            label: 'Seleccione esquema',
            type: 'select2',
            placeholder: 'Selecciona',
            defaultValue: "",
            key: 'idSchema',
            className: 'select2',
            options: arraySchema,
            idHtml: "lblSelectScheme"
        },
        {
            id: 2,
            label: 'Unidad organizacional',
            type: 'text2',
            key: 'defoultOu',
            placeholder: '',
            defaultValue: '',
            disabled: false,
            multiline: false,
            resizable: false,
            typeInput: "text",
            description: "",
            onRenderLabel: null,
            defaultValu: null,
            className: 'select2',
            idHtml: "lblOrganizationalUnit"
        },
        {
            id: 3,
            label: 'Cuenta de servicio',
            key: 'serviceUser',
            type: 'text2',
            placeholder: '',
            onRenderLabel: null,
            className: 'select2',
            idHtml: "lblServiceAccount",
            value: "",
        },
        {
            id: 4,
            label: 'Contraseña',
            type: 'text2',
            onRenderLabel: null,
            placeholder: 'Selecciona',
            defaultValue: '',
            key: 'servicePassword',
            typeInput: 'password',
            className: 'select2',
            canRevealPassword: true,
            idHtml: "lblMailPasword"
        },
        {
            id: 5,
            label: 'Grupo de habilitación del MFA de Office 365',
            key: 'enableGroupMFA',
            type: 'text2',
            placeholder: '',
            onRenderLabel: null,
            className: 'select2',
            value: "",
            idHtml: "lblEnablingGroup", 
        },
        {
            id: 6,
            label: 'Grupo de inhabilitación por días para el MFA de Office 365',
            key: 'disableGroupMFA',
            type: 'text2',
            placeholder: '',
            onRenderLabel: null,
            className: 'select2',
            value: "",
            idHtml: "lblDisqualificationGroupByDays",
        },
        {
            id: 7,
            label: '¿Es esquema principal?',
            type: 'select2',
            placeholder: 'Selecciona',
            defaultValue: false,
            isRequired: false,
            key: 'isPrimary',
            className: 'select2',
            value: isPrimary,
            options: [{ key: false, text: controlsPage?.lblNo?.value },
                { key: true, text: controlsPage?.lblYes?.value }],
            idHtml: "lblIsTheMainSchematic"
        },
    ]);
    const openPanelAssociate2 = () =>{
        setCampFormAssociate(changeLangForm([
            {
                id: 0,
                label: 'Dominio',
                key: 'aliasAssociate',
                type: 'text2',
                placeholder: '',
                defaultValue: domainName,
                disabled: isDisable,
                multiline: false,
                resizable: false,
                typeInput: "text",
                onRenderLabel: null,
                isRequired: true,
                defaultValu: domainName,
                readOnly: true,
                idHtml: "lblDomainName",
                className: 'w10',
            },
            {
                id: 1,
                label: 'Seleccione esquema',
                type: 'select2',
                placeholder: 'Selecciona',
                key: 'idSchema',
                className: 'select2',
                options: arraySchema,
                idHtml: "lblSelectScheme",
                value: idSchema
            },
            {
                id: 2,
                label: 'Unidad organizacional',
                type: 'text2',
                key: 'defoultOu',
                placeholder: '',
                disabled: false,
                multiline: false,
                resizable: false,
                typeInput: "text",
                onRenderLabel: null,
                defaultValu: null,
                className: 'select2',
                idHtml: "lblOrganizationalUnit",
                value: " ",
            },
            {
                id: 3,
                label: 'Cuenta de servicio',
                key: 'serviceUser',
                type: 'text2',
                placeholder: '',
                onRenderLabel: null,
                className: 'select2',
                idHtml: "lblServiceAccount",
                value: " ",

            },
            {
                id: 4,
                label: 'Contraseña',
                type: 'text2',
                onRenderLabel: null,
                placeholder: 'Selecciona',
                defaultValue: '',
                key: 'servicePassword',
                typeInput: 'password',
                className: 'select2',
                canRevealPassword: true,
                idHtml: "lblMailPasword"
            },{
                id: 5,
                label: 'Grupo de habilitación del MFA de Office 365',
                key: 'enableGroupMFA',
                type: 'text2',
                placeholder: '',
                onRenderLabel: null,
                className: 'select2',
                value: " ",
                idHtml: "lblEnablingGroup" 
            },
            {
                id: 6,
                label: 'Grupo de inhabilitación por días para el MFA de Office 365',
                key: 'disableGroupMFA',
                type: 'text2',
                placeholder: '',
                onRenderLabel: null,
                className: 'select2',
                value: " ",
                idHtml: "lblDisqualificationGroupByDays"
            },
            {
                id: 7,
                label: '¿Es esquema principal?',
                type: 'select2',
                placeholder: 'Selecciona',
                defaultValue: false,
                isRequired: false,
                key: 'isPrimary',
                className: 'select2',
                value: isPrimary,
                options: [{ key: false, text: controlsPage?.lblNo?.value },
                    { key: true, text: controlsPage?.lblYes?.value }],
                idHtml: "lblIsTheMainSchematic"
            },
        ], controlsPage))
        
        openPanelAssociate()
    }
    const dismissPanelAssociate2 = (edit) => {

        if(!edit){dismissPanelAssociate()}
        
        setTimeout(() => {
        setCampFormAssociate(changeLangForm([
            {
                id: 0,
                label: 'Dominio',
                key: 'aliasAssociate',
                type: 'text2',
                placeholder: '',
                defaultValue: domainName,
                disabled: isDisable,
                multiline: false,
                resizable: false,
                typeInput: "text",
                onRenderLabel: null,
                isRequired: true,
                defaultValu: domainName,
                readOnly: true,
                idHtml: "lblDomainName",
                className: 'w10',
            },
            {
                id: 1,
                label: 'Seleccione esquema',
                type: 'select2',
                placeholder: 'Selecciona',
                key: 'idSchema',
                className: 'select2',
                options: arraySchema,
                idHtml: "lblSelectScheme",
                value: idSchema
            },
            {
                id: 2,
                label: 'Unidad organizacional',
                type: 'text2',
                key: 'defoultOu',
                placeholder: '',
                disabled: false,
                multiline: false,
                resizable: false,
                typeInput: "text",
                onRenderLabel: null,
                defaultValu: null,
                className: 'select2',
                idHtml: "lblOrganizationalUnit",
                value: " ",
            },
            {
                id: 3,
                label: 'Cuenta de servicio',
                key: 'serviceUser',
                type: 'text2',
                placeholder: '',
                onRenderLabel: null,
                className: 'select2',
                idHtml: "lblServiceAccount",
                value: " ",

            },
            {
                id: 4,
                label: 'Contraseña',
                type: 'text2',
                onRenderLabel: null,
                placeholder: 'Selecciona',
                defaultValue: '',
                key: 'servicePassword',
                typeInput: 'password',
                className: 'select2',
                canRevealPassword: true,
                idHtml: "lblMailPasword"
            },{
                id: 5,
                label: 'Grupo de habilitación del MFA de Office 365',
                key: 'enableGroupMFA',
                type: 'text2',
                placeholder: '',
                onRenderLabel: null,
                className: 'select2',
                value: " ",
                idHtml: "lblEnablingGroup"
            },
            {
                id: 6,
                label: 'Grupo de inhabilitación por días para el MFA de Office 365',
                key: 'disableGroupMFA',
                type: 'text2',
                placeholder: '',
                onRenderLabel: null,
                className: 'select2',
                value: " ",
                idHtml: "lblDisqualificationGroupByDays"
            },
            {
                id: 7,
                label: '¿Es esquema principal?',
                type: 'select2',
                placeholder: 'Selecciona',
                defaultValue: false,
                isRequired: false,
                key: 'isPrimary',
                className: 'select2',
                value: isPrimary,
                options: [{ key: false, text: controlsPage?.lblNo?.value },
                    { key: true, text: controlsPage?.lblYes?.value }],
                idHtml: "lblIsTheMainSchematic"
            },
        ], controlsPage))
        
        }, 100);
    }

    /************************
     * @methods 
     ************************/
    useEffect(() => {
        let controlsPage = {};
        let controlsPageValue = {};
        if (stateLanguage.lenguaje != 0) {
            stateLanguage.lenguaje.map((pass) => {
                if (pass.path == 'domain' ||
                    pass.path == 'controls' 
                ) {
                    pass.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                        controlsPageValue[data.idHtml] = data;                        
                    })
                    setControlsPage(controlsPage)
                    setControlsPageValue(controlsPageValue)
                    }
            })
            setColumnTitles(current => changeLang(current, controlsPage))
            setCampFormAssociate(current => changeLangForm(current, controlsPage))
            setCampForm(current => changeLangForm(current, controlsPage))
        }
    }, [stateLanguage])

    const methodsDomain = {
        getDataItem: (object) => {
            setDefaultValueInput([
                {
                    key: "aliasAssociate",
                    text: object.alias
                }
            ])
            const stateList = new Lists(itemsDomain, campForm);
            setIdDomainSelect(object);
            setDomainName(object[0] ? object[0].alias : '')
            setMailSSL(object[0] ? object[0].mailSSL : false)
            setDefaultCredentials(object[0] ? object[0].defaultCredentials : false)
            setMailPortValue(object[0] ? object[0].mailPort : 0)
            setRequestSecurityQuestionsValue(object[0] ? object[0].requestSecurityQuestions : 0)
            setnumberSecurityQuestionsValue(object[0] ? object[0].numberSecurityQuestions : 0)
            setSessionTimeValue(object[0] ? object[0].sessionTime : 0)
            setDailyUserRecoveryLimitValue(object[0] ? object[0].dailyUserRecoveryLimit : 0)
            setisEnable(object[0] ? object[0].isEnable : true)
            const stateCurrent = stateList.updateCurrent(object);
            setCampForm(stateCurrent.object);
            setDeleteD(stateCurrent.delete);
            setEdit(stateCurrent.edit);
            setAssociateSchema(stateCurrent.associate);
            setDisable([
                { disable: false, key: "alias" },
                { disable: true, key: "aliasAssociate" },
                { disable: false, key: "valueDefault" }
            ]);
        },
        voidCamps: () => {
            const stateList = new Lists(itemsDomain, campForm)
            setCampForm(stateList.clearCamps())
        }
    }
const methosRequests = {
        getDomains: async (filter) => {
            handleLoadingData()
            const response = await Requests.getItem({ param: filter, tenant: clientName })
            setDomainMeta(response.data.meta)
            setItemsDomain(
                response.data.data.map(
                    user => {
                        return (
                            {
                                ...user,
                                stateEnable: user.isEnable ? controlsPage?.lblEnable.value : controlsPage?.lblDisabled.value
                            }
                        )
                    }
                )
            )
            const {
                currentPage,
                pageZise,
                totalCount,
                totalPages
            } = response.data.meta;
            setPagination({
                currentPage: currentPage,
                pageSize: pageZise,
                totalItems: totalCount,
                totalPages: totalPages
            })
            handleLoadedData();

        },
        crateItem: (dataForm) => {
            Requests.createItem({ body: onSubmitEventFormPanel(), ct: 'json', tenant: clientName,  op: 'b',
            infoModal:{
            title:controlsPage?.lblSuccessfulProcess?.value,
            description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
            }
         })    
            //window.location.reload()
        },
        updateItem: (dataForm, id) => {
            Requests.confirmRequest({
                body: onSubmitEventFormPanel(), id: id, ct: "json", tenant: clientName, option: 'updateItem', op: 'b',
                infoModal: {
                    title: controlsPage?.lblSuccessfulProcess?.value,
                    description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                }
            })
        },
        crateItemAssociateSchema: (dataForm) => {
            RequestsAssociate.createItem({ body: onSubmitEventPanelForm(), ct: 'json', tenant: clientName, op: 'b',
            infoModal: {
                title: controlsPage?.lblSuccessfulProcess?.value,
                description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
            }
         })
        },
        updateItemAssociateSchema: (dataForm) => {
            RequestsAssociate.confirmRequest({
                body: onSubmitEventPanelForm(), id: editSchema.id, ct: 'json', tenant: clientName, option: 'updateItem', op: 'b',
                infoModal: {
                    title: controlsPage?.lblSuccessfulProcess?.value,
                    description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                }
            })
            //window.location.reload()
        },
        deleteItem: () => {
            idDomainSelect.forEach(element => RequestsDelete.confirmRequest({
                id: element.id, tenant: clientName, option: 'updateItem', op: 'b',
                infoModal: {
                    title: controlsPage?.lblDisabled?.value,
                    description: controlsPage?.lblItHbeenSuccessfullyDisabled?.value
                }
            }))
            //window.location.reload()
        },


    }
    const actionOnChange = (data) => {
        if (data.id === "alias") {
            if (data.selectedItem !== undefined ) {
                setAlias(data.selectedItem)
            }else if(data.defaulvalue !== ""){
                setAlias(data.defaulvalue)
            }else{
                setAlias("")
            }
        }
        if (data.id === "mailHost") {
            if (data.selectedItem !== undefined ) {
                setMailHost(data.selectedItem)
            }else if(data.defaulvalue !== ""){
                setMailHost(data.defaulvalue)
            }else{
                setMailHost("")
            }
        }
        if (data.id === "mailAddress") {
            if (credential === false) {
                if (data.selectedItem !== undefined ) {
                    setMailAddress(data.selectedItem)
                }else if(data.defaulvalue !== ""){
                    setMailAddress(data.defaulvalue)
                }else{
                    setMailAddress("")
                }
            }
        }
        if (data.id === "mailPassword") {
            if (data.selectedItem !== undefined ) {
                setMailPassword(data.selectedItem)
            }else{
                setMailPassword("")
            }
        }
        if (data.id === "mailPort") {
            if (data.selectedItem !== undefined ) {
                setMailPort(data.selectedItem)
            }else if(data.defaulvalue !== ""){
                setMailPort(data.defaulvalue)
            }else{
                setMailPort("")
            }
        }
        if (data.id === "mailSSL") {
            if (data.selectedItem == '') {
                setMailSSL(false)
            } else {
                setMailSSL(data.selectedItem)
            }
        }
        if (data.id === "defaultCredentials") {
            if (data.selectedItem == '') {
                data.selectedItem = false
                setDefaultCredentials(data.selectedItem)
            } else {
                setDefaultCredentials(data.selectedItem)
            }
        }
        if (data.id === "requestSecurityQuestions") {
            if (data.selectedItem !== undefined ) {
                setRequestSecurityQuestions(data.selectedItem)
            }else if(data.defaulvalue !== ""){
                setRequestSecurityQuestions(data.defaulvalue)
            }else{
                setRequestSecurityQuestions("")
            }
        }
        if (data.id === "numberSecurityQuestions") {
            if (data.selectedItem !== undefined ) {
                setNumberSecurityQuestions(data.selectedItem)
            }else if(data.defaulvalue !== ""){
                setNumberSecurityQuestions(data.defaulvalue)
            }else{
                setNumberSecurityQuestions("")
            }
        }
        if (data.id === "sessionTime") {
            setSessionTime(data)
        }
        if (data.id === "dailyUserRecoveryLimit") {
            if (data.selectedItem !== undefined ) {
                setDailyUserRecoveryLimit(data.selectedItem)
            }else if(data.defaulvalue !== ""){
                setDailyUserRecoveryLimit(data.defaulvalue)
            }else{
                setDailyUserRecoveryLimit("")
            }

        }

        if (data.id === "idSchema") {
            if (data.selectedItem !== undefined ) {
            setIdSchema(data.selectedItem.key)
            }else{
            setIdSchema(0)
            }
        }
        if (data.id === "defoultOu") {
            if (data.selectedItem !== undefined ) {
                setDefoultOu(data.selectedItem)
            }else{
                setDefoultOu("")
            }
        }
        if (data.id === "serviceUser") {
            if (data.selectedItem !== undefined ) {
                setServiceUser(data.selectedItem)
            }else{
                setServiceUser("")
            }
        }
        if (data.id === "isPrimary") {
            if (data.selectedItem !== undefined) {
                setIsPrimary(data.selectedItem.key)
            }
            else{
                setIsPrimary(false)
                }
        }
        if (data.id === "servicePassword") {
            if (data.selectedItem !== undefined ) {
                setServicePassword(data.selectedItem)
            }else{
                setServicePassword("")
            }
        }
        if (data.id === "enableGroupMFA") {
            if (data.selectedItem !== undefined ) {
                setGroupToEnableMFA(data.selectedItem)
            }else{
                setGroupToEnableMFA("")
            }
        }
        if (data.id === "disableGroupMFA") {
            if (data.selectedItem !== undefined ) {
                setGroupToDisableMFAPerDays(data.selectedItem)
            }else{
                setGroupToDisableMFAPerDays("")
            }
        }
    }
    const onSubmitEventFormPanel = (data) => {
        let dataForm = {
            idClient: idClient,
            alias: alias,
            mailHost: mailHost,
            mailAddress: mailAddress,
            mailPassword: mailPassword ? mailPassword : '',
            mailPort: mailPort,
            mailSSL: mailSSL,
            defaultCredentials: defaultCredentials,
            requestSecurityQuestions: requestSecurityQuestions,
            numberSecurityQuestions: numberSecurityQuestions,
            sessionTime: sessionTime.selectedItem ? sessionTime.selectedItem : sessionTime.defaulvalue,
            dailyUserRecoveryLimit: dailyUserRecoveryLimit,
            isEnable:isEnable,
        }
        return dataForm
    }
    useEffect(()=>{
      if(credential){  
        if (alias !== undefined && alias !== null && alias.length>0 ) {
            if (mailHost !== undefined && mailHost !== null && mailHost.length>0 ) {
                if (mailPort !== undefined && mailPort !== null && mailPort!=="" ) {
                    if (requestSecurityQuestions !== undefined && requestSecurityQuestions !== null && requestSecurityQuestions!=="" && requestSecurityQuestions>0  ) {
                        if (numberSecurityQuestions !== undefined && numberSecurityQuestions !== null && numberSecurityQuestions!=="" && numberSecurityQuestions>0  ) {
                            if (dailyUserRecoveryLimit !== undefined && dailyUserRecoveryLimit !== null && dailyUserRecoveryLimit!=="" && dailyUserRecoveryLimit>0  ) {
                                setButtonDisable(false)    
                            }
                            else{
                                setButtonDisable(true)
                            }
                        }
                        else{
                            setButtonDisable(true)
                        }
                    }
                    else{
                        setButtonDisable(true)
                }
                }else{
                    setButtonDisable(true)
            }
            }else{
                setButtonDisable(true)
        }

        }else{
                setButtonDisable(true)
        }}
        else{
            if (alias !== undefined && alias !== null && alias.length>0 ) {
                if (mailHost !== undefined && mailHost !== null && mailHost.length>0 ) {
                    if (mailAddress !== undefined && mailAddress !== null && mailAddress.length>2 && mailAddress.includes("@")) {
                        if (mailPassword !== undefined && mailPassword !== null && mailPassword.length>0 ) {
                            if (mailPort !== undefined && mailPort !== null && mailPort !=="" ) {
                                if (requestSecurityQuestions !== undefined && requestSecurityQuestions !== null && requestSecurityQuestions!=="" && requestSecurityQuestions>0  ) {
                                    if (numberSecurityQuestions !== undefined && numberSecurityQuestions !== null && numberSecurityQuestions!=="" && numberSecurityQuestions>0  ) {
                                        if (dailyUserRecoveryLimit !== undefined && dailyUserRecoveryLimit !== null && dailyUserRecoveryLimit!=="" && dailyUserRecoveryLimit>0  ) {
                                            setButtonDisable(false)    
                                        }
                                        else{
                                            setButtonDisable(true)
                                        }
                                    }
                                    else{
                                            setButtonDisable(true)
                                    }
                                }
                                else{
                                    setButtonDisable(true)
                            }
                            }else{
                                setButtonDisable(true)
                        }
                    }else{
                        setButtonDisable(true)
                    }
                }else{
                        setButtonDisable(true)
                }
                }else{
                    setButtonDisable(true)
            }
            }else{
                    setButtonDisable(true)
            }
        }
    },[credential, alias,mailHost,mailAddress,mailPassword,mailPort,numberSecurityQuestions,dailyUserRecoveryLimit])
    const actionOnClick = (data) => { };
    
    useEffect(async () => {
        const schema = await RequestsSchemas.getItem({
            tenant: clientName,
            param: { idLanguage: JSON.parse(CookiesJS.get("dataUser")).user.idLanguage, pageSize:100 },
        })
        schema.data.data.map(sch => {
            arraySchema.push({ key: sch.id, text: sch.domainAddress })
        })
        setArraySchema(arraySchema)
    }, []);
    
    const onSubmitEventPanelForm = (data) => {
        let dataForm = {
            idDomain: idDomainSelect[0].id,
            idSchema: idSchema,
           /// idSchema: idSchemaselectedItem ? idSchema.selectedItem.key : idSchema.selectedItem,
            isPrimary: isPrimary,
            defoultOu: defoultOu,
            serviceUser: serviceUser,
            servicePassword: servicePassword,
            groupToEnableMFA: groupToEnableMFA,
            groupToDisableMFAPerDays: groupToDisableMFAPerDays,
        }
        return dataForm
    }
    useEffect(() => {
            if (editSchema != null) {
            setCampFormAssociate(changeLangForm([
                {
                    id: 0,
                    label: 'Dominio',
                    key: 'aliasAssociate',
                    type: 'text2',
                    placeholder: '',
                    defaultValue: domainName,
                    disabled: isDisable,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: domainName,
                    readOnly: true,
                    idHtml: "lblDomainName",
                    className: 'w10',
                },
                {
                    id: 1,
                    label: 'Seleccione esquema',
                    type: 'select2',
                    placeholder: 'Selecciona',
                    key: 'idSchema',
                    className: 'select2',
                    options: arraySchema,
                    idHtml: "lblSelectScheme",
                    value:idSchema
                },
                {
                    id: 2,
                    label: 'Unidad organizacional',
                    type: 'text2',
                    key: 'defoultOu',
                    placeholder: '',
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    description: "",
                    onRenderLabel: null,
                    defaultValu: "null",
                    className: 'select2',
                    value: editSchema ? editSchema.defoultOu : defoultOu,
                    idHtml: "lblOrganizationalUnit"
                },
                {
                    id: 3,
                    label: 'Cuenta de servicio',
                    key: 'serviceUser',
                    type: 'text2',
                    placeholder: '',
                    typeInput: "text",
                    onRenderLabel: null,
                    className: 'select2',
                    value: editSchema ? editSchema.serviceUser : serviceUser,
                    isRequired: true,
                    idHtml: "lblServiceAccount"
                },
                {
                    id: 4,
                    label: 'Contraseña',
                    type: 'text2',
                    onRenderLabel: null,
                    placeholder: 'Selecciona',
                    defaultValue: '',
                    key: 'servicePassword',
                    typeInput: 'password',
                    className: 'select2',
                    canRevealPassword: true,
                    idHtml: "lblMailPasword"
                },
                {
                    id: 5,
                    label: 'Grupo de habilitación del MFA de Office 365',
                    key: 'enableGroupMFA',
                    type: 'text2',
                    placeholder: '',
                    onRenderLabel: null,
                    className: 'select2',
                    idHtml: "lblEnablingGroup", 
                    value:editSchema ? editSchema.groupToEnableMFA : groupToEnableMFA,
                },
                {
                    id: 6,
                    label: 'Grupo de inhabilitación por días para el MFA de Office 365',
                    key: 'disableGroupMFA',
                    type: 'text2',
                    placeholder: '',
                    onRenderLabel: null,
                    className: 'select2',
                    idHtml: "lblDisqualificationGroupByDays",
                    value:editSchema ? editSchema.groupToDisableMFAPerDays : groupToDisableMFAPerDays,
                },
                {
                    id: 7,
                    label: '¿Es esquema principal?',
                    type: 'select2',
                    placeholder: 'Selecciona',
                    defaultValue: false,
                    key: 'isPrimary',
                    isRequired: false,
                    className: 'select2',
                    value: isPrimary,
                    options: [{ key: false, text: controlsPage?.lblNo?.value },
                        { key: true, text: controlsPage?.lblYes?.value }],
                    idHtml: "lblIsTheMainSchematic"
                },
            ], controlsPage))
            setIdSchema(editSchema.idSchema)
            setIsPrimary(editSchema.isPrimary)
            setValidator(false)
        }else{
            setDefoultOu("")
            setServiceUser("")
            setGroupToEnableMFA("")
            setGroupToDisableMFAPerDays("")
            dismissPanelAssociate2(true)
            setValidator(true)
        }
    }, [editSchema])
 
    useEffect(()=>{
      if(validator){  
        if(idSchema !== undefined && idSchema !== null && idSchema>0){
            if (serviceUser !== undefined && serviceUser !== null && serviceUser.length>0  && serviceUser !== " " ) {
                if (defoultOu !== undefined && defoultOu !== null && defoultOu.length>0  && defoultOu !== " " ) {
                    if (servicePassword !== undefined && servicePassword !== null && servicePassword.length>0 ) {
                        setButtonDisableAssociate(false)
                    } else{
                        setButtonDisableAssociate(true)
                    }
                } else{
                    setButtonDisableAssociate(true)
                }
            } else{
                setButtonDisableAssociate(true)
            }
        }else{
            setButtonDisableAssociate(true)
        }
    } else{
        if (servicePassword !== undefined && servicePassword !== null && servicePassword.length>0 ) {
            if (serviceUser !== undefined && serviceUser !== null && serviceUser.length>0 ) {
                if (defoultOu !== undefined && defoultOu !== null && defoultOu.length>0 ) {
                    setButtonDisableAssociate(false)
                } else{
                    setButtonDisableAssociate(true)
                }
            } else{
                setButtonDisableAssociate(true)
            }
        }
        else{
        setButtonDisableAssociate(true)
        }
    }
        
    },[validator,idSchema, serviceUser, defoultOu,servicePassword])

    useEffect(() => {
        //setCampForm(current=>changeLangForm(current))
        if (credential === true) {
            // setCampForm(current=>changeLangForm(current))
            setCampForm(changeLangForm([
                {
                    id: 0,
                    label: 'Nombre del dominio',
                    key: 'alias',
                    type: 'text2',
                    placeholder: '',
                    //max: 30,
                    max: controlsPageValue?.txtDomainName?.maxlength,
                    defaultValue: formCred ? formCred.alias : '',
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    description: "",
                    onRenderLabel: null,
                    defaultValu: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblDomainName"
                },
                {
                    id: 1,
                    label: 'Mail host',
                    // max: 100,
                    max: controlsPageValue?.txtMailHost?.maxlength,
                    key: 'mailHost',
                    type: 'text2',
                    placeholder: '',
                    defaultValue: formCred ? formCred.mailHost : '',
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    description: "",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblMailHost"
                },
                {
                    id: 2,
                    label: 'Mail address',
                    key: 'mailAddress',
                    max: controlsPageValue?.txtMailAddress?.maxlength,
                    type: 'text2',
                    placeholder: '',
                    defaultValue: '',
                    disable: credential,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    description: "",
                    onRenderLabel: null,
                    isRequired: false,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblMailAddress"
                },
                {
                    id: 3,
                    label: 'Mail password',
                    max: controlsPageValue?.txtMailPasword?.maxlength,
                    key: 'mailPassword',
                    type: 'text2',
                    placeholder: '',
                    disable: credential,
                    multiline: false,
                    resizable: false,
                    typeInput: "password",
                    autoComplete:"new-password",
                    description: "",
                    onRenderLabel: null,
                    isRequired: false,
                    defaultValu: "",
                    canRevealPassword: true,
                    className: 'select2',
                    idHtml: "lblMailPasword"
                },
                {
                    id: 4,
                    label: 'Mail port',
                    type: 'text2',
                    key: 'mailPort',
                    max: 50,
                    placeholder: '',
                    defaultValue: mailPortValue,
                    value: mailPortValue,
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    isNumeric: true,
                    typeInput: "text",
                    description: "",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblMailPort"
                },
                {
                    id: 5,
                    label: 'Mail SSL',
                    type: 'toggle',
                    placeholder: 'Selecciona',
                    disabled: false,
                    key: 'mailSSL',
                    defaultValue: mailSSL,
                    checked: mailSSL,
                    idHtml: "lblMailSSL"

                },
                {
                    id: 6,
                    label: 'Default credentials',
                    type: 'toggle',
                    placeholder: 'Selecciona',
                    disabled: false,
                    key: 'defaultCredentials',
                    defaultValue: credential,
                    checked: credential,
                    idHtml: "lblDefauldCredentials"
                },
                {
                    id: 7,
                    typeInput: "text",
                    label: 'Total de preguntas de seguridad por usuario.',
                    type: 'text2',
                    defaultValue: requestSecurityQuestionsValue,
                    value: requestSecurityQuestionsValue,
                    key: 'requestSecurityQuestions',
                    min: 0,
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    isNumeric: true,
                    description: "",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblTotalUserSecurityQuestions"
                },
                {
                    id: 8,
                    label: 'Número de preguntas para el desafío de usuario.',
                    type: 'text2',
                    placeholder: '',
                    defaultValue: formCred ? formCred.numberSecurityQuestions : '',
                    key: 'numberSecurityQuestions',
                    defaultValue: numberSecurityQuestionsValue,
                    value: numberSecurityQuestionsValue,
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    isNumeric: true,
                    description: "",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblNumberQuestionsChallengeTheUser"
                },
                {
                    id: 9,
                    label: 'Tiempo de sesión.',
                    type: 'text2',
                    placeholder: '',
                    defaultValue: formCred ? formCred.sessionTime : '',
                    key: 'sessionTime',
                    value: sessionTimeValue,
                    defaultValue: sessionTimeValue,
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    isNumeric: true,
                    description: "",
                    onRenderLabel: null,
                    isRequired: false,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblSessionTime"
                },
                {
                    id: 10,
                    label: 'Numero de accesos.',
                    type: 'text2',
                    placeholder: '',
                    defaultValue: formCred ? formCred.dailyUserRecoveryLimit : '',
                    key: 'dailyUserRecoveryLimit',
                    value: dailyUserRecoveryLimitValue,
                    defaultValue: dailyUserRecoveryLimitValue,
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    isNumeric: true,
                    description: "",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblAccessNumber"
                }

            ], controlsPage));
        } else {
            setCampForm(changeLangForm([
                {
                    id: 0,
                    label: 'Nombre del dominio',
                    key: 'alias',
                    type: 'text2',
                    placeholder: '',
                    //max: 30,
                    max: controlsPageValue?.txtDomainName?.maxlength,
                    defaultValue: formCred ? formCred.alias : '',
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    description: "",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblDomainName"
                },
                {
                    id: 1,
                    label: 'Mail host',
                    //max: 100,
                    max: controlsPageValue?.txtMailHost?.maxlength,
                    key: 'mailHost',
                    type: 'text2',
                    placeholder: '',
                    defaultValue: formCred ? formCred.mailHost : '',
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    description: "",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblMailHost"
                },
                {
                    id: 2,
                    label: 'Mail address',
                    max: controlsPageValue?.txtMailAddress?.maxlength,
                    key: 'mailAddress',
                    type: 'text2',
                    placeholder: '',
                    defaultValue: formCred ? formCred.mailAddress : '',
                    disable: credential,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    description: "",
                    onRenderLabel: null,
                    isRequired: false,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblMailAddress"
                },
                {
                    id: 3,
                    label: 'Mail password',
                    max: controlsPageValue?.txtMailPasword?.maxlength,
                    key: 'mailPassword',
                    type: 'text2',
                    placeholder: '',
                    disable: credential,
                    multiline: false,
                    resizable: false,
                    typeInput: "password",
                    description: "",
                    autoComplete:"new-password",
                    onRenderLabel: null,
                    isRequired: false,
                    defaultValu: "",
                    canRevealPassword: true,
                    className: 'select2',
                    idHtml: "lblMailPasword"
                },
                {
                    id: 4,
                    label: 'Mail port',
                    type: 'text2',
                    key: 'mailPort',
                    max: 50,
                    placeholder: '',
                    defaultValue: mailPortValue,
                    value: mailPortValue,
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    isNumeric: true,
                    description: "",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblMailPort"
                },
                {
                    id: 5,
                    label: 'Mail SSL',
                    type: 'toggle',
                    placeholder: 'Selecciona',
                    disabled: false,
                    key: 'mailSSL',
                    required: true,
                    defaultValue: mailSSL,
                    checked: mailSSL,
                    idHtml: "lblMailSSL"
                },
                {
                    id: 6,
                    label: 'Default credentials',
                    type: 'toggle',
                    placeholder: 'Selecciona',
                    disabled: false,
                    required: true,
                    key: 'defaultCredentials',
                    defaultValue: credential,
                    checked: credential,
                    idHtml: "lblDefauldCredentials"
                },
                {
                    id: 7,
                    typeInput: "text",
                    label: 'Total de preguntas de seguridad por usuario.',
                    type: 'text2',
                    defaultValue: formCred ? formCred.requestSecurityQuestions : '',
                    key: 'requestSecurityQuestions',
                    defaultValue: requestSecurityQuestionsValue,
                    value: requestSecurityQuestionsValue,
                    oninput: "validity.valid||(value='');",
                    min: 0,
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    isNumeric: true,
                    description: "",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblTotalUserSecurityQuestions"
                },
                {
                    id: 8,
                    label: 'Número de preguntas para el desafío de usuario.',
                    type: 'text2',
                    placeholder: '',
                    defaultValue: formCred ? formCred.numberSecurityQuestions : '',
                    key: 'numberSecurityQuestions',
                    defaultValue: numberSecurityQuestionsValue,
                    value: numberSecurityQuestionsValue,
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    isNumeric: true,
                    description: "",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblNumberQuestionsChallengeTheUser"
                },
                {
                    id: 9,
                    label: 'Tiempo de sesión.',
                    type: 'text2',
                    placeholder: '',
                    defaultValue: formCred ? formCred.sessionTime : '',
                    key: 'sessionTime',
                    value: sessionTimeValue,
                    defaultValue: sessionTimeValue,
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    isNumeric: true,
                    description: "",
                    onRenderLabel: null,
                    isRequired: false,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblSessionTime"
                },
                {
                    id: 10,
                    label: 'Numero de accesos.',
                    type: 'text2',
                    placeholder: '',
                    defaultValue: formCred ? formCred.dailyUserRecoveryLimit : '',
                    key: 'dailyUserRecoveryLimit',
                    value: dailyUserRecoveryLimitValue,
                    defaultValue: dailyUserRecoveryLimitValue,
                    disabled: false,
                    multiline: false,
                    resizable: false,
                    typeInput: "text",
                    isNumeric: true,
                    description: "",
                    onRenderLabel: null,
                    isRequired: true,
                    defaultValu: null,
                    className: 'select2',
                    idHtml: "lblAccessNumber"
                }


            ], controlsPage))
            campForm[5] = {
                id: 5,
                label: 'Mail SSL',
                type: 'toggle',
                placeholder: 'Selecciona',
                disabled: false,
                required: true,
                key: 'mailSSL',
                defaultValue: mailSSL,
                checked: mailSSL,
                idHtml: "lblMailSSL"
            }
        }
    }, [credential])
    
    useEffect(()=>{
        setCredential(defaultCredentials)
    },[defaultCredentials])
    
    useEffect(() => {
        if (idDomainSelect[0]) {
            setFormCred(idDomainSelect[0])
            setCredential(idDomainSelect[0].defaultCredentials)
        } else {
            setFormCred()
            setCredential(false)
        }
    }, [idDomainSelect[0]])

    campForm[0] = Object.assign(campForm[0], {max: controlsPageValue?.txtDomainName?.maxlength})
    campForm[1] = Object.assign(campForm[1], {max: controlsPageValue?.txtMailHost?.maxlength})
    campForm[2] = Object.assign(campForm[2], {max: controlsPageValue?.txtMailAddress?.maxlength})
    campForm[3] = Object.assign(campForm[3], {max: controlsPageValue?.txtMailPasword?.maxlength})

    campFormAssociate[2] = Object.assign(campFormAssociate[2], {max: controlsPageValue?.txtOrganizationalUnit?.maxlength})
    campFormAssociate[3] = Object.assign(campFormAssociate[3], {max: controlsPageValue?.txtServiceAccount?.maxlength})
    // campFormAssociate[4] = Object.assign(campFormAssociate[4], {max: controlsPageValue?.txtMailPasword?.maxlength})
    // campFormAssociate[5] = Object.assign(campFormAssociate[5], {max: controlsPageValue?.txtMailPasword?.maxlength})
    // campFormAssociate[6] = Object.assign(campFormAssociate[6], {max: controlsPageValue?.txtMailPasword?.maxlength})


    useEffect(() => {
        if (Object.entries(controlsPage).length > 0) {
            methosRequests.getDomains(filter)
        }
        if (itemsDomain != null) {
        }
    }, [filter, controlsPage])




    /************************
     * @resturns 
     ************************/

    const numberPage = CountPages(domainMeta?.totalPages)
    return (
        <>
            {itemsDomain != null && Object.entries(controlsPage).length > 0 ?
                <>
                    <SubMenu
                        contentcontrols={
                            !deleteD && !edit && !associateSchema ?
                            [
                                {
                                    key: 'Add',
                                    cacheKey: 'cacheAdd',
                                    text:controlsPage?.OnClickNewDomain.value,
                                    iconProps:{ iconName: 'Add' },
                                    onClick:() => { methodsDomain.voidCamps(); openPanel(); }
                                }
                                
                            ]
                            :
                            [
                                {
                                    key: 'Edit',
                                    cacheKey: 'cacheEdit',
                                    text:controlsPage?.OnClickEditDomain.value,
                                    iconProps:{ iconName: 'Edit' },
                                    onClick:() => openPanel()
                                },
                                {
                                    key: 'delete',
                                    cacheKey: 'cacheDelete',
                                    text:controlsPage?.OnClickDesableDomain.value,
                                    iconProps:{ iconName: 'delete' },
                                    onClick:() => methosRequests.deleteItem()
                                },
                                {
                                    key: 'list',
                                    cacheKey: 'cacheList',
                                    text:controlsPage?.OnClickAssciateSchsDmain.value,
                                    iconProps:{ iconName: 'list' },
                                    onClick:() => openPanelAssociate2()
                                }
                            ]
                        }
                        contentcontrolsTwo={
                            <>
                            <Panel isOpen={isOpen}
                                    closeButtonAriaLabel="Close"
                                    isHiddenOnDismiss={true}
                                    type={PanelType.medium}
                                    hasCloseButton={false}
                                >

                                    {isOpen ? <ContainerLargeForm
                                        dataForm={(e) => {
                                            edit ? methosRequests.updateItem(e, idDomainSelect[0].id) : methosRequests.crateItem(e);
                                            //associateSchema ? methosRequests.associateSchema
                                        }}
                                        formTitle={edit ? controlsPage?.lblEditDomain.value : controlsPage?.lblNewDomain.value}
                                        Items={campForm}
                                        actionOnChange={actionOnChange}
                                        actionOnClick={actionOnClick}
                                        isDisableOpt={isDisable}
                                        defaultValueInput={defaultValueInput}
                                        //changeDefaultCredentials={changeDefaultCredentials}
                                        defaultSelectedKeys={defaultSelectedKeys}
                                        closePanel={dismissPanel}
                                        onSubmitEventFormPanel={onSubmitEventFormPanel}
                                        buttonDisable={buttonDisable}
                                        controlsPage={controlsPage}
                                        /> : ''}    
                                </Panel>
                                <Panel isOpen={isOpenAssociate}
                                    closeButtonAriaLabel="Close"
                                    isHiddenOnDismiss={true}
                                    type={PanelType.custom}
                                    customWidth={'700px'}
                                    hasCloseButton={false}
                                >
                                    {isOpenAssociate ? <ContainerForm
                                        dataForm={(e) => {
                                            editSchema ? methosRequests.updateItemAssociateSchema(e) : methosRequests.crateItemAssociateSchema(e)
                                        }}
                                        infoExt={idDomainSelect[0].id}
                                        formEdit={(e) => { setEditSchema(e) }}
                                        formTitle={editSchema ? controlsPage?.lblEditSchemas?.value : controlsPage?.lblAssciateSchsDmain.value}
                                        Items={campFormAssociate}
                                        actionOnChange={actionOnChange}
                                        actionOnClick={actionOnClick}
                                        defaultValueInput={defaultValueInput}
                                        //changeDefaultCredentials={changeDefaultCredentials}
                                        isDisableOpt={isDisable}
                                        closePanel={()=>{dismissPanelAssociate2(false)}}
                                        titleTable={controlsPage?.lblAssociatedSchemes.value}
                                        defaultSelectedKeys={defaultSelectedKeys}
                                        onSubmitEventFormPanel={() => { }}
                                        buttonDisable={buttonDisableAssociate}
                                        arraySchema={arraySchema}
                                        keyForm="associateSchema"
                                    /> : ''}
                                </Panel>
                            </>
                        }
                    />
                    <div className="admin--body-container">
                        <ListHeader
                            showReport={false}
                            subRute={controlsPage?.lblUrlDomainAdministration.value}
                            subRute3={controlsPage?.lblDomainAdministration.value}
                            title={controlsPage?.lblDomainAdministration.value}
                            hideSearch={true}
                            onChange={(e) => {
                                setFilter({
                                    ...filter,
                                    pageSize: e.target.value,
                                    pageNumber: filter.pageNumber === "0" ? "0" : "1"
                                });
                            }}
                            InputChange={(e) => {
                                setFilter({
                                    ...filter,
                                    search: e,
                                    pageNumber: filter.pageNumber === "0" ? "0" : "1"
                                })
                            }}
                        />
                        <DetailsListBext
                            enableShimmer={isLoadingData}
                            listKey="listSelect"
                            selectionMode={1}
                            items={itemsDomain}
                            //renderItemColumn={_defaultRenderItemColumn}
                            columnTitles={columnTitles}
                            onSelect={methodsDomain.getDataItem}
                            onPageChange={page => {
                                if (page !== +filter.pageNumber) {
                                    setFilter({
                                        ...filter,
                                        pageNumber: String(page),
                                    });
                                }
                            }}
                            pagination={pagination}
                        />
                    </div>
                </>
                :
                <></>}
        </>
    );
}

export default Domain;