import React, { useContext, useEffect, useState } from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import CookiesJS from 'js-cookie';
import { actualiza, consulta, getText, RequestFetch } from '../../../utils/Requests/requests.Controller';
import ContainerBaseLoading from '../../containers/baseLoading/containerBaseLoading.component';
import LenguajeContext from '../../../context/Lenguaje/lenguajeContext';

/**
 *@author Yeison Quiroga(14-04-2021)
 *@version 1.0
 *@lastCommit 
 */

const SubMenu = (props)=>{

    /************************
     * @arguments 
     ************************/
     const requestLang = new RequestFetch(`Languages`)
     const nameClient = CookiesJS.get('nameClient');
     const userText = new RequestFetch('Pages/userTexts')
     const dataUserSession = JSON.parse(CookiesJS.get("dataUser"));
     const idLngCookie = JSON.parse(CookiesJS.get("idLng"));
     const { idClient } = JSON.parse(CookiesJS.get("dataClient"));
     const [lng, setLng] = useState({})
     const [itemsLng, setItemsLng] = useState([])
     const[commandItems, setComandItems]=useState([])
     const [isLoad, setIsLoad] = useState(false)
     const [idLng, setIdLng] = useState(dataUserSession.user.idLanguage)
     const [itemsFiles, setItemFiles] = useState([])
     const [textOnPages, setTextOnPages] = useState({})
     const [controlsPage, setControlsPage] = useState({})
     const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
     const [contentcontrols, setContentcontrols] = useState([])

    const files = async(path) =>{
        const miUrl = new URL(path);
        if (miUrl) {
            window.open(miUrl);
        }
    }


    const openDB = () => {
        return new Promise((resolve, reject) => {
          const request = indexedDB.open('textListApp',1);
          
          request.onsuccess = (event) => {
            const db = event.target.result;
            resolve(db);
          };
          request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains("textF")) {
              db.createObjectStore("textF", { keyPath: "clave" });
            }
          };
          request.onerror = (event) => {
            reject(event.target.error);
          };
        });
    };


    useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            stateLanguage.lenguaje.map((users) => {
                if (users.path == 'controls') {
                    users.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                    })
                }
                setControlsPage(controlsPage)
            })
        }else{
            if (Object.entries(textOnPages).length > 0) {
                
                textOnPages.ssoPagesData.map((users) => {
                    if (users.path == 'controls') {
                        users.webcontrolspages.map((data) => {
                            controlsPage[data.idHtml] = data.languagewebcontrols[0];
                        })
                    }
                    setControlsPage(controlsPage)
                })
            }
        }
        // })
    }, [textOnPages, stateLanguage])

    useEffect(async() => {
        // const res = await userText.getItem({tenant:nameClient,
        //     param:{
        //         idUser:dataUserSession.user.id,
        //         idLanguage:idLng
        //         }
        //     })

        const lang = await requestLang.getItem({
            id:'',
            tenant:nameClient,
            param: {idClient:idClient, idLanguage:idLngCookie?idLngCookie:dataUserSession.user.idLanguage},
            headerEx: {idLanguageUser:idLngCookie?idLngCookie:dataUserSession.user.idLanguage}
        })
        if (Object.entries(lang).length > 0) {
            let arr = []
            lang.data.data.forEach(lng=>{
                arr.push({key:lng.id, text:lng.name, idClient:lng.idClient,  onClick:()=>idiom(lng.id)})
            })
            setItemsLng(arr)
        }

        const requestFiles= new RequestFetch (`Access/file/${idClient}`);
        const fileResponse = {
            idLanguage:idLngCookie?idLngCookie:dataUserSession.user.idLanguage,
            isToAdmin:true,
        };
        const dataResponse = await requestFiles.getItem({
            param: fileResponse,
            tenant: nameClient,
            ct:'json',
            reload:true,
            cancelIcon:true,
            headerEx: {idLanguageUser:idLngCookie?idLngCookie:dataUserSession.user.idLanguage}
        })
        if (Object.entries(dataResponse).length > 0) {
            let arr = []
            dataResponse.data.data.forEach(file=>{
                arr.push({key:file.id, text:file.name, idClient:file.idClient, onClick:()=>files(file.path)})
            })
            setItemFiles(arr)
        }
    }, [idLng, ])
    

    const idiom = async(id) =>{
        if (dataUserSession.user.idLanguage != id) {
            setIsLoad(true)
            setIdLng(id)
            dataUserSession.user.idLanguage = id
            const newCookie = JSON.stringify(dataUserSession)
            CookiesJS.set("dataUser",newCookie)
            CookiesJS.set("idLng",id)
            const promise = await userText.getItem({
                param: {idLanguage: id, idUser: dataUserSession.user.id}, 
                tenant: nameClient
            });
            getLenguaje(promise.data.data)
            getText(promise.data.data)
            if (promise) {
                openDB().then((db) => {
                    actualiza(db,{clave:'textUsr' ,ssoPagesData:promise.data.data})
                })
                .catch((error) => {
                console.error("Error al abrir la base de datos:", error);
                });
            }
            setIsLoad(false)
        }

    }
    useEffect(() => {
        // setContentcontrols([
        //     {
        //       key: 'newItem',
        //       text: 'New',
        //       cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        //       iconProps: { iconName: 'Add' }
        //     },
        //     {
        //         key: 'ddffdfd',
        //         text: 'Newdffddf',
        //         cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        //         iconProps: { iconName: 'Add' },
        //         subMenuProps: {
        //           items: [
        //             {
        //               key: 'emailMessage',
        //               text: 'Email message',
        //               iconProps: { iconName: 'Mail' },
        //               ['data-automation-id']: 'newEmailButton', // optional
        //             },
        //             {
        //               key: 'calendarEvent',
        //               text: 'Calendar event',
        //               iconProps: { iconName: 'Calendar' },
        //             },
        //           ],
        //         },
        //       },
        //     {
        //       key: 'upload',
        //       text: 'Upload',
        //       iconProps: { iconName: 'Upload' },
        //       subMenuProps: {
        //         items: [
        //           {
        //             key: 'uploadfile',
        //             text: 'File',
        //             preferMenuTargetAsEventTarget: true,
        //             onClick: ()=>{console.log('file')}
        //           },
        //           {
        //             key: 'uploadfolder',
        //             text: 'Folder',
        //             preferMenuTargetAsEventTarget: true,
        //             onClick: ()=>{console.log('Folder')}
        //           },
        //         ],
        //       },
        //     },
        //     {
        //       key: 'share',
        //       text: 'Share',
        //       iconProps: { iconName: 'Share' },
        //       onClick: () => console.log('Share'),
        //     },
        //     {
        //       key: 'download',
        //       text: 'Download',
        //       iconProps: { iconName: 'Download' },
        //       onClick: () => console.log('Download'),
        //     },
        //   ]);
        setContentcontrols(props.contentcontrols)
    }, [props.contentcontrols])
    
    
    useEffect(() => {
        
        setComandItems([
            {
                key:'Ayuda', 
                text:controlsPage.OnClickHelp?.value,
                iconProps: {iconName: 'Info'},
                subMenuProps:{
                    items:itemsFiles
                }
            }, 
            {
                key:'Lenguaje', 
                text:controlsPage.slcLanguage?.value,
                iconProps: {iconName: 'Flag'},
                subMenuProps:{
                    items: itemsLng
                }
            }, 
        ]);

    }, [lng, itemsLng, itemsFiles, controlsPage, ])


    /************************
     * @methods 
     ************************/

    /************************
     * @hooks 
     ************************/
    

    /************************
     * @resturns 
     ************************/
        return(
            <>
            {isLoad && (
                <ContainerBaseLoading />                    
            )
            }
            <div className="header--container-submenu">
                <div className="submenu--container-modal">  
                    {contentcontrols && <CommandBar items={contentcontrols} className='menu-items'/>}
                    {props.contentcontrolsTwo?props.contentcontrolsTwo:''}
                </div>
                <div className="submenu--container-access">
                    <CommandBar items={commandItems} className="submenu--container-lang" />
                </div>
            </div>
            </>
        );
}

export default SubMenu;