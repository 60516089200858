import React, { Fragment, useContext, useEffect, useState } from 'react'
import CookiesJS from 'js-cookie';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks'

//*****Componnents*****//
    import SubMenu from '../../components/navigation/subMenu/subMenu.component'
    import ContainerLargeForm from '../../components/containers/largeForm/containerLargeForm.component'
    import ContainerBaseLoading from '../../components/containers/baseLoading/containerBaseLoading.component'
    import ListHeader from '../../components/lists/listHeader/listHeader.component'
    import ListBody from'../../components/lists/listBody/listBody.component'


//*****Methods*****//
    import Lists from '../../utils/logicRendering/lists.controller'
    import {RequestFetch} from '../../utils/Requests/requests.Controller'
    import CountPages from '../../utils/logicRendering/countPages.controller'
    import { useParams } from 'react-router-dom';
    import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';
    import LenguajeContext from '../../context/Lenguaje/lenguajeContext';
    import { changeLang, changeLangForm } from "../../utils/logicRendering/changeIdiom";

/**
 *@author Jhon Beltran(26-05-2022)
 *@version 1.0
 *@lastCommit Jhon Luna(29-06-2022)
 */

const Messages = ()=>{

    /************************
     * @arguments 
     ************************/
        //***Propertise***//
        const {clientName} = useParams()
        const [currentPages, setCurrentPage]= useState(1);
        const [deleteD, setDeleteD] = useState(false);
        const [edit, setEdit] = useState(false);
        const [filter, setFilter] = useState({ 
            pageSize: 5,
            pageNumber: "0",
            dDomain: "" 
        });
        const [idMessageSelect, setIdQuestionSelect] = useState([]);
        const [itemsMessage, setItemsMessage] = useState(null)
        const [isLoadingData,{setTrue: handleLoadingData, setFalse: handleLoadedData}] = useBoolean(false);
        const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
        const [onSelected, setOnSelected] = useState();
        const [pagination, setPagination] = useState(null);
        const [promise,setPromise] = useState([])
        const [controlsPage, setControlsPage] = useState({});
        const [controlsPageValue, setControlsPageValue] = useState({});
        const [textOnPages, setTextOnPages] = useState({})
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        
        //***Data Propertise***//
        const Requests = new RequestFetch('Exception');
        //const ResquetsDomain = new RequestFetch('Domains');
        // const [domainForm, setDomainForm] = useState(ResquetsDomain.getItem({
        //     tenant:clientName
        // }));
        const [showElements, setShowElements]= useState(5);

        // useEffect(()=>{
        //     domainForm.then(data =>{
        //         setDomainForm(data.data.data);
        //     })
        // },[])

        const [columnTitles, setColumnTitles] = useState([{
            code:{
                name:"Código",
                maxWidth: 75,
                isMultiline:true,
                idHtml: "coluCode"
            },
            name:{
                name:"Nombre",
                maxWidth:170,
                isMultiline:true,
                idHtml: "coluName"
            },
            message:{
                name:"Mensaje de usuario final",
                maxWidth:220,
                isMultiline: true,
                idHtml: "coluEndUserMessage"
            },
            creatingDate: {
              name: "Fecha de Creación",
              minWidth: 100,
              maxWidth: 200,
              isResizable: true,
              idHtml: "coluCreationDate",
            },
            creatorUser: {
              name: "Creado por",
              maxWidth: 110,
              isResizable: true,
              idHtml: "coluCreatedBy",
            },
            updatingDate: {
              name: "Fecha de Modificación",
              minWidth: 100,
              maxWidth: 200,
              isResizable: true,
              idHtml: "coluLastModification",
            },
            updateUser: {
              name: "Modificado por",
              maxWidth: 110,
              isResizable: true,
              idHtml: "lblModifiedBy"
            },
        }])
        useEffect(() => {
            let controlsPage = {};
            let controlsPageValue = {};
            if (stateLanguage.lenguaje !=0) {
                stateLanguage.lenguaje.map((pass) => {
                    if (pass.path == 'controls'|| pass.path =='domain' || pass.path == "messages-error") {
                      pass.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                        controlsPageValue[data.idHtml] = data;
                        })    
                      setControlsPage(controlsPage)
                      setControlsPageValue(controlsPageValue)
                    }              
                })          
                setColumnTitles(current => changeLang(current, controlsPage))
                setCampForm(current => changeLangForm(current, controlsPage))
            }
        
        }, [textOnPages, stateLanguage])


        const [campForm, setCampForm]= useState([
            {
                id:1, 
                key:'code', 
                label:'Código', 
                type:'text',
                placeholder:'...',
                required: true,
                disable: true,
                idHtml: "txtEditCode"
            },
            {
                id:2, 
                key:'name', 
                label:'Nombre', 
                type:'text', 
                placeholder:'...', 
                defaultValue:'',
                required: true,
                idHtml: "lblEditNameMessage"
               // max:70,
               // min:12,
            },
            {
                id:3, 
                key:'message', 
                label:'Mensaje de usuario final', 
                type:'text', 
                placeholder:'...',
                required: true,
                defaultValue:'',
                idHtml: "txtEditNameMessage"
            },
        ])
        campForm[0] = Object.assign(campForm[0], {defaultValue: onSelected ? onSelected.code : ''})
        campForm[1] = Object.assign(campForm[1], {defaultValue: onSelected ? onSelected.name : '', max: controlsPageValue?.txtEditNameMessage?.maxlength})
        campForm[2] = Object.assign(campForm[2], {defaultValue: onSelected ? onSelected.message : '', max: controlsPageValue?.txtEndUsrMssge?.maxlength})
        // campForm[0] ={           
        //         id:1, 
        //         key:'code', 
        //         label:'Código', 
        //         type:'text',
        //         required: true,
        //         defaultValue: onSelected ? onSelected.code : '' ,
        //         placeholder:'...',    
        //         disable: true,
        //         idHtml: "txtEditCode"
            
        // };
        // campForm[1] ={           
        //     id:2, 
        //     key:'name', 
        //     label:'Nombre', 
        //     type:'text',
        //     required: true,
        //     placeholder:'...', 
        //     defaultValue:onSelected ? onSelected.name : '',
        //     idHtml: "lblEditNameMessage"
        // };
        // campForm[2] ={           
        //     id:3, 
        //     key:'message', 
        //     label:'Mensaje de usuario final', 
        //     type:'text',
        //     required: true, 
        //     placeholder:'...', 
        //     defaultValue:onSelected ? onSelected.message : '',
        //     idHtml: "txtEditNameMessage",
        // };





    /************************
    * @methods 
    ************************/
        const methodsList = {
            getDataItem:(object)=>{
                const stateList = new Lists(itemsMessage, campForm)
                setIdQuestionSelect(stateList.getDataItem(object, idMessageSelect))
                const stateCurrent = stateList.updateCurrent(idMessageSelect)
                setCampForm(stateCurrent.object)
                setDeleteD(stateCurrent.delete)
                setEdit(stateCurrent.edit)
            },
            voidCamps:()=>{
                const stateList = new Lists(itemsMessage, campForm)
                setCampForm(stateList.clearCamps())
            }
        }
     
        const methosRequests = {
            updateItem:(dataForm, id)=>{
                dataForm = Object.assign(dataForm,{idLanguage:1, code:onSelected.code})
                Requests.confirmRequest({body: dataForm, id:id, ct:'json', tenant:clientName, option:'updateItem',
                infoModal:{
                    title:controlsPage?.lblUpdate?.value,
                    description:controlsPage?.lblTheUpdateHasBeenSuccessful?.value
                },
                op:"b"
                })
            },
            patchItem:()=>{
                const data = [
                    {
                      op: "remove",
                      path: "language",
                    },
                  ];
                Requests.confirmRequest(
                    {
                        body: data,
                        id:1,
                        ct: "application/json-patch+json",
                        tenant:clientName,
                        option:'patchItem'
                    })
                //location.reload()
            },
            deleteItem:()=>{
                idMessageSelect.forEach(element=>Requests.deleteItem({id:element.id, tenant:clientName,
                    infoModal:{
                      title:controlsPage?.lblDisabled?.value, 
                      description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
                    }
                }))
                window.location.reload()
            },
            getMessage: async (filter,clientName) =>{
                handleLoadingData()
                const promise = await Requests.getItem({ param: {...filter, idlanguage: JSON.parse(CookiesJS.get("dataUser")).user.idLanguage}, tenant: clientName })
            setItemsMessage(promise.data.data);
                const {
                currentPage,
                pageZise,
                totalCount,
                totalPages
            } = promise.data.meta;
            setPagination({
                currentPage: currentPage,
                pageSize: pageZise,
                totalItems: totalCount,
                totalPages: totalPages
            });
            handleLoadedData()
            }
        }
    const _MessageUser = (items) =>{
        if (items.length > 0){     
            setOnSelected(items[0]);
        } else {
            setOnSelected();
        }   
    }

    /************************
     * @hooks 
     ************************/

        // useEffect(async()=>{
        //     handleLoadingData()
        //     const promise = await Requests.getItem({ param: { pageSize: showElements, pageNumber: currentPages,idlanguage: JSON.parse(CookiesJS.get("dataUser")).user.idLanguage }, tenant: clientName })
        //     setItemsMessage(promise.data.data);
        //     const {
        //         currentPage,
        //         pageZise,
        //         totalCount,
        //         totalPages
        //     } = promise.data.meta;
        //     setPagination({
        //         currentPage: currentPage,
        //         pageSize: pageZise,
        //         totalItems: totalCount,
        //         totalPages: totalPages
        //     });
        //     handleLoadedData()
        // }, [])
        
        const _defaultRenderItemColumn = (item, index, column) => {
            const fieldContent = item[column.fieldName];
            return <span>{fieldContent}</span>;
        }
        useEffect(() => {
            if((filter.pageNumber > 0) || (filter.search)||(filter.pageSize)) {
                methosRequests.getMessage(filter, clientName);
            }
        }, [filter, stateLanguage])

    
 
    /************************
     * @resturns 
     ************************/
        if(itemsMessage === null){
            return (<ContainerBaseLoading/>)
        }
        // const numberPage = CountPages(itemsMessage.meta.totalPages)

        return(
            <>
                <SubMenu 
                    contentcontrols={
                        onSelected?[
                            {
                                text:controlsPage?.lblEditMessage.value,
                                iconProps:{iconName: 'Edit'},
                                onClick: ()=>openPanel()
                            }
                        ]
                        :
                        [
                            {
                                text:controlsPage?.OnClickRestore?.value,
                                iconProps:{iconName: 'Sync'},
                                onClick: ()=>methosRequests.patchItem()
                            }
                        ]
                    }
                    contentcontrolsTwo={
                        <>
                            <Panel isOpen={isOpen}
                                closeButtonAriaLabel="Close"
                                isHiddenOnDismiss={true}
                                type={PanelType.smallFixedFar}
                                hasCloseButton={false}
                            >   
                                {isOpen ? <ContainerLargeForm
                                    dataForm={(e)=>{
                                        edit 
                                        ? methosRequests.crateItem(e, onSelected)
                                        : methosRequests.updateItem(e, onSelected.id)
                                    }}
                                    formTitle={edit ? controlsPage?.lblEditMessage?.value : ''}
                                    Items={campForm}
                                    closePanel={dismissPanel}
                                    onSubmitEventFormPanel={()=>{}}
                                    controlsPage={controlsPage} 
                                />:''}
                            </Panel>
                        </>
                    }
                />
                <div className="admin--body-container">
                    <ListHeader 
                        showReport={false} 
                        subRute={controlsPage?.lblUrlErrorMssgeConfig?.value}
                        subRute3= {controlsPage?.OnClickErrorMssgeConfig?.value}
                        title={controlsPage?.lblErrorExceptionMessageList?.value}
                        hideSearch={true}
                        onChange={(event) => {
                            setFilter({
                                ...filter,
                                pageSize: event.target.value,
                                pageNumber: filter.pageNumber === "0" ? "0" : "1"
                            });
                        }}
                        InputChange={(e) => {
                            setFilter({
                                ...filter,
                                search: e
                            })
                        }}
                    />
                    <DetailsListBext
                        enableShimmer={isLoadingData}
                        listKey="ListMessagesError"
                        selectionMode={1}
                        items={itemsMessage}
                        meta={itemsMessage}
                        renderItemColumn={_defaultRenderItemColumn}
                        columnTitles={columnTitles}
                        onSelect={_MessageUser}
                        onPageChange={page => {
                        if(page !== +filter.pageNumber){
                            setFilter({
                                ...filter,
                                pageNumber: String(page),
                            });
                            }
                        }}
                        pagination={pagination}
                    />
                </div>
            </>    
        );
}

export default Messages