class Lists {
    constructor(items, estruct){
        this.items = items
        this.estruct = estruct
    }

    getDataItem(object, arrayItems){
        let index = -1

        if(arrayItems.length != 0){
            index = arrayItems.findIndex((item)=>{
                return item.id == object.id
            })
        }
        if(index == -1){
            arrayItems.push(object)
        }else if(index != -1){
            arrayItems.splice(index, 1)
        }

        return arrayItems
    }

    getDataItem2(object, arrayItems){
        let index = -1
        if(arrayItems.length != 0){
            index = arrayItems.findIndex((item)=>{
                // console.log('entra 1')
                return item.id == object.id
            })
        }
        if(index == -1){
            arrayItems.length=0;
            arrayItems.push(object)
        }else if(index != -1){
            // console.log('entra 3')
            arrayItems.splice(index, 1)
        }

        return arrayItems
    }

    updateCurrent(items){
        let objEst = this.estruct

        if(items.length == 1){
            const indexItem = items[0]
            
            for(let i = 0; i< objEst.length; i++){
                objEst[i].defaultValue = indexItem[objEst[i].key]
            }
            return{
                edit: true,
                delete: true,
                associate: true,
                object: objEst
            }
        }else if(items.length == 0){
            return{
                edit: false,
                delete: false,
                associate: false,
                object: objEst
            }
        }else if(items.length >= 2 ){
            return{
                edit: false,
                delete: true,
                associate: false,
                object: objEst
            }
        }
    }

    clearCamps(){
        let objEst = this.estruct
        for(let i = 0; i< objEst.length; i++){
            objEst[i].defaultValue = ''
        }
        return objEst
    }
}

export default Lists