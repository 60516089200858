//****Librarys ****//
import React, { useContext,useEffect, useState } from "react";
import { Checkbox, DefaultButton, FontIcon, Slider, TeachingBubble } from "@fluentui/react";
import { RequestFetch,consulta } from "../../utils/Requests/requests.Controller";
import { useParams } from "react-router-dom";
import CookiesJS from "js-cookie";
import { isStrongPassword, isByteLength, isInt } from 'validator'
import { isCurrency } from "validator";
//*****Controller*******//
//import { RequestFetch } from '../../utils/Requests/requests.Controller';
//*****Componnents*****//
import { ReactComponent as IconData } from "../../assets/img/ico-cambiocontrasena.svg";
import ContainerBaseLoading from "../../components/containers/baseLoading/containerBaseLoading.component";
import LargeFormInput from "../../components/containers/largeForm/components/LargeFormInput";
import HeaderForm from "../../components/Forms/headerForm/headerForm.component";
import InputFile from '../../components/Forms/inputFile/inputFile.component';
import { mergeStyles } from "@fluentui/react/lib/Styling";
//*****File*****//
import IconChangePass from '../../assets/img/ico-cambiocontrasena.svg'
import ModalFluent from "../../components/tools/modalFluent/ModalFluent";
import { type } from "@testing-library/user-event/dist/type";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import LenguajeContext from '../../context/Lenguaje/lenguajeContext';


//**Methods**//

/**
 *@author Yeison Quiroga(15-04-2021)
 *@version 1.0
 *@lastCommit
 */

const ChangePassword = (props) => {

  /************************
   * @arguments
   ************************/
  // const Questions = [
  //     {id:0, text:'seleccione'},
  //     {id:1, text:'seleccione'}
  // ]
  useEffect(() => {
    const footerStyle = document.querySelector('.admin--container-footer');
    footerStyle?.classList.add('div-foot-rel');
    props.onChange('div-foot-rel')
  }, [props])
  

  const requestsUser = new RequestFetch('Users');
  const { clientName } = useParams();
  const dataUserSession = JSON.parse(CookiesJS.get("dataUser"));
  const [idUser, setIdUSer] = useState(dataUserSession.user.id);
  const [idDomain, setIdDomain] = useState(dataUserSession.user.idDomain)
  const [colorSlider, setColorSlider] = useState('gray')
  const requestsPolices = new RequestFetch(`Users/passwordPolicies/${idDomain}`)
  const [colorIcon, setColorIcon] = useState('gray')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorMessageDown, setErrorMessageDown] = useState('')
  const [numberDefault, setNumberDefault] = useState(0)
  const [disables, setDisables] = useState(true)
  const [dataError, setDataError] = useState(null)
  const [arrayPolices, setArrayPolices] = useState(null)
  const [valuesReg, setValuesReg] = useState([])
  const userText = new RequestFetch('Pages/userTexts')
  const [textOnPages, setTextOnPages] = useState({})
  const [controlsPage, setControlsPage] = useState({});
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const [passText, setPassText] = useState('')
  const [passText2, setPassText2] = useState('')
  const [disableText, setDisableText] = useState(true)
  const dataUserIdLng = JSON.parse(CookiesJS.get("idLng"));

  const [score, setScore] = useState(0)
  const [type1, setType1] = useState({})
  const [type2, setType2] = useState({})
  const [type3, setType3] = useState({})
  const [type4, setType4] = useState({})
  const [type5, setType5] = useState({})
  const [type6, setType6] = useState({})
  const [words, setWords] = useState()
  // let ids = []
  const [ids, setIds] = useState([])
  const [passString, setPassString] = useState('')
  const [passString2, setPassString2] = useState('')

  let arrayIds = []

  const contadorPunt = (id) =>{

    arrayIds.push(id)
    // if (!ids.includes(id)) {
      setIds([...arrayIds])
    // }
  }

  

  useEffect(async () => {
    setErrorMessage('')
    let values1 = []
    let values2 = []
    let values3 = []
    let values4 = []
    let values6 = []
    const polices = await requestsPolices.getItem({ tenant: clientName, param:{idLanguage:dataUserIdLng?dataUserIdLng:dataUserSession.user.idLanguage}, headerEx: {idLanguageUser:dataUserIdLng?dataUserIdLng:dataUserSession.user.idLanguage}})
    setArrayPolices(polices.data.data);
    setWords (Object.keys(polices.data.data))
    polices.data.data.map(item => {
      item.values.map(values => {

        //if para restriccion de texto
        if (values.idSecurityPolicyType == 1) {
          values1.push(values.value)
          setType1({
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values1
          })
        }

        //if para restriccion de datos
        if (values.idSecurityPolicyType == 3 && values.data) {
          values1.push(values.data)
          setType1({
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values1
          })
        }

        //if para cacteres requeridos
        if (values.idSecurityPolicyType == 2) { 
          values2.push(values.value)
          setType2({
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values2
          })
        }

        //if para loonguitud maxima
        if (values.idSecurityPolicyType == 4) {
          values4.push(values.value)
          setType4({
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values.value
          })
        }

        //if para longuitud minima
        if (values.idSecurityPolicyType == 5) {
         
          setType5({
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values.value
          })
        }
        if (values.idSecurityPolicyType == 6) {
          values3.push(values.value)
          setType3({...type3, 
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values3
          })
        }
      })
    })
  }, [dataUserSession.user.idLanguage, ])

  const onSubmitEventFormPanel = (data) => {
    data.preventDefault();
    let pass1 = data.target[0].value;
    let pass2 = data.target[1].value;

    
    // useEffect(() => {
      if ((pass1 == pass2) && (pass1 != null) && (pass2 != null)) {
        let dataFormSubmit = [];
        dataFormSubmit.push({
          op: "replace",
          path: "password",
          value: pass1
        });
        requestsUser.confirmRequest({
          body: dataFormSubmit,
          id: idUser,
          ct: "application/json-patch+json",
          tenant: clientName,
          option: "patchItem",
          hideIcon:true,
          infoModal:{
            title:controlsPage?.lblUpdate?.value,
            description:controlsPage?.lblTheUpdateHasBeenSuccessful?.value
            }
        });
      }
      else if ((pass1 != null) && (pass2 != null) && (pass1 != pass2)) {
        let infor = {
          title: "Error",
          description: controlsPage?.lblPasswordsDoNotMatchPleaseCheck?.value,
          type: 'danger'
        }
        setDataError(infor)
      }
    
    
    // }, [pass1,pass2])


  };
  useEffect(() => {

    let col = 0

    if(ids.length > 0 ){

      col = ids.length * ids.length

      setColorSlider(`rgb(255, ${col}${ids.length}, 0)`)
      setDisables(true)
      setColorIcon('gray')
      if (ids.length == words.length) {
        setColorSlider('#15A215')
        setColorIcon('#15A215')
        setErrorMessage(controlsPage?.lblThePasswordCompliesWithAllSecurityPolicies?.value)
        if (passText == passText2) {
          setDisables(false)
        }
      }

      if (passText == passText2) {
        setErrorMessageDown(false)
      }else{
        setErrorMessageDown(true)
      }
    }

  }, [passString])

  useEffect(() => {
    if(ids.length > 0 ){
    
    if (passText == passText2 && ids.length == words.length) {
      setDisables(false)
      setErrorMessageDown(false)
      if(score == 5){
        setErrorMessage(controlsPage?.lblThePasswordCompliesWithAllSecurityPolicies?.value)
      }
    }else{
      setDisables(true)
      if(score == 5 && passString2.length > 0){
        setErrorMessageDown(true)
      }
    }
  }
  }, [passString2])
  
  /************************
   * @methods
   ************************/

   const indexedDB = window.indexedDB
   let db

   const requestInfo = indexedDB.open('textListApp',1)
   useEffect(() => {
       requestInfo.onsuccess =async()=>{
           // alert('sdsdsd')
           db = requestInfo.result
           setTextOnPages(await consulta(db)) 
       }
   }, [])
  //#region Consultar el id del usuario
  const actionOnClick = () => {
    setDataError(null)
  }
  const confirmAction = () => {
    setDataError(null)
  }
  const useStyles = {
    activeSection: { background: colorSlider },
    thumb: { background: colorSlider, border: colorSlider },
    titleLabel: {
      color: colorSlider,
      paddingLeft: '7px',
      'font-size': '12px'
    }
  };


  /************************
   * @hooks
   ************************/
   const blackListValidation = (value, blackList) => {
    let invalid = false;
    if (blackList.length > 0) {
      blackList.forEach((item) => {
        if (value.toLowerCase().includes(item.toLowerCase()))
          invalid = true
      })
    }
    return invalid;
  }

  const regValidation = (name, reg) => { 
    return reg.every( resg => { 
      const regex = new RegExp(resg); 
      return regex.test(name); 
    }) 
  }
  
  const numsValidate = (value, nums) => {
    let valid = false;
    if (nums.length > 0) {
      nums.forEach((item) => {
        if (value.toLowerCase().includes(item.toLowerCase()))
        valid = true
      })
  }
    return valid;
  }
  const maxLimt = (value,limit,id)=>{
    if (value.length <= limit ) {
      contadorPunt(id)
    }else{
      setErrorMessage(type4.description)
    }
  }
  const minLimt = (value,limit,id)=>{
    if (value.indexOf(' ') >= 0) {
      setScore(0)
      setErrorMessage(controlsPage.lblMustNotContainSpaces?.value)//no debe contener espacios 
      setNumberDefault(0)
      setColorIcon('gray')
    }else{
      
      if (value.length >= limit ) {
        contadorPunt(id)
        setColorIcon('gray')
      }else{
        setErrorMessage(type5.description)
      }
    }
  }
const actionOnchange = (e) =>{
  let pass = e.target.value
  setPassText(pass)
  if (pass.indexOf(' ') >= 0) {
    setPassString('')
    setPassString2('')
  }else{
    setPassString(pass)
  }
  
  let numb = 0
      let simbr = 0
      let min = 0

      
      
      if(Object.entries(type2).length > 0 ){
        if(blackListValidation(pass,type2.values)){
          contadorPunt(type2.id)
          setScore(4)
          simbr = 1
        }else{
          setErrorMessage(type2.description)
        }
      }
      if(Object.entries(type3).length > 0 ){
        if(regValidation(pass,type3.values)){
          contadorPunt(type3.id)
          numb = 1
        }else{
          setErrorMessage(type3.description)
        }
      }
      if(Object.entries(type4).length > 0){
        maxLimt(pass,type4.values,type4.id)
      }
      if(Object.entries(type5).length > 0){
        min = type5.values
        minLimt(pass,min,type5.id)
      }
      if(Object.entries(type1).length > 0){
        if (blackListValidation(pass, type1.values)) {
          setErrorMessage(type1.description)
          setNumberDefault(0)
          setColorIcon('gray')
        }else{
          contadorPunt(type1.id)
          
        }
      }

      if (arrayIds.length == words.length) {
        // setErrorMessage('la oka loka')
        setDisables(false)
        setColorIcon('#15A215')
        setScore(5)
      }
      else{
        setScore(0)
      }

      
}

const actionPassB = (e)=>{
  let pass = e.target.value
  setPassText2(pass)
  if (pass.indexOf(' ') >= 0) {
    setErrorMessageDown(true)
    setPassString2('')
  }else{
    setPassString2(pass)
  }
}

  
  const estilo = mergeStyles({
    color: colorIcon,
    fontSize:'20px',
    fontWeight:'900'
  })
  useEffect(() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        //console.log(stateLanguage);
        stateLanguage.lenguaje.map((pass) => {
          // console.log(pass)
            if (pass.path == 'change-password' || pass.path == 'reset-password' || pass.path == 'controls') {
              pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })    
              setControlsPage(controlsPage)
            }              
        })          
    }

}, [textOnPages, stateLanguage])

  /************************
   * @resturns
   ************************/
  return (
    <>
      <form className="w10 c--flex-wse container--flex" onSubmit={(e) => onSubmitEventFormPanel(e)}>
        <HeaderForm iconurl={<IconData fill="var(--color-primary)" className="claso" />} title={controlsPage?.imgChangePswd?.value} subtitle={controlsPage?.lblChangePswd?.value} />
        <InputFile onChange={actionOnchange} name="password" type="password" label={controlsPage?.lblEnterNewPassword?.value}style="wb" politics={controlsPage.OnClickSeePolicies?.value} listPolicies={arrayPolices} disable={disableText} setDisable={(e)=>{setDisableText(e)}}/>
        <Slider
          className="w8 pl1"
          value={passString.length>0
            ?ids.length
            :0}
          label={errorMessage}
          min={0} max={words?.length}
          showValue={false}
          styles={useStyles}
          disabled
        />
        <div className={`wr2 center-vrt center-items ${errorMessage != ''?'pgt10':''}`}>
          <FontIcon aria-label="Like" className={`mrg-lg-neg ${estilo}`} iconName="Like" />
        </div>
        <InputFile 
          onChange={actionPassB} 
          name="passwordConfirmation" 
          type="password" label={controlsPage?.lblNewPsswrdConfir?.value} 
          style={`wb ${errorMessageDown?'space-bottom':''}`} 
          disable={disableText}
          setDisable={(e)=>{setDisableText(e)}}
        />
        <div className="w10 pl2">
          <label style={{
            fontSize: '12px', 
            fontWeight: '600', 
            color:'var(--color-danger)',
            marginLeft:'3px'
          }}>
            {errorMessageDown?controlsPage?.lblPasswordsDoNotMatchPleaseCheck?.value:''}
          </label>
        </div>
        <button type="submit" 
        style={{marginTop:`${errorMessageDown?'20px':''}`}}
        className={`button--default ${disables?'button--disable':'button--primary'}`} 
        disabled={disables}>
            {controlsPage?.smtNext?.value}
        </button>
        </form>
    </>
  );

}

export default ChangePassword;
