import React, {useReducer} from 'react'
import CredentialsContext from './credentialsContext'
import credentialsReducer from './credentialsReducer'

const CredentialsState = (props)=>{

    const initialState = ''

    const [token, dispatch] = useReducer(credentialsReducer, initialState)

    const getToken = (uptoken) =>{
        dispatch({
            type: 'GET_CREDENTIALS_TOKEN',
            payload: uptoken
        })
    }

    return (
        <CredentialsContext.Provider value={{token, getToken}}>
        {props.children}
        </CredentialsContext.Provider>
    )
}

export default CredentialsState