import React, {useContext,useEffect,useState} from 'react';
//import {CommandBar} from 'office-ui-fabric-react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { actualiza,consulta,RequestFetch} from '../../../utils/Requests/requests.Controller';
import LenguajeContext from '../../../context/Lenguaje/lenguajeContext';
import { useParams } from 'react-router-dom';
import CookiesJS from 'js-cookie'
import ContainerBaseLoading from '../../containers/baseLoading/containerBaseLoading.component';

const MenuUser =(props)=>{
    /************************
     * @arguments 
     ************************/
     const requestDomain = new RequestFetch("Pages/userTexts");
     const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
     const nameClient = CookiesJS.get("nameClient");
     const dataUserSession = JSON.parse(CookiesJS.get("dataUser"));
     const [isLoad, setIsLoad] = useState(false)
     const {clientName} = useParams();
     const [controlsPage, setControlsPage] = useState({})
     const [textOnPages, setTextOnPages] = useState({})
     const [itemsLng, setItemsLng] = useState([])
     const [itemsFiles, setItemsFiles] = useState([])

    //  let db
    //     const requestInfo = indexedDB.open('textListApp',1)
        const openDB = () => {
            return new Promise((resolve, reject) => {
              const request = indexedDB.open('textListApp',1);
              
              request.onsuccess = (event) => {
                const db = event.target.result;
                resolve(db);
              };
              request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains("textF")) {
                  db.createObjectStore("textF", { keyPath: "clave" });
                }
              };
              request.onerror = (event) => {
                reject(event.target.error);
              };
            });
        };
    const idiom = async(id) =>{
        if (dataUserSession.user.idLanguage != id) {
            
            setIsLoad(true)
            props.idLng(id)
            dataUserSession.user.idLanguage = id
            const newCookie = JSON.stringify(dataUserSession)
            CookiesJS.set("dataUser",newCookie)
            CookiesJS.set('idLng',id)
        }
        // const promise = await requestDomain.getItem({
        //     param: {idLanguage: id, idUser: dataUserSession.user.id}, 
        //     tenant: clientName
        // });
        // getLenguaje(promise.data.data)
        // if (promise) {
        //     actualiza(db,{clave:'textUsr' ,ssoPagesData:promise.data.data})
        // }
    }

    useEffect(() => {
        if (stateLanguage.lenguaje !=0) {
            setIsLoad(false)
        }
    }, [stateLanguage])
    

    const files = async(path) =>{
        const miUrl = new URL(path);
        if (miUrl) {
            window.open(miUrl);
        }
    }
    useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            stateLanguage.lenguaje.map((users) => {
                if (users.path == 'controls') {
                    users.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                    })
                }
                setControlsPage(controlsPage)
            })
        }else{
            if (Object.entries(textOnPages).length > 0) {
                
                textOnPages.ssoPagesData.map((users) => {
                    if (users.path == 'controls') {
                        users.webcontrolspages.map((data) => {
                            controlsPage[data.idHtml] = data.languagewebcontrols[0];
                        })
                    }
                    setControlsPage(controlsPage)
                })
            }
        }
        // })
    }, [textOnPages, stateLanguage, props])

    const[commandItems, setComandItems]=useState([]);

    useEffect(() => {
        if (props.lng && Object.entries(props.lng).length > 0) {
            let arr = []
            props.lng.map(lng=>{
                arr.push({key:lng.id, text:lng.name, idClient:lng.idClient, onClick:()=>idiom(lng.id)})
            })
            setItemsLng(arr)
        }
        if (props.files && Object.entries(props.files).length > 0) {
            let arr = []
            props.files.map(file=>{
                arr.push({key:file.id, text:file.name, idClient:file.idClient, onClick:()=>files(file.path)})
            })
            setItemsFiles(arr)
        }
        setComandItems([
            {
                key:'Ayuda', 
                text:props.warn?(props.warn.OnClickHelp):(controlsPage.OnClickHelp?.value),
                iconProps: {iconName: 'Info'},
                subMenuProps:{
                     items: itemsFiles
                    //[
                    //     {key: 'Document', text: controlsPage.OnClickDocuments?.value, canCheck: true},
                    //     {key: 'Videos', text: controlsPage.OnClickVideos?.value},
                    //     {key: 'Images', text: controlsPage.OnClickImages?.value}
                    // ]
                }
            }, 
            {
                key:'Lenguaje', 
                text:props.warn?(props.warn.slcLanguage):(controlsPage.slcLanguage?.value),
                iconProps: {iconName: 'Flag'},
                subMenuProps:{
                    items:
                    itemsLng
                    // [
                    //     {key: 'Español', text: controlsPage.OnClickSpanish?.value, onClick:()=>idiom(1)},
                    //     {key: 'Ingles', text: controlsPage.OnClickEnglish?.value, onClick:()=>idiom(2)},
                    //     {key: 'Frances', text: controlsPage.OnClickFrançais?.value, onClick:()=>idiom(3)}
                    // ]
                }
            }, 
        ])
    }, [controlsPage,props])
        /************************
        * @methods 
        ************************/
        
        /************************
        * @hooks 
        ************************/
            
        /************************
        * @resturns 
        ************************/
            return(
                <>
                {isLoad && (
                    <ContainerBaseLoading />                    
                )
                }
                <div className="menu--user-cotainer">
                    <CommandBar items={commandItems}/>
                </div>
                </>
            );
}

export default MenuUser;