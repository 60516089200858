import React, {useContext, useEffect, useState} from "react";
import {Panel, PanelType} from "@fluentui/react/lib/Panel";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {useBoolean} from "@fluentui/react-hooks";
import {useParams} from "react-router-dom";

import SubMenu from "../../../components/navigation/subMenu/subMenu.component";
import ListHeader from "../../../components/lists/listHeader/listHeader.component";
import IpRestrictionPanel from "./ipRestrictionPanel";
import {RequestFetch} from "../../../utils/Requests/requests.Controller";
import DetailsListBext from "../../../components/lists/DetailsList/detailsList.component";
import {COLUMN_TITLES} from "./constants";
import { changeLang } from "../../../utils/logicRendering/changeIdiom";
import LenguajeContext from "../../../context/Lenguaje/lenguajeContext";
import ContainerBaseLoading from "../../../components/containers/baseLoading/containerBaseLoading.component";

const IpRestriction = () => {
  const {clientName} = useParams();

  const [pagination, setPagination] = useState(null);
  const [ipsItems, setIpsItems] = useState([]);
  const [ipSelected, setIpSelected] = useState();
  const { stateLanguage } = useContext(LenguajeContext);
  const [columnTitles, setColumnTitles] = useState(COLUMN_TITLES)
  const [controlsPage, setControlsPage] = useState({});

  const requestsDeniedIpAddresses = new RequestFetch('DeniedIpAddresses');

  const [filter, setFilter] = useState({
    pageSize: 5,
    pageNumber: "0",
    idDomain: ""
  });

  useEffect(async() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        stateLanguage.lenguaje.map((pass) => {
            if (pass.path == 'ip-restriction'|| pass.path =='controls') {
                pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })    
              setControlsPage(controlsPage)
            } 
        })
        setColumnTitles(current=>changeLang(current,controlsPage))
    }
  }, [stateLanguage])

  const [isLoadingData, {
    setTrue: handleLoadingData,
    setFalse: handleLoadedData
  }] = useBoolean(true);

  const [isOpen, {
    setTrue: openPanel,
    setFalse: dismissPanel
  }] = useBoolean(false);

  useEffect(() => {
    async function onFilterChange() {
      if (filter.pageNumber > 0) {
        await executeIpQuery();
      }
    }
    onFilterChange();
  }, [filter])

  useEffect(() => {
    async function onInit() {
      await executeIpQuery();
    }
    onInit();
  }, [stateLanguage])

  const executeIpQuery = async () => {
    await methodsRequests.getRestrictedIps(filter, clientName);
  }

  const _defaultRenderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    return <span>{fieldContent}</span>;
  }

  const campFilters = [
    {
      id: 1,
      key: 'searchValue',
      label: '',
      type: 'text',
    }
  ]

  const methodsRequests = {
    getRestrictedIps: async (filters, tenant) => {
      handleLoadingData();
      setPagination(null);
      const response = await requestsDeniedIpAddresses.getItem({param: filters, tenant});
      if (response.data.data) {
        setIpsItems(response.data.data)
      }
      const {
        currentPage,
        pageZise,
        totalCount,
        totalPages
      } = response.data.meta;
      setPagination({
        currentPage: currentPage,
        pageSize: pageZise,
        totalItems: totalCount,
        totalPages: totalPages
      });
      handleLoadedData();
    },
    deleteRestrictedIp: (id, tenant) => {
      requestsDeniedIpAddresses.confirmRequest({ id: id, tenant, option: 'deleteItem' })
    },
  };

  const handleDeleteIpRestriction = () => {
    methodsRequests.deleteRestrictedIp(ipSelected.id, clientName);
  }

  const handleSelectIp = (items) => {
    if (items.length > 0) {
      setIpSelected(items[0]);
    } else {
      setIpSelected(null);
    }
  }

  return (
    <>
    {Object.entries(controlsPage).length>0?
    <>
      <SubMenu
        contentcontrols={
          ipSelected ?
          [
            {
              text:controlsPage?.OnClickEditRestriction?.value,
              iconProps:{iconName: 'Edit'},
              onClick:() => openPanel()
            },
            {
              text:controlsPage?.OnClickDeleteRestriction?.value,
              iconProps:{iconName: 'delete'},
              onClick:() => handleDeleteIpRestriction()
            }
          ]
          :
          [
            {
              text:controlsPage?.OnClickNewRestrictionIp?.value,
              iconProps:{iconName: 'Add'},
              onClick:() => openPanel()
            }
          ]
        }
        contentcontrolsTwo={
          <>
            <Panel
              isOpen={isOpen}
              closeButtonAriaLabel="Close"
              isHiddenOnDismiss={true}
              type={PanelType.medium}
              hasCloseButton={false}
            >
              {isOpen && (
                <IpRestrictionPanel
                  controlsPage={controlsPage}
                  ipSelected={ipSelected}
                  onClosePanel={async (actionExecuted = false) => {
                    dismissPanel();
                    if (actionExecuted) {
                      await executeIpQuery();
                    }
                  }}
                />
              )}
            </Panel>
          </>
        }
      />
      <div className="admin--body-container">
        <ListHeader
          filters={campFilters}
          showReport={false}
          hideSearch={true}
          subRute={controlsPage?.lblUrlAdminIpRestriction?.value}
          subRute3={controlsPage?.lblIpRestrictionManagement?.value}
          title={controlsPage?.lblIpRestrictionManagement?.value}
          onChange={(event) => {
            setFilter({
              ...filter,
              pageSize: event.target.value,
              pageNumber: filter.pageNumber === "0" ? "0" : "1"
            });
          }}
          dataForm={({searchValue}) => {
            setFilter({
              ...filter,
              pageNumber: "1",
              search: searchValue
            });
          }}
        />
        <DetailsListBext
          enableShimmer={isLoadingData}
          listKey="supportBsrList"
          selectionMode={1}
          items={ipsItems}
          renderItemColumn={_defaultRenderItemColumn}
          columnTitles={columnTitles}
          onSelect={handleSelectIp}
          onPageChange={page => {
            if (page !== +filter.pageNumber) {
              setFilter({
                ...filter,
                pageNumber: String(page),
              });
            }
          }}
          pagination={pagination}
        />
      </div>
      </>
       :
       <ContainerBaseLoading /> }
       </>
  );
};
export default IpRestriction;
