import React, { useState, useEffect, useMemo } from "react";
import { FocusZone, FocusZoneDirection } from "@fluentui/react/lib/FocusZone";
import { FontIcon, mergeStyles } from "@fluentui/react";
import { List } from "@fluentui/react/lib/List";
import { Label } from "@fluentui/react/lib/Label";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { useConst } from "@fluentui/react-hooks";
import { PrimaryButton, Checkbox, Stack } from "@fluentui/react";

const theme = getTheme();
const { palette, semanticColors, fonts } = theme;
const classNames = mergeStyleSets({
  container: {
    overflow: "auto",
    maxHeight: 500,
    margin: "0 auto",
  },
  button: {
    float: "left",
    width: "20%",
    margin: "5px",
  },
  itemCell: [
    getFocusStyle(theme, { inset: 1 }),
    {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      selectors: {
        "&:hover": { background: palette.neutralLight },
      },
    },
  ],
  itemImage: {
    flexShrink: 0,
  },
  itemContent: {
    marginLeft: 10,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    fonts.xLarge,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
});

const getPageHeight = () => {
  return "";
};

const getItemCountForPage = (itemIndex, surfaceRect) => {
  return 16;
};

const  LargeFormTable = (props) => {
  let item = props.item;
  let arr =item.defaultValue?item.defaultValue.map((element) => {return element.previousDay.toString()} ):[]
  const onChange = (e, isChecked) => {
    let objectFinal = {...{ state: isChecked }, ...{ key: e.target.id }, ...{ id: item.key }};
    props.actionOnChange(objectFinal)
  };
  const onRenderCell = (check, index, isScrolling) => {
    //const ischecked= false
    return (
      <div className={classNames.button} >
        <Checkbox
          id={`TableCheckbox${index}`}
          label={check}
          onChange={onChange}
          defaultChecked={arr.includes(check)}
        />
      </div>
    );
  };
  const items = useConst(() => item.bodyTable);
  return (
    <>
    <FocusZone style={item.styleFocusZone} direction={FocusZoneDirection.bidirectional}>
    <div className={item.className}>
      <Stack style={item.styleStackFather} id={item.id}>
      <Label>{item.label ? <div style={{position: "relative"}}> <span>{item.label}</span>{item.isRequired || item.required ? <FontIcon aria-label="requerido" iconName="SkypeCircleCheck"  /> : ''} </div> : ""}</Label>
        <Stack style={item.styleStack} id={item.key}>
        <List
          items={items}
          onRenderCell={onRenderCell}
          getItemCountForPage={getItemCountForPage}
          getPageHeight={getPageHeight}
          renderedWindowsAhead={4}          
          className={item.aditionalClassName || item.className}
          style={item.style}
        />
        </Stack>
      </Stack>
      </div>
    </FocusZone>
    </>
    );
};
export default LargeFormTable;
