import React, {useEffect, useState} from "react";
import {useBoolean} from "@fluentui/react-hooks";

import {SUPPORT_HISTORY_COLUMN_TITLES} from "./constants";
import DetailsListBext from "../../../components/lists/DetailsList/detailsList.component";
import {RequestFetch} from "../../../utils/Requests/requests.Controller";
import { changeLang } from "../../../utils/logicRendering/changeIdiom";
import CookiesJS from "js-cookie";

const SupportHistoryBsr = ({user, clientName, controlsPage}) => {

    const [supportList, setSupportList] = useState([]);
    const [historyTitles, setHistoryTitles] = useState(SUPPORT_HISTORY_COLUMN_TITLES)
    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);
    const dataUserIdLng = JSON.parse(CookiesJS.get("idLng"));
    const userAdmin = JSON.parse(CookiesJS.get("dataUser")).user;
    const supportRequest = new RequestFetch('Support/values');

    useEffect(() => {
        if (user) {
            requests.getSupportHistory();
        }
        setHistoryTitles(current=>changeLang(current,controlsPage))
    }, [user])

    const requests = {
        getSupportHistory: async () => {
            handleLoadingData()
            try {
                const response = await supportRequest.getItem({
                    param: {
                        idUser: user.id
                    },
                    headerEx:{idLanguageUser:dataUserIdLng?dataUserIdLng:userAdmin.idLanguage},
                    tenant: clientName
                });
                if (Array.isArray(response.data.data)) {
                    setSupportList(
                        response.data.data.map(
                            support => (
                                {
                                    ...support,
                                    creatingDateLabel: _formatDate(support.creatingDate),
                                    creatingDateTime: _formatTime(support.creatingDate),
                                    userEmail: user.institutionalEmail
                                }
                            )
                        )
                    );
                }
                handleLoadedData();
            } catch (e) {
                handleLoadedData();
            }
        }
    }

    const _formatDate = (date) => {
        const newDate = new Date(date);
        const month = (`0${newDate.getMonth() + 1}`).slice(-2);
        const day = (`0${newDate.getDate()}`).slice(-2);
        return `${day}/${month}/${newDate.getFullYear()}`;
    }

    const _formatTime = (date) => {
        const newDate = new Date(date);
        return `${newDate.getHours()}:${newDate.getMinutes()}`;
    }

    return (
        <div className="container--flex c--flex-dc">
            <div className="mg10-h">
                <h1>{controlsPage?.OnClickSupportHtories.value}</h1>
            </div>
            <div className="mg10-h container--flex">
                <label>{`${controlsPage?.lblUserName.value} :`}</label>
                <input type="text" className="c--flex-f1" disabled value={user.fullName}/>
            </div>
            <div className="w10 mg10-h">
                {
                    (isLoadingData || (!isLoadingData && supportList.length > 0))  ?
                    <DetailsListBext
                        enableShimmer={isLoadingData}
                        listKey="historySupportBsrList"
                        selectionMode={0}
                        items={supportList}
                        columnTitles={historyTitles}
                    />
                    :
                    <h4>No hay registros para este usuario</h4>
                }

            </div>
        </div>
    )
}

export default SupportHistoryBsr;
