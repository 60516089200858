//****Librarys ****//
import JSCookies from 'js-cookie'
import React, { useContext,useEffect, useState } from 'react'
//import { PrimaryButton } from 'office-ui-fabric-react'
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { appKey } from '../../utils/Requests/models.Controller.js';

//*****Componnents*****//
import IconButton from '../../components/Forms/IconsButtons/iconsButton.component'

//*****File*****//
import { ReactComponent as IconData } from '../../assets/img/ico-datos.svg'
import { ReactComponent as IconQueries } from '../../assets/img/ico-preguntas-new.svg'
import { ReactComponent as IconDirectory } from '../../assets/img/ico-directorio.svg'
import { ReactComponent as IconChangePass } from '../../assets/img/ico-cambiocontrasena.svg'
import { ReactComponent as IconEmail } from '../../assets/img/ico-correo.svg'
import { ReactComponent as IconPagWeb } from '../../assets/img/ico-pagweb.svg'
import { ReactComponent as IconDobleFactor } from '../../assets/img/ico-doblefactor.svg'
// import { ReactComponent as IconPagWeb } from '../../assets/img/ico-preguntas-new.svg'

import { ReactComponent as IconDataTwo } from '../../assets/img/ico-datos-02.svg'
import { ReactComponent as IconQueriesTwo } from '../../assets/img/ico-preguntas-02.svg'
import { ReactComponent as IconDirectoryTwo } from '../../assets/img/ico-directorio-02.svg'
import { ReactComponent as IconChangePassTwo } from '../../assets/img/ico-cambiocontrasena-02.svg'
import { ReactComponent as IconEmailTwo } from '../../assets/img/ico-correo-02.svg'
import { ReactComponent as IconPagWebTwo } from '../../assets/img/ico-pagweb-02.svg'
import { ReactComponent as IconDobleFactorTwo } from '../../assets/img/ico-doblefactor-02.svg'

import LenguajeContext from '../../context/Lenguaje/lenguajeContext.js';
import {ReactComponent as Lgoo} from '../../assets/img/ico-cambiocontrasena.svg'
//*****Setting*****//
import {PATH_SSO, SSO_URI} from '../../assets/settings/settinsrouters'
import { useParams } from 'react-router-dom';
import { RequestFetch } from '../../utils/Requests/requests.Controller';
import ContainerBaseLoading from '../../components/containers/baseLoading/containerBaseLoading.component.jsx';

//*****Methods*****//

/**
 *@author Yeison Quiroga(15-04-2021)
 *@version 1.0
 *@lastCommit 
 */

const Loby = () =>{          
    /************************
     * @arguments 
     ************************/
     const {clientName} = useParams()
     const Requests = new RequestFetch("OAuth/revoke");
     const [dataUser, setDataUser] = useState(JSCookies.get('dataUser'));
     const token = JSCookies.get('token');
     const userText = new RequestFetch('Pages/userTexts')
     const [controlsPage, setControlsPage] = useState({});
     const [textOnPages, setTextOnPages] = useState({})
     const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
     const DU = JSON.parse(dataUser);
    /************************
     * @methods 
     ************************/
        const MethodsOAuth = {
            ExitOAuth: ()=>{
                const params = new URLSearchParams();
                params.append('client', `${appKey}`);
                params.append('tenant', `${clientName}`);
                params.append('domain', `${DU.user.idDomain}`);
                params.append('token', `${token}`);
                
                
                const revoke = Requests.createItem({ body: params, ct: "encode", tr: 'revoke', 
                    infoModal:{
                    title:controlsPage?.lblSuccessfulProcess?.value,
                    description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                    }
                })
                .then(resp=>{
                    JSCookies.remove('token')
                    const DBDeleteRequest = window.indexedDB.deleteDatabase("textListApp");

                    DBDeleteRequest.onerror = (event) => {
                    console.error("Error deleting database.");
                    };

                    DBDeleteRequest.onsuccess = (event) => {
                    console.log("Database deleted successfully");
                    };
                    window.location.href = `${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`
                })
            }
        } 
    /************************
     * @hooks 
     ************************/

    //  useEffect(async() => {
    //     let controlsPage = {}
    //        const res = await userText.getItem({tenant:clientName,
    //         param:{
    //             idUser:DU.user.id,
    //             idLanguage:DU.user.idLanguage
    //             }
    //         })
    //       res.data.data.map((spd) =>{
    //           //  console.log(spd)
    //           if(spd.path == 'associate-security-questions') {
    //               spd.webcontrolspages.map((data)=>{
    //                   controlsPage[data.idHtml] = data.languagewebcontrols[0];
    //               })
    //           }
    //       })
    //       setControlsPage(controlsPage)
    //       getLenguaje(res.data.data)
    //   }, [])

      

      useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            //console.log(stateLanguage);
            stateLanguage.lenguaje.map((pass) => {
              // console.log(pass)
                if (pass.path == 'associate-security-questions' ||pass.path == 'change-password' || pass.path == 'query-update-data' || pass.path == 'controls')  {
                  pass.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  })    
                  setControlsPage(controlsPage)
                }              
            })          
        }
    }, [textOnPages, stateLanguage])

    


    /************************
     * @resturns 
     ************************/
        return(
            <>
            {!Object.entries(controlsPage).length>0?
            <ContainerBaseLoading />
                :''
            }
            
            <div className="conten--iconsbutton">
                <IconButton iconurl={<IconQueries fill='var(--color-primary)' className='icon-color-escudo absol'/>} iconrev={<IconQueriesTwo fill='white' className='icon-color-escudo-hov absol'/>} url={`${clientName}/user/associate-security-questions`} th={controlsPage?.imgAssociateQuestion?.value}/>
                <IconButton iconurl={<IconChangePass fill='var(--color-primary)' className='icon-color-escudo absol'/>} iconrev={<IconChangePassTwo fill='white' className='icon-color-escudo-hov absol'/>} url="/user/change-password" th={controlsPage?.imgChangePswd?.value}/>
                <IconButton iconurl={<IconData fill='var(--color-primary)' className='icon-color-escudo absol'/>} iconrev={<IconDataTwo fill='white' className='icon-color-escudo-hov absol'/>} url="/user/query-update-data" th={controlsPage?.lblCheckMyData?.value}/>
                <IconButton iconurl={<IconDobleFactor fill='var(--color-primary)' className='icon-color-escudo absol'/>} iconrev={<IconDobleFactorTwo fill='white' className='icon-color-escudo-hov absol'/>} url="/user/manage-mfa" th="MFA"/>
                {/* <IconButton iconurl={<IconDirectory fill='var(--color-primary)' className='icon-color-escudo'/>} url="/user/AssosiateQuestions" th="Directorio"/> */}
            </div>
            <PrimaryButton 
                text={controlsPage?.smtGoOut?.value}
                className="button--default button--primary" 
                onClick={MethodsOAuth.ExitOAuth}
            />
            
            
            </>    
        );
}

export default Loby;