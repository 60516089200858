import React, {useContext, useEffect, useState} from "react";
import {useBoolean} from "@fluentui/react-hooks";
import {useParams} from "react-router-dom";

import {RequestFetch} from "../../../utils/Requests/requests.Controller";
import {ActionButton, DefaultButton} from "@fluentui/react/lib/Button";
import DetailsListBext from "../../../components/lists/DetailsList/detailsList.component";
import {DOMAIN_SELECTED_ROLE_ASSIGNMENT_TITLE} from "./constants";
import LenguajeContext from "../../../context/Lenguaje/lenguajeContext";
import { changeLang } from "../../../utils/logicRendering/changeIdiom";

const DomainRole = ({
    domainForm,
    domainId,
    controlsPage
}) => {


    const rolesRequest = new RequestFetch('Roles');
    const domainRoleRequest = new RequestFetch('Domain_Role');
    const {clientName} = useParams();

    const [rolesList, setRolesList] = useState([]);
    const [domainsRoleList, setDomainsRoleList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [roleSelected, setRoleSelected] = useState("");
    const [domainSelected, setDomainSelected] = useState("");
    const [columnTitles, setColumnTitles] = useState(DOMAIN_SELECTED_ROLE_ASSIGNMENT_TITLE)
    const [textOnPages, setTextOnPages] = useState({})
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
    const [filter, setFilter] = useState({
        pageSize: 10,
        pageNumber: "0"
    });
    const [loadingRolesList, setLoadingRolesList] = useState(true);

    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);

    useEffect(async () => {
        if(domainId){
            setDomainSelected(domainId);
        }
        await requests.getRoles(clientName);
    }, []);

    useEffect(async () => {
        if (!loadingRolesList && domainId) {
            _loadDomainRoles(domainId);
        }
    }, [loadingRolesList]);

    const _loadDomainRoles = (domainId) => {
        requests.getDomainRoles(filter, clientName, domainId);
    }

    const _handleRoleChange = (event) => {
        setRoleSelected(event.target.value);
    }

    const _handleDomainChange = (event) => {
        setDomainSelected(event.target.value);
        if(event.target.value){
            _loadDomainRoles(event.target.value);
        } else {
            setDomainsRoleList([]);
        }
    }

    const _handleAddRole = () => {
        if (roleSelected && domainSelected) {
            requests.addRole({
                idRole: roleSelected,
                idDomain: domainSelected,
                enable: true,
            })
        }
    }

    const _handleRemoveRole = (role) => {
        requests.removeRole(role.id);
    }

    const requests = {
        getRoles: async (tenant) => {
            setPagination(null);
            const response = await rolesRequest.getItem({tenant});
            setRolesList(
                response.data.data.map(
                    role => (
                        {
                            id: role.id,
                            text: role.name
                        }
                    )
                )
            );
            setLoadingRolesList(false);
        },
        getDomainRoles: async (filters, tenant, idDomain) => {
            setPagination(null);
            handleLoadingData();
            const response = await domainRoleRequest.getItem({
                param: {
                    ...filters,
                    idDomain
                },
                tenant
            });
            if (response.data) {
                let idNumber = 0;
                setDomainsRoleList(
                    response.data.data.map(
                        domainRole => {
                            idNumber++;
                            return (
                                {
                                    domain: domainForm.find(role => role.id === domainRole.idDomain).alias || "No encontrado",
                                    date: domainRole.creatingDate,
                                    role: rolesList.find(role => role.id === domainRole.idRole).text || "No encontrado",
                                    actions: '',
                                    id: domainRole.id,
                                    idNumber
                                }
                            )
                        }
                    )
                );
                const {
                    currentPage,
                    pageZise,
                    totalCount,
                    totalPages
                } = response.data.meta;
                setPagination({
                    currentPage: currentPage,
                    pageSize: pageZise,
                    totalItems: totalCount,
                    totalPages: totalPages
                });

            } else {
                setDomainsRoleList([]);
            }

            handleLoadedData();
        },
        addRole: async (body) => {
            await domainRoleRequest.createItem({
                body,
                ct: 'json',
                tenant: clientName,
                infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                }
            });
        },
        removeRole: async (id) => {
            await domainRoleRequest.confirmRequest({id, tenant: clientName, option: 'deleteItem',
            infoModal:{
                title:controlsPage?.lblDisabled?.value, 
                description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
            }
            })
        }
    }

    const _defaultRenderItemColumn = (item, index, column) => {
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case 'actions':
                return (
                    <ActionButton
                        text=""
                        iconProps={{iconName: 'Cancel'}}
                        onClick={_ => {
                            _handleRemoveRole(item);
                        }}
                    />
                );

            default:
                return <span>{fieldContent}</span>;
        }
    }

    return <>
        <div className="h1"/>
        <div className="container--flex c--flex-hc mg5-w">
            <label className="mg10-w wr3">{controlsPage?.lblDomain.value}</label>
            <select name="rol-select" className="clr--base bdr--base mg10-w w3" value={domainSelected} onChange={_handleDomainChange}>
                <option value="">{controlsPage?.slcSelectDomain.value}</option>
                {domainForm.map(option =>
                    <option key={option.id} value={option.id}>{option.alias}</option>
                )}
            </select>
        </div>
        <div className="h1" />
        <div className="container--flex c--flex-hc mg5-w">
            <label className="mg10-w wr3">{controlsPage?.lblRole.value}</label>
            <select name="rol-select" className="clr--base bdr--base mg10-w w3" onChange={_handleRoleChange}>
                <option value="">{controlsPage?.slcSelectRole.value}</option>
                {rolesList.map(option =>
                    <option key={option.id} value={option.id}>{option.text}</option>
                )}
            </select>
            <DefaultButton
                disabled={roleSelected === "" && domainSelected === ""}
                className="button--primary clr--light-III w2 mg10-w"
                onClick={_handleAddRole}
                text="Agregar rol"/>
        </div>
        <div className="h2"/>
        <DetailsListBext
            enableShimmer={isLoadingData}
            listKey="domainRoleAssignmentDetailsListRoles"
            selectionMode={0}
            items={domainsRoleList}
            columnTitles={changeLang(DOMAIN_SELECTED_ROLE_ASSIGNMENT_TITLE,controlsPage)}
            renderItemColumn={_defaultRenderItemColumn}
            onPageChange={page => {
                if (page !== +filter.pageNumber) {
                    setFilter({
                        ...filter,
                        pageNumber: String(page),
                    });
                }
            }}
            pagination={pagination}
        />
    </>
}

export default DomainRole;
