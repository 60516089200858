import axios from 'axios'

import OauthModels from './oauth.model'

const ValidateAuth = async({accessToken, refreshToken, cp, type, tenant})=>{
    const res = await axios(OauthModels({
            accessToken: accessToken,
            refreshToken: refreshToken,
            cp: cp,
            type: type,
            tenant: tenant
        })).then( response =>{
            return response
        }).catch(error =>{
            if (error.response) {
                return error.response.data
            }
        })
    return res //.data.data.active;
}

const FetchOAuth = ({accessToken, refreshToken, cp, type, tenant})=>{
    return Promise.all([
        ValidateAuth({accessToken, refreshToken, cp, type, tenant})
    ]).then(([data])=>{
        return{data}
    })
}

export default FetchOAuth
