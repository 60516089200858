import React from 'react';

import IconEscudoC from '../../../assets/img/ico-candado-cerrado.svg'
import { ReactComponent as IconLogo} from '../../../assets/img/ico-candado-abierto.svg'

const ContainerBaseLoading =()=>{
    return(
        <div className="loading--container">
            <div className="login--container-icon">                
                <IconLogo className='img1' />
            </div>
            <div className="login--container-icon2">
                 <img src={IconEscudoC} alt="" className='img1'/> 
                
            </div>
        </div>
    );
}

export default ContainerBaseLoading;