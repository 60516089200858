import React, { useState, useEffect, useMemo, useContext } from "react";
import { HexColorPicker, RgbColor } from "react-colorful";
import { RequestFetch } from "../../../utils/Requests/requests.Controller";
import InputFile from "../../../components/Forms/inputFile/inputFile.component";
import ConvColors from "../../../utils/logicRendering/convColor.controller";
import Select from "react-select";
import LargeFormInput from "../../../components/containers/largeForm/components/LargeFormInput";
import LargeFormCheckbox from "../../../components/containers/largeForm/components/LargeFormCheckbox";
import LargeFormDate from "../../../components/containers/largeForm/components/LargeFormDate";
import LargeFormSelect from "../../../components/containers/largeForm/components/LargeFormSelect";
import LargeFormPickers from "../../../components/containers/largeForm/components/LargeFormPickers";
import { useParams } from "react-router-dom";
import LargeFormSlider from "../../../components/containers/largeForm/components/LargeFormSlider";
import LargeFormToggle from "../../../components/containers/largeForm/components/LargeFormToggle";
//import { Form} from '@fluentui/react-northstar';

import { SearchableDropdown, IStackTokens, Stack, Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption, ThemeProvider, initializeIcons, SearchBox, Slider, ActionButton } from "@fluentui/react"
import LargeFormMaskedTextField from "../../../components/containers/largeForm/components/LargeFormMaskedTextField";
import LargeFormButtonTable from "../../../components/containers/largeForm/components/LargeFormTable";
import LargeFormTable from "../../../components/containers/largeForm/components/LargeFormTable";
import LargeFormButton from "../../../components/containers/largeForm/components/LargeFormButton";
import LargeFormLabel from "../../../components/containers/largeForm/components/LargeFormLabel";
import RadioButton from "../../../components/containers/largeForm/components/LargeFormRadioButton";
import { useBoolean } from '@fluentui/react-hooks';
import DetailsListBext from "../../../components/lists/DetailsList/detailsList.component";
import LenguajeContext from "../../../context/Lenguaje/lenguajeContext";
import { changeLang } from "../../../utils/logicRendering/changeIdiom";

const ContainerForm = (props) => {
  const [stateWidth, setStateWidth] = useState();
  const [edit, setEdit] = useState(null)
  const { clientName } = useParams()
  const [controlsPage, setControlsPage] = useState({});
  const [textOnPages, setTextOnPages] = useState({})
  const { getLenguaje, stateLanguage } = useContext(LenguajeContext);
  const [pagination, setPagination] = useState(null);
  const RequestsAssociate = new RequestFetch('DomainSchema');
  const Requests = new RequestFetch('Domains');
  const RequestsSchemas = new RequestFetch('Schemas');
  const [isLoadingData, {
    setTrue: handleLoadingData,
    setFalse: handleLoadedData
  }] = useBoolean(false);
  const [schemasList, setSchemasList] = useState([])
  const [noWorkDays, setNoWorkDays] = useState([])
  const [daysList, setDaysList] = useState([])
  const [filter, setFilter] = useState({
    pageSize: 5,
    pageNumber: "0",
    idDomain: ""
  });
  const COLUMN_TITLES = [{
    typeSchema: {
      name: "Tipo de Esquema",
      minWidth: 60,
      maxWidth: 150,
      isResizable:true,
      idHtml: "coluSchemaType"
    },
    primary: {
      name: "Esquema Principal",
      minWidth: 50,
      maxWidth: 100,
      isResizable:true,
      isMultiline: true,
      idHtml: "coluMainScheme"
    },
    serviceUser: {
      name: "Cuenta de Servicio",
      minWidth: 60,
      maxWidth: 150,
      isResizable:true,
      idHtml: "coluAccountService"
    },
    action2: {
      name: "Editar",
      minWidth: 60,
      maxWidth: 80,
      isMultiline: true,
      idHtml: "coluEdit"
    },
    actions: {
      name: "Eliminar",
      minWidth: 20,
      maxWidth: 70,
      isMultiline: true,
      idHtml: "coluDelete"
    },


  }];
  const COLUMN_TITLES_DAYS = [{
    day: {
      name: "Día",
      maxWidth: 150,
      Lenght: 10,
      Height: 10,
    },
    startTime: {
      name: "Hora Inicial",
      maxWidth: 150,
    },
    endTime: {
      name: "Hora Final",
      maxWidth: 150,
    },
    actions: {
      name: "Agregar",
      maxWidth: 150,
    },
  }];
  const COLUMN_TITLES_WORKDAYS = [{
    day: {
      name: "Día",
      isResizable: true,
      maxWidth: 50,
      minWidth: 10,
    },
    startTime: {
      name: "Hora Inicial",
      maxWidth: 50,
      minWidth: 10,
      isResizable: true,
      isMultiline: true,
    },
    endTime: {
      name: "Hora Final",
      isResizable: true,
      minWidth: 10,
      maxWidth: 50,
    },
    actions: {
      name: "Eliminar",
      isResizable: true,
      minWidth: 10,
      maxWidth: 30,
    },


  }];

  const COLUMN_TITLES_NOWORKDAYS = [{
    nonWorkingDate: {
      name: "Fecha",
      isResizable: true,
      minWidth: 30,
      maxWidth: 80,
    },
    actions: {
      name: "Eliminar",
      isResizable: true,
      minWidth: 10,
      maxWidth: 30,
    },
  }];

  const [columnTitles, setColumnTitles] = useState(COLUMN_TITLES)

  useEffect(() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje != 0) {
      stateLanguage.lenguaje.map((pass) => {
        if (pass.path == 'controls' || pass.path == 'domain' || pass.path == 'suport' || pass.path == 'access-hours') {
          pass.webcontrolspages.map((data) => {
            controlsPage[data.idHtml] = data.languagewebcontrols[0];
          })
          setControlsPage(controlsPage)
          setColumnTitles(current => changeLang(current, controlsPage))
        }
      })
    }
  }, [stateLanguage])


  useEffect(() => {
    if (props.keyForm == "workDay") {
      methosRequests.getWorkDays(filter, clientName)
      methosRequests.getNonWorkDays(filter, clientName)

    }
  }, [props])
  useEffect(() => {
    if (props.keyForm == "associateSchema") {
      methosRequests.getSupportUsers(filter, clientName)
    }
  }, [])


  const calcWidth = () => {
    setTimeout(() => {
      let stateW = document.querySelector(".form--container-body");
      if (stateW.clientWidth !== null) {
        setStateWidth(stateW.clientWidth);
      }
    }, 200);
  };
  useEffect(() => {
    if (filter.pageNumber > 0) {
      methosRequests.getSupportUsers(filter, clientName);
    }
  }, [])
  const [optionIdSchema, setOptionIdSchema] = useState(0)
  const [optionIsPrimary, setOptionIsPrimary] = useState(false)
  const defaultRenderItemColumn = (item, index, column) => {

    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          <ActionButton
            text=""
            iconProps={{ iconName: 'Cancel' }}
            onClick={() =>
              RequestsAssociate.confirmRequest({
                id: item.id, tenant: clientName, option: 'deleteItem', op : 'b',
                infoModal: {
                  title: controlsPage?.lblDisabled?.value,
                  description: controlsPage?.lblItHbeenSuccessfullyDisabled?.value
                }
              })
            }
          />
        );
      case 'action2':
        return (
          <ActionButton
            text=""
            iconProps={{ iconName: 'Edit' }}
            onClick={() => { 
              setOptionIdSchema(item.idSchema); 
              setOptionIsPrimary(item.isPrimary); 
              props.formEdit(item); 
              setEdit(item) }}
          />
        );

      default:
        return <span>{fieldContent}</span>;
    }
  }
  const defaultRenderItemColumnDays = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          <div className="h1">
            <ActionButton
              text=""
              className="h1"
              iconProps={{ iconName: 'add' }}
              onClick={() => {
                props.actionOnClick(item)
              }
              }
            />
          </div>
        );
      case 'startTime':
        return (
          <div className="h2" style={{ marginTop: "-1rem" }}>
            <LargeFormInput
              item={{
                id: item.id,
                key: "startTime" + item.id,
                //label: "Hora final",
                type: "text2",
                defaultValue: "",
                defaultValu: "07:00",
                disabled: true,
                multiline: false,
                resizable: false,
                required: false,
                typeInput: "time",
                description: "",
                onRenderLabel: null,
                isRequired: false,
                sizeInput: 100,
                style: { fontSize: "10px", marginTop: "0px", height: "2rem" },
                className: 'select2',
                onGetErrorMessage: [{ messageValidateSize: "Hora no valida" }]
              }}
              props={props}
              actionOnChange={()=>{}}
              isDisableOpt={props.isDisableOpt}
              defaultValueInput={props.defaultValueInput}
              //textFileType={'time'}
            />
          </div>
        );
      case 'endTime':
        return (
          <div className="h2" style={{ marginTop: "-1rem" }}>
            <LargeFormInput
              item={{
                id: item.id,
                key: "endTime" + item.id,
                //label: "Hora final",
                type: "text2",
                defaultValue: "",
                defaultValu: "18:00",
                disabled: false,
                multiline: false,
                resizable: false,
                required: false,
                typeInput: "time",
                description: "",
                onRenderLabel: null,
                isRequired: false,
                sizeInput: 100,
                style: { fontSize: "10px", marginTop: "0px", height: "2rem" },
                className: 'select2 w10',
                onGetErrorMessage: [{ messageValidateSize: "Hora no valida" }]
              }}
              props={props}
              actionOnChange={()=>{}}
              isDisableOpt={props.isDisableOpt}
              defaultValueInput={props.defaultValueInput}
              textFileType={'time'}
            />
          </div>
        );
      default:
        return <div className="h1"><span>{fieldContent}</span></div>;
    }
  }
  const defaultRenderItemColumnWorkDay = (item, index, column) => {

    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          
          <ActionButton
            text=""
            className='h1'
            iconProps={{ iconName: 'Cancel' }}
            onClick={() =>
              //RequestsAssociate.confirmRequest({id:item.id, tenant: clientName, option:'deleteItem'})
              props.RemoveDay(item)
            }
          />
        );
      default:
        return <span>{fieldContent}</span>;
    }
  }
  const defaultRenderItemColumnNoWorkDay = (item, index, column) => {

    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        return (
          <ActionButton
            text=""
            iconProps={{ iconName: 'Cancel' }}
            className='h1'
            onClick={() =>
              //RequestsAssociate.confirmRequest({id:item.id, tenant: clientName, option:'deleteItem'})
              props.RemoveNoWorkDay(item)
            }
          />
        );
      default:
        return <span>{fieldContent}</span>;
    }
  }
  const onSubmitEvent = (event) => {
    const formData = new FormData(event.target);
    const user = {};
    event.preventDefault();
    if (props.keyForm == "workDay") {
      props.dataForm(user);
      //props.onSubmitEventFormPanel(event);

    } else {
      for (let entry of formData.entries()) {
        user[entry[0]] = entry[1];
      }

      props.dataForm(user);
      props.onSubmitEventFormPanel(event);
    }
  };

  const _defaultRenderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];

    return <span>{fieldContent}</span>;
  }

  const methosRequests = {
    getSupportUsers: async (filters, tenant) => {
      setPagination(null);
      handleLoadingData()
      const resp = await Requests.getItem({ tenant });
      const respSchem = await RequestsSchemas.getItem({ tenant });
      const response = await RequestsAssociate.getItem({
        param: {
          idDomain: props.infoExt
        },
        tenant
      });

      response.data.data.map(item => {
        resp.data.data.map(dom => {
          if (item.idDomain == dom.id) {
            item = Object.assign(item, { name: dom.alias, primary: item.isPrimary ? 'Sí' : 'No' })
          }

        })
        respSchem.data.data.map(dom => {
          if (item.idSchema == dom.id) {
            item = Object.assign(item, { typeSchema: dom.domainAddress })
          }

        })
      })

      setSchemasList(response.data.data)


      handleLoadedData();
    },
    getWorkDays: async (filters, tenant) => {
      setPagination(null);
      handleLoadingData()
      // const resp = await Requests.getItem({tenant});
      // const respSchem = await RequestsSchemas.getItem({tenant});
      // const response = await RequestsAssociate.getItem({
      //     param:{
      //         idDomain:props.infoExt
      //     },
      //     tenant});

      //     response.data.data.map(item=>{
      //         resp.data.data.map(dom=>{
      //         if (item.idDomain == dom.id) {
      //             item = Object.assign(item,{name:dom.alias, primary:item.isPrimary?'Sí':'No'})
      //         }

      //         })

      //         respSchem.data.data.map(dom=>{
      //         if (item.idSchema == dom.id) {
      //             item = Object.assign(item,{typeSchema:dom.name})
      //         }

      //         }) 
      //     })

      //setSchemasList([{"day":"lunes", startTime:"08:00",endTime:"12:00"}])
      setDaysList(props.DaysList)


      handleLoadedData();
    },
    getNonWorkDays: async (filters, tenant) => {
      setPagination(null);
      handleLoadingData()
      setNoWorkDays(props.DaysNonWorkList)


      handleLoadedData();
    }
  }
  const addForm = (item, props) => {

    //item = Object.assign(item,{value:edit?:edit})

    if (item.type === "select2") {
      return <LargeFormSelect item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} itemsComponentInformation={props.itemsComponentInformation} defaultSelectedKeys={props.defaultSelectedKeys} />
    } else if (item.type === "checkbox2") {
      return <LargeFormCheckbox item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    } else if (item.type === "radio2") {
      return <RadioButton item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    } else if (item.type === "maskedTextField") {
      return <LargeFormMaskedTextField item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    }
    else if (item.type === "label") {
      return <LargeFormLabel item={item} props={props} isDisableOpt={props.isDisableOpt} defaultValue={props.defaultValue} defaultValueInput={props.defaultValueInput} />
    }
    else if (item.type === "table") {
      return <LargeFormTable item={item} props={props} actionOnClick={props.actionOnClick} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    }
    else if (item.type === "tagpicker") {
      return <LargeFormPickers item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    } else if (item.type === "slider") {
      return <LargeFormSlider item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    } else if (item.type === "toggle") {
      return <LargeFormToggle item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} onText={item.onText} offText={item.offText} />
    } else {
      if (item.type === "text2") {
        return (
          <LargeFormInput
            item={item}
            props={props}
            actionOnChange={props.actionOnChange}
            isDisableOpt={props.isDisableOpt}
            defaultValueInput={props.defaultValueInput}
            textFileType={props.textFileType}
          />
        );
      } else if (item.type === "date2") {
        return (
          <div style={{ display: "block", marginTop: "1rem" }}>
            <h3>{item.label}</h3>
            <LargeFormDate
              item={item}
              props={props}
              actionOnChange={props.actionOnChange}
              isDisableOpt={props.isDisableOpt}
            />
          </div>
        )
      } else if (item.type === "button2") {
        return (
          <LargeFormButton
            item={item}
            props={props}
            actionOnClick={props.actionOnClick}
            isDisableOpt={props.isDisableOpt}
          />
        )
      } else if (item.type === "detailList") {
        return (
          < div className="w10 pgt10 " >
            <h3>{item.label}</h3>
            <DetailsListBext
              enableShimmer={isLoadingData}
              listKey={item.key}
              selectionMode={0}
              compact={true}
              items={[{ day: "Lunes", id: "1" }, { day: "Martes", id: "2" }, { day: "Miércoles", id: "3" }, { day: "Jueves", id: "4" }, { day: "Viernes", id: "5" }, { day: "Sábado", id: "6" }, { day: "Domingo", id: "7" }]}
              renderItemColumn={defaultRenderItemColumnDays}
              columnTitles={COLUMN_TITLES_DAYS}
            />
          </div>
        )
      }
      else {
        return (

          <InputFile
            name={item.key}
            defaultValue={
              !item.defaultValue ? "" : `${item.defaultValue}`
            }
            key={item.id}
            label={item.label}
            type={item.type}
            style={stateWidth < 300 ? "w10" : "w4"}
            placeholder={item.placeholder}
            required={item.required}
            disable={item.disable}
            maxLength={item.max}
            minLength={item.min}
          />
        );
      }
    }
  };

  const disabled = props.buttonDisable
  return (
    <>
      {!edit ?
      <div>
      {Object.entries(controlsPage).length > 0 ?
        <div>
          <form
            name="formulario"
            className="form--container-panel"
            onSubmit={(e) => onSubmitEvent(e)}
          >
            <div className="form--container-title">
              <h4>{props.formTitle}</h4>
            </div>
            <div
              onLoad={calcWidth("dsf")}
              className={`form--container-body ${stateWidth < 300 ? "form--container-body-small" : ""
                }`}
              {...props.style ? { style: props.style } : ""}
            >
              {props.Items.map((item) => addForm(item, props))}

              <div className="w10">
                <center>
                  {props.keyForm =="workDay" ? 
                  <button
                  // className="button--default button--primary mg-hw pala"
                  className={`button--default ${disabled ? 'button--disable' : 'button--primary'} mg-hw pala`}
                  type="submit"
                  disabled={disabled}
                >
                  {props.editCalendar ? controlsPage?.smtSave.value : controlsPage?.smtAdd.value}
                </button>
                  :
                  <button
                  // className="button--default button--primary mg-hw pala"
                  className={`button--default ${disabled ? 'button--disable' : 'button--primary'} mg-hw pala`}
                  type="submit"
                  disabled={disabled}
                >
                  {edit ? controlsPage?.smtSave.value : controlsPage?.smtAdd.value}
                </button>
                }
                  {edit ? <button
                    className="button--default button--second mg-hw pala"
                    type="button"
                    onClick={() => { props.formEdit(null); setEdit(null) }}
                  >
                    {controlsPage?.smtCancel.value}
                  </button> : ''}
                </center>
              </div>
              <div className="form--container-subtitle">
                <h4>{props.titleTable}</h4>
              </div>
            </div>

            <div className="w10" style={{ display: "flex" }}>
              {props.keyForm == "workDay" ? (isLoadingData || (!isLoadingData && daysList.length > 0)) ?
                <div className="w5">
                  <h2 htmlFor="">Días Asociados</h2>
                  <DetailsListBext
                    enableShimmer={isLoadingData}
                    listKey="workDaylist"
                    selectionMode={0}
                    compact={true}
                    items={daysList}
                    renderItemColumn={defaultRenderItemColumnWorkDay}
                    columnTitles={COLUMN_TITLES_WORKDAYS}
                    className="w9"
                  //onSelect={selectSchema}
                  />
                </div>
                : ""

                : ""}
              {props.keyForm == "workDay" ? (isLoadingData || (!isLoadingData && noWorkDays.length > 0)) ?
                <div className="w4">
                  <h2 htmlFor="">{controlsPage?.lblHolidays.value}</h2>
                  <DetailsListBext
                    enableShimmer={isLoadingData}
                    listKey="workDaylist"
                    compact={true}
                    selectionMode={0}
                    items={noWorkDays}
                    renderItemColumn={defaultRenderItemColumnNoWorkDay}
                    columnTitles={COLUMN_TITLES_NOWORKDAYS}
                    className={"w8"}
                  //onSelect={selectSchema}
                  />
                </div>
                : ""

                : ""}
            </div>
              {
                props.keyForm !== "workDay" ?
              (isLoadingData || (!isLoadingData && schemasList.length > 0)) ?
                <DetailsListBext
                  enableShimmer={isLoadingData}
                  listKey="domainAsociate"
                  selectionMode={0}
                  items={schemasList}
                  renderItemColumn={defaultRenderItemColumn}
                  columnTitles={columnTitles}
                //onSelect={selectSchema}
                /> :
                <div className="form--container-subtitle">
                  <h5 style={{"margin-left":"1.7%"}}>{controlsPage?.lblNoAssociatedScheme.value} </h5>
                </div>
                :""
              }
          </form>

            <center>
            <button
              className="button--default button--second mg-hw pala"
              type="button"
              onClick={props.closePanel}
            >
              {controlsPage?.smtClose.value}
            </button>
            </center>
        </div>        :
        ''} </div>
        : <div> {Object.entries(controlsPage).length > 0 ?
          <div>
            <form
              name="formulario"
              className="form--container-panel"
              onSubmit={(e) => onSubmitEvent(e)}
            >
              <div className="form--container-title">
                <h4>{props.formTitle}</h4>
              </div>
              <div
                onLoad={calcWidth("dsf")}
                className={`form--container-body ${stateWidth < 300 ? "form--container-body-small" : ""
                  }`}
                {...props.style ? { style: props.style } : ""}
              >
                {props.Items.map((item) => {
                  return item.key !== 'idSchema' && item.key !== 'isPrimary' ?
                  addForm(item, props) : 
                  item.key !== 'isPrimary' ?
                <LargeFormSelect item={
                       {id: 1,
                       label: 'Seleccione esquema',
                       type: 'select2',
                       placeholder: 'Selecciona',
                       //defaultValue: '',
                       key: 'idSchema',
                       defaultValue: optionIdSchema,
                       //defaultValue:null,
                       //selectedKey:1,
                       className: 'select2',
                       options: props.arraySchema,
                       idHtml: "lblSelectScheme"}
                } props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} itemsComponentInformation={props.itemsComponentInformation} defaultSelectedKeys={props.defaultSelectedKeys} />
                :
                <LargeFormSelect item={
                  {id: 1,
                  label: '¿Es esquema principal?',
                  type: 'select2',
                  placeholder: 'Selecciona',
                  //defaultValue: '',
                  key: 'isPrimary',
                  defaultValue: optionIsPrimary,
                  //defaultValue:null,
                  //selectedKey:1,
                  className: 'select2',
                  options: [{ key: false, text: 'No' },
                  { key: true, text: 'Si' }],
                  idHtml: "lblIsTheMainSchematic"
                }
           } props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} itemsComponentInformation={props.itemsComponentInformation} defaultSelectedKeys={props.defaultSelectedKeys} />
              })}
                <div className="w10">
                  <center>
                    <button
                    // className="button--default button--primary mg-hw pala"
                    className={`button--default ${disabled ? 'button--disable' : 'button--primary'} mg-hw pala`}
                    type="submit"
                    disabled={disabled}
                  >
                    {edit ? controlsPage?.smtSave.value : controlsPage?.smtAdd.value}
                  </button>
  
                    {edit ? <button
                      className="button--default button--second mg-hw pala"
                      type="button"
                      onClick={() => { props.formEdit(null); setEdit(null) }}
                    >
                  {controlsPage?.smtCancel.value}   
                 </button> : ''}
                  </center>
                </div>
              </div>
              <center>
              <button
                className="button--default button--second mg-hw pala"
                type="button"
                onClick={props.closePanel}
              >
                {controlsPage?.smtClose.value}
              </button>
              </center>
            </form>
          </div>        :
          ''}</div>
      }
    </>
  );
};

export default ContainerForm;
