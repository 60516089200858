//*****librarys*****//
  import React from "react";
  import { render } from "react-dom";
  import "react-app-polyfill/ie11";
  import "react-app-polyfill/stable";
  import {BrowserRouter} from "react-router-dom";
  import images from './assets/img/images'
//*****Style*****//
  import './assets/scss/index.scss';

//*****Component*****//
  import RouterViews from './router/router';


//*****App Rendering*****//
  render(<RouterViews/>,
    document.getElementById("root")
  );
