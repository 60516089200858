//****Librarys ****//
import React, { useContext, useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { RequestFetch } from "../../utils/Requests/requests.Controller";
import { useParams } from "react-router-dom";
import CookiesJS from "js-cookie";
import { useNavigate } from "react-router-dom";
//*****Componnents*****//
import HeaderForm from "../../components/Forms/headerForm/headerForm.component";

//*****File*****//
import { ReactComponent as IconData } from "../../assets/img/ico-datos.svg";
import ContainerBaseLoading from "../../components/containers/baseLoading/containerBaseLoading.component";
import LargeFormInput from "../../components/containers/largeForm/components/LargeFormInput";
import LenguajeContext from "../../context/Lenguaje/lenguajeContext";

//**Methods**//

/**
 *@author Sebastian Ocampo(02-08-2022)
 *@version 1.0
 *@lastCommit
 */
const DataUser = (props) => {
  /************************
   * @arguments
   ************************/
   
  const navigate = useNavigate();
  const requestsAttribute = new RequestFetch("Attribute/values");
  const requestsUser = new RequestFetch("Users");
  const { clientName } = useParams();
  const [buttonDisable, setButtonDisable] = useState(true)
  const dataUserSession = JSON.parse(CookiesJS.get("dataUser"));
  const dataUserIdLng = JSON.parse(CookiesJS.get("idLng"));
  const [arrayAttributes, setArrayAttributes] = useState([]);
  const [renderForm, setRenderForm] = useState([]);
  const [isDisable, setDisable] = React.useState([
    { disable: false, key: "valueDefault" },
    { disable: true, key: "name" },
  ]);
  const [controlsPage, setControlsPage] = useState({});
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const [itemsComponentInformation, setItemsComponentInformation] = useState(
    []
  );
  const [defaultValueInput, seDefaultValueInput] = useState([]);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([]);
  const [idUser, setIdUSer] = useState(dataUserSession.user.id);
  const [idEditables, setIdEditables] = useState([])

  let listSchemasUser = [];

  //#region Constantes de accion para los componentes
  const actionOnChange = (data) => {
    
    if (data.selectedItem != "" && data.selectedItem != undefined && data.selectedItem != data.defaulvalue) {
        setButtonDisable(false)
    }
    else{
      setButtonDisable(true)
    }
    if (data.id === "listSchemasUser" && data.key !== undefined) {
      setRenderForm([]);
      setRenderForm(arrayAttributes[data.key]);
    }
  };

  const blackItem = (value, blackList) =>{
    let valid = true
    if (blackList.length > 0) {
      blackList.forEach((item) => {
         if (item == value){
           valid = false
        }
      })
    }
    return valid
  }
  const onSubmitEventFormPanel = (data) => {
    data.preventDefault();
    let dataFormSubmit = [];
    let acceptTermsConditions = false;
     for (let entry of data.target) {
       if (entry.localName === "input" && entry.type !== "checkbox" && blackItem(entry.id,idEditables)) {
         dataFormSubmit.push({
           op: "replace",
           path: entry.id,
           value: entry.value,
         });
       }
       if (entry.type === "checkbox") {
         acceptTermsConditions = entry.checked;
       }
     }
    
    requestsUser.confirmRequest({
      body: dataFormSubmit,
      id: idUser,
      ct: "application/json-patch+json",
      tenant: clientName,
      option: "patchItem",
      acceptTermsConditions: acceptTermsConditions,
      op:'b',
      headerEx: {idLanguageUser:dataUserIdLng?dataUserIdLng:dataUserSession.user.idLanguage},
      infoModal:{
        title:controlsPage?.lblSuccessfulProcess?.value,
        description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
      }
    });
  };
  
  useEffect(() => {
    const footerStyle = document.querySelector('.admin--container-footer');
    footerStyle?.classList.add('div-foot-in');
    props.onChange('div-foot-in')
  }, [props])

  const typeInput = (data) =>{
    let inpType = ''

    if (data.isEmail) {
      inpType='email'
    }

    if (data.isPhone) {
      inpType='number'
    }
    return inpType

  }


  useEffect(async() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        stateLanguage.lenguaje.map((pass) => {
            if (pass.path == 'query-update-data' || pass.path == 'controls')  {
              pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })  
            }              
        })          
      setControlsPage(controlsPage)
    }
  }, [stateLanguage])
  const actionOnClick = (data) => {};
  //#endregion
  /************************
   * @methods
   ************************/
  //#region Consultar el id del usuario
  const [promiseAuthenticationFactor, setPromiseAuthenticationFactor] =
    useState();

  useEffect(async() => {
    let arrayTemporary = [];
    let idsBlack = []
    if (arrayAttributes.length>0) {
      window.location.reload()
    }
    const form = await requestsAttribute.getItem({ tenant: clientName, headerEx: {idLanguageUser:dataUserIdLng?dataUserIdLng:dataUserSession.user.idLanguage}})
    form.data.data.map(idEdit=>{
    if (!idEdit.isEditable) {
        idsBlack.push(idEdit.id)
      }
    })
    setIdEditables(idsBlack)

    form.data.data.map(inp =>{
      inp = {...inp,...{typeInput:typeInput(inp)}}
      arrayTemporary.push(
        inp
      )
      setArrayAttributes([...arrayAttributes,...arrayTemporary]);
    })
  
  }, [CookiesJS.get("dataUser"),])
  

  

  //#endregion

  /************************
   * @hooks
   ************************/

  /************************
   * @resturns
   ************************/
  if (arrayAttributes.length === 0 || Object.entries(controlsPage).length === 0) {
    return <ContainerBaseLoading />;
  }
  return (
    <>
      <HeaderForm
        iconurl={<IconData fill="var(--color-primary)" className="claso"/>}
        title={controlsPage?.lblUserData?.value}
        subtitle={controlsPage?.lblProfileData?.value}
      />

      <form
        className="c--flex-wse w10"
        onSubmit={(e) => onSubmitEventFormPanel(e)}
        style={{marginBottom:'10%'}}
      >
        <div className="prueb">
          <div className={props.isAdmin?"scrollUserAdmin":"scrollUserFinal"}>

        { arrayAttributes.map(inp=>{
          return(
            <LargeFormInput
              item={{
                id: inp.attribute,
                key: inp.attribute,
                label: inp.attribute,
                ariaLabel: null,
                defaultValu: inp.value
                  ? inp.value
                  : "",
                  defaultValue: inp.value
                  ? inp.value
                  : "",
                disabled: true,
                disable:!inp.isEditable,
                resizable: false,
                typeInput: inp.typeInput,
                description: "",
                title: null,
                onRenderLabel: null,
                isRequired: false,
                onGetErrorMessage: null,
                errorMsg:true,
                className:props.isAdmin?'w45 fl w9-resp':'w5 fl w10-resp'
              }}
              actionOnChange={actionOnChange}
              actionOnClick={actionOnClick}
              isDisableOpt={isDisable}
              onSubmitEventFormPanel={onSubmitEventFormPanel}
              itemsComponentInformation={itemsComponentInformation}
              defaultValueInput={defaultValueInput}
              defaultSelectedKeys={defaultSelectedKeys}
            />
          )
        })
      }
          </div>
          </div>
        <Stack
          style={{
            width: "70%",
            "margin-left": "auto",
            "margin-right": "auto",
            "padding-bottom": "1rem",
            "padding-top": "1rem",
          }}
        >
          
        </Stack>
        <Stack
          style={{
            width: "20%",
            marginLeft: "auto",
            marginRight: "42%",
          }}
        >
          <button
            type="submit"
            className={`button--default-pass ${buttonDisable?'button--disable':'button--primary'}`}
            disabled={buttonDisable}
          >
            {controlsPage?.smtSave?.value}
          </button>
        </Stack>
      </form>
    </>
  );
};

export default DataUser;
