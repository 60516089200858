export const COLUMN_TITLES = [{
    startRange: {
      name: "IP inicial",
      maxWidth: 200,
      isMultiline: true,
      idHtml:"coluInitialIp"
    },
    endRange: {
      name: "IP final",
      maxWidth: 200,
      isMultiline: true,
      idHtml:"coluEndIp"
    },
    creatingDate: {
        name: "Fecha de creación",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
        idHtml: "coluCreationDate",
    },
    updatingDate: {
        name: "Última Modificación",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
        idHtml: "coluLastModification",
    },
    creatorUser: {
      name: "Creado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "coluCreatedBy",
    },
    updateUser: {
      name: "Modificado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "lblModifiedBy"
    },
  }];
  
  export const IP_RESTRICTION_FORM = [
    {
      id: 0,
      key: "startRange",
      label: "Ip inicial",
      type: "text2",
      defaultValue: "",
      multiline: false,
      resizable: false,
      typeInput: "text",
      description: "La dirección IP se debe ingresar con puntos.",
      idHtmlForDesc:"lblTheIpAddressMustEnteredWithDots",
      onRenderLabel: null,
      isRequired: true,
      required: true,
      sizeInput: 16,
      defaultValu: "",
      onGetErrorMessage: [{ messageValidateSize: "Formato de Ip no valido" }],
      className: 'w10',
      idHtml:"lblInitialIp"
    },
    {
      id: 1,
      key: "endRange",
      label: "Ip final",
      type: "text2",
      defaultValue: "",
      multiline: false,
      resizable: false,
      typeInput: "text",
      description: "La dirección IP se debe ingresar con puntos.",
      idHtmlForDesc:"lblTheIpAddressMustEnteredWithDots",
      onRenderLabel: null,
      isRequired: true,
      required: true,
      sizeInput: 16,
      defaultValu: "",
      onGetErrorMessage: [{ messageValidateSize: "Formato de Ip no valido" }],
      className: 'w10',
      idHtml:"lblEndIp"
    }
  ]
  