import { Toggle } from "@fluentui/react/lib/Toggle";
import { Stack, IStackTokens } from "@fluentui/react/lib/Stack";
import React, { useState, useEffect, useMemo } from "react";
import { useBoolean } from "@fluentui/react-hooks";
const LargeFormToggle = (props) => {
  let item = props.item;
  const stackTokens = { childrenGap: 20 };
  const [selectedItem, setSelectedItem] = useState();

  //#region Buscar en un arreglo el id para habilitar y deshabilitar
  let found;
  if (props.isDisableOpt.length === 1) {
    found = props.isDisableOpt.find(
      (element) => element.key === "valueDefault"
    );
  } else {
    found = props.isDisableOpt.find((element) => element.key === item.key);
    if (found === undefined) {
      found = props.isDisableOpt.find(
        (element) => element.key === "valueDefault"
      );
    }
  }
  if (found === undefined) {
    found = props.isDisableOpt.find((element) => element.key === item.key);
  }
  //#endregion
  //#region Accion onChange
  const [valueCheck, setvalueCheck] = useState(false);

  const onChange = (e, value) => {
    setSelectedItem(value);
    setvalueCheck(value)
  };
  useEffect(() => {
    setvalueCheck(item.checked)
   }, [item.checked]);
  useEffect(() => {
    let objectFinal = {
      ...(selectedItem === undefined
        ? { selectedItem: item.defaultValue }
        : { selectedItem }),
      ...{ id: item.key, state: found.disable },
    };
    props.actionOnChange(objectFinal);
  }, [selectedItem, found]);
  //#endregion

  return (
    <div className={item.className}>
    <Stack style={item.styleStackFather} id={item.id}>
      <Stack style={item.styleStack} id={item.key}>
        <Stack tokens={stackTokens}>
          <Toggle
            label={item.label}
            defaultChecked={item.defaultValue}
            checked={valueCheck}
            onText={item.onText}
            offText={item.offText}
            onChange={onChange}
            disabled={item.disable}
            defaultValue={item.defaultValue}
          />
        </Stack>
      </Stack>
    </Stack>
    </div>
  );
};
export default LargeFormToggle;
