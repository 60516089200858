export const COLUMN_TITLES = [{
    idNumber: {
        name: "Nº",
        idHtml: "coluNumber",
        maxWidth: 30
    },
    username: {
        name: "Nombre",
        idHtml: "coluName",
        isMultiline: true
    },
    domain: {
        name: "Dominio",
        idHtml: "coluDomain"
    },
    assignedTo: {
        name: "Asignado a",
        idHtml: "coluAssingto"
    },
    rol: {
        name: "Rol",
        idHtml: "coluRole"
    },
    updatingDate: {
        name: "Ultima modificación",
        idHtml: "coluDateChange",
        minWidth: 180
    }
}];

export const USER_ROLE_ASSIGNMENT_TITLE = [{
    idNumber: {
        name: "Nº",
        idHtml: "coluNumber",
        maxWidth: 30
    },
    username: {
        name: "Nombre",
        idHtml: "coluName",
        isMultiline: true
    },
    domain: {
        name: "Dominio",
        idHtml: "coluDomain"
    },
    actions: {
        name: ""
    }
}]

export const USER_SELECTED_ROLE_ASSIGNMENT_TITLE = [{
    idNumber: {
        name: "Nº",
        idHtml: "coluNumber",
        maxWidth: 30
    },
    username: {
        name: "Usuario",
        idHtml: "coluUser",
        isMultiline: true
    },
    role: {
        name: "Rol",
        idHtml: "coluRole"
    },
    date: {
        name: "Fecha de asignacion",
        idHtml: "coluAssingDate",
        minWidth: 180
    },
    actions: {
        name: "",
    }
}]

export const DOMAIN_SELECTED_ROLE_ASSIGNMENT_TITLE = [{
    idNumber: {
        name: "Nº",
        idHtml: "coluNumber",
        maxWidth: 30
    },
    domain: {
        name: "Dominio",
        idHtml: "coluDomain",
        isMultiline: true
    },
    role: {
        name: "Rol",
        idHtml: "coluRole"
    },
    date: {
        name: "Fecha de asignacion",
        idHtml: "coluAssingDate",
        minWidth: 180
    },
    actions: {
        name: ""
    }
}]

