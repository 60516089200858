//****Librarys ****//
import React, { useContext,useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CookiesJS from "js-cookie";
import qs from 'qs'
//*****File*****//
import { ReactComponent as IconQueries } from '../../assets/img/ico-preguntas.svg';

//*****Controller*******//
import { RequestFetch,consulta } from '../../utils/Requests/requests.Controller'
//*****Componnents*****//
import HeaderForm from '../../components/Forms/headerForm/headerForm.component';
import InputFile from '../../components/Forms/inputFile/inputFile.component';
import SelectFile from '../../components/Forms/selectFile/selectFile.component';
import ContainerBaseLoading from '../../components/containers/baseLoading/containerBaseLoading.component'
import { render } from 'react-dom';
import ModalFluent from '../../components/tools/modalFluent/ModalFluent';
import LenguajeContext from '../../context/Lenguaje/lenguajeContext';
import { PATH_APPLICATION, PATH_SSO, SSO_URI } from '../../assets/settings/settinsrouters';

//**Methods**//

/**
 *@author Sebastian Ocampo(12-05-2022)
 *@version 1.0
 *@lastCommit 
 */

const AssoQuestions = (props) =>{          
    /************************
     * @arguments 
     ************************/
    const navigate = useNavigate()
     const token = CookiesJS.get("token");
    const { clientName } = useParams();
    const Requests = new RequestFetch('SecurityQuestions');
    const domainRequest = new RequestFetch('Domains');
    const dataUser = JSON.parse(CookiesJS.get("dataUser"));
    const dataToken = JSON.parse(CookiesJS.get("dataToken"));
    const requestUserSecurityQuestion = new RequestFetch('UserSecurityQuestion/AssociateSecurityQuestions');
    const [requestSecurityQuestions, setRequestSecurityQuestions] = useState(null);
    const [items, setItems] = useState([]);
    const [errorMesage,setErrorMesage]=useState(false);
    const [errorInput, setErrorInput] = useState([])
    const [infoIndex, setInfoIndex] = useState(null)
    const [showError, setShowError] = useState(true)
    const [questions, setQuestions] = useState([]);
    const userText = new RequestFetch('Pages/userTexts')
    const [textOnPages, setTextOnPages] = useState({})
    const [controlsPage, setControlsPage] = useState({});
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
    const [isLoad, setIsLoad] = useState(false);
    const [questionsSelected, setQuestionsSelected] = useState([])
    const [noLoad, setNoLoad] = useState(true)
    let arrayItems = [];

    useEffect(() => {
        const footerStyle = document.querySelector('.admin--container-footer');
        footerStyle?.classList.add('div-foot-rel');
        props.onChange('div-foot-rel')
      }, [props])
    /************************
     * @methods 
     ************************/
     const indexedDB = window.indexedDB
     let db

     const requestInfo = indexedDB.open('textListApp',1)
     useEffect(() => {
         requestInfo.onsuccess =async()=>{
             // alert('sdsdsd')
             db = requestInfo.result
             setTextOnPages(await consulta(db)) 
         }
     }, [])
     
     useEffect(async() => {
        let controlsPage = {}
        if (stateLanguage.lenguaje !=0) {
        stateLanguage.lenguaje.map((spd) =>{
            if(spd.path == 'associate-security-questions') {
                spd.webcontrolspages.map((data)=>{
                    controlsPage[data.idHtml] = {...data.languagewebcontrols[0],...{maxlength:data.maxlength,minlength:data.minlength}};
                })
            }
        })
        setControlsPage(controlsPage)
        arrayItems.push({id:"", text:controlsPage?.lblSelectAQuestion?.value})
        if (showError) {
            getInfo()
            setShowError(false)
        }
    }
    }, [stateLanguage])
    

     const methosRequests = {
        crateItem:(dataForm)=>{
            setIsLoad(true);
            dataForm.preventDefault();
            let _dataForm = [];
            questions.map(resp =>{
                _dataForm.push({
                    idSecurityQuestion: parseInt(resp.question),
                    reply: resp.answer
                })
            });
            const result =  qs.stringify({"userSecurityQuestion": JSON.stringify(_dataForm)})
            const secQuestion = requestUserSecurityQuestion.createItem({
                body: result,
                ct:'encode',
                tenant: clientName,
                headerEx:{idLanguageUser:dataUser.user.idLanguage}, 
                infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                }
            }).then((response)=>{
                setIsLoad(false);
                if(response.status == 200){
                    let info ={
                        title: controlsPage.lblSuccessfulValidation?.value,
                        description: controlsPage.lblTheSecurityQustionsWereCorrectlyAssociated?.value,
                        type: "success",
                      }
                    render(
                        <ModalFluent
                            props={info}
                            actionOnClick={_ => {oncli();}}
                            errorGet={true}
                            op={_ => {oncli();}}
                        />,

                        document.getElementById("modal")
                    );
                }
            }
            ).catch((error)=>{
                setIsLoad(false);
                let errors = error.response.data.errors
                let desc = ''
                errors.map(err=>{
                    desc= err.code+ ' - '+ err.detail
                })
                let info ={
                    title: errors[0].title,
                    description: desc,
                    type: "danger",
                    }
                render(
                    <ModalFluent
                        props={info}
                        actionOnClick={_ => {
                            CookiesJS.remove('token')
                            CookiesJS.remove('dataUser')
                            const DBDeleteRequest = window.indexedDB.deleteDatabase("textListApp");

                            DBDeleteRequest.onerror = (event) => {
                            console.error("Error deleting database.");
                            };

                            DBDeleteRequest.onsuccess = (event) => {
                            console.log("Database deleted successfully");
                            };
                            window.location.href = `${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`
                        }}
                    />,
                    document.getElementById("modal")
                );
            })
            
        }
     }
     
     const _confirmationModalBody = (title, description) => {
        return <div className="container--flex c--flex-dc c--flex-hc">
            <h3 className="mg10-h">{title}</h3>
            <h4 className="mg10-h txt--aling-c">{description}</h4>
        </div>
    }


    const oncli = () =>{
        let pa = window.location.href.split('/')
        if(pa.find(element => element == 'user') == 'user'){
            navigate(`${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/${clientName}/user`)
        }
        if(pa.find(element => element == 'administration') == 'administration'){
            navigate(`${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/${clientName}/administration`)
        }
    }
     
    /************************
     * @hooks 
     ************************/
    // const [promise, setPromise]= useState(Requests.getItem({
    //     param:{
    //         idDomain: dataUser.user.idDomain,
    //         idLanguage: dataUser.user.idLanguage
    //     },
    //     tenant: clientName
    // }))

    // useEffect(() => {

    //     setPromise(Requests.getItem({
    //         param:{
    //             idDomain: dataUser.user.idDomain,
    //             idLanguage: dataUser.user.idLanguage
    //         },
    //         tenant: clientName
    //     }))

    // }, [CookiesJS.get("dataUser")])
    
    useEffect(() => {
        if (infoIndex) {
            
            if(infoIndex[0].length < 3 || infoIndex[0].length > 60 ){
              setErrorMesage(true)
            }
            else{
                setErrorMesage(false)
            }
        }
    }, [infoIndex])
    
    useEffect(() => {
        if (items.length>1) {
            setNoLoad(true)
        }
    }, [items])
    
    // useEffect(async () => {
        const getInfo = async()=>{
        setNoLoad(false)
        const domainData = await domainRequest.getById({
            id: dataUser.user.idDomain,
            tenant: clientName,
            ct: 'json'
        })
        setRequestSecurityQuestions(domainData.data.data.requestSecurityQuestions);
        const promise = await Requests.getItem({
            param:{
                idDomain: dataUser.user.idDomain,
                idLanguage: dataUser.user.idLanguage
            },
            tenant: clientName
        })
        promise.data.data.map(element=>{
            arrayItems.push({
                id: element["id"],
                text: element["question"], 
            });
        })
        setItems(arrayItems);
    }
    // },[controlsPage])

    useEffect(() => {
        setQuestions(
            [...Array(requestSecurityQuestions).keys()]
                .map( _ => ({
                    question: '',
                    answer: ''
                }))
        )
    },[requestSecurityQuestions,controlsPage])




    useEffect(()=>{
        if(questions){
            setQuestionsSelected(questions
                .filter(item => item.question)
                .map(item => item.question)
            );
        }
    },[questions])
    /************************
     * @resturns
     ************************/
    if(items && requestSecurityQuestions && Object.entries(controlsPage).length>0 && noLoad){  
        return( 
            <>
            {isLoad?

            <ContainerBaseLoading />

            :''
}
            <form onSubmit={methosRequests.crateItem} className="w10 c--flex-wse container--flex" style={{marginBottom:'10%'}}>
                <HeaderForm iconurl={<IconQueries fill="var(--color-primary)" className="claso"/>} url="/user" title={controlsPage?.lblAssociateQuestion?.value} subtitle={controlsPage?.lblAssociateSecurityQuestions?.value} />
                {questions.map( (question, index) => {
                    return <>
                        <SelectFile
                            value={question.question}
                            options={
                                items.filter( ({id}) => questions[index].question !== (String(id)) ? !questionsSelected.includes(String(id)) : true)
                            }
                            placeholder='jsdsjdj'
                            onChange={
                                event => {
                                    setQuestions([
                                        ...questions.slice(0, index),
                                        {
                                            ...questions[index],
                                            question: event.target.value
                                        },
                                        ...questions.slice(index + 1)
                                    ])
                                }
                            }
                            id={index}
                        />
                        <InputFile
                            type="password"
                            style="wb"
                            max = {controlsPage?.txtAnswer?.maxlength}
                            min={controlsPage?.txtAnswer?.minlength}
                            id={index}
                            onChange={
                                event => {
                                    if(event.target.value.length < 3 && event.target.value.length > 60){
                                        setErrorInput(index)
                                    }else{
                                        setErrorInput([])
                                    }
                                    setQuestions([
                                        ...questions.slice(0, index),
                                        {
                                            ...questions[index],
                                            answer: event.target.value
                                        },
                                        ...questions.slice(index + 1)
                                    ])
                                    setInfoIndex([event.target.value, index])
                                }
                            }
                        />
                        {/* {errorMesage && errorInput == index ?
                        
                            <div className='message-error'>
                                <p className='paragraf-error'>Ingrese mas caracteres</p>
                            </div>
                        :''} */}
                    </>})}
                <button type="submit" className="button--default-pass button--primary">{controlsPage?.smtSave?.value}</button>
            </form>
            </>  
        )
    }else{
        return (<ContainerBaseLoading />)
    }
}

export default AssoQuestions;

