// import React from 'react'
// import { CalendarComponent} from '@syncfusion/ej2-react-calendars';

// export const LargeFormDate = (props) => {
//     const values: Date[] = [new Date('3/1/2022'), new Date('1/15/2020'), new Date('1/3/2020'), new Date('1/25/2020')];
//     return (
//         <div>
//             <CalendarComponent id="calendar" isMultiSelection={true}  />
//         </div>
//     )
// }
// export default LargeFormDate;


import * as React from 'react';
import {
  Calendar,
  DayOfWeek,
  Dropdown,
  IDropdownOption,
  mergeStyles,
  defaultDatePickerStrings,
} from '@fluentui/react';
import { useState } from 'react';
import { itemLayoutClassName } from '@fluentui/react-northstar';
const rootClass = mergeStyles({ maxWidth: 300, selectors: { '> *': { marginBottom: 15 } } });

const format = "MM/DD/YYYY";

const LargeFormDate = (props) => {

    let item= props.item
    const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(DayOfWeek.Sunday);
  const [dates, setDates] = useState([]);

  const onFormatDate = (date)=> {
    return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() % 100);
  };

  const days = [
    { text: 'Domingo', key: 1 },
    { text: 'Lunes', key: 2 },
    { text: 'Martes', key: 3 },
    { text: 'Miércoles', key: 4 },
    { text: 'Jueves', key: 5 },
    { text: 'Viernes', key: 6 },
    { text: 'Sábado', key: 7 },
  ];
const onchange = (e) =>{
  //if(props.item.key="nonWorkingDay"){}
  if(props.props.keyForm == "workDay"){
    props.actionOnChange({id:"nonWorkingDay",selectedItem:e})
  }
}
  return (
    <div className={item.className}>
      <Calendar
        firstDayOfWeek={firstDayOfWeek}
        placeholder="Seleccione fecha"
        ariaLabel="Select a date"
        // DatePicker uses English strings by default. For localized apps, you must override this prop.
        strings={defaultDatePickerStrings}
        formatDate={onFormatDate}
        onSelectDate={onchange}
        // parseDateFromString={onParseDateFromString}
      />
    </div>
  );
}
export default LargeFormDate;
