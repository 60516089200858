import {Stack } from "@fluentui/react/lib/Stack";
import { Label } from '@fluentui/react/lib/Label';
const LargeFormLabel = (props) => {
    let item = props.label ? props.label : props.item
    return(
      <Stack style={item.styleStackFather} id={item.id}>
        
        <Stack style={item.styleStack} id={item.key}>
    
    <Label 
        {...props.isDisableOpt? {disabled:item.disabled} : ""} 
        {...item.required ? {required:item.required} : ""} 
        >{item.defaultValue ? item.defaultValue : item.label}</Label>
        </Stack>
        </Stack>
        );
}
export default LargeFormLabel;