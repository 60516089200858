import React, { Fragment, useContext, useEffect, useState } from 'react';
//import {DefaultButton} from 'office-ui-fabric-react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import CookiesJS from 'js-cookie'
import { Link as RouterLink } from 'react-router-dom';
import { isElementVisibleAndNotHidden, Link } from '@fluentui/react';
import { useParams } from "react-router-dom";
//**Components**//
import SelectFile from '../../Forms/selectFile/selectFile.component';
import { FontIcon } from '@fluentui/react';
import LenguajeContext from '../../../context/Lenguaje/lenguajeContext';

//**Methods**//
import ContainerBaseLoading from '../../containers/baseLoading/containerBaseLoading.component';
import { PATH_APPLICATION, URL_REPORTS } from '../../../assets/settings/settinsrouters';
/**
 *@author Yeison Quiroga(14-04-2021)
 *@version 1.0
 *@lastCommit 
 */


const ListHeader=(props)=>{
    
    /************************
     * @arguments 
     ************************/
        const countShow = [5, 10, 15, 20];
        const [showFilter, setShowFilter] = useState(false);
        const [showReport, setShowReport] = useState(props.showReport);
        const [inputValue, setInputValue] = useState('');
        const [icon, setIcon] = useState('Home')
        const [dataT, setDataT] = useState(CookiesJS.get('dataToken'));
        const [dataUser, setDataUser] = useState(CookiesJS.get('dataUser'));
        const DT = JSON.parse(dataT);
        const lastLogin = DT.dateLastLogin;
        const { clientName } = useParams();
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        const [controlsPage, setControlsPage] = useState({});
        // const myarr = lastLogin.split("T");
        /* Hora */
        // const time = myarr[1];
        // const arrTime = time.split(":")
        // const timeStr = arrTime[0] + ':' + arrTime[1]
        /***********/
        
        /* Fecha y hora actual */
        const currentDate = new Date();
        const month = currentDate.getUTCMonth() + 1;
        const day = currentDate.getUTCDate();
        const year = currentDate.getUTCFullYear();
        const fullCurrentDate = day + '/' + month + '/' + year

        const clock = () => {
            setInterval(() => {
                let now = new Date().toLocaleTimeString();
                setTimeNow(now);
            }, 1000)
            
        }
        const [timeNow, setTimeNow] = useState(clock())
        /*****************/
    /************************
     * @methods 
     ************************/
    useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            stateLanguage.lenguaje.map((pass) => {
                if (pass.path == 'support'|| pass.path =='controls' || pass.path =='manage-mfa'|| pass.path == 'administration') {
                    pass.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  })    
                  setControlsPage(controlsPage)
                } 
            })
        }
    }, [stateLanguage])
        const MethodsDate = {
            getDataForm: (event)=>{
                const formData = new FormData(event.target)
                const user = {}
                event.preventDefault()
                for (let entry of formData.entries()) {
                    user[entry[0]] = entry[1]
                }
                props.dataForm(user)
            },
            cleanDataForm: ()=>{
                document.getElementById("filter").reset();
                const user = {}
                props.dataForm(user)
            }
        }

        const MethodsRendering = {
            makeForm: (item)=>{
                if(item.type == 'select'){
                    return(
                        <div className="container--flex c--flex-hc mg5-w mrg-top-resp1 w10-resp">
                            <label className="mg5-w">{item.label}</label>
                            <select name={item.key} className="clr--base bdr--base bkgn--light-III w-inputs-filter w10-resp">
                                {item.placeholder &&
                                    <option value="">{item.placeholder}</option>
                                }
                                {item.options.map(option=>
                                    <option key={option.id} value={option.id}>{option.text}</option>    
                                )}
                            </select>
                        </div> 
                    )
                } else if (item.type == 'text') {
                    return (<div className="container--flex c--flex-hc mg5-w w-inputs-filter w10-resp mrg-top-resp1">
                        {item.label && <label className="mg5-w">{item.label}</label>}
                        <input
                            className='input-filter bdr--base w10-resp'
                            type="text"
                            placeholder={item.placeholder ? item.placeholder : controlsPage?.txtFindMatches?.value}
                            key={item.key}
                            name={item.key}
                        />
                    </div>)
                }
            }
        }

        const handleInputChange = (e) => {
            setInputValue(e.target.value);
            // if(inputValue.length >= 3){
            //     props.InputChange(inputValue)
            // }else{
            //     props.InputChange('')
            // }
        }
useEffect(()=>{
if(props.InputChange!==undefined){
    if(inputValue.length >= 0  ){
    props.InputChange(inputValue)
}else{
    props.InputChange('')
}
}
},[inputValue])
    /************************
     * @hooks 
     ************************/
        function changeIcon(e){
         setIcon('HomeSolid') 
        }
        function changeIcons(e){
            setIcon('Home') 
           }
           
    /************************
     * @resturns 
     ************************/
        return(
            <>
            {Object.entries(controlsPage).length>0?
            <>
            <div className="list--container-header">
                <div className="list--subcontainer-header w10">  
                    <div className="dsp-flex w10">                  
                        {
                            props.subRute ?
                        <p className="history">
                            {props.subRute.split('/').map((pal,key)=>{
                                return(
                                    <>
                                    <label>{pal}</label>
                                    <label className='mg8-w'>{key != props.subRute.split('/').length-1?'/':'|'}</label>
                                    </>
                                )
                            })}
                            <Link as={RouterLink} to={`${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/${clientName}/administration/`}>
                                {props.subRute2}
                            <FontIcon className='arrow' aria-label="Compass" iconName="ChromeBackMirrored" />
                            <FontIcon className='house' iconName= {icon} id='iconHouse'
                                onMouseEnter={ changeIcon}
                                onMouseLeave= {changeIcons}      
                            />
                            
                            </Link>
                            <label className='mg8-w'>/</label>
                            <span className='FinalColor'>{props.subRute3}</span>
                        </p>
                            :""
                        }
                        <div className="end-visit">
                        <h6>{controlsPage?.lblYourLastVisitWas?.value}: { lastLogin } <br/> {controlsPage?.lblCurrentDateAndTime?.value}: { fullCurrentDate }, { timeNow }</h6>
                        </div>
                    </div>
                    <div className="initial-inbox w10 dsp-flex c--flex-he c--flex-wsb">
                        <h1 className={showReport?'w-title mrg-bot-resp':'w10'}>{props.title}</h1>
                        {showReport?<DefaultButton className='align-resp-start button--primary clr--light-III w10-resp' text={controlsPage?.OnClickReports?.value} onClick={()=>{window.open(`${URL_REPORTS}`);}}/>:<span></span>}
                    </div>
                </div>
                {props.targets && Object.entries(controlsPage).length>0?
                    props.targets:
                ''}

                {props.filters && Object.entries(controlsPage).length>0
                   ?<div className="w10 mg10 bkgn--light-I">
                        <div className="w10 container--flex c--flex-wsb" onClick={()=>showFilter?setShowFilter(false):setShowFilter(true)}>
                            <a className="mg8 clr--primary txt--tertiary icon-filter">  {controlsPage?.slcFilters?.value}</a>
                            <a className="mg8 clr--primary txt--tertiary icon-down-open-big"></a>
                        </div>
                        {showFilter
                            ?<form id='filter' className="mg10 container--flex c--flex-wsb" onSubmit={(e)=>MethodsDate.getDataForm(e)} onReset={(e)=>MethodsDate.cleanDataForm(e)}>
                                <div className="container--flex c--flex-f1">
                                    {props.filters.map(filter=>MethodsRendering.makeForm(filter))}
                                </div>
                                <div className="container--flex c--flex-dr w10-resp mrg-top-resp1">
                                    <button type="submit" className="button--default button--border w10-resp mrg-top-resp1">{controlsPage?.lblSearch?.value}</button>
                                    <div className="wr1"></div>
                                    <button type="reset" className="button--default button--border w10-resp mrg-top-resp1">{controlsPage?.smtCleanUp?.value}</button>
                                </div>
                            </form>
                            :''
                        }
                    </div>
                :''}
                <div className="w10 dsp-flex c--flex-wsb c--flex-hc pdg1-w">
                {Object.entries(controlsPage).length>0?
                <>
                {props.hideShowCounter  ? <></> :
                <div className="list--subcontainer-header w5 w10-resp">
                    <p> 
                        {controlsPage?.lblShow?.value}
                        <select onChange={props.onChange} className="mg8-w wr5 sel-resp no-style select-filter" name="countShow">
                            {countShow.map((item)=><option key={item} value={item}>{item}</option>)}
                        </select>
                        {controlsPage?.lblPerPage?.value}
                    </p> 
                </div>
                }
                </>
                :''}
                {props.hideSearch && Object.entries(controlsPage).length>0?
                    <div className="list--subcontainer-header w5 w9-resp">
                        <label htmlFor="">{controlsPage?.lblSearch?.value}&nbsp;</label>
                        <input
                        className='w8-resp'
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                    </div>:''
                }
                </div>
            </div>
            </>:
            <ContainerBaseLoading />
        }
        </>
        );
}

export default ListHeader;
