import React, {useEffect, useState} from "react";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {Panel, PanelType} from "@fluentui/react/lib/Panel";
import {useBoolean} from "@fluentui/react-hooks";
import {useParams} from "react-router-dom";
import {render} from "react-dom";

import SubMenu from "../../../components/navigation/subMenu/subMenu.component";
import ListHeader from "../../../components/lists/listHeader/listHeader.component";
import DetailsListBext from "../../../components/lists/DetailsList/detailsList.component";
import {RequestFetch} from "../../../utils/Requests/requests.Controller";
import ModalFluent from "../../../components/tools/modalFluent/ModalFluent";

import IndividualAssignment from "./individualAssignment";
import {COLUMN_TITLES} from "./constants";
import MassiveAssignment from "./massiveAssignment";
import UserLicencesReport from "./userLicencesReport";
import { changeLang, changeLangForm } from '../../../utils/logicRendering/changeIdiom';


const UserLicences = () => {
    const domainRequest = new RequestFetch('Domains');
    const usersRequest = new RequestFetch('Users');
    const usersLicencesRequest = new RequestFetch('Users/licensesStatus');

    const {clientName} = useParams();
    const [licenceSelected, setLicenceSelected] = useState();
    const [filtersList, setFiltersList] = useState([]);
    const [domainForm, setDomainForm] = useState();
    const [licenceList, setLicenceList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [templatePanel, setTemplatePanel] = useState();
    const [columnTitles, setColumnTitles] = useState(COLUMN_TITLES)
    const [filter, setFilter] = useState({
        pageSize: 5,
        pageNumber: "0",
        idDomain: ""
    });

    const [isPanelOpen, {
        setTrue: handleOpenPanel,
        setFalse: handleDismissPanel
    }] = useBoolean(false);
    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);

    useEffect(async () => {
        const domainData = await domainRequest.getItem({
            tenant: clientName
        })
        setDomainForm(domainData.data.data);
    }, [])

    useEffect(() => {
        if (filter.pageNumber > 0) {
            requests.getUsers(filter, clientName);
        }
    }, [filter])

    useEffect(() => {
        if (domainForm) {
            setFiltersList([
                {
                    id: "search-value",
                    key: 'searchValue',
                    type: 'text',
                    label: '',
                    placeholder: "Nombre de Usuario"
                },
                {
                    id: "domain-select",
                    key: 'idDomain',
                    label: '',
                    type: 'select',
                    placeholder: "Seleccione dominio",
                    options: domainForm.map(
                        domain => ({
                            id: domain.id,
                            text: domain.alias
                        })
                    )
                },
                {
                    id: "status-select",
                    key: 'statusValue',
                    label: '',
                    type: 'select',
                    placeholder: "Estado de la licencia",
                    options: [
                        {
                            id: "enable",
                            text: "Habilitada"
                        },
                        {
                            id: "disable",
                            text: "Deshabilitada"
                        }
                    ]
                }

            ]);
        }
    }, [domainForm])

    const _selectUser = (items) => {
        if (items.length > 0) {
            setLicenceSelected(items[0]);
        } else {
            setLicenceSelected();
        }
    }

    const _assignLicence = (id, status) => {
        requests.patchItem(id, status);
    }

    const _changeLicenceStatus = () => {
        const newStatus = licenceSelected.isActive !== "Habilitada";
        _assignLicence(licenceSelected.id, newStatus);
    }

    const _panelTemplate = (template) => {
        switch (template) {
            case 'asignacion-masiva':
                return (
                    <MassiveAssignment onCancel={handleDismissPanel} clientName={clientName}/>
                );
            case 'asignacion-individual':
                return (
                    <IndividualAssignment domains={domainForm} onCancel={handleDismissPanel} onAssignLicense={_assignLicence}/>
                );
            case 'reporte-operaciones':
                return (
                    <UserLicencesReport onCancel={handleDismissPanel} clientName={clientName} domainForm={domainForm}/>
                );
            default:
                return <span>Template no encontrado</span>;
        }
    }

    const requests = {
        getUsers: async (filters, tenant) => {
            setPagination(null);
            handleLoadingData()
            const response = await usersLicencesRequest.getItem({param: filters, tenant});
            let idNumber = 0;
            setLicenceList(
                response.data.data.map(
                    user => {
                        idNumber++;
                        return (
                            {
                                ...user,
                                isActive: user.active ? 'Habilitada' : 'Deshabilitada',
                                idNumber
                            }
                        )
                    }
                )
            );
            const {
                currentPage,
                pageZise,
                totalCount,
                totalPages
            } = response.data.meta;
            setPagination({
                currentPage: currentPage,
                pageSize: pageZise,
                totalItems: totalCount,
                totalPages: totalPages
            });
            handleLoadedData();
        },
        patchItem:(id, status)=>{
            const data = [
                {
                    "op":"replace",
                    "path":"Active",
                    "value": status
                },
            ];
            usersRequest.confirmRequest(
                {
                    body: data,
                    id,
                    ct: "application/json-patch+json",
                    tenant:clientName,
                    option:'patchItem'
                })
        }
    }

    return (
        <>

            <SubMenu
                contentcontrols={
                    [
                        {
                            text:"Asignación y Denegación de licencias",
                            iconProps:{iconName: 'AddGroup'},
                            onClick:() => {
                                setTemplatePanel("asignacion-masiva");
                                handleOpenPanel();
                            }
                        },
                        {
                            text:"Asignación individual licencia",
                            iconProps:{iconName: 'AddFriend'},
                            onClick:() => {
                                setTemplatePanel("asignacion-individual");
                                handleOpenPanel();
                            }
                        },
                        {
                            text:"Reporte de operaciones",
                            iconProps:{iconName: 'AnalyticsView'},
                            onClick:() => {
                                setTemplatePanel("reporte-operaciones");
                                handleOpenPanel();
                            }
                        },
                        licenceSelected?
                        {
                            text:`${licenceSelected.isActive === "Habilitada" ? 'Inh' : 'H'}abilitar licencia`,
                            iconProps:{iconName: 'EditNote'},
                            onClick:()=>_changeLicenceStatus()
                        }:null
                    ].filter(obj => obj !== null)
                }
                contentcontrolsTwo={
                    <>
                        <Panel isOpen={isPanelOpen}
                            closeButtonAriaLabel="Close"
                            type={templatePanel === "reporte-operaciones" ? PanelType.extraLarge : PanelType.medium}
                            hasCloseButton={false}
                            onDismiss={handleDismissPanel}
                            isFooterAtBottom={true}
                        >
                            {_panelTemplate(templatePanel)}
                        </Panel>
                    </>
                }
            />
            <div className="admin--body-container">
                <ListHeader
                    showReport={false}
                    filters={filtersList}
                    subRutes="Inicio/Administración/Administrar Usuario - Licencia"
                    title="Administrar licencias de usuario"
                    hideSearch={true}
                    onChange={(event) => {
                        setFilter({
                            ...filter,
                            pageSize: event.target.value,
                            pageNumber: filter.pageNumber === "0" ? "0" : "1"
                        });
                    }}
                    dataForm={({idDomain, searchValue, statusValue}) => {
                        setFilter({
                            ...filter,
                            pageNumber: "1",
                            idDomain,
                            // TODO: add status to the filter when the service supports it : {statusValue}
                            search: searchValue
                        });
                    }}
                />
                <DetailsListBext
                    enableShimmer={isLoadingData}
                    listKey="userLicencesList"
                    selectionMode={1}
                    items={licenceList}
                    columnTitles={columnTitles}
                    onSelect={_selectUser}
                    onPageChange={page => {
                        if (page !== +filter.pageNumber) {
                            setFilter({
                                ...filter,
                                pageNumber: String(page),
                            });
                        }
                    }}
                    pagination={pagination}
                />
            </div>
        </>
    );
}

export default UserLicences;
