import React, { Fragment, useContext, useEffect, useState } from "react";
import CookiesJS from "js-cookie";
//import {DefaultButton, Panel, PanelType, } from 'office-ui-fabric-react'
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { useBoolean } from "@fluentui/react-hooks";
import { useParams } from "react-router-dom";

//*****Componnents*****//
import SubMenu from "../../components/navigation/subMenu/subMenu.component";
import ContainerLargeForm from "../../components/containers/largeForm/containerLargeForm.component";
import ContainerBaseLoading from "../../components/containers/baseLoading/containerBaseLoading.component";
import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';
import ListHeader from "../../components/lists/listHeader/listHeader.component";
import ListBody from "../../components/lists/listBody/listBody.component";
import { changeLang, changeLangForm } from "../../utils/logicRendering/changeIdiom";

//*****Methods*****//
import Lists from "../../utils/logicRendering/lists.controller";
import { RequestFetch } from "../../utils/Requests/requests.Controller";
import CountPages from "../../utils/logicRendering/countPages.controller";
import LenguajeContext from "../../context/Lenguaje/lenguajeContext";

const PasswordExpirationNotifications = () => {
  const [dataUser, setDataUser] = useState(CookiesJS.get("dataUser"));
  const dataUserFormat = JSON.parse(dataUser);
  const [defaultValueInput, seDefaultValueInput] = useState([
    { key: "criticalIndicator", text: dataUserFormat.expirationDays },
  ]
  );
  /************************
   * @arguments
   ************************/

  //***Propertise***//
  const [edit, setEdit] = useState(false);
  const [deleteD, setDeleteD] = useState(false);
  const [domainauthenticationfactors, setdomainauthenticationfactors] =
    useState([]);
  const [currentPages, setCurrentPage] = useState(1);
  const [showElements, setShowElements] = useState(5);
  const [
    itemsDomainauthenticationfactors,
    setitemsDomainauthenticationfactors,
  ] = useState(null);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const { clientName } = useParams();
  const [isDisable, setDisable] = React.useState([
    { disable: false, key: "valueDefault" },
    { disable: true, key: "criticalIndicator" },
  ]);
  //#region Variables de estado de los componentes
  const [hour, setHour] = useState("");
  const [mildIndicator, setMildIndicator] = useState({});
  const [averageIndicator, setAverageIndicator] = useState({});
  const [controlsPage, setControlsPage] = useState({});
  const [textOnPages, setTextOnPages] = useState({})
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const [daysbeforenotification, setDaysbeforenotification] = useState([]);
  const [daySelect, setDaySelect] = useState()
  const [buttonDisable, setButtonDisable] = useState(true)
  // useEffect(() => {
  //   if (daySelect) {
  //     if (daySelect.selected && daySelect.id!= undefined) {
  //     let idSel = {id: daySelect.id}
  //     setDayArrSelect([...dayArrSelect, idSel])

  //     }else{
  //       setDayArrSelect(dayArrSelect.filter(item=>item.id != daySelect.id))
  //     }
  //   }
  // }, [daySelect])
  //#endregion
  const { idClient } = JSON.parse(CookiesJS.get("dataClient"));
  //Nos ayuda a llenar de forma dinamica un componente
  const [itemsComponentInformation, setItemsComponentInformation] = useState();
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
  const Requests = new RequestFetch("PasswordExpirationNotifications");
  const RequestsDaysBeforeNotification = new RequestFetch(
    "DaysBeforeNotification"
  );
  //#region Consulta de tipos de autenticacion
  const [isLoadingData, {
      setTrue: handleLoadingData,
      setFalse: handleLoadedData
  }] = useBoolean(false);

  const [daysBeforeNotification, setDaysBeforeNotification] = useState()
  const [columnTitles, setColumnTitles] = useState( [{
    color: {
      name: "Color",
      isRowHeader: true,
      //minWidth: 50,
      maxWidth: 150,
      isResizable: true,
      idHtml : "coluColor",
    },
    name: {
      name: "Tiempo de visualización en %",
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      idHtml : "coluDisplayTime",
    },
    creatingDate: {
      name: "Fecha de Creación",
      isResizable: true,
      maxWidth: 200,
      idHtml: "coluCreationDate"
    },
    updatingDate: {
      name: "Última Modificación",
      minWidth: 50,
      isResizable: true,
      maxWidth: 200,
      idHtml: "coluLastModification",
  },
  creatorUser: {
    name: "Creado por",
    maxWidth: 150,
    isResizable: true,
    idHtml: "coluCreatedBy",
  },
  updateUser: {
    name: "Modificado por",
    maxWidth: 150,
    isResizable: true,
    idHtml: "lblModifiedBy"
  },
  }]);

  const defaultRenderItemColumn = (item, index, column) => {

    const fieldContent = item[column.fieldName];
    switch (column.name) {
      case controlsPage?.coluColor?.value:
        return (
          <>

            <ul
              style={{
                height: "80px",
                listStyle: "none",
                width: "100%",
                marginRight: "0px",
                marginTop: "0px",
                marginLeft: "-40px",
                display: "flex",
                alignItems: "right",
                display: "inline-block",
              }}
            >
              <li
                className="wr1 h1 "
                style={{
                  backgroundColor: "#DEEDDE",
                  height: "20px",
                  width: "100%",
                  "border-radius": "5px",
                  color:"#0C5A0C"
                }}
              >{controlsPage?.lblOnTime?.value}</li>
              <li
                className="wr1 h1"
                style={{
                  backgroundColor: "#ffedbf",
                  height: "20px",
                  width: "100%",
                  "border-radius": "5px",
                  position: "right",
                  marginTop: "10px",
                  color:"#F37807"
                }}
              >{controlsPage?.lblHalftime?.value}</li>
              <li
                className="wr1 h1 "
                style={{
                  backgroundColor: "#F0CECE",
                  height: "20px",
                  width: "100%",
                  "border-radius": "5px",
                  position: "center",
                  marginTop: "10px",
                  color:"#CC0000"
                }}
              >{controlsPage?.lblExpirationTime?.value}</li>
            </ul>
          </>
        );
      case controlsPage?.coluDisplayTime?.value:
        return (<>
          <ul
            style={{
              alignItems: "left",
              padding: 0,
              listStyle: "none",
              marginTop: "0px",
            }}
          >
            <>
              <li style={{ marginTop: "0px", }}>0% - {item["mildIndicator"]}%</li>
              <li style={{ marginTop: "12px", }}>{item["mildIndicator"]}% - {item["averageIndicator"]}%</li>
              <li style={{ marginTop: "12px", }}>{item["averageIndicator"]}% - {item["criticalIndicator"]}%</li>
            </>
          </ul></>)
      default:
        return <span>{fieldContent}</span>;
    }
  }
  let arrayDaysBeforeNotificationFormat = [];
  useEffect(() => {
    if (daysBeforeNotification !== undefined) {
      daysBeforeNotification.then((data) => {
        for (const [key, value] of Object.entries(data.data)) {
          if (key === "data") {
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              if (domainauthenticationfactors[0].id === element["idPasswordExpirationNotification"]) {
                arrayDaysBeforeNotificationFormat.push(
                  element["previousDay"]
                );
              }
            }
          }
        }
      });
      setDefaultSelectedKeys(arrayDaysBeforeNotificationFormat);
    }
  }, [daysBeforeNotification]);
  //#endregion
  //#region Acciones de los componentes
  const [arrayDaysbeforenotification, setArrayDaysbeforenotification] = useState([])
  const actionOnChange = (data) => {

    //#region Se llenan los datos de los controles
    if (data.id === "mildIndicator") {
      if (data.selectedItem != undefined) {
        setMildIndicator(data.selectedItem);
      }else if(data.defaulvalue !== ""){
        setMildIndicator(data.defaulvalue)
      }else{
          setMildIndicator("")
      }
    }
    if (data.id === "averageIndicator") {
      if (data.selectedItem != undefined) {
        setAverageIndicator(data.selectedItem);
      }else if(data.defaulvalue !== ""){
          setAverageIndicator(data.defaulvalue)
      }else{
          setAverageIndicator("")
      }
    }
    if (data.id === "daysbeforenotification") {
      if (data.key != undefined) {
        setDaySelect(data)

      }
    }
    if (data.id === "hour") {
      if (data.selectedItem != undefined) {
        setHour(data);
      }
    }
    //#endregion
  };
  //#region Esta función remueve datos duplicados
  
  useEffect(() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        stateLanguage.lenguaje.map((pass) => {
            if (
            pass.path == 'controls'|| 
            pass.path == 'notifications-expiry-passwords'
            ) {
              pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })    
              setControlsPage(controlsPage)
            }              
        })   
        setColumnTitles(current => changeLang(current, controlsPage))
            setCampForm(current => changeLangForm(current, controlsPage))       
    }
}, [textOnPages, stateLanguage])

useEffect(() => {
  if (daySelect) {
    if (daySelect.selected) {
    let daySel = {previousDay: daySelect.text}
    setArrayDaysbeforenotification([...arrayDaysbeforenotification,daySel])
    
    }else{
      setArrayDaysbeforenotification(arrayDaysbeforenotification.filter(item=>item.previousDay != daySelect.text))
    }
  }
  
}, [daySelect])

  useEffect(() => {
    if (daySelect) {
      let day = daySelect.key.replace("TableCheckbox", '')
      if (daySelect.state) {
        let daySel = { previousDay: day }
        setArrayDaysbeforenotification([...arrayDaysbeforenotification, daySel])

      } else {
        setArrayDaysbeforenotification(arrayDaysbeforenotification.filter(item => item.previousDay != day))
      }
    }

  }, [daySelect])

  //#endregion
  const actionOnClick = (data) => {
  };
  const onSubmitEventFormPanel = (data) => {
    let dataForm = {
      idClient: idClient,
      mildIndicator: mildIndicator,
      averageIndicator: averageIndicator,
      criticalIndicator: 100,
      hour: hour.selectedItem,
      daysbeforenotification: arrayDaysbeforenotification
    };
    return dataForm
  };

  //#endregion
  //#region Configuracion de componentes

  const [campForm, setCampForm] = useState([
    {
      id: 1,
      key: "criticalIndicator",
      label: "Estado critico",
      ariaLabel: "Solo se pueden insertar nuemros",
      type: "text2",
      defaultValu: "100",
      disable: true,
      multiline: false,
      resizable: false,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: { float: "right", marginRight: "0rem", marginBottom: "30px" },
      styleStack: {
        width: "230px",
        position: "center",
        "background-color": "#F0CECE",
      },
      suffix: "%",
      title: "Solo se acpetan 3 numeros consecutivos",
      description: "Tu contraseña vence el día dd/mm/aaaa, debes renovarla en 00 días.",
      onRenderLabel:
        "El porcentaje para el nivel critico de caducidad de la contraseña",
      isRequired: false,
      onGetErrorMessage: [
        { messageValidateFormat: "Solo se aceptan datos numéricos" },
      ],
      idHtml: "NumCriticalState",
      idHtmlForDesc: "lblNotificationMessage",
      idHtmlRenderLabel: "lblDescriptionCriticalLevel"
    },
    {
      id: 2,
      key: "averageIndicator",
      label: "Estado medio",
      ariaLabel: "Solo se pueden insertar nuemros",
      type: "text2",
      defaultValu: null,
      disabled: false,
      multiline: false,
      resizable: false,
      typeInput: "text",
      isNumeric: true,
      max:2,
      description: "Tu contraseña vence el día dd/mm/aaaa, debes renovarla en 00 días.",
      styleStackFather: { float: "right", marginRight: "1rem", marginBottom: "1rem", marginBottom: "30px" },
      styleStack: {
        width: "230px",
        position: "center",
        "background-color": "#FFEDBF",
      },
      suffix: "%",
      title: "Solo se acpetan 3 numeros consecutivos",
      onRenderLabel:
        "El porcentaje para el nivel medio de caducidad de la contraseña",
      isRequired: true,
      onGetErrorMessage: [
        { messageValidateFormat: "Solo se aceptan datos numéricos" },
      ],
      idHtml: "NumMiddleState",
      idHtmlForDesc: "lblNotificationMessage",
      idHtmlRenderLabel: "lblMediumLevelDescription"
    },
    {
      id: 3,
      key: "mildIndicator",
      label: "Estado leve",
      ariaLabel: "Solo se pueden insertar nuemros",
      type: "text2",
      defaultValu: null,
      disabled: false,
      multiline: false,
      resizable: false,
      typeInput: "text",
      isNumeric: true,
      max:2,
      description: "Tu contraseña vence el día dd/mm/aaaa, debes renovarla en 00 días.",
      styleStackFather: { float: "left", marginRight: "0rem", marginBottom: "30px" },
      styleStack: {
        width: "230px",
        position: "center",
        "background-color": "#DEEDDE",
      },
      suffix: "%",
      title: "Solo se acpetan 3 numeros consecutivos",
      onRenderLabel:
      "El porcentaje para el primer nivel de caducidad de la contraseña",
      isRequired: true,
      onGetErrorMessage: [
        { messageValidateFormat: "Solo se aceptan datos numéricos" },
      ],
      idHtml: "NumMildState",
      idHtmlForDesc: "lblNotificationMessage",
      idHtmlRenderLabel: "lblDescriptionMildLevel"
    },
    {
      id: 6,
      key: "line1",
      type: "line",
      className: "w9_5",
      color: "#0C3273",
      styleStack: {
        marginBottom: "25px"
      }
    },
    {
      id: 4,
      key: "hour",
      label: "Tiempo de envío de las notificaciones.",
      ariaLabel: "Solo se pueden insertar nuemros",
      type: "text2",
      defaultValu: hour.selectedItem,
      disable: false,
      multiline: false,
      resizable: false,
      typeInput: "time",
      suffix: "00:00:00 h-min-s",
      description: "Recuerde que se enviará la notificación de contraseña a vencer.",
      onRenderLabel:
      "El formato está de 24 horas ",
      isRequired: false,
      onGetErrorMessage: null,
      style: { color: "black" },
      styleStackFather: { float: "right", marginRight: "2rem" },
      styleStack: {
        width: "300px",
        position: "center",
        marginBottom: "30px"
      },
      
      idHtml: "lblTimeNotificationSent",
      idHtmlForDesc: "lblNotificationReminder",
      idHtmlRenderLabel: "lblTimeFormat"
    },
    {
      id: 5,
      key: "daysbeforenotification",
      label: "Por favor, seleccionar los días en que se enviaran las notificaciones.",
      type: "table2",
      idHtml: "lblNotificationDays",
      defaultValu: null,
      disabled: false,
      multiline: false,
      resizable: false,
      bodyTable: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"],
      className: "w5",
      aditionalClassName: "w7",
      styleStackFather: { float: "left", marginRight: "0rem" },
      styleStack: {
        width: "400px",
        position: "center",
      },
      suffix: "%",
      title: "Solo se acpetan 3 numeros consecutivos",
      onRenderLabel:
        "El porcentaje para el primer nivel de caducidad de la contraseña",
      isRequired: true,
      onGetErrorMessage: [
        { messageValidateFormat: "Solo se aceptan datos numéricos" },
      ],
    },
  ]);
  campForm[4] = Object.assign(campForm[4], {defaultValu: hour.selectedItem})
  const [campFilters, setCampFilters] = useState([]);

  //#endregion
  /************************
   * @methods
   ************************/
  const methodsList = {
    getDataItem: (object) => {
      const stateList = new Lists(itemsDomainauthenticationfactors, campForm);
      const stateCurrent = stateList.updateCurrent(object);
      setCampForm(stateCurrent.object);
      setDeleteD(stateCurrent.delete);
      setEdit(stateCurrent.edit);
      setdomainauthenticationfactors(object);
      setMildIndicator({ selectedItem: object[0].mildIndicator })
      setAverageIndicator({ selectedItem: object[0].averageIndicator })
      setArrayDaysbeforenotification(object[0].daysbeforenotification.map((element) => { return { previousDay: element.previousDay } }))
      setHour({ selectedItem: object[0].hour })
    },
    voidCamps: () => {
      setDefaultSelectedKeys([])
      setItemsComponentInformation([])
      const stateList = new Lists(itemsDomainauthenticationfactors, campForm);
      setCampForm(stateList.clearCamps());
    },
  };

  const methosRequests = {
    chageGetItems: async (filters) => {
      handleLoadingData()
      const response = await Requests.getItem({ param: filters, tenant: clientName });
      setitemsDomainauthenticationfactors(response.data);
      setShowEdit(response.data.data.length ==0 ? false : true)
      handleLoadedData();
    },
    crateItem: (dataForm) => {
      Requests.createItem({body: onSubmitEventFormPanel(), ct:'json', tenant: clientName, 
          infoModal:{
          title:controlsPage?.lblSuccessfulProcess?.value,
          description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          },
          op:"b",
      })
    },
    updateItem: (dataForm, id) => {
      Requests.confirmRequest({body: onSubmitEventFormPanel(), id:id, ct:'json', tenant: clientName, option:'updateItem',
          infoModal:{
          title:controlsPage?.lblSuccessfulProcess?.value,
          description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          },
          op:"b",
      })
    },
    deleteItem: () => {
      domainauthenticationfactors.forEach((element) =>
        Requests.confirmRequest({
          id: element.id,
          tenant: clientName,
          infoModal:{
            title:controlsPage?.lblDisabled?.value, 
            description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
          },
          op:"b",
          option:'deleteItem',
        })
      );
    },
  };

  /************************
   * @hooks
   ************************/
  const [promise, setPromise] = useState(
    // Requests.getItem({
    //   param: { pageSize: showElements, pageNumber: currentPages },
    //   tenant: clientName,
    // })
  );
  
  useEffect(() => {
    if (Object.entries(controlsPage).length > 0) {
      methosRequests.chageGetItems({
        param: { pageSize: showElements, pageNumber: currentPages,
          idLanguage: JSON.parse(CookiesJS.get("dataUser")).user.idLanguage
        },
        tenant: clientName,
      })
    }
}, [controlsPage])

  const [showEdit, setShowEdit] = useState(false)
  // useEffect(() => {
  //   promise.then((data) => {
  //     setitemsDomainauthenticationfactors(data.data);
  //     setShowEdit(data.data.data.length ==0 ? false : true)
  //   });
  // }, [promise]);
  useEffect(() =>
  {
  if(averageIndicator !== undefined && averageIndicator !== null && averageIndicator!=="" && averageIndicator>1 ){
    if(mildIndicator !== undefined && mildIndicator !== null && mildIndicator!=="" && mildIndicator>0 && Number(mildIndicator)<Number(averageIndicator)){
      if (arrayDaysbeforenotification.length>0 ){
    if(hour !== undefined && hour !== null && hour!=="" && hour.selectedItem !=="" && hour.selectedItem !== undefined ){
      setButtonDisable(false)
    }else{
      setButtonDisable(true)
    }
      }else{
      setButtonDisable(true)
    }
    }else{
      setButtonDisable(true)
    }
  }else{
    setButtonDisable(true)
  }
  },[averageIndicator,mildIndicator,arrayDaysbeforenotification,hour])

  /************************
   * @resturns
   ************************/
  if (itemsDomainauthenticationfactors === null) {
    return <ContainerBaseLoading />;
  }
  const numberPage = CountPages(
    itemsDomainauthenticationfactors.meta.totalPages
  );
  const styleFrom = {
    justifyContent: "center",
    alignItems: "center",
    display: "inline-block",
  }; 
  return (
    <>
      <SubMenu
        contentcontrols={
          !showEdit ? [
            {
              text:controlsPage?.OnClickNotificationSettings?.value,
              iconProps:{ iconName: "Add" },
              onClick:() => {
                  methodsList.voidCamps();
                  openPanel();
                }
            },
          ]:
          [
            edit? {
              text:controlsPage?.lblEditNotification?.value,
              iconProps:{ iconName: "Edit" },
              onClick:()=>openPanel()
            }:null,
            // {
            //   text:controlsPage?.OnClickDeleteNotification?.value,
            //   iconProps:{ iconName: "delete" },
            //   onClick:()=>methosRequests.deleteItem()
            // }
          ].filter(obj => obj !== null)
        }
        contentcontrolsTwo={
          <Panel
              isOpen={isOpen}
              closeButtonAriaLabel="Close"
              isHiddenOnDismiss={true}
              type={PanelType.custom}
              customWidth={'800px'}
              hasCloseButton={false}
            >
              {isOpen ? (
                <ContainerLargeForm
                  dataForm={(e) => {
                    edit
                      ? methosRequests.updateItem(
                        e,
                        domainauthenticationfactors[0].id
                      )
                      : methosRequests.crateItem(e);
                  }}
                  formTitle={
                    edit ? controlsPage?.lblEditNotification?.value : controlsPage?.lblConfigureNotifications?.value
                  }
                  style={styleFrom}
                  Items={campForm}
                  closePanel={dismissPanel}
                  case="notificationFlow"
                  actionOnChange={actionOnChange}
                  actionOnClick={actionOnClick}
                  isDisableOpt={isDisable}
                  onSubmitEventFormPanel={onSubmitEventFormPanel}
                  itemsComponentInformation={itemsComponentInformation}
                  defaultValueInput={defaultValueInput}
                  defaultSelectedKeys={defaultSelectedKeys}
                  controlsPage={controlsPage}
                  buttonDisable={buttonDisable}
                />
              ) : (
                ""
              )}
            </Panel>
        }
      />
      <div className="admin--body-container">
        <ListHeader
          //filters={campFilters}
          showReport={false}
          subRute={controlsPage?.lblUrlAdminNotificPsswrdExpire?.value}
          subRute3={controlsPage?.lblNotificPsswrdExpire?.value}
          title={controlsPage?.lblNotificPsswrdExpire?.value}
          dataForm={(e) =>
            methosRequests.chageGetItems({
              pageSize: showElements,
              pageNumber: currentPages,
              idDomain: e.idDomain,
            })
          }
          hideSearch={false}
          hideShowCounter={true}
        // onChange={(e) => {
        //   setShowElements(e.target.value);
        //   methosRequests.chageGetItems({
        //     pageSize: e.target.value,
        //     pageNumber: currentPages,
        //   });
        // }}
        // InputChange={(e) => {
        //   methosRequests.chageGetItems({
        //     search: e,
        //     pageSize: showElements,
        //     pageNumber: currentPages,
        //   });
        // }}
        />
        {/* <ListBody
          spaceTitle={SpaceTitel}
          Items={itemsDomainauthenticationfactors.data}
          meta={itemsDomainauthenticationfactors.meta}
          numberPages={numberPage}
          initRangeText={currentPages}
          orditem={OrdItems}
          finishRangeText={showElements}
          totalRangeText={itemsDomainauthenticationfactors.data.length}
          typelist="listSelect"
          selectdata={methodsList.getDataItem}
          paginate={(number) => {
            setCurrentPage(number);
            methosRequests.chageGetItems({
              pageSize: showElements,
              pageNumber: number,
            });
          }}
        /> */}
        <div style={{marginTop:"50px"}}>
        <DetailsListBext
          enableShimmer={isLoadingData}
          listKey="listSelect"
          selectionMode={1}
          items={itemsDomainauthenticationfactors.data}
          meta={itemsDomainauthenticationfactors.meta}
          renderItemColumn={defaultRenderItemColumn}
          columnTitles={columnTitles}
          onSelect={methodsList.getDataItem}
        // onPageChange={page => {
        //   if (page !== +filter.pageNumber) {
        //     setFilter({
        //       ...filter,
        //       pageNumber: String(page),
        //     });
        //   }
        // }}
        // pagination={pagination}
        />
        </div>
      </div>
    </>
  );
};
export default PasswordExpirationNotifications;
