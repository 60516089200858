export const ClassContainerBody = (URLused) =>{
    const newUrl = URLused.at(-2)
    const contentsUrl = URLused.at(-1)
    let className = `admin--container-body`
    const paths= ["AssosiateQuestions", "PasswordChange", "DataUserAdmin", "manage-mfa"]
    if (newUrl == "administration" && paths.includes(contentsUrl)){
        let className = "baseBodyUser"
        return {className}
    }
        return {className}
}