//****Librarys ****//
import React, { useContext,useEffect, useState } from "react";
import { useLocation, Routes, Route, useNavigate } from "react-router-dom";
import { useBoolean } from '@fluentui/react-hooks';

//*****Componnents*****//
import ContainerBaseUser from "../../components/containers/baseUser/containerBaseUser.component";
import MenuUser from "../../components/navigation/menuUser/menuUser.component";
import CookiesJS from "js-cookie";

//*****Layouts*****//
import Loby from "./loby";
import AssoQuestions from "./assoQuestions";
import ChangePassword from "./changePassword";
import DataUser from "./dataUser";
import Mfa from "./mfa";

//**Methods**//

//*****File*****//
import IconLogo from "../../assets/img/logo-bsr.svg";
import ButtonFloat from "../../components/tools/buttonFloat/buttonFloat.component";
import NoticeAlert from "../../components/tools/noticeAlert/noticeAlert.component";
import FetchOAuth from "../../utils/validations/oauth.controller";
import { BSR_URI, PATH_APPLICATION, PATH_SSO, SSO_URI } from "../../assets/settings/settinsrouters";
import { render } from "@testing-library/react";
import ModalFluent from "../../components/tools/modalFluent/ModalFluent";
import { validateRoute } from "../../helpers/validateRoute";
import AvisoError from "../../components/containers/containerError/avisoError";
import { Modal, IconButton, Panel, FontIcon } from "@fluentui/react";
import { actualiza, consulta, getText, RequestFetch } from "../../utils/Requests/requests.Controller";
import { DefaultButton, IButtonStyles } from '@fluentui/react/lib/Button';
import LenguajeState from "../../context/Lenguaje/lenguajeState";
import LenguajeContext from '../../context/Lenguaje/lenguajeContext';
import ContainerBaseLoading from "../../components/containers/baseLoading/containerBaseLoading.component";
import ChallengesMfa from "./challengesMfa";
import Warn from "../../components/containers/containerWarning/containerWarn";



/**
 *@author Yeison Quiroga(15-04-2021)
 *@version 1.0
 *@lastCommit
 */

const User = () => {
  let dataUserSession = JSON.parse(CookiesJS.get("dataUser"));
  let nameUser = dataUserSession.user.institutionalEmail;
  /************************
   * @arguments
   ************************/
  const { path } = useLocation();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [isModalOpen2, { setTrue: showModal2, setFalse: hideModal2 }] = useBoolean(false);
  const requestTyC = new RequestFetch('TermCondition')
  const [itemTyC, setItemTyC] = useState([])
  const [textOnPages, setTextOnPages] = useState({})
  const { logoPath, idClient } = JSON.parse(CookiesJS.get("dataClient"));
  const nameClient = CookiesJS.get('nameClient');
  const userText = new RequestFetch('Pages/userTexts')
  const [dataT, setDataT] = useState(CookiesJS.get("dataToken"));
  const requestLang = new RequestFetch(`Languages`)
  const requestUserSecurityQuestion = new RequestFetch('UserSecurityQuestion');
  const [currentLocation, setCurrentLocation] = useState();
  const dataUserFormat = JSON.parse(dataT);
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const [controlsPage, setControlsPage] = useState({});
  const token = CookiesJS.get('token')
  const [showNotice, setShowNotice] = useState(true);
  const [iconArrow, setIconArrow] = useState(false)
  const [idiom, setIdiom] = useState({})
  const [func, setFunc] = useState()
  const [funcAcept, setFuncAcept] = useState()
  const navigate = useNavigate();
  const [dataError, setDataError] = useState({})
  const [required, setRequired] = useState(false)
  const [textButton, setTextButton] = useState({})
  const [questionReq, setQuestionReq] = useState(Boolean)
  const [linkVerification, setLinkVerification] = useState()

  const [idLng, setIdLng] = useState()
  const [itemFiles, setItemFiles] = useState({})
  const [infUsr, setinfUsr] = useState({})
  const [hideComponent, setHideComponent] = useState(false)
  const [typeClass,setTypeClass] = useState("")
  /************************
   * @methods
   ************************/
  let url = window.location.href
  const ubicationUrl = url.split("/")
  const iconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },

  };



  /************************
   * @hooks
   ************************/




   let db

   const openDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('textListApp',1);
      
      request.onsuccess = (event) => {
        const db = event.target.result;
        resolve(db);
      };
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("textF")) {
          db.createObjectStore("textF", { keyPath: "clave" });
        }
      };
      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };
  
  const deleteDB = (dbName) => {
    return new Promise((resolve, reject) => {
      // const dbName = "textList";
      const requestSso = indexedDB.open(dbName, 1);
  
      requestSso.onsuccess = (event) => {
        const db = event.target.result;
        db.close();
  
        const deleteRequest = indexedDB.deleteDatabase(dbName);
  
        deleteRequest.onsuccess = event => {
          console.log(`Se borra.`);
          resolve();
        };
  
        deleteRequest.onerror = event => {
          console.error(`Error al eliminar`);
          reject(event.target.error);
        };
      };
  
      requestSso.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };

  const actionOnClick = (func) =>{
    if(func == 'a'){
      CookiesJS.remove('token')
      window.location.href = `${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${nameClient}/home`
    }else if(func == 'b'){
      setRequired(true)
      setDataError({})
      navigate(`${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/${nameClient}/user/associate-security-questions`)
    }
  }
   useEffect(async() => {
    dataUserSession.user.idLanguage = CookiesJS.get('idLng')?CookiesJS.get('idLng'):dataUserSession.user.idLanguage
          const newCookie = JSON.stringify(dataUserSession)
          CookiesJS.set("dataUser",newCookie)
    let controlsPage = {}
    
       const res = await userText.getItem({tenant:nameClient,
        param:{
            idUser:dataUserSession.user.id,
            idLanguage:dataUserSession.user.idLanguage
            },
            headerEx: {idLanguageUser:dataUserSession.user.idLanguage}
        })

      const lang = await requestLang.getItem({
          id:'',
          tenant:nameClient,
          param: {idClient:idClient,idLanguage:dataUserSession.user.idLanguageng},
          headerEx: {idLanguageUser:dataUserSession.user.idLanguage}
      })

      setIdiom(lang.data.data)
      
      openDB().then((info) => {
        let db = info
        const transaction = db.transaction(['textF'],'readwrite')
        const objectStore = transaction.objectStore('textF')
        const requests = objectStore.openCursor();
        const request = objectStore?.add({clave:'textUsr' ,ssoPagesData:res.data.data})
        if (res) {
          actualiza(db,{clave:'textUsr' ,ssoPagesData:res.data.data})
        }
      }).catch(error=>{
        deleteDB("textListApp").then(e=>{window.reload()})
      })
      deleteDB("textList").then((info) => {
        console.log(info)
      })
      res.data.data.map((spd) =>{
        if (spd.path == 'controls' || spd.path == 'notifications-expiry-passwords') {
          spd.webcontrolspages.map((data) => {
            controlsPage[data.idHtml] = data.languagewebcontrols[0];
          })
          setControlsPage(controlsPage)
        } 
      })
      
      getLenguaje(res.data.data)
      getText(res.data.data)
  }, [idLng,])

  useEffect(async() => {
    const fileResponse = {
      idLanguage:idLng?idLng:'1',
      isToAdmin:false,
  };
  const requestFiles= new RequestFetch (`Access/file/${idClient}`);
  const dataResponse = await requestFiles.getItem({
      param: fileResponse,
      tenant: nameClient,
      ct:'json',
      reload:true,
      cancelIcon:true
  })
  setItemFiles(dataResponse.data.data)
  }, [idLng,])
  
  useEffect(()=>{
    if(dataUserFormat.colorLevel == "CriticalIndicator"){
        setTypeClass("criticalIndicatorStyle noticealert--container--criticalIndicator mrgin-top")
    }
    else if(dataUserFormat.colorLevel == "AverageIndicator"){
        setTypeClass("warning noticealert--container mrgin-top")
    }
    else if(dataUserFormat.colorLevel == "MildIndicator"){
        setTypeClass("mildIndicatorStyle noticealert--container--mildIndicator mrgin-top")
    }
},[])

  // useEffect(() => {
  //   let controlsPage = {};
  //   if (stateLanguage.lenguaje !=0) {
  //     stateLanguage.lenguaje.map((pass) => {
  //           if (pass.path == 'controls' || pass.path == 'notifications-expiry-passwords') {
  //             pass.webcontrolspages.map((data) => {
  //               controlsPage[data.idHtml] = data.languagewebcontrols[0];
  //             })
  //             setControlsPage(controlsPage)
  //           }              
  //       })          
  //   }
  //   else{
  //       if (Object.entries(textOnPages).length > 0) {
  //           textOnPages.ssoPagesData.map((pass) => {
  //           if (pass.path == 'controls' || pass.path == 'notifications-expiry-passwords') {
  //             pass.webcontrolspages.map((data) => {
  //               controlsPage[data.idHtml] = data.languagewebcontrols[0];
  //             })
  //             setControlsPage(controlsPage)
  //           }              
  //       })  
  //     }

  //   }
  // }, [textOnPages, stateLanguage])

  useEffect(async() => {
    const resp = await requestUserSecurityQuestion.getItem({
        param:{
            idUser: dataUserSession.user.id
        },
        tenant: nameClient
    })
    if (url.includes('identity-verification')) {
      setLinkVerification(true)
    }
    if (resp.data.data.length == 0) {
      if (ubicationUrl[ubicationUrl.length-1] == 'associate-security-questions') {
        setRequired(true)
      }else{
      let info = {
          title:controlsPage?.lblWarning?.value,
          description:controlsPage?.lblMrUserToAssociateQuestionsOrderToContinue?.value,
          type:'warning',
      }
      setFunc('a')
      setFuncAcept('b')
      setQuestionReq(true)
      if (Object.entries(controlsPage).length>0) {
        const {smtAccept, smtClose} =controlsPage
        setTextButton({smtAccept, smtClose})
        setDataError(info)
      }
    }
    }else{
      setRequired(false)
    }
  }, [url,controlsPage])
  const getTyC = async () => {
    // const res = await requestTyC.getItem({tenant:nameClient,param:{idClient:userData.client,idLanguage:userData.idLanguage}})
    const res = await requestTyC.getItem({ tenant: nameClient })
    setItemTyC(res.data.data)
  }
  useEffect(() => {
    validateRoute().then(isValid => {
      if (!isValid) {
        navigate(`/user/error`)
      }
    })
  }, [])
  useEffect(() => {
    const urlWithout = ubicationUrl.filter(Boolean)
    setIconArrow(false)
    if (urlWithout.at(-1) != 'user') {
      setIconArrow(true)
    }
  }, [ubicationUrl])
  useEffect(async () => {
    validateRoute().then(isValid => {
      if (!isValid) {
        navigate(`/user/error`)
      }
    })
    if (currentLocation != window.location.href) {
      
      let isvalid = await FetchOAuth({
        accessToken: token,
        type: 'introspect',
        tenant: nameClient
      })
      if (isvalid.data.data.data.active == 'false') {
        // let info = {
        //   title: "Token vencido",
        //   description: "Vuelva a iniciar sesión.",
        //   type: "danger",
        // }
        // render(
        //   <ModalFluent
        //     props={info}
        //     actionOnClick={(e) => {
        //       CookiesJS.remove('token')
        //       window.location.href = `${SSO_URI}/${nameClient}/home`
        //     }
        //     }
        //   />,
        //   document.getElementById("modal")
        // );
        // const DBDeleteRequest = window.indexedDB.deleteDatabase("textListApp");

        // DBDeleteRequest.onerror = (event) => {
        // console.error("Error deleting database.");
        // };
    
        // DBDeleteRequest.onsuccess = (event) => {
        // console.log("Database deleted successfully");
        // };
      }
      setCurrentLocation(window.location.href)
    }
  }, [window.location.href])

  /************************
   * @resturns
   ************************/
const onChange=()=>{}
if(linkVerification != null && linkVerification != undefined){
  return(
    <Routes>
      <Route path={`/identity-verification`} element={<Warn />} />
    </Routes>
  )
}
  

  let userPasswordExpiryTime =
    dataUserFormat.userPasswordExpiryTime.split("T");
  return (
    <>
      {!Object.entries(controlsPage).length>0 && controlsPage.urlTermsCondition?
      <ContainerBaseLoading />
          :''
      }
      <div id="modal"></div>
      {Object.entries(dataError).length>0?
      <ModalFluent
          props={dataError} 
          objectRequest={()=>{actionOnClick(funcAcept);setDataError({});setQuestionReq(false)}} 
          actionOnClick={()=>{actionOnClick(func);setDataError({})}}
          text={textButton}
          hideCancel={questionReq}
          hideIcon={questionReq}
          />
      :''
      }
      
      <ContainerBaseUser
        header={<MenuUser lng={idiom} inf={infUsr} files={itemFiles} idLng={(e)=>setIdLng(e)}/>}
        main={
          <>
            <img src={logoPath} alt="LOGO" className="logo-style" />

            <ButtonFloat
              // icon={iconArrow ? "icon-left-open hipertext--icon" : null}
              icon={iconArrow ? <FontIcon aria-label="ChevronLeftSmall" iconName="ChevronLeftSmall" className="hipertext--icon" />:null}
              href="/LoginForm"
              type={1}
              typef={iconArrow ? "btnFloat-header container--buttonFloat clr--primary" : "btnFloat-header container--buttonFloat clr--primary"}
              text={nameUser}
              // text2={!iconArrow ? `conectado desde la Ip ${dataUserSession.publicIP}` : null}
              text2={iconArrow ? `` : null}
              style={!iconArrow ? "flt-er" : "buttonFloat--text"}
              special={true}
              required={required}
            />

            {showNotice ? (
              <> 
                <NoticeAlert
                  // icon={iconArrow ? "icon-left-open hipertext--icon" : null}
                  icon={iconArrow ? <FontIcon aria-label="ChevronLeftSmall" iconName="ChevronLeftSmall" className="hipertext--icon" />:null}
                  href="/LoginForm"
                  type={1}
                  typef={iconArrow ? "btnFloat-header container--buttonFloat clr--primary" : "btnFloat-header container--buttonFloat clr--primary"}
                  text={nameUser}
                  // text2={!iconArrow ? `conectado desde la Ip ${dataUserSession.publicIP}` : null}
                  text2={iconArrow ? `` : null}
                  style={!iconArrow ? "flt-er" : "buttonFloat--text"}
                  special={true}
                  required={required}
                  msg={controlsPage?.lblTDateUser?.value}
                  typeClass={typeClass}
                  //typeClass="warning noticealert--container mrgin-top"
                  date={userPasswordExpiryTime[0]}
                  days={dataUserFormat.expirationDays}
                  cancel={true}
                  onClose={() => setShowNotice(false)}
                />
              </>
            ) : undefined}


            <Routes>
              <Route path={`/`} element={<Loby />} />
              <Route path={`/query-update-data`} element={<DataUser onChange={onChange}/>} />
              <Route path={`/manage-mfa`} element={<Mfa onChange={onChange}/>} />
              <Route path={`/change-password`} element={<ChangePassword onChange={onChange} showModal={() => { getTyC(); showModal() }} />} />
              <Route path={`/associate-security-questions`} element={<AssoQuestions onChange={onChange} required={required}/>} />
              <Route path={`/verification-challenge`} element={<ChallengesMfa onChange={onChange} required={required}/>} />
              <Route path={`/identity-verification`}/>
              <Route path="*" element={<AvisoError />} />
            </Routes>
          </>
        }
        footer={
          <>
            <a onClick={() => { getTyC();  showModal() }}
              className="hipertext-end hipertext--second"
            >{controlsPage?.urlTermsCondition?.value}
            </a>
            <a onClick={showModal2}
              className="hipertext-end hipertext--second"
            >...</a>
            <Modal
              className={"modal-terms"}
              headerText={controlsPage?.urlTermsCondition?.value}
              isOpen={isModalOpen}
              onDismiss={hideModal}
              isHiddenOnDismiss={true}
              closeButtonAriaLabel="Close"
            >
               <div className="header-modal">
               <span>{controlsPage?.urlTermsCondition?.value}</span>
              <IconButton
                  styles={iconButtonStyles}
                  iconProps={{ iconName: "cancel" }}
                  ariaLabel="Close popup modal"
                  onClick={hideModal}
                />
                </div>
                {itemTyC.map(opt =>
                  <>
                    <ul className="classp">
                      <li className="listStyle" key={opt.name}>
                        <a href={opt.url} className="hipertext  clr--light" target='_blank'>{opt.name}</a>
                      </li>
                    </ul>
                  </>
                )
                }
            </Modal>

            <Modal
              isOpen={isModalOpen2}
              onDismiss={hideModal2}
              isBlocking={false}
              className={"modal-terms"}
            >
              <div className="header-modal">
                <span>Version producto</span>
                <IconButton
                  styles={iconButtonStyles}
                  iconProps={{ iconName: "cancel" }}
                  ariaLabel="Close popup modal"
                  onClick={hideModal2}
                />
              </div>
              <div className="pl2">
                <br></br>
                <img src={logoPath} alt="LOGO" className="w3" />
                <br></br>
                <p>
                  Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                  At vero eos et accusam et justo duo dolores et ea rebum.
                  Stet clita kasd gubergren,
                  no sea takimata sanctus est Lorem ipsum dolor sit amet. Amet,
                  consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                  invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                  At vero eos et accusam et justo duo dolores et ea rebum.
                  Stet clita kasd gubergren, no sea takimata sanctus est
                  Lorem ipsum dolor sit amet.
                </p>
              </div>
            </Modal>
          </>
        }
      />
    </>
  );
};

export default User;