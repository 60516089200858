import { Slider, IStackTokens, Stack, IStackStyles } from "@fluentui/react";
import React, { useState, useEffect, useMemo } from "react";
const LargeFormSlider = (props) => {
  let item = props.item;
  const stackStyles = { root: { maxWidth: 300 } };
  const stackTokens = { childrenGap: 20 };
  const [selectedItem, setSelectedItem] = useState();
  //#region Buscar en un arreglo el id para habilitar y deshabilitar
  let found;
  if (props.isDisableOpt.length === 1) {
    found = props.isDisableOpt.find(
      (element) => element.key === "valueDefault"
    );
  } else {
    found = props.isDisableOpt.find((element) => element.key === item.key);
    if (found == undefined) {
      found = props.isDisableOpt.find(
        (element) => element.key === "valueDefault"
      );
    }
  }
  if (found == undefined) {
    found = props.isDisableOpt.find((element) => element.key === item.key);
  }
  //#endregion
  //#region Accion onChange
  const onChange = (e) => {
    setSelectedItem(e);
  };
  const sliderValueFormat = (value) => item.format ?`${value}`+`  ${item.format}` : `${value}`;
  useEffect(()=>{
    setSelectedItem(item.value)
  },[item.value])

  useEffect(() => {
    let objectFinal = {
      ...{ selectedItem: selectedItem ? selectedItem : (item.defaultValue >0?item.defaultValue:item.min)},
      ...{ id: item.key, state: found.disable },
    };
    props.actionOnChange(objectFinal);
  }, [selectedItem, found]);
  //#endregion
  return (
    <div className={item.className} style={item.divStyle}>
    <Stack tokens={stackTokens} style={item.stackStyles?item.stackStyles:stackStyles}>
      <Slider
        id={item.key}
        label={item.label}
        disabled={found.disable}
        min={item.min}
        max={item.max}
        step={item.step}
        value={item.value}
        onChange={onChange}
        styles={item.style}
        defaultValue={item.defaultValue}
        valueFormat={sliderValueFormat}
        showValue
        snapToStep
      />
    </Stack>
    </div>
  );
};
export default LargeFormSlider;
