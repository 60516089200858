import React, { useContext, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useParams } from 'react-router-dom';
import CookiesJS from "js-cookie";
//*****Components*****//
import SubMenu from '../../components/navigation/subMenu/subMenu.component';
import ContainerLargeForm from '../../components/containers/largeForm/containerLargeForm.component';
import ContainerBaseLoading from '../../components/containers/baseLoading/containerBaseLoading.component';
import ListHeader from '../../components/lists/listHeader/listHeader.component';
import ListBody from '../../components/lists/listBody/listBody.component';
import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';


//****** Methods *******//
import List from '../../utils/logicRendering/lists.controller';
import { RequestFetch } from '../../utils/Requests/requests.Controller';
import CountPages from '../../utils/logicRendering/countPages.controller';
import LenguajeContext from '../../context/Lenguaje/lenguajeContext';
import { changeLang, changeLangForm } from '../../utils/logicRendering/changeIdiom';

const FileTypes = () => {

    /*********** 
     *@arguments
    ************/
    const [itemsComponentInformation, setItemsComponentInformation] = useState();
    const [isDisable, setDisable] = useState([
        { disable: false, key: "valueDefault" },
        { disable: true, key: "name" },
    ]);
    const [edit, setEdit] = useState(false);
    const [deleteD, setDeleteD]  = useState(false);
    const [idFileSelect, setIdFileSelect] = useState([]);
    const [currentPages, setCurrentPage] = useState(1);
    const [showElements, setShowElements] = useState(5);
    const [itemsFiles, setItemsFiles] = useState(null);
    const [users, setUsers] = useState(null);
    const [meta, setMeta] = useState(null);
    const [valueDataForm, setValueDataForm] = useState(null)
    const [dataText, setDataText] = useState(null)
    let dataTableArray = [];
    const [kbMax, setKbMax] = useState(null)
    const [dataTable, setDataTable] = useState([]);
    const [isOpen, {setTrue: openPanel, setFalse: dismissPanel}] = useBoolean(false);
    const Request = new RequestFetch('FileTypes');
    const requestUsers = new RequestFetch('Users');
    const { clientName } = useParams();
    const [controlsPage, setControlsPage] = useState({});
    const [textOnPages, setTextOnPages] = useState({})
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
    const spacetitle = ['Tipo de documento', 'Categoria de documento', 'fecha de categorización', 'Categorizó'];
    const ordItems = ['name', 'type', 'creatingDate', 'creatorUser'];
    const[defaultSelectedKeys,setDefaultSelectedKeys] = useState([])
    const[keyIsToAdminm,setkeyIsToAdminm] = useState([])
    const [defaultValueInput, setDefaultValueInput] = useState([])
    const [urlAllow, setUrlAllow] = useState();
    const [maxKb, setMaxKb] = useState(null)
    const [filter, setFilter] = useState({
        pageSize: 5,
        pageNumber: "0",
        idDomain: "",
        idLanguage: JSON.parse(CookiesJS.get("dataUser")).user.idLanguage
    });
    const [pagination, setPagination] = useState(null);
    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);


    const COLUMN_TITLES = [{
        name: {
            name: "Tipo de documento",
            minWidth: 80,
            isResizable: true,
            idHtml: "coluDomain",
            maxWidth: 180
        },
        type: {
            name: "Categoria de documento",
            minWidth: 80,
            isResizable: true,
            idHtml: "coluEmail",
            maxWidth: 180
        },
        creatingDate: {
            name: "Fecha de creación",
            minWidth: 50,
            isResizable: true,
            maxWidth: 150,
            idHtml: "coluCreationDate",
        },
        updatingDate: {
            name: "Última Modificación",
            minWidth: 50,
            isResizable: true,
            maxWidth: 150,
            idHtml: "coluLastModification",
        },
        creatorUser: {
          name: "Creado por",
          maxWidth: 150,
          isResizable: true,
          idHtml: "coluCreatedBy",
        },
        updateUser: {
          name: "Modificado por",
          maxWidth: 150,
          isResizable: true,
          idHtml: "lblModifiedBy"
        },
    }];
    const [columnTitles, setColumnTitles] = useState(COLUMN_TITLES)

    const actionOnChange = (data) =>{
        if (data.id == 'maxSizeKb') {
            setMaxKb(data.selectedItem)
        }
        if(data.id == "allowUrl"){
            if(data.selectedItem != undefined && data.selectedItem.text == 'URL'){
            
            setUrlAllow(
                data
            )

            setCampForm(
                [
                    {   
                        id:0,
                        key: 'name',
                        label: 'Tipo de documento',
                        type: 'text',
                        placeholder: '...',
                        defaultValue: '',
                        required: true,
                    },
                    {
                        id: 1,
                        key: "allowUrl",
                        label: "Formato",
                        type: "select2",
                        required: true,
                        defaultSelectedKeys: defaultSelectedKeys,
                        options:[
                                    {key: false, text: 'PDF'},
                                    {key: true, text:'URL'}
                                ],
                        placeholder: "Seleccione...",
                        multiSelect: false,
                        disabled: false,
                    },
                    {
                        id: 3,
                        key: 'isToAdmin',
                        type: 'radio2',
                        options:[
                            {key: false, text: 'Usuario Final'},
                            {key: true, text:'Usuario Administrador'}
                        ]
                    },
                    {
                        id:4,
                        key:'description',
                        label:'Descripción',
                        type:'text',
                        required: true,
                        placeholder:'...',
                        defaultValue:''
                    }
                ]
            ) 
        }else if(data.selectedItem != undefined && data.selectedItem.text == 'PDF'){
            setUrlAllow(
                data
            )
            setCampForm(
                [
                    {   
                        id:0,
                        key: 'name',
                        label: 'Tipo de documento',
                        type: 'text',
                        required: true,
                        placeholder: '...',
                        defaultValue: ''
                    },
                    {
                        id: 1,
                        key: "allowUrl",
                        label: "Formato",
                        type: "select2",
                        required: true,
                        defaultSelectedKeys: defaultSelectedKeys,
                        options:[
                                     {key: false, text: 'PDF'},
                                     {key: true, text:'URL'}
                                 ],
                        placeholder: "Seleccione...",
                        multiSelect: false,
                        disabled: false,
                      },
                    {
                        id: 2,
                        key: "maxSizeKb",
                        label: "Tamaño max",
                        type: "select2",
                        required: true,
                        onRenderLabel: null,
                        defaultSelectedKeys: defaultSelectedKeys,
                        options:[
                            {key: 1000, text: '100 MB'},
                            {key: 2000, text: '200 MB'},
                            {key: 3000, text: '300 MB'},
                            {key: 4000, text: '400 MB'},
                            {key: 5000, text: '500 MB'},
                            {key: 6000, text: '600 MB'},
                            {key: 7000, text: '700 MB'}
                        ],
                        placeholder: "Seleccione...",
                        multiSelect: false,
                        disabled: isDisable,
                    },
                    {
                        id: 3,
                        key: 'isToAdmin',
                        type: 'radio2',
                        options:[
                            {key: false, text: 'Usuario Final'},
                            {key: true, text:'Usuario Administrador'}
                        ]
                    },
                    {
                        id:4,
                        key:'description',
                        label:'Descripción',
                        type:'text',
                        required: true,
                        placeholder:'...',
                        defaultValue:''
                    },
                ]
            )
        }}
        if(data.id==='isToAdmin'){
            setkeyIsToAdminm(data.key)
        }
        if(data.id === "maxSizeKb"){
            setKbMax(data.key);
        }
        if(data.text === "PDF"){
            setDataText(data.text)

        }else if(data.text === "URL"){
            setDataText(data.text)
        }
    }

    useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            //console.log(stateLanguage);
            stateLanguage.lenguaje.map((pass) => {
              // console.log(pass)
                if (pass.path == 'controls'|| pass.path =='domain') {
                  pass.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  })    
                  setControlsPage(controlsPage)
                }              
            })          
            setColumnTitles(current => changeLang(current, controlsPage))
            
        }
    
    }, [textOnPages, stateLanguage])

    useEffect(() => {
        if (Object.entries(controlsPage).length > 0) {
            methosRequests.chageGetItems(filter)
        }
    }, [filter, controlsPage])


    const actionOnClick = (data) => {};
    const onSubmitEventFormPanel = (data) => {}

    const [campForm, setCampForm] = useState([
        {   
            id:0,
            key: 'name',
            label: 'Tipo de documento',
            type: 'text',
            placeholder: '...',
            required: true,
            defaultValue: ''
        },
        {
            id: 1,
            key: "allowUrl",
            label: "Formato",
            required: true,
            type: "select2",
            defaultSelectedKeys: defaultSelectedKeys,
            options:[
                         {key: false, text: 'PDF'},
                         {key: true, text:'URL'}
                     ],
            placeholder: "Seleccione...",
            multiSelect: false,
            disabled: false,
        },
        {
            id: 3,
            key: 'isToAdmin',
            type: 'radio2',
            options:[
                {key: false, text: 'Usuario Final'},
                {key: true, text:'Usuario Administrador'}
            ]
        },
        {
            id:4,
            key:'description',
            label:'Descripción',
            required: true,
            type:'text',
            placeholder:'...',
            defaultValue:''
        }
    ])

    

    /**************** 
    * @methods
    ****************/
    const methodsList = {
        getDataItem:(object)=>{
            const stateList = new List(itemsFiles, campForm);
            setIdFileSelect(stateList.getDataItem(object, idFileSelect));
            const stateCurrent = stateList.updateCurrent(idFileSelect);
            setCampForm(stateCurrent.object);
            setDeleteD(stateCurrent.delete);
            setEdit(stateCurrent.edit);
            
        },
        voidCamps:()=>{
            const stateList = new List(itemsFiles, campForm);
            setCampForm(stateList.clearCamps());
        }
    }

    const methosRequests = {
        chageGetItems: async (filters)=>{
            handleLoadingData()
            const response = await Request.getItem({param:filters, tenant: clientName});
            setMeta(response.data.meta);
            const promiseData = response.data.data;
            setDataTable(
                promiseData.map(item=>{
                    return({
                        ...item,type:item.allowUrl? 'URL' : 'PDF'
                    })
                })
            );
            setItemsFiles(response.data);
            const {
                currentPage,
                pageZise,
                totalCount,
                totalPages
            } = response.data.meta;
            setPagination({
                currentPage: currentPage,
                pageSize: pageZise,
                totalItems: totalCount,
                totalPages: totalPages
            })
            handleLoadedData();

        },
        createItem:(dataForm)=>{
            if(dataText === 'PDF'){
                setValueDataForm(Object.assign(dataForm,{
                    allowedFormat: "PDF",
                    maxSizeKb: kbMax,
                    isToAdmin: keyIsToAdminm
                }));
            }else if(dataText === 'URL'){
                setValueDataForm(Object.assign(dataForm,{
                    allowUrl: true,
                    isToAdmin: keyIsToAdminm
                }))
            }
            let resp = Request.createItem({body:dataForm, ct:'json', tenant: clientName, 
                infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                }
            })
            // if(resp){
            //     window.location.reload();
            // }
        },
        updateItem:(dataForm, id)=>{

            dataForm = Object.assign(dataForm, {allowUrl: urlAllow.selectedItem.key, isToAdmin: true, maxSizeKb:kbMax})
            
            if(dataForm.allowUrl == 'false'){
                
                dataForm =Object.assign(dataForm,{
                    allowedFormat: "PDF",
                    isToAdmin: keyIsToAdminm
                });
            }
            let resp = Request.confirmRequest({body: dataForm, ct:'json', tenant: clientName, option:'updateItem', id:id,
            infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
            }
            })
            if(resp){
                //window.location.reload();
            }
        },
        deleteItem:()=>{
            idFileSelect.forEach(element=>Request.confirmRequest({id:element.id, tenant: clientName, option:'deleteItem',
            infoModal:{
              title:controlsPage?.lblDisabled?.value, 
              description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
            }
        }))
            //window.location.reload();
        }
    }

    /******** 
    *@hooks
    *********/

    const [promiseUser, setPromiseUser] = useState(requestUsers.getItem({
        tenant: clientName
    }))

    // const [promise, setPromise] = useState(Request.getItem({
    //     param: {pageSize: showElements, pageNumber: currentPages}, tenant: clientName
    // }))

    useEffect(() => {
        promiseUser.then((data) =>{
            setUsers(data.data.data);
            //setMeta(data.data.meta);
        })
    }, [])
    

    useEffect(() => {
        promiseUser.then((data) =>{
            setUsers(data.data.data);
        })

        // promise.then(data =>{
        //     setMeta(data.data.meta);
        //     const promiseData = data.data.data;
        //     setDataTable(
        //         promiseData.map(item=>{
        //             return({
        //                 ...item,type:item.allowUrl? 'URL' : 'PDF'
        //             })
        //         })
        //     );
        //     setItemsFiles(data.data);
        //     const {
        //         currentPage,
        //         pageZise,
        //         totalCount,
        //         totalPages
        //     } = data.data.meta;
        //     setPagination({
        //         currentPage: currentPage,
        //         pageSize: pageZise,
        //         totalItems: totalCount,
        //         totalPages: totalPages
        //     })
        // })
    }, [ users])

    if(itemsFiles === null){
        return (<ContainerBaseLoading />)
    }

    const numberPages = CountPages(itemsFiles.meta.totalPages);

    return (
        <>
            <SubMenu
                contentcontrols={
                    !deleteD && !edit ?
                    [
                        {
                            text:"Nueva categoría",
                            iconProps:{iconName: 'Add'},
                            onClick:()=>{methodsList.voidCamps(); openPanel();}
                        }
                    ]
                    :
                    [
                        edit?
                        {
                            text:"Editar categoría",
                            iconProps:{iconName: 'Edit'},
                            onClick:()=>openPanel()
                        }:null,
                        deleteD?{
                            text:"Inhabilitar categoría",
                            iconProps:{iconName: 'delete'},
                            onClick: ()=>methosRequests.deleteItem()
                        }:null
                    ].filter(obj => obj !== null)
                }
                contentcontrolsTwo={
                    <>
                        <Panel isOpen={isOpen}
                            closeButtonAriaLabel="Close"
                            isHiddenOnDismiss={true}
                            type={PanelType.smallFixedFar}
                            hasCloseButton={false}
                        >
                            {isOpen ?<ContainerLargeForm
                                dataForm={(e)=>{
                                    edit ? methosRequests.updateItem(e, idFileSelect[0].id)
                                    : methosRequests.createItem(e);
                                }}
                                formTitle={edit?'Editar categoría':'Nueva categoría'}
                                Items={campForm}
                                closePanel={dismissPanel}
                                actionOnChange={actionOnChange}
                                actionOnClick={actionOnClick}
                                isDisableOpt={isDisable}
                                onSubmitEventFormPanel={onSubmitEventFormPanel}
                                itemsComponentInformation={itemsComponentInformation}
                                defaultSelectedKeys={defaultSelectedKeys}
                                defaultValueInput={defaultValueInput}
                            />:''}
                        </Panel>
                    </>
                }
            />
            <div className="admin--body-container">
                <ListHeader
                    showReport={ false }
                    subRute="Documentos/Administración | "
                    subRute3= " Archivos categorizados"
                    title="Archivos categorizados"
                    onChange={(e)=>{
                        setShowElements(e.target.value); 
                        methosRequests.chageGetItems({pageSize:e.target.value, pageNumber:currentPages});
                    }}
                    InputChange={(e)=>{
                        methosRequests.chageGetItems({search:e, pageSize: showElements, pageNumber: currentPages})
                    }}
                />
                {/* <ListBody
                    key={"fileTypes"}
                    spaceTitle={spacetitle}
                    Items={dataTable}
                    meta={meta}
                    numberPages={numberPages}
                    initRangeText={currentPages}
                    orditem={ordItems}
                    finishRangeText={showElements}
                    totalRangeText={itemsFiles.data.length}
                    typelist={'listSelect'}
                    selectdata={methodsList.getDataItem}
                    paginate={(number)=>{
                        setCurrentPage(number);
                        methosRequests.chageGetItems({pageSize:showElements, pageNumber:number});
                    }}
                /> */}
                <DetailsListBext
                    enableShimmer={isLoadingData}
                    listKey="listSelect"
                    selectionMode={1}
                    items={dataTable}
                    //renderItemColumn={_defaultRenderItemColumn}
                    columnTitles={columnTitles}
                    onSelect={methodsList.getDataItem}
                    onPageChange={page => {
                        if (page !== +filter.pageNumber) {
                            setFilter({
                                ...filter,
                                pageNumber: String(page),
                            });
                        }
                    }}
                    pagination={pagination}
                />
            </div>
        </>
    );
}

export default FileTypes