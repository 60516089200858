import React, {useState, useEffect, useContext} from 'react'
import { FontIcon } from '@fluentui/react/lib/Icon';
import FluentUIReact from '@fluentui/react'
import JSCookies from 'js-cookie'
import { DefaultButton, Modal, IconButton, getTheme, mergeStyleSets, FontWeights } from '@fluentui/react'
import './modal.component.scss'
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { appKey } from '../../../utils/Requests/models.Controller';
import { PATH_SSO, SSO_URI } from '../../../assets/settings/settinsrouters';
import { useParams } from 'react-router-dom';
import { RequestFetch, consulta } from '../../../utils/Requests/requests.Controller';
import Cookies from 'js-cookie';



const ModalFluent = (props)=>{
  let info ={
    title:props.props.title,
    description:props.props.description,
    type:props.props.type,
  }
  const {clientName} = useParams();
  const token = JSCookies.get('token');
  const [dataUser, setDataUser] = useState(JSCookies.get('dataUser'));
  const [confirm, setConfirm] = useState(null);
  const Requests = new RequestFetch("OAuth/revoke");
  const DU = JSON.parse(dataUser);
  const idLng = Cookies.get('idLng')
  const [controlsPage, setControlsPage] = useState({})

    const openDB = () => {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open('textListApp',1);
        
        request.onsuccess = (event) => {
          const db = event.target.result;
          resolve(db);
        };
        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          if (!db.objectStoreNames.contains("textF")) {
            db.createObjectStore("textF", { keyPath: "clave" });
          }
        };
        request.onerror = (event) => {
          reject(event.target.error);
        };
      });
  };
    useEffect(() => {
      openDB().then((db) => {
        consulta(db).then((textos) => {
          let controlsPage={}
          if (Object.entries(textos).length > 0) {
              textos.ssoPagesData.map((users) => {
                  if (users.path == 'controls') {
                      users.webcontrolspages.map((data) => {
                          controlsPage[data.idHtml] = data.languagewebcontrols[0];
                      })
                  }
                })
                setControlsPage(controlsPage)
          }else{
            window.reload()
          }
        })
      })
    }, [props])

  // const [buttonTxt, setButtonTxt] = useState({
  //   accept:(props.text?props.text.smtAccept?.value:'Aceptar'),
  //   cancel:(props.text?props.text.smtClose?.value:'Cancelar')
  // })



  const actionOnClick = (e) => {
    if(props.objectRequest){
      setConfirm(props.objectRequest)
    }else{
      setConfirm("confirm")
    }

    setIsModalOpen(false);
  }

  useEffect(() => {
    if(confirm) {
      props.actionOnClick(confirm)
      setConfirm(null)
    }
  }, [confirm])
  
  const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
      if(Object.keys(props.props).length > 0){
        info.title = props.props.title
        info.description = props.props.description
        info.type=props.props.type
      setIsModalOpen(true)
      }
    }, [props])
    
  const hideModal = () => {
    if(props.props.type == "success"){
      window.location.reload()
    }
    if(props.props.errorGet){
      // window.history.back()
    }
    if (props.errToken) {
      props.func()
    }
    if (props.actionOnClick()) {
      props.actionOnClick()
    }

    setIsModalOpen(false);
    
  }
  const aceptModal = () => {

    if(props.props.path == 'change-password' || props.props.path == 'PasswordChange'){
    
      const params = new URLSearchParams();
      params.append('client', `${appKey}`);
      params.append('tenant', `${props.props.tenant}`);
      params.append('domain', `${DU.user.idDomain}`);
      params.append('token', `${token}`);
      const revoke = Requests.createItem({ body: params, ct: "encode", tr: 'revoke'})
      revoke.then(resp=>{
          JSCookies.remove('token')
          JSCookies.remove('dataUser')
          JSCookies.remove('dataToken')
          JSCookies.remove('dataClient')
          JSCookies.remove('refresh_token')
          const DBDeleteRequest = window.indexedDB.deleteDatabase("textListApp");

          DBDeleteRequest.onerror = (event) => {
          console.error("Error deleting database.");
          };

          DBDeleteRequest.onsuccess = (event) => {
          console.log("Database deleted successfully");
          };
          window.location.href = `${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${props.props.tenant}/home`
      })
    }else if(!props.props.path || props.props.path != 'PasswordChange'){ 
      setIsModalOpen(false);      
      if(props.op){

        props.actionOnClick(props.op)

      }
    }
    if(props.objectRequest){
      setConfirm(props.objectRequest)
    }
  }
  const limitText = (text) => {
    if (text.length > info.description) {
      return text.slice(0, info.description) + '...';
    }
    return text;
  };
  const theme = getTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [
      {
        flex: '1 1 auto',
        color: theme.palette.neutralPrimary,
        backgroundColor: '#eaeaea',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '8px 12px 10px 20px',
        fontSize: '20px'
      },
    ],
    body: {
      
      minWidth:'520px',
      minHeight:'30px',
      flex: '4 4 auto',
      padding: '0px 0px 0px 0px',
      fontSize: '15px'
    },
    p: {
      padding: '40px 40px 40px 40px',
    },
  });
  const iconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
  
  };

  const [typeModal, setTypeModal] = useState({})
  const typeM = {
    'success' : {
        error: false,
        title: 'Success',
        icon: 'icon-check',
        class: 'modal--icon-success'
    },
    'warning': {
        error: false,
        title: 'Warning',
        icon: 'icon-attention-alt',
        class: 'modal--icon-warning'
    },
    'danger': {
        error: true,
        title: 'Error',
        icon: 'icon-cancel',
        class: 'modal--icon-danger'
    },
    'default': {
        error: false,
        title: 'Default',
        icon: 'icon-lock-filled',
        class: 'modal--icon-default'
    },
}

useEffect(()=>{
  setTypeModal(typeM[info.type])
},[props])  

  return (
    <>
      <Modal
        titleAriaId="id"
        isOpen={isModalOpen}
        onDismiss={()=>hideModal}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <span id="id" className='mg4-h'>{info.title}</span>
          {props.hideIcon?
          null
          :<IconButton
          styles={iconButtonStyles}
            iconProps = {{iconName: 'Cancel'}}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />}
        </div>
        <div className={`modal--icon ${typeModal.class}`}>
            
            <a className={`${typeModal.icon}`}/>
        </div>
        <div className="content-modal">
        <div className={`modal--body ${contentStyles.body}`}>
        
        
          {typeModal.class == 'modal--icon-warning' || typeModal.class == 'modal--icon-success'?
          <p className={`${contentStyles.p} ${typeModal.class}`}>
            {limitText(info.description)}
          </p>
          : <p className={`${contentStyles.p}`}>
          {info.description}
        </p>
        }
        </div>
        {info.type === 'success' || props.hideCancel?
        <div className={`modal--content-btns flex-fc pd ${contentStyles.body}`}>
            <button onClick={aceptModal} className="button--default button--primary btn-h-resp br w10-resp button--default-resp-h">{controlsPage.smtClose?.value}</button>
        </div>
        :
        <div className={`modal--content-btns flex-fc pd ${contentStyles.body}`}>
          <div className={`dsp-flex ${!typeModal.error?'c--flex-wsb':'c--flex-wc'} w10-resp w5`}>
          {!typeModal.error?
            <button onClick={actionOnClick} disabled={props.disable} className={`button--default br mrg-b btn-h-resp w10-resp button--default-resp-h ${props.disable?'button--disable':'button--primary'}`}>{controlsPage.smtAccept?.value}</button>
            : ""
          }
            <button onClick={hideModal} className={`button--default button--second br btn-h-resp w10-resp button--default-resp-h ${info.type === 'danger'?"ml3":""}`}>{controlsPage.smtClose?.value}</button>
          </div>
        </div>
        }
        </div>
      </Modal>
    </>
  );
};
export default ModalFluent