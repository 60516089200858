export const COLUMN_TITLES = [{
    idNumber: {
        name: "Nº",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
        maxWidth: 30
    },
    username: {
        name: "Usuario",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
        isMultiline: true
    },
    domain: {
        name: "Dominio",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
    },
    isActive: {
        name: "Estado (Licencia)",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
    },
    creatingDate: {
        name: "Fecha de creación",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
        idHtml: "coluCreationDate",
    },
    updatingDate: {
        name: "Última Modificación",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
        idHtml: "coluLastModification",
    },
    creatorUser: {
      name: "Creado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "coluCreatedBy",
    },
    updateUser: {
      name: "Modificado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "lblModifiedBy"
    },
}];

export const INDIVIDUAL_ASSIGNMENT_COLUMN_TITLES = [{
    idNumber: {
        name: "Nº",
        maxWidth: 30
    },
    username: {
        name: "Usuario",
        isMultiline: true,
        maxWidth: 150
    },
    domain: {
        name: "Dominio",
        maxWidth: 150
    },
    actions: {
        name: "",
    }
}];

export const REPORT_COLUMN_TITLES = [{
    code: {
        name: "Código",
        maxWidth: 60
    },
    user: {
        name: "Usuario",
        isMultiline: true
    },
    domain: {
        name: "Dominio",
    },
    operation: {
        name: "Operación",
    },
    errorType: {
        name: "Tipo de error presentado",
    },
    creationDate: {
        name: "Fecha",
        minWidth: 180
    }
}];

