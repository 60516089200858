//****Librarys ****//
import React, { useState, useContext, useEffect } from 'react';
import LenguajeContext from '../../../context/Lenguaje/lenguajeContext';

//*****Componnents*****//

//**Methods**//

/**
 *@author Sebastian Ocampo(14-05-2022)
 *@version 1.0
 *@lastCommit 
 */

const SelectFile = ({
    label,
    options,
    value,
    onChange,
    id
}) =>{
    /************************
     * @arguments 
     ************************/
        const [currentSelect, setCurrentSelect] = useState(value ? value : 0)
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        const [controlsPage, setControlsPage] = useState({});

     /************************
      * @methods 
      ************************/
     useEffect(async() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            stateLanguage.lenguaje.map((pass) => {
                if ( pass.path == 'controls' || pass.path == 'change-password' || pass.path == 'associate-security-questions')  {
                  pass.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  })  
                }              
            })          
            setControlsPage(controlsPage)
        }
      }, [stateLanguage])

     const _handleOnChange = (event) => {
         onChange(event);
     }

     const validateSelect = () => {
        if(id !== undefined){
            let miSelect = document.getElementById("select" + id);
            if(miSelect !== null){
                if (miSelect.value === "") {
                miSelect.setCustomValidity(controlsPage?.txtAnswer?.errorRequiredMessage);
                } else {
                miSelect.setCustomValidity("");
                }
            }
        }
    }
     
     /************************
      * @hooks 
      ************************/
         
     /************************
      * @resturns 
      ************************/
        return(
            <>
                <div className="selectFile--container">
                    {label?<label>{label}</label>:undefined}
                    <select id={"select" + id} defaultValue={""} onSelect={validateSelect()} onChange={(e)=> {
                        setCurrentSelect(e.target.value);
                        _handleOnChange(e);
                        validateSelect();
                    }}
                    required
                    >
                        {options.map(option=>
                            <option 
                                className={currentSelect == option.id ? 'active': ''}
                                key={option.id} value={option.id}
                                selected={option.id === currentSelect}
                            >
                                {option.text}
                            </option>
                        )}
                    </select>
                </div>
            </>
        );
}

export default SelectFile;
