import React, {useState, useEffect, useContext} from "react";
import {useParams} from "react-router-dom";
import {useBoolean} from "@fluentui/react-hooks";

import ContainerLargeForm from "../../../components/containers/largeForm/containerLargeForm.component";
import {RequestFetch} from "../../../utils/Requests/requests.Controller";
import {validateEmptyFields} from "../../../utils/validations/forms";
import {IP_RESTRICTION_FORM} from "./constants";
import {render} from "react-dom";
import ModalFluent from "../../../components/tools/modalFluent/ModalFluent";
import { changeLangForm } from "../../../utils/logicRendering/changeIdiom";
import LenguajeContext from "../../../context/Lenguaje/lenguajeContext";
import ContainerBaseLoading from "../../../components/containers/baseLoading/containerBaseLoading.component";

const IpRestrictionPanel = (props) => {
  const {
    ipSelected,
    onClosePanel
  } = props;

  const {clientName} = useParams();
  const [fieldsForm, setFieldsForm] = useState([]);
  const [formValues, setFormValues] = useState(getDefaultValues());
  const { stateLanguage } = useContext(LenguajeContext);
  const [controlsPage, setControlsPage] = useState({});
  const [isEdited, {
    setTrue: handleEdited
  }] = useBoolean(false);

  const requestsDeniedIpAddresses = new RequestFetch('DeniedIpAddresses');

  useEffect( () => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        stateLanguage.lenguaje.map((pass) => {
            if (pass.path == 'ip-restriction'|| pass.path =='controls') {
                pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })    
              setControlsPage(controlsPage)
            } 
        })
    }
    setFieldsForm(changeLangForm(IP_RESTRICTION_FORM,controlsPage))
    buildFields();
  }, []);

  function getDefaultValues() {
    return {
      startRange: ipSelected?.startRange || null,
      endRange: ipSelected?.endRange || null,
    }
  }

  const buildFields = () => {
    let defaultValues;
    if (ipSelected) {
      defaultValues = getDefaultValues();
      setFormValues(defaultValues);
    }
    const form = IP_RESTRICTION_FORM.map(field => {
      let mappedField = field;
      if (defaultValues?.[field.key] && ipSelected) {
        mappedField = {
          ...mappedField,
          defaultValu: defaultValues[field.key],
          defaultValue: defaultValues[field.key]
        }
      }
      return mappedField;
    })
    setFieldsForm(form);
  }

  const invalidIpFormat = (formValues) => {
    const ipRegex = new RegExp(
      "^([0-9]{1,3}).([0-9]{1,3}).([0-9]{1,3}).([0-9]{1,3})$"
    );
    return Object.values(formValues).find(value => ipRegex.test(value) === false)
  }

  const submitForm = async () => {
    if (validateEmptyFields(formValues)) {
      if(invalidIpFormat(formValues)){
        render(
          <ModalFluent
            props={{
              description: <div className="container--flex c--flex-dc c--flex-hc">
                <h3 className="mg10-h">Validación</h3>
                <h4 className="mg10-h txt--aling-c">El formato de ip es incorrecto</h4>
              </div>,
              type:'danger'
            }}
            actionOnClick={_ => {}}
            objectRequest={_ => {}}
            disable={false}
          />,
          document.getElementById("modal")
        );
      } else {
        let payload = {
          ...formValues
        }
        if(ipSelected){
          methodRequests.updateItem(payload, ipSelected.id, clientName);
          handleEdited();
        } else {
          await methodRequests.createItem(payload, clientName);
          onClosePanel(true);
        }
      }

    }
  }


  const methodRequests = {
    createItem: async (payload, tenant) => {
      await requestsDeniedIpAddresses.createItem({body: payload, ct: "json", tenant});
    },
    updateItem: (payload, id) => {
      requestsDeniedIpAddresses.confirmRequest({
        body: payload,
        id: id,
        ct: "json",
        tenant: clientName,
        option: 'updateItem'
      })
    }
  };

  const actionOnChange = (data) => {
    const value = data?.selectedItem;
    if (formValues.hasOwnProperty(data.id) && formValues[data.id] !== value && value !== undefined) {
      setFormValues(currentValue => ({
        ...currentValue,
        [data.id]: value
      }));
    }
  };

  return (
    <>
    {Object.entries(controlsPage).length>0?
    <>
      <ContainerLargeForm
        dataForm={_ => {
        }}
        formTitle={ipSelected ? controlsPage?.lblEditRestriction?.value : controlsPage?.lblNewRestrictionIp?.value}
        Items={fieldsForm}
        closePanel={() => onClosePanel(isEdited)}
        actionOnChange={actionOnChange}
        isDisableOpt={[
          {disable: false, key: "valueDefault"},
          {disable: true, key: "name"},
        ]}
        onSubmitEventFormPanel={submitForm}
        itemsComponentInformation={{}}
        defaultSelectedKeys={[]}
        defaultValueInput={[]}
      />
    </>
       :
       <ContainerBaseLoading /> }
       </>
  );
};


export default IpRestrictionPanel;
