import React, { useState, useEffect, useMemo } from "react";
import { Checkbox, Stack } from "@fluentui/react";

const LargeFormCheckbox = (props) => {
  const [selectedItem, setSelectedItem] = useState();
  let item = props.item;

  const onChange = (e, item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    let objectFinal = {
      ...selectedItem,
      ...{ id: item.key },
    };
    props.actionOnChange(objectFinal);
  }, [selectedItem]);

  //Buscar en un arreglo el id para habilitar y deshabilitar
  let found;
  if (props.isDisableOpt.length === 1) {
    found = props.isDisableOpt.find((element) => element.key === "valueDefault");
  } else {
    found = props.isDisableOpt.find((element) => element.key === item.key);
    if (found === undefined) {
      found = props.isDisableOpt.find((element) => element.key === "valueDefault");
    }
  }
  if (found === undefined) {
    found = props.isDisableOpt.find((element) => element.key === item.key);
  }
  const stackTokens = { childrenGap: 10 };
  return (
    <Stack tokens={stackTokens}>
      <Checkbox 
       id={item.key}
       label={item.label} 
       onChange={onChange} 
       disabled={found.disable}
       />

    </Stack>
  );
};
export default LargeFormCheckbox;
