import React, {useState, useEffect} from "react";
import {useBoolean} from "@fluentui/react-hooks";
import {DefaultButton} from "@fluentui/react/lib/Button";

import ListHeader from "../../../components/lists/listHeader/listHeader.component";
import {REPORT_COLUMN_TITLES} from "./constants";
import DetailsListBext from "../../../components/lists/DetailsList/detailsList.component";
import {RequestFetch} from "../../../utils/Requests/requests.Controller";

const UserLicencesReport = ({
                                onCancel,
                                clientName,
                                domainForm
                            }) => {

    const reportUsersRequest = new RequestFetch('Report/user');

    const [licenceList, setLicenceList] = useState([]);
    const [filtersList, setFiltersList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [filter, setFilter] = useState({
        pageSize: 5,
        pageNumber: "0",
        idTypeOperation: 6
    });

    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);

    useEffect(() => {
        if (filter.pageNumber > 0) {
            requests.getReportUsers(filter, clientName);
        }
    }, [filter])

    useEffect(() => {
        if (domainForm) {
            setFiltersList([
                {
                    id: "domain-select",
                    key: 'idDomain',
                    label: '',
                    type: 'select',
                    placeholder: "Seleccione dominio",
                    options: domainForm.map(
                        domain => ({
                            id: domain.id,
                            text: domain.alias
                        })
                    )
                },
                {
                    id: 0,
                    key: 'codeFilter',
                    type: 'text',
                    label: '',
                    placeholder: "Código de reporte"
                }

            ]);
        }
    }, [domainForm])

    const _handleOnCancel = () => {
        onCancel();
    }

    const requests = {
        getReportUsers: async (filters, tenant) => {
            setPagination(null);
            handleLoadingData()
            const response = await reportUsersRequest.getItem({
                param: {
                    userSearchType: 4,
                    ...filter
                },
                tenant
            });
            if (response.data) {
                setLicenceList(
                    response.data.data.map(
                        operation => {
                            return ({
                                code: operation.operation.id,
                                user: operation.user.name,
                                domain: domainForm.find(domain => domain.id === operation.idDomain).alias || "No encontrado",
                                operation: operation.operation.description,
                                errorType:  operation.operation.name,
                                creationDate: operation.creatingDate
                            })
                        }
                    )
                );
            }
            console.log("response", response, licenceList);
            if(response.data.meta){
                const {
                    currentPage,
                    pageZise,
                    totalCount,
                    totalPages
                } = response.data.meta;
                setPagination({
                    currentPage: currentPage,
                    pageSize: pageZise,
                    totalItems: totalCount,
                    totalPages: totalPages
                });
            }


            handleLoadedData();
        }
    }

    return (
        <div className="container--flex c--flex-dc">
            <div className="mg10-h">
                <h1>Reporte de Operaciones</h1>
            </div>
            <div className="mg10-h container--flex c--flex-dc bkgn--light-III pg8">
                <div className="admin--body-container">
                    <ListHeader
                        showReport={false}
                        filters={filtersList}
                        hideSearch={true}
                        hideUserSessioninformation={true}
                        onChange={(event) => {
                            setFilter({
                                ...filter,
                                pageSize: event.target.value,
                                pageNumber: filter.pageNumber === "0" ? "0" : "1"
                            });
                        }}
                        dataForm={({codeFilter, idDomain}) => {
                            setFilter({
                                ...filter,
                                pageNumber: "1",
                                idDominio: idDomain || "1",
                                idTypeOperation: codeFilter ? codeFilter : 6
                            });
                        }}
                    />
                    {

                    (isLoadingData || (!isLoadingData && licenceList.length > 0))  ?
                        <DetailsListBext
                            enableShimmer={isLoadingData}
                            listKey="userLicencesReportList"
                            selectionMode={0}
                            items={licenceList}
                            columnTitles={REPORT_COLUMN_TITLES}
                            onPageChange={page => {
                                if (page !== +filter.pageNumber) {
                                    setFilter({
                                        ...filter,
                                        pageNumber: String(page),
                                    });
                                }
                            }}
                            pagination={pagination}
                        />
                    :
                    <h4>No hay registros para este usuario</h4>
                    }

                </div>

            </div>

            <div className="c--flex-dc">
                <DefaultButton className="button--second w3 mg10-w" onClick={_handleOnCancel}>CERRAR</DefaultButton>
            </div>

        </div>
    )
}

export default UserLicencesReport;
