import React, { useContext, useEffect, useState } from 'react'
import CookiesJS from 'js-cookie';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks'
import { useParams } from 'react-router-dom';
//*****Componnents*****//
    import SubMenu from '../../components/navigation/subMenu/subMenu.component'
    import ContainerLargeForm from '../../components/containers/largeForm/containerLargeForm.component'
    import ListHeader from '../../components/lists/listHeader/listHeader.component'
    import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';


//*****Methods*****//
    import Lists from '../../utils/logicRendering/lists.controller'
    import {RequestFetch} from '../../utils/Requests/requests.Controller'
import LenguajeContext from '../../context/Lenguaje/lenguajeContext';


const NotificationChannel = ()=>{

  
    /************************
     * @arguments 
     ************************/
        //***Propertise***//
        const [edit, setEdit] = useState(false);
        const [deleteD, setDeleteD] = useState(false);
        const [idChannelSelect, setIdChannelSelect] = useState([]);
        const [currentPages, setCurrentPage]= useState(1);
        const [showElements, setShowElements]= useState(5);
        const [itemsType, setItemsType] = useState(null);
        // const [itemsChannel, setItemsChannel] = useState(null);
        const [itemsChannel, setItemsChannel] = useState(null);
        const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
        const [responseCode, setResponseCode] = useState(null);
        const { clientName } = useParams();
        const [controlsPage, setControlsPage] = useState({});
        const [textOnPages, setTextOnPages] = useState({})
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        const {idClient} = JSON.parse(CookiesJS.get('dataClient'));
        const [ValueNotifiType ,setValueNotifiType]=useState([])
        let NotifiType = [];
        let ArrayLanguage = []

        //***Data Propertise***//
        const Requests = new RequestFetch('NotificationChannel');
        const ResquetsNotifiType = new RequestFetch("Notification/types");
        const SpaceTitel = ['Tipo','Proveedor de Servicio','Usuario', 'Endpoint', 'Fecha de creación', 'Creado por']
        const OrdItems = ['type','nameProvider','userProvider', 'endPointProvider', 'creatingDate', 'creatorUser']

        const RequestsLanguages = new RequestFetch("Languages")
        const [conSelected, setConSelected] = useState();
        const [pagination, setPagination] = useState(null);
        const [filter, setFilter] = useState({
            pageSize: 5,
            pageNumber: "0"
        });
        const [isLoadingData, {
            setTrue: handleLoadingData,
            setFalse: handleLoadedData
        }] = useBoolean(false);
        
        const TITULOS_COLUMNAS = [{
            type: {
                name:SpaceTitel[0],
                isResizable: true,
                maxWidth: 100,
                isMultiline: true
            },
            nameProvider: {
                name:SpaceTitel[1],
                isResizable: true,
                maxWidth: 100,
                isMultiline: true
            },
            userProvider:{
                name:SpaceTitel[2],
                isResizable: true,
                maxWidth: 100,
                isMultiline: true
            },
            endPointProvider:{
                name: SpaceTitel[3],
                isResizable: true,
                maxWidth: 100,
                isMultiline: true
            },
            creatingDate: {
                name: "Fecha de creación",
                minWidth: 50,
                isResizable: true,
                maxWidth: 150,
                idHtml: "coluCreationDate",
            },
            updatingDate: {
                name: "Última Modificación",
                minWidth: 50,
                isResizable: true,
                maxWidth: 150,
                idHtml: "coluLastModification",
            },
            creatorUser: {
              name: "Creado por",
              maxWidth: 150,
              isResizable: true,
              idHtml: "coluCreatedBy",
            },
            updateUser: {
              name: "Modificado por",
              maxWidth: 150,
              isResizable: true,
              idHtml: "lblModifiedBy"
            },
        }];

        const [campForm, setCampForm] = useState([
            {  
                id:0, 
                key: 'idNotificationType',
                label: 'Tipo',
                required: true,
                type: 'select',
                options: NotifiType,
                defaultValue:ValueNotifiType,
            },
            {  
                id:1, 
                key: 'userProvider',
                label: 'Usuario',
                type: 'text',
                placeholder: 'Ingrese usuario',
                defaultValue: '',
                required: true,
                max:70,
                min:10,
            },
            {  
                id:2, 
                key: 'passwordProvider',
                label: 'Password',
                type: 'password',
                placeholder: 'Ingrese password',
                defaultValue: '',
                required: true,
                max:70,
                min:10,
            },
            {  
                id:3, 
                key: 'endPointProvider',
                label: 'End point',
                type: 'text',
                placeholder: 'Ingrese endpoint',
                defaultValue: '',
                required:true,
                max:400,
                min:10,
            },
            {  
                id:4, 
                key: 'nameProvider',
                label: 'Proveedor de Servicio',
                type: 'text',
                placeholder: 'Ingrese el proveedor de servicio',
                defaultValue: '',
                required: true,
                max:150,
                min:10,
            },
        ])

        useEffect(() => {
            let controlsPage = {};
            if (stateLanguage.lenguaje !=0) {
                //console.log(stateLanguage);
                stateLanguage.lenguaje.map((pass) => {
                  // console.log(pass)
                    if (pass.path == 'controls'|| pass.path =='domain') {
                      pass.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                      })    
                      setControlsPage(controlsPage)
                    }              
                })          
            }
        
        }, [textOnPages, stateLanguage])

        

    /************************
    * @methods 
    ************************/
        const methodsList = {
            getDataItem:(object)=>{
                const stateList = new Lists(itemsChannel, campForm)
                setIdChannelSelect(stateList.getDataItem(object, idChannelSelect))
                const stateCurrent = stateList.updateCurrent(idChannelSelect)
                setCampForm(stateCurrent.object)
                setDeleteD(stateCurrent.delete)
                setEdit(stateCurrent.edit)
            },
            voidCamps:()=>{
                const stateList = new Lists(itemsChannel, campForm)
                setCampForm(stateList.clearCamps())
            }         
        }

        const methosRequests = {
            chageGetItems:(filters)=>{
                
                // setPromiseNotifiType(ResquetsNotifiType.getItem({param:filters}));
                // setPromise(Requests.getItem({param:filters, tenant: clientName}));
            },
            crateItem:(dataForm)=>{
                dataForm = Object.assign(dataForm, {
                    idClient: idClient,
                });
                Requests.createItem({ body: dataForm, ct: "json" , tenant:clientName, 
                    infoModal:{
                    title:controlsPage?.lblSuccessfulProcess?.value,
                    description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                    }
                })
                
            },
            updateItem:(dataForm, id)=>{
               
              Requests.confirmRequest({ body: dataForm, id: id, ct: "json", tenant: clientName, option:'updateItem',
              infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                } })
            },
            deleteItem: () => {
                Requests.confirmRequest({ id: conSelected.id, tenant: clientName, option: 'deleteItem',
                infoModal:{
                    title:controlsPage?.lblDisabled?.value, 
                    description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
                  } })
            },
            getNChanel: async (filters,tenant)=>{
                setPagination(null);
                handleLoadingData()
                
                const promise = await Requests.getItem(
                    {
                        param: filters, tenant
                    }
                    
                )
    
                const promiseLng = await RequestsLanguages.getItem(
                    {
                        tenant: clientName
                    }
                )
                    promiseLng.data.data.map(item=>
                        {
                            ArrayLanguage.push({name: item.name, id: item.id})
                        }
                    )
                const {
                    currentPage,
                    pageZise,
                    totalCount,
                    totalPages
                } = promise.data.meta;
                setPagination({
                    currentPage: currentPage,
                    pageSize: pageZise,
                    totalItems: totalCount,
                    totalPages: totalPages
                });
                handleLoadedData()            
            }
        }

        const _defaultRenderItemColumn = (item, index, column) => 
        {
            const fieldContent = item[column.fieldName];
            return <span>{fieldContent}</span>;           
        }

        const _selectUser = (items) => 
        {
            if (items.length > 0){     
                setConSelected(items[0]);
            } else {
                setConSelected();
            }       
        }

    /************************
     * @hooks 
    ************************/
        useEffect(() => {     
            if (filter.pageNumber >0 || filter.search) {   
                methosRequests.getNChanel(filter, clientName);
            }   
        }, [filter])

        useEffect( async () => {
            handleLoadingData()
            const promise = await Requests.getItem(
                {
                    param: {
                        pageSize: showElements,
                        pageNumber: currentPages,
                        idClient: idClient
                    }, 
                    tenant: clientName
                }
            )
            const notificationType = await ResquetsNotifiType.getItem({
                    tenant: clientName
                })
            notificationType.data.data.map(item=>{
                NotifiType.push({
                    value: item.id,
                    text: item.name,
                })
            })
            setItemsChannel(
                promise.data.data.map(
                    nt => {
                        return (
                            {
                                ...nt,
                                type: nt.idNotificationType == 1 ? notificationType.data.data[0].name : 
                                nt.idNotificationType == 2 ? notificationType.data.data[1].name : 
                                notificationType.data.data[2].name
                            }
                        )
                    }
                )
            )
            const promiseLng = await RequestsLanguages.getItem(
                {
                    tenant: clientName
                }
            )

            promiseLng.data.data.map(item=>{
                ArrayLanguage.push({name: item.name, id: item.id})
            })

        const{
            currentPage,
            pageZise,
            totalCount,
            totalPages,

            } = promise.data.meta;

        setPagination({
            currentPage: currentPage,
            pageSize: pageZise,
            totalItems: totalCount,
            totalPages: totalPages,
        });

        handleLoadedData()

    },[controlsPage])
        
    /************************
     * @resturns 
     ************************/
        return(
            <>
                <SubMenu
                contentcontrols={
                    conSelected?[
                        {
                            text:'Editar canal de envío',
                            iconProps:{iconName: 'Edit'},
                            onClick:()=>openPanel()
                        },
                        {
                            text:'Inhabilitar canal de envio',
                            iconProps:{iconName: 'delete'},
                            onClick:()=>methosRequests.deleteItem()
                        }
                    ]
                    :
                    [
                        {
                            text:'Nuevo canal de envio',
                            iconProps:{iconName: 'Add'},
                            onClick:() => { methodsList.voidCamps(); openPanel(); }
                        }
                    ]
                }
                contentcontrolsTwo={
                    <Panel isOpen={isOpen}
                            closeButtonAriaLabel="Close"
                            isHiddenOnDismiss={true}
                            type={PanelType.smallFixedFar}
                            hasCloseButton={false}
                        >   
                        
                            {isOpen ?<ContainerLargeForm
                                dataForm ={(e) =>{
                                    conSelected ? methosRequests.updateItem(e, conSelected.id): methosRequests.crateItem(e)
                                }}
                                formTitle={conSelected?'Editar Canal':'Nuevo Canal de envío'}
                                Items={campForm}
                                closePanel={dismissPanel}
                            />:''}
                    </Panel>
                }
            /> 
                <div className="admin--body-container">
                    <ListHeader 
                        filters={false}
                        showReport={false}
                        subRute="Administración | "
                        subRute3= " Configuración de Servicios Externos"
                        title="Lista de Canales de Envío"
                        dataForm={(e)=>methosRequests.chageGetItems({
                            pageSize:showElements, pageNumber:currentPages,
                            idDomain:e.idDomain, idLanguage:e.idLanguage
                        })}
                        onChange={(event)=>{
                            setFilter({
                                ...filter,
                                pageSize: event.target.value,
                                pageNumber: filter.pageNumber === "0" ? "0" : "1"
                            });
                        
                        }}
                        InputChange={(e)=>{
                            setFilter({
                                ...filter,
                                search: e
                            })
                        
                        }}
                    />
                    <DetailsListBext
                    enableShimmer={isLoadingData}
                    listKey="NotiChanelBsrList"
                    selectionMode={1}
                    items={itemsChannel} 
                    renderItemColumn={_defaultRenderItemColumn}
                    columnTitles={TITULOS_COLUMNAS}
                    onSelect={_selectUser}
                    onPageChange={page => {
                        if(page !== +filter.pageNumber){
                            setFilter({
                                ...filter,
                                pageNumber: String(page),
                            });
                        }
                    }} 

                    pagination={pagination}
                    />
                </div>
            </>    
        );
}

export default NotificationChannel