export const changeLang = (current, controlsPage) =>{
    const newColumns = Object.entries(current[0])
    .reduce((newObject,[key,value])=>{
        return{
            ...newObject,
            [key]:{
                ...value,
                name:value?.idHtml ? (controlsPage?.[value.idHtml].value) : value.name,
            }
        }
    },{})
    return[
        newColumns
    ]
}

export const changeLangForModal = (current, controlsPage) =>{
    const newColumns = Object.entries(current)
    .reduce((newObject,[key,value])=>{
        return{
            ...newObject,
            [key]:{
                ...value,
                ...value?.idHtmlForTitle?{title:(controlsPage?.[value.idHtmlForTitle].value)}:{},
                ...value?.idHtmlForTitle?{description:(controlsPage?.[value.idHtmlForDesc].value)}:{}
            }
        }
    },{})
    return newColumns
}


export const changeLangForm = (current, controlsPage) =>{
    let nuevoArray = [];
    current.map(obj=>{
        let idHtml = obj.idHtml;
        let idHtmlForDesc = obj.idHtmlForDesc;
        let idHtmlRenderLabel = obj.idHtmlRenderLabel;
        let idHtmlPlaceholder = obj.placeholder;
        let initialLabel = controlsPage[idHtml];
        let descriptionLabel = controlsPage[idHtmlForDesc];
        let renderLabel = controlsPage[idHtmlRenderLabel];
        let placeholderLabel = controlsPage[idHtmlPlaceholder];
        if (initialLabel) {
        // obj.label = initialLabel.value;
        obj= Object.assign(
            obj,
            {
                ...obj.label?{label:initialLabel.value}:{},
                ...obj.placeholder?{placeholder:initialLabel.placeholder}:{},
                ...obj.title?{title:initialLabel.value}:{},
                ...obj.description?{description:descriptionLabel.value}:{},
                ...obj.onRenderLabel?{onRenderLabel:renderLabel?.value}:{},
                // placeholder:initialLabel.value
                ...obj.placeholder?{placeholder:placeholderLabel?.value}:{}
            })
        }
    
        nuevoArray.push(obj);
    })
    // console.log(nuevoArray)
    return nuevoArray;
}