import DomainModels from './domain.Model'

import {SERVISE_URI} from '../../assets/settings/settinsrouters'

export const appKey = 'BSR SSO'
const url = `${SERVISE_URI}api`

const ContrlModel =({ method, params, body, category, token, id, tenant, headerEx, idLanguageUser})=>{
    if(tenant != 'undefined'){
        const headers = {
            'Basic':{
                'Accept': 'application/json',
                'content-type': 'application/json',
                'idLanguageUser': idLanguageUser,
                ...headerEx
            },
            'Oauth':{
                'Accept': 'application/json',
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'pageNumber': `1`,
                'pageSize': `5`,
                'tenant': tenant,
                'idLanguageUser': idLanguageUser,
                ...headerEx
            }
        }
    
        const REQUEST_MODEL =  DomainModels({
                id: id,
                url: url,
                method: method,
                body: body,
                params: params,
                appkey: appKey,
                headers: headers['Oauth'], 
                category: category
            })

        return REQUEST_MODEL
    }
}

export default ContrlModel
