import React, { useContext, useEffect, useState } from "react";
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useBoolean } from "@fluentui/react-hooks";
import CookieJS from "js-cookie";
import { useParams } from "react-router-dom";

//**Components**//
import SubMenu from "../../components/navigation/subMenu/subMenu.component";
import ContainerLargeForm from "../../components/containers/largeForm/containerLargeForm.component";
import ContainerBaseLoading from "../../components/containers/baseLoading/containerBaseLoading.component";
import ListHeader from "../../components/lists/listHeader/listHeader.component";
import ListBody from "../../components/lists/listBody/listBody.component";
import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';

//**Methods**//
import Lists from "../../utils/logicRendering/lists.controller";
import { RequestFetch } from "../../utils/Requests/requests.Controller";
import CountPages from "../../utils/logicRendering/countPages.controller";
import Modal from "../../components/tools/modal/modal.component";
import { DetailsList, buildColumns, IColumn } from '@fluentui/react/lib/DetailsList';
import { changeLang, changeLangForm } from "../../utils/logicRendering/changeIdiom";
import LenguajeContext from "../../context/Lenguaje/lenguajeContext";

/**
 *@author Jhon Beltran(26-05-2022)
 *@version 1.0
 *@lastCommit 
 */

const Themes = () => {
  /************************
   * @arguments
   ************************/
  //***Propertise***//

  const [stateModal, setStateModal] = useState(false);
  const [stateModal2, setStateModal2] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [edit, setEdit] = useState(false);
  const [deleteD, setDeleteD] = useState(false);
  const [idThemeSelect, setIdThemeSelect] = useState([]);
  const [currentPages, setCurrentPage] = useState(1);
  const [showElements, setShowElements] = useState(5);
  const [itemsTheme, setItemsTheme] = useState(null);
  const { getLenguaje, stateLanguage } = useContext(LenguajeContext);
  const [controlsPage, setControlsPage] = useState({})
  const [controlsPageValue, setControlsPageValue] = useState({});
  const [filtersList, setFiltersList] = useState([]);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const { clientName } = useParams()
  const [valueFont, setValueFont] = useState();
  const changeFontValue = (data) => {
    setValueFont(data);
  };
  const client = CookieJS.get('nameClient');
  //***Data Propertise***//
  const [isLoadingData, {
    setTrue: handleLoadingData,
    setFalse: handleLoadedData
  }] = useBoolean(false);
  const [columnTitles, setColumnTitles] = useState([{
    idNumber: {
      name: "Número",
      idHtml: "coluNumber",
      maxWidth: 80
    },
    name: {
      name: "Tema o personalización",
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: false,
      isResizable: true,
      idHtml: "coluTheme",
      applyColumnFilter: true,
    },
    primaryColor: {
      name: "Preview",
      idHtml: "coluPreview",
      maxWidth: 150
    },
    creatingDate: {
        name: "Fecha de creación",
        minWidth: 100,
        isResizable: true,
        maxWidth: 200,
        idHtml: "coluCreationDate",
    },
    creatorUser: {
      name: "Creado por",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      idHtml: "coluCreatedBy",
    },
    updatingDate: {
        name: "Última Modificación",
        minWidth: 100,
        isResizable: true,
        maxWidth: 200,
        idHtml: "coluLastModification",
    },
    updateUser: {
      name: "Modificado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "lblModifiedBy"
    }
  }])

  const Client = CookieJS.get('dataClient');
  const dataClient = JSON.parse(Client);
  const [idClient, setIdClient] = useState(dataClient.idClient)
  const Requests = new RequestFetch("Template");
  const RequestsAcces = new RequestFetch(`Access/client/${client}`);
  const SpaceTitel = ["Tema", "Preview"];
  const OrdItems = ["name", "preview"];
  const [campForm, setCampForm] = useState([
    {
      id: 1,
      key: "name",
      title: "Nombre",
      label: "Nombre",
      type: "",
      max: controlsPageValue?.txtName?.maxlength,
      //max: 30,
      placeholder: "ej: Tema 1",
      required: 1,
      idHtml: 'txtName'
    },

    {
      id: 3,
      key: "primaryFont",
      label: "Fuente",
      type: "radius",
      title: "Fuentes",
      idHtml: "lblFonts",
      value: 1,
      options: [
        { id: 1, label: "Arial" },
        { id: 2, label: "Open Sans" },
        { id: 3, label: "Helvetica" },
        { id: 4, label: "PT Sans" },
        { id: 5, label: "Dosis" },
        { id: 6, label: "IBM Plex Sans" },
        { id: 7, label: "Exo" },
      ],
      required: 1,
    },
    {
      id: 4,
      key: "logoPath",
      title: "Logo",
      label: "Imagen logo",
      idHtml: "lblLogo",
      type: "file",
      required: 0,
    },
    {
      id: 5,
      key: "imageBackgroundPath",
      title: "Imagen de fondo",
      label: "Imagen background",
      idHtml: "lblBackgroundImage",
      type: "file",
      required: 0,
    },
    {
      id: 6,
      key: "primaryColor",
      type: "color",
      title: "Color primario y gama adicional",
      idHtml: "lblPrimaryColorAndAdditionalRange",
      ranges: [
        { id: 7, name: "ligthColor", type: "color", ran: 0.5 },
        { id: 8, name: "semiligthColor", type: "color", ran: 0.2 },
        { id: 9, name: "primaryColor", type: "color", ran: 0 },
        { id: 10, name: "semidarkColor", type: "color", ran: -0.2 },
        { id: 11, name: "darkColor", type: "color", ran: -0.5 },
      ],
    },
  ]);

  let colorAttribute = [
    "ligthColor",
    "semiligthColor",
    "primaryColor",
    "semidarkColor",
    "darkColor",
  ];
  useEffect(() => {
    let controlsPage = {};
    let controlsPageValue = {};
    if (stateLanguage.lenguaje != 0) {
      stateLanguage.lenguaje.map((pass) => {
        if (pass.path == 'themes' || 
          pass.path == 'controls') {
          pass.webcontrolspages.map((data) => {
            controlsPage[data.idHtml] = data.languagewebcontrols[0];
          controlsPageValue[data.idHtml] = data;
        })
          setControlsPage(controlsPage)
          setControlsPageValue(controlsPageValue)
        }
      })
      setColumnTitles(current => changeLang(current, controlsPage))
      setCampForm(current => changeLangForm(current, controlsPage))
    }
  }, [stateLanguage])

  const defaultRenderItemColumn = (item, index, column) => {
    campForm[0] = Object.assign(campForm[0], {max: controlsPageValue?.txtName?.maxlength})
  
    const fieldContent = item[column.fieldName];
    switch (column.idHtml) {
      case 'coluPreview':
        return (
          <div >
            <p>{item["primaryFont"]}</p>
            <div className="w10 container--flex " style={{
              listStyle: 'none'
            }}>
              <li className="wr1 h1 "
                style={{
                  backgroundColor: `#${item["darkColor"]}`,
                  decoration: 'none'
                }}>
              </li>
              <li
                className="wr1 h1 mg2-w"
                style={{
                  backgroundColor: `#${item["semidarkColor"]}`,
                }}
              ></li>
              <li
                className="wr1 h1 mg2-w"
                style={{
                  backgroundColor: `#${item["primaryColor"]}`,
                }}
              ></li>
              <li
                className="wr1 h1 mg2-w"
                style={{
                  backgroundColor: `#${item["semiligthColor"]}`,
                }}
              ></li>
              <li
                className="wr1 h1 "
                style={{
                  backgroundColor: `#${item["ligthColor"]}`,
                }}
              ></li>
            </div>
          </div>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  }
  /************************
   * @methods
   ************************/
  const methodsList = {
    getDataItem: (object) => {
      const stateList = new Lists(itemsTheme, campForm);
      setIdThemeSelect(object);

      const stateCurrent = stateList.updateCurrent(object);
      setCampForm(stateCurrent.object);
      setDeleteD(stateCurrent.delete);
      setEdit(stateCurrent.edit);
    },
    voidCamps: () => {
      const stateList = new Lists(itemsTheme, campForm);
      setCampForm(stateList.clearCamps());
    },
  };
  const style = document.documentElement.style
  const colrs = () => {
    /*Funcion que se encarga de actualizar el tema seleccionado en el servicio Access/client/{tenant}*/
    const dataImg = [
      {
        op: "replace",
        path: 'isSelected',
        value: true
      },
    ];
    Requests.confirmRequest({
      body: dataImg,
      id: idThemeSelect[0].id,
      ct: "json",
      tenant: clientName,
      option: "patchItem",
        op: "b",
        infoModal:{
        title:controlsPage?.lblSuccessfulProcess?.value,
        description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
      }
    })
    idThemeSelect[0].isSelected = true
    // style.setProperty('--color-primary', `#${idThemeSelect[0].primaryColor}`)
    // style.setProperty('--color-light', `#${idThemeSelect[0].ligthColor}`)
    // style.setProperty('--color-semilight', `#${idThemeSelect[0].semiligthColor}`)
    // style.setProperty('--color-semidark', `#${idThemeSelect[0].semidarkColor}`)
    // style.setProperty('--color-dark', `#${idThemeSelect[0].darkColor}`)
    // style.setProperty('--color-hover', `#${idThemeSelect[0].darkColor}`)  
    // style.setProperty('--font-primary', `${idThemeSelect[0].primaryFont}`.)
  }
  const methosRequests = {
    crateItem: (dataForm) => {
      for (let i = 0; i < colorAttribute.length; i++) {
        dataForm[colorAttribute[i]] = dataForm[colorAttribute[i]].replace(
          "#",
          ""
        );
      }
      Requests.createItem({ body: dataForm, ct: "json" , tenant:clientName,  op: 'b',
          infoModal:{
          title:controlsPage?.lblSuccessfulProcess?.value,
          description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          }
      })

    },
    updateItem: (dataForm, id) => {
      for (let p = 0; p < colorAttribute.length; p++) {
        dataForm[colorAttribute[p]] = dataForm[colorAttribute[p]].replace(
          "#",
          ""
        );
      }
      // dataForm = Object.assign(dataForm, { isSelected: true })
      Requests.confirmRequest({
        body: dataForm,
        id: id,
        ct: "json",
        tenant: clientName,
        option: 'updateItem',
        op: 'b',
        infoModal:{
          title:controlsPage?.lblSuccessfulProcess?.value,
          description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
        }
      })

    },
    patchItem: (response) => {
      if (response != null && response != "") {
        //MethodsRedering.openModal(response);
      }
      ;
    },

    deleteItem: () => {
        idThemeSelect.forEach((element) => Requests.confirmRequest({id:element.id, tenant:clientName, option:'deleteItem', op:'b',
        infoModal:{
          title:controlsPage?.lblDisabled?.value, 
          description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
        }}));
    },
  };
  /************************
   * @hooks
   ************************/
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    if(Object.entries(controlsPage).length>0) {
        setFiltersList(changeLangForm([
            {
                id:1,
                key:'searchValue',
                label:'',
                type:'text',
            }
        ],controlsPage));
    }
  },[controlsPage])

  useEffect(async () => {
    const promise = await Requests.getItem({
      method: "get",
      category: "Items",
      param: { idClient: idClient, pageSize: showElements, pageNumber: currentPages },
      tenant: clientName
    })
    handleLoadingData()
    let idNumber = 0;
    let aux = promise.data
    const {
      currentPage,
      pageZise,
      totalCount,
      totalPages
    } = promise.data.meta;
    if (currentPage > 1) {
      idNumber = (currentPage - 1) * pageZise
    }
    let themes = promise.data.data.map(
      theme => {
        idNumber++;
        return (
          {
            ...theme,
            idNumber
          }
        )
      }
    )
    aux.data = themes
    setItemsTheme(aux);
    setPagination({
      currentPage: currentPage,
      pageSize: pageZise,
      totalItems: totalCount,
      totalPages: totalPages
    })

    handleLoadedData();

  }, []);
  const [filter, setFilter] = useState({
    pageSize: 5,
    pageNumber: "0",
    idDomain: ""
  });
  useEffect(() => {
    if ((filter.pageNumber > 0) || (filter.pageSize) || (filter.search)) {
      const pro = Requests.getItem({ param: filter, tenant: clientName })
      pro.then((data) => {
        handleLoadingData()
        let idNumber = 0;
        let aux = data.data
        const {
          currentPage,
          pageZise,
          totalCount,
          totalPages
        } = data.data.meta;
        if (currentPage > 1) {
          idNumber = (currentPage - 1) * pageZise
        }
        let themes = data.data.data.map(
          theme => {
            idNumber++;
            return (
              {
                ...theme,
                idNumber
              }
            )
          }
        )
        aux.data = themes
        setItemsTheme(aux);

        // setPromise(pro);
        setPagination({
          currentPage: currentPage,
          pageSize: pageZise,
          totalItems: totalCount,
          totalPages: totalPages
        });
        handleLoadedData()

      })

    } 
  }, [filter,controlsPage])

  /************************
   * @resturns
   ************************/

  // const numberPage = CountPages(itemsTheme.meta.totalPages);
  return (
    <>
      {itemsTheme != null && Object.entries(controlsPage).length > 0 ?
        <>
          <SubMenu
            contentcontrols={
                edit ? 
                  [
                    {
                      key: 'Edit',
                      cacheKey: 'cacheEdit',
                      text:controlsPage?.lblEditTheme?.value,
                      iconProps:{iconName: 'Edit'},
                      onClick:() => openPanel()
                    },
                    {
                      key: 'Brush',
                      cacheKey: 'cacheBrush',
                      text:controlsPage?.OnClickApplyTheme?.value,
                      iconProps:{iconName: 'Brush'},
                      onClick:() => colrs()
                    },
                    {
                      key: 'delete',
                      cacheKey: 'cachedelete',
                      text:controlsPage?.OnClickDisableTheme?.value,
                      iconProps:{iconName: 'delete'},
                      onClick:() => methosRequests.deleteItem()
                    }
                  ]
                 : (
                  [
                    {
                      key: 'Add',
                      cacheKey: 'cacheAdd',
                      text:controlsPage?.OnClickNewTheme?.value,
                      iconProps:{iconName: 'Edit'},
                      onClick:() => {
                              methodsList.voidCamps();
                              openPanel();
                            }
                    }
                  ]
                )
                // {!deleteD && !edit ? (
                //   <DefaultButton
                //     text={controlsPage?.OnClickNewTheme?.value}
                //     iconProps={{ iconName: "Add" }}
                //     onClick={() => {
                //       methodsList.voidCamps();
                //       openPanel();
                //     }}
                //   />
                // ) : (
                //   ""
                // )}
                // {edit ? (
                //   <DefaultButton
                //     text={controlsPage?.lblEditTheme?.value}
                //     iconProps={{ iconName: "Edit" }}
                //     onClick={openPanel}
                //   />
                // ) : (
                //   ""
                // )}
                // {edit ? (
                //   <DefaultButton
                //     text={controlsPage?.OnClickApplyTheme?.value}
                //     onClick={colrs}
                //   />
                // ) : (
                //   ""
                // )}
                // {deleteD ? (
                //   <DefaultButton
                //     text={controlsPage?.OnClickDisableTheme?.value}
                //     iconProps={{ iconName: "delete" }}
                //     onClick={methosRequests.deleteItem}
                //   />
                // ) : (
                //   ""
                // )}
            }
            contentcontrolsTwo={<>
                <Panel
                  isOpen={isOpen}
                  closeButtonAriaLabel="Close"
                  isHiddenOnDismiss={true}
                  type={PanelType.large}
                  hasCloseButton={false}
                >
                  {isOpen ? (
                    <ContainerLargeForm
                      dataForm={(e) => {
                        edit
                          ? methosRequests.updateItem(e, idThemeSelect[0].id)
                          : methosRequests.crateItem(e);
                      }}
                      keyForm="themes"
                      formTitle={edit ? controlsPage?.lblEditTheme?.value : controlsPage?.OnClickNewTheme?.value}
                      valueFont={valueFont}
                      idTheme={idThemeSelect[0]}
                      changeFontValue={changeFontValue}
                      Items={campForm}
                      response={(response) => {
                        methosRequests.patchItem(response);
                      }}
                      controlsPage={controlsPage}
                      closePanel={dismissPanel}
                    />
                  ) : (
                    ""
                  )}
                </Panel>
              </>
            }
          />
          <div className="admin--body-container">
            <ListHeader
              showReport={false}
              filters={filtersList}
              subRute={controlsPage?.lblUrlThemeAdministration?.value}
              subRute3={controlsPage?.lblThemeAdministration?.value}
              title={controlsPage?.lblThemeAdministration?.value}
              hideSearch={false}
              onChange={(e) => {

                setFilter({
                  ...filter,
                  pageSize: e.target.value,
                  pageNumber: filter.pageNumber === "0" ? "0" : "1"
                });
              }}
              InputChange={(e) => {
                setFilter({
                  ...filter,
                  search: e,
                  pageNumber: filter.pageNumber === "0" ? "0" : "1"
                })
              }}
              dataForm={({searchValue}) => {
                if(searchValue != undefined){
                setFilter({
                    ...filter,
                    pageNumber: "1",
                    search: searchValue
                });
            }else{
              setFilter({
                pageSize: 5,
                pageNumber: "0",
                idDomain: ""
              })
            }
            }}
            />
            <DetailsListBext
              enableShimmer={isLoadingData}
              listKey="listSelect"
              selectionMode={1}
              items={itemsTheme.data}
              renderItemColumn={defaultRenderItemColumn}
              columnTitles={columnTitles}
              onSelect={methodsList.getDataItem}
              onPageChange={page => {
                if (page !== +filter.pageNumber) {
                  setFilter({
                    ...filter,
                    pageNumber: String(page),
                  });
                }
              }}
              pagination={pagination}
            />
          </div>
        </>
        :
        <ContainerBaseLoading />}
    </>
  );
};

export default Themes;