import React, { useContext, useEffect, useState } from 'react'
import CookiesJS from 'js-cookie';
import {Panel, PanelType, } from '@fluentui/react/lib/Panel'
import { DefaultButton } from '@fluentui/react/lib/Button'
import { useBoolean } from '@fluentui/react-hooks'
import { useParams } from 'react-router-dom';

//*****Componnents*****//
import SubMenu from '../../components/navigation/subMenu/subMenu.component'
import ContainerLargeForm from '../../components/containers/largeForm/containerLargeForm.component'
import ContainerBaseLoading from '../../components/containers/baseLoading/containerBaseLoading.component'
import ListHeader from '../../components/lists/listHeader/listHeader.component'
import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';
import { changeLang, changeLangForm } from "../../utils/logicRendering/changeIdiom";

 
//*****Methods*****//
import Lists from '../../utils/logicRendering/lists.controller'
import {RequestFetch} from '../../utils/Requests/requests.Controller'
import LenguajeContext from '../../context/Lenguaje/lenguajeContext';


const TyC = () =>{
    /************************
     * @arguments 
     ************************/
    //***Propertise***//
    //let ArrayLanguage = []
    const [arrayLanguage, setArrayLanguage] = useState([])
    const [selectLng, setSelectLng] = useState([]);
    const [isActTogg, setIsActTogg] = useState(false)

    const [sendNotification, setSendNotification] = useState({});
    const [lngKey, setLngKey] = useState({})
    //***Data Propertise***//
    
    const actionOnChange = (data) => {
        if (data.id === "sendNotification") {
            setSendNotification(data.selectedItem);
        }
        if (data.id === "idLanguage") {
            if (data.selectedItem instanceof Object) {
                setLngKey(data.selectedItem);
            }
            
        }
    };
    const [isDisable, setDisable] = useState([

        { disable: false, key: "valueDefault" },
    
        { disable: true, key: "name" },
        ]);
    
    const [conSelected, setConSelected] = useState();
    const { clientName } = useParams();
    const [currentPages, setCurrentPage]= useState(1);
    const [dataTyC, setDataTyC] = useState([]);
    const [edit, setEdit] = useState(false);
    const [idTyCSelect, setIdTyCSelect] = useState([]);
    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [filter, setFilter] = useState({
        pageSize: 5,
        pageNumber: "0"
    });
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
    const [defaultValueInput,setDefaultValueInput]= useState([]);
    const { idClient } = JSON.parse(CookiesJS.get('dataClient'));
    const language = CookiesJS.get('languages');
    const [controlsPage, setControlsPage] = useState({});
    const [textOnPages, setTextOnPages] = useState({})
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
    const lng = JSON.parse(language);
    const Requests = new RequestFetch('TermCondition');
    const RequestsLanguages = new RequestFetch("Languages")
    const [pagination, setPagination] = useState(null);
    const [showElements, setShowElements]= useState(5);
    // const COLUMN_TITLES = [{
    //     name: {
    //         name:"Titulo",
    //         maxWidth: 300,
    //         isMultiline: true,
    //         idHtml: "coluTitle"
    //     },
    //     nameLanguage: {
    //         name:"Lenguaje",
    //         minWidth: 300,
    //         isMultiline: true,
    //         idHtml: "coluLanguage"
    //     },
    //     creatingDate:{
    //         name:"Fecha de creación",
    //         minWidth: 300,
    //         isMultiline: true,
    //         idHtml: "coluCreationDate"
    //     },
    //     creatorUser:{
    //         name: "Creado por",
    //         minWidth: 300,
    //         isMultiline: true,
    //         idHtml: "coluCreatedBy"
    //     }
    // }];
    const [columnTitles, setColumnTitles] = useState([
        {
            name: {
                name:"Titulo",
                minWidth: 100,
                maxWidth: 200,
                isMultiline: true,
                idHtml: "coluTitle"
            },
            nameLanguage: {
                name:"Lenguaje",
                minWidth: 50,
                isResizable: true,
                maxWidth: 150,
                isMultiline: true,
                idHtml: "coluLanguage"
            },
            creatingDate: {
                name: "Fecha de creación",
                minWidth: 100,
                isResizable: true,
                maxWidth: 200,
                idHtml: "coluCreationDate",
            },
            creatorUser: {
              name: "Creado por",
              maxWidth: 150,
              isResizable: true,
              idHtml: "coluCreatedBy",
            },
            updatingDate: {
                name: "Última Modificación",
                minWidth: 100,
                isResizable: true,
                maxWidth: 200,
                idHtml: "coluLastModification",
            },
            updateUser: {
              name: "Modificado por",
              maxWidth: 150,
              isResizable: true,
              idHtml: "lblModifiedBy"
            },
        }
    ])
    const [campForm, setCampForm]= useState([
        {
            id:0, 
            key:'name', 
            label:'Titulo', 
            type:'text', 
            placeholder:'...', 
            required: 1,
            max:500,
            min:10,
            defaultValue:'',
            disabled: isDisable,
            typeInput: "text",
            onRenderLabel: null,
            isRequired: true,
            className: 'w10',
            title:"",
            idHtml: "txtTitle"
        },
        {
            id:1, 
            key:'idLanguage', 
            label: 'Lenguaje', 
            type:'select2', 
            required: 1,
            options: '' ,
            defaultValue:'',
            disabled: isDisable,
            onRenderLabel: null,
            isRequired: true,
            title:"",
            className:"w10",
            idHtml: "lblLanguageTerms"
        },
        {
            id:2, 
            key:'url', 
            label:'Enlace', 
            type:'text', 
            placeholder:'...', 
            required: 1,
            max:500,
            min:10,
            defaultValue:'',
            disabled: isDisable,
            title:"",
            idHtml: "txtLink"
        },
        {
            id: 3,
            key: "sendNotification",
            label: "¿Enviar notificacion de aceptación de términos y condiciones a todos los usuarios ?",
            type: "toggle",
            disabled: isDisable,
            defaultChecked: false,
            defaultValue:isActTogg ,
            onText: "Si",
            offText: "No",
            styleStack: {
                width: "14rem",
                position: "center",
            },
            title:"",
            idHtml: "lblSendNotiTermsConditsAllUsrs"
            
        }
    ])
    // useEffect(() => {  
        // campForm[1] = {
        //     id:1, 
        //     key:'idLanguage', 
        //     label: 'Lenguaje', 
        //     type:'select', 
        //     required: 1,
        //     options: selectLng,
        //     defaultValue:'',
        //     disabled: isDisable,
        //     onRenderLabel: null,
        //     isRequired: true,
        // }
    // },[selectLng])
   
    campForm[0] = Object.assign(campForm[0], {defaultValue: conSelected ? conSelected.name : ''})
    campForm[1] = Object.assign(campForm[1], {defaultValue: conSelected ? conSelected.idLanguage : '', options: arrayLanguage})
    campForm[2] = Object.assign(campForm[2], {defaultValue: conSelected ? conSelected.url : ''})
    campForm[3] = Object.assign(campForm[3], {defaultValue: isActTogg, onText: controlsPage?.lblYes?.value, offText: controlsPage?.lblNo?.value})

    const [campFilters, setCampFilters] = useState([])

    // const campFilters = [
    //     {
    //         id:0, 
    //         key:'idLanguage', 
    //         label:'Lenguaje', 
    //         type:'select', 
    //         options: arrayLanguage
    //     }
    // ]

    useEffect(() => {
        setCampFilters([
            {
                id:0, 
                key:'idLanguage', 
                label:'Lenguaje', 
                type:'select', 
                options: arrayLanguage
            }
        ])
    },[arrayLanguage])

    useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            //console.log(stateLanguage);
            stateLanguage.lenguaje.map((pass) => {
              // console.log(pass)
                if (pass.path == 'controls'|| pass.path =='domain' || pass.path == 'terms-conditions') {
                  pass.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  })    
                  setControlsPage(controlsPage)
                }              
            })   
            setColumnTitles(current => changeLang(current, controlsPage))
            setCampForm(current => changeLangForm(current, controlsPage))          
        }
      }, [textOnPages, stateLanguage])


    /************************
    * @methods 
    ************************/
    const methodsList = {
        getDataItem:(object)=>{
            const stateList = new Lists(dataTyC, campForm)
            setIdTyCSelect(stateList.getDataItem(object, idTyCSelect))
            const stateCurrent = stateList.updateCurrent(idTyCSelect)
            setCampForm(stateCurrent.object)
            setEdit(stateCurrent.edit)
            setDefaultSelectedKeys()
        },
        voidCamps:()=>{
            setDefaultSelectedKeys([])
            const stateList = new Lists(dataTyC, campForm)
            setCampForm(stateList.clearCamps())
        }
    }
    
    const methosRequests = {
        chageGetItems:async(filters)=>{
            const promise = await Requests.getItem({param: filters, tenant: clientName})             
            setDataTyC(
                promise.data.data.map(item =>{
                    arrayLanguage.map(leng =>{
                        if (item.idLanguage == leng.id) {
                            item = Object.assign(item, {nameLanguage: leng.text} )
                        }
                    }
                )  
                return(
                    {
                        ...item
                    })    
                })  
            )
        }, 
        crateItem:(dataForm)=>{
            dataForm = Object.assign(dataForm,{
                idClient: idClient,
                sendNotification: sendNotification,
                idLanguage:lngKey.id
            })
            Requests.createItem({body: dataForm, ct:'json', tenant: clientName, op:'b', 
                infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                }
            })
        },
        updateItem:(dataForm, id)=>{
            dataForm = Object.assign(dataForm,{
                idClient: idClient,
                sendNotification: sendNotification,
                idLanguage:lngKey.id
            })
            Requests.confirmRequest({body: dataForm, id:id, ct:'json', tenant: clientName, option:'updateItem', op:'b',
            infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
            }
            })
        },
        deleteItem:()=>{
            Requests.confirmRequest({id:conSelected.id, tenant: clientName, option:'deleteItem', op:'b',
            infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
            }
            })
        },
        getTyC: async (filters,tenant)=>{
            setPagination(null);
            handleLoadingData()
            const promise = await Requests.getItem(
                {
                    param: filters, tenant
                }
                
            )
            
            setDataTyC(
                promise.data.data.map(item =>{
                    arrayLanguage.map(leng =>{
                        if (item.idLanguage == leng.id) {
                            item = Object.assign(item, {nameLanguage: leng.text} )
                        }
                    }
                )  
                return(
                    {
                        ...item
                    })    
                })  
                )
            const {
                currentPage,
                pageZise,
                totalCount,
                totalPages
            } = promise.data.meta;
            setPagination({
                currentPage: currentPage,
                pageSize: pageZise,
                totalItems: totalCount,
                totalPages: totalPages
            });
            handleLoadedData()            
        }         
    }

    const _defaultRenderItemColumn = (item, index, column) => 
        {
            const fieldContent = item[column.fieldName];
            return <span>{fieldContent}</span>;           
        }

    const _selectUser = (items) => 
        {
            if (items.length > 0){ 
                let objLng = {id:items[0].idLanguage} 
                setLngKey(objLng) 
                setConSelected(items[0]); 
                setIsActTogg(items[0].sendNotification)         
            } else {
                setConSelected();

                setIsActTogg(false)
            }       
        }
    const onSubmitEventFormPanel = (data) => {
        if(conSelected !== undefined){
            let dataForm = {
                name: conSelected.name,
                idLanguage: conSelected.idLanguage,
                url: conSelected.url
            }
            return dataForm
        }else{
            let dataFormNew = {
                idLanguage: lngKey.id,
                sendNotification: sendNotification
            }
            return dataFormNew
        }
    };

    /************************
     * @hooks 
     ************************/

    useEffect(() => {    
        if (Object.entries(controlsPage).length > 0) {
            methosRequests.getTyC(filter, clientName);
        }   
    }, [filter, controlsPage])
        
    useEffect( async () => {
        handleLoadingData()
        const promise = await Requests.getItem(
            {
                param: {
                    pageSize: showElements,
                    pageNumber: currentPages
                }, 
                tenant: clientName
            }
        )
        const promiseLng = await RequestsLanguages.getItem(
            {
                tenant: clientName
            }
        )
        let lngs = []
        promiseLng.data.data.map(item=>{
            lngs.push({text: item.name, id: item.id, key: item.id})
            //ArrayLanguage.push({name: item.name, id: item.id})
            setArrayLanguage(lngs)
        })

        let itemstyC = promise.data.data
    
        setDataTyC(  
            itemstyC.map(item => {
                promiseLng.data.data.map(leng =>{
                    if (item.idLanguage == leng.id ) 
                    {
                        item = Object.assign(item, {nameLanguage: leng.name} )
                    }
                }
            )  
            return({
                    ...item
                })               
            }) 
        );
    const{
        currentPage,
        pageZise,
        totalCount,
        totalPages,

        } = promise.data.meta;

    setPagination({
        currentPage: currentPage,
        pageSize: pageZise,
        totalItems: totalCount,
        totalPages: totalPages,
    });

    // let arrayPush = []
    // arrayLanguage.map(e => {
    //     console.log(e)
    //     let lngArray = {value: e.id, text: e.name}
    //     arrayPush.push(lngArray)
    //     if(arrayPush.length == lng.length){
    //         setSelectLng(arrayPush);   
    //     }
    // })
    handleLoadedData()

    },[])
    
 
    /************************
     * @returns 
     ************************/
        if(dataTyC === null){
            
            return (<ContainerBaseLoading/>)
        }

        return(
            <>
                <SubMenu 
                    contentcontrols={
                        conSelected?
                        [
                            {
                                text:controlsPage?.lblEditTermsConditions?.value,
                                iconProps:{iconName: 'Edit'},
                                onClick: ()=>openPanel()
                            },
                            {
                                text:controlsPage?.btnDeleteTerms?.value,
                                iconProps:{iconName: 'delete'},
                                onClick:()=>methosRequests.deleteItem()
                            }
                        ]
                        :
                        [
                            {
                                text:controlsPage?.lblNewTermsConditions?.value,
                                iconProps:{iconName: 'Add'},
                                onClick: () => { methodsList.voidCamps(); openPanel(); }
                            }
                        ]

                    }
                    contentcontrolsTwo={
                        <>
                            <Panel isOpen={isOpen}
                                closeButtonAriaLabel="Close"
                                isHiddenOnDismiss={true}
                                type={PanelType.smallFixedFar}
                                hasCloseButton={false}
                            >   
                                {isOpen?<ContainerLargeForm
                                    dataForm={(e)=>{
                                        conSelected 
                                        ? methosRequests.updateItem(e, conSelected.id)
                                        : methosRequests.crateItem(e)
                                    }}
                                    formTitle={conSelected?'Editar término y condición':'Nuevo término y condición'}
                                    Items={campForm}
                                    actionOnChange={actionOnChange}
                                    isDisableOpt={isDisable}
                                    closePanel={dismissPanel}
                                    defaultSelectedKeys={defaultSelectedKeys}
                                    defaultValueInput={defaultValueInput}
                                    onSubmitEventFormPanel={onSubmitEventFormPanel}
                                    controlsPage={controlsPage}
                                />:''}
                            </Panel>
                        </>
                    }
                />
                <div className="admin--body-container">
                    <ListHeader 
                        showReport={false} 
                        filters={campFilters}
                        subRute={controlsPage?.lblUrlAdminitrationTermsConditions?.value}
                        subRute3 ={controlsPage?.lblAdminitrationTermsConditions?.value}
                        title={controlsPage?.lblAdminitrationTermsConditions?.value}
                        hideSearch={false}
                        dataForm={({idLanguage}) => {
                            setFilter({
                                ...filter,
                                pageNumber: "1",
                                idLanguage
                            });
                        }}
                        onChange={(event)=>{
                            setFilter({
                                ...filter,
                                pageSize: event.target.value,
                                pageNumber: filter.pageNumber === "0" ? "0" : "1"
                            });
                        
                        }}
                        InputChange={(e)=>{
                         
                            setFilter({
                                ...filter,
                                search: e
                            })
                        
                        }}

                    />
          
                    <DetailsListBext
                        enableShimmer={isLoadingData}
                        listKey="TyCBsrList"
                        selectionMode={1}
                        items={dataTyC}
                        renderItemColumn={_defaultRenderItemColumn}
                        columnTitles={columnTitles}
                        onSelect={_selectUser}
                        onPageChange={page => {
                            if(page !== +filter.pageNumber){
                                setFilter({
                                    ...filter,
                                    pageNumber: String(page),
                                });
                            }
                        }}   

                        pagination={pagination}
                    />
                </div>
            </>    
        );    
}

export default TyC