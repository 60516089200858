import React from "react";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {Pivot, PivotItem, Label} from "@fluentui/react";
import UserRole from "./userRole";
import DomainRole from "./domainRole";

const RoleAssignment = ({onCancel, domains, userOrRoleSelected, controlsPage}) => {

    const _handleOnCancel = () => {
        onCancel();
    }

    return <div className="container--flex c--flex-dc h100">
        <div className="list--subcontainer-header mb">
            <h1><b>{'Asignación de roles a usuarios ó dominios'}</b></h1>
        </div>

        {
            userOrRoleSelected ?
                userOrRoleSelected.isAssignedToUser ?
                    <Pivot className="w10 mg10-h c--flex-f1">
                        <PivotItem headerText={controlsPage?.lblUser.value} itemIcon="AccountManagement">
                            <UserRole domainForm={domains} controlsPage={controlsPage} userId={userOrRoleSelected.idUser}/>
                        </PivotItem>
                    </Pivot>
                    :
                    <Pivot className="w10 mg10-h c--flex-f1">
                        <PivotItem headerText={controlsPage?.lblDomain.value} itemIcon="Globe">
                            <DomainRole domainForm={domains} controlsPage={controlsPage} domainId={userOrRoleSelected.idDomain}/>
                        </PivotItem>
                    </Pivot>
                : <Pivot className="w10 mg10-h c--flex-f1">
                    <PivotItem headerText={controlsPage?.lblUser.value} itemIcon="AccountManagement">
                        <UserRole domainForm={domains} controlsPage={controlsPage}/>
                    </PivotItem>
                    <PivotItem headerText={controlsPage?.lblDomain.value} itemIcon="Globe">
                        <DomainRole domainForm={domains} controlsPage={controlsPage}/>
                    </PivotItem>
                </Pivot>
        }

        <div className="admin--container-footer">
            <DefaultButton className="button--second w3 mg10-w" onClick={_handleOnCancel}>{controlsPage?.smtCancel.value}</DefaultButton>
        </div>
    </div>;
}

export default RoleAssignment;
