import React, { useState } from 'react';
//import {useHistory} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/img/ico-escudo-hover.svg'
import { ReactComponent as ImgEscudo } from '../../../assets/img/ico-escudo-reposado.svg'
const IconButton =(props)=>{
    
    /************************
     * @arguments 
     ************************/
        const [statehover, setStateHover]=useState(false);
        const navUrl = () => {
            if (props.url) {
                const url2 = (props.url).split("/");
                navigate(url2[2]);
            }else{
                props.onClick()
            }
        }
    /************************
     * @methods 
     ************************/

    /************************
     * @hooks 
     ************************/
        const navigate = useNavigate();
    /************************
     * @resturns 
     ************************/

    return(
        <div className="container--icon-button">
            {/* <div className="icon--button-base" 
                onClick={()=>navUrl()}
                onMouseEnter={()=>setStateHover(true)}
                onMouseLeave={()=>setStateHover(false)}
            >
                {!statehover?<h1>hola</h1>:<h2>adios</h2>}
                {!statehover?<img src={props.iconurl} alt=""/>:<span>{props.th}</span>}
            </div> */}
            
            
            {!statehover?
           <center>
            <div className="icon--button-base2"
            onClick={()=>navUrl()}
            onMouseEnter={()=>setStateHover(true)}
            onMouseLeave={()=>setStateHover(false)}
            >
                {props.iconurl}
            </div>
            </center>
            :
            <center>
            <div className="icon--button-base2"
            onClick={()=>navUrl()}
            onMouseEnter={()=>setStateHover(true)}
            onMouseLeave={()=>setStateHover(false)}
            >
            
            <Logo
            
            className="icon--button-base2 absol"/>
            {props.iconrev}
                
            </div>
            </center>
            }
            <div className="color-span">
            <span>{props.th}</span>
            </div>
        </div>
    );
}

export default IconButton;