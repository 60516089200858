import React, { Fragment, useContext, useEffect, useState } from 'react'
import CookiesJS from 'js-cookie';
//import {DefaultButton, Panel, PanelType, } from 'office-ui-fabric-react'
import { Panel, PanelType, } from '@fluentui/react/lib/Panel'
import { DefaultButton } from '@fluentui/react/lib/Button'
import { useBoolean } from '@fluentui/react-hooks'
import { useParams } from 'react-router-dom';

//*****Componnents*****//
import SubMenu from '../../components/navigation/subMenu/subMenu.component'
import ContainerLargeForm from '../../components/containers/largeForm/containerLargeForm.component'
import ContainerBaseLoading from '../../components/containers/baseLoading/containerBaseLoading.component'
import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';
import ListHeader from '../../components/lists/listHeader/listHeader.component'
import ListBody from '../../components/lists/listBody/listBody.component'
import ContainerForm from './domain/panelAsociate';
import { changeLang, changeLangForm } from "../../utils/logicRendering/changeIdiom";

//*****Methods*****//
import Lists from '../../utils/logicRendering/lists.controller'
import { RequestFetch } from '../../utils/Requests/requests.Controller'
import CountPages from '../../utils/logicRendering/countPages.controller'
import LenguajeContext from '../../context/Lenguaje/lenguajeContext';


const Workday = () => {
    /************************
     * @arguments 
     ************************/
    //***Propertise***//
    const [isDisable, setDisable] = useState([
        { disable: false, key: "valueDefault" },
        { disable: true, key: "name" },
    ]);
    const [itemsComponentInformation, setItemsComponentInformation] = useState();
    const [edit, setEdit] = useState(false);
    const [deleteD, setDeleteD] = useState(false);
    const [idQuestionSelect, setIdQuestionSelect] = useState([]);
    const [currentPages, setCurrentPage] = useState(1);
    const [showElements, setShowElements] = useState(5);
    const [itemsWeekDay, setItemsWeekDay] = useState(null);
    const [itemsCalendar, setItemsCalendar] = useState(null);
    const [controlsPage, setControlsPage] = useState({});
    const [textOnPages, setTextOnPages] = useState({})
    const { getLenguaje, stateLanguage } = useContext(LenguajeContext);
    const [itemsCalendarBase, setItemsCalendarBase] = useState(null);
    const [valueStartTime1, setValueStartTime1] = useState("07:00");
    const [valueStartTime2, setValueStartTime2] = useState("07:00");
    const [valueStartTime3, setValueStartTime3] = useState("07:00");
    const [valueStartTime4, setValueStartTime4] = useState("07:00");
    const [valueStartTime5, setValueStartTime5] = useState("07:00");
    const [valueStartTime6, setValueStartTime6] = useState("07:00");
    const [valueStartTime7, setValueStartTime7] = useState("07:00");
    const [valueEndTime1, setValueEndTime1] = useState("18:00");
    const [valueEndTime2, setValueEndTime2] = useState("18:00");
    const [valueEndTime3, setValueEndTime3] = useState("18:00");
    const [valueEndTime4, setValueEndTime4] = useState("18:00");
    const [valueEndTime5, setValueEndTime5] = useState("18:00");
    const [valueEndTime6, setValueEndTime6] = useState("18:00");
    const [valueEndTime7, setValueEndTime7] = useState("18:00");
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
    const [defaultValueInput, seDefaultValueInput] = useState([]);
    const [nameCalendar, setNameCalendar] = useState("");
    const [arrCalendarWeekDay, setArrCalendarWeekDay] = useState([]);
    const [arrIdWorkDays, setArrIdWorkDays] = useState([]);
    const [daysList, setDaysList] = useState([]);
    const [daysNonWorkList, setDaysNonWorkList] = useState([]);
    //const [daysList, setDaysList] = useState([{"day":"lunes", start
    //***Data Propertise***//
    const Requests = new RequestFetch('Calendar');
    const RequestsWeekDay = new RequestFetch('WeekDay');
    const { clientName } = useParams();
    const { idClient } = JSON.parse(CookiesJS.get('dataClient'));
    const SpaceTitel = ['Nombre calendario', 'Días laborales', 'Aplica festivos', 'Horas de acceso']
    const OrdItems = ['name']
    const arrayCalendarBase = [];
    const arrayWeekDay = [];
    let strStartTime = '';
    let strEndTime = '';
    const [columnTitles, setColumnTitles] = useState([{
        idNumber: {
            name: "N°",
            idHtml: "coluNumber",
            minWidth: 10,
            isResizable: true,
            maxWidth: 50
        },
        name: {
            name: "Nombre calendario",
            minWidth: 50,
            isResizable: true,
            maxWidth: 200,
            idHtml: "coluCalendarName",
        },
        mailAddress: {//por definir
            name: "Días laborales",
            minWidth: 50,
            isResizable: true,
            maxWidth: 70,
            idHtml: "coluBusinessDays",
        },
        type1: {//por definir
            name: "Aplica festivos",
            minWidth: 50,
            isResizable: true,
            maxWidth: 70,
            idHtml: "coluHolidays"
        },
        type: {//por definir
            name: "Horas de acceso",
            minWidth: 50,
            isResizable: true,
            maxWidth: 70,
            idHtml: "coluAccessHours",
        },
        creatingDate: {
            name: "Fecha de creación",
            minWidth: 50,
            isResizable: true,
            maxWidth: 200,
            idHtml: "coluCreationDate",
        },
        updatingDate: {
            name: "Última Modificación",
            minWidth: 50,
            isResizable: true,
            maxWidth: 200,
            idHtml: "coluLastModification",
        },
        creatorUser: {
          name: "Creado por",
          maxWidth: 150,
          isResizable: true,
          idHtml: "coluCreatedBy",
        },
        updateUser: {
            isResizable: true,
            name: "Modificado por",
            maxWidth: 200,
            idHtml: "lblModifiedBy",
          },
    }])
    const [filter, setFilter] = useState({
        pageSize: 5,
        pageNumber: "0",
        idDomain: ""
    });
    const [pagination, setPagination] = useState(null);
    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);
    const dismissPanel2 = () => {
        dismissPanel()
        setNameCalendar("")
        setArrIdWorkDays([])
        setDaysList([])
        setDaysNonWorkList([])
    }

    useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje != 0) {
            stateLanguage.lenguaje.map((pass) => {
                if (pass.path == 'controls' ||
                    pass.path == 'domain' ||
                    pass.path == "administration" ||
                    pass.path == 'themes' ||
                    pass.path == "security-questions" ||
                    pass.path == 'access-hours' ||
                    pass.path == 'user-license'
                ) {
                    pass.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                    })
                    setControlsPage(controlsPage)
                }
            })
            setColumnTitles(current => changeLang(current, controlsPage))
            setCampForm(current => changeLangForm(current, controlsPage))
        }
    }, [stateLanguage])
    useEffect(() => {
        if (Object.entries(controlsPage).length > 0) {
            methosRequests.chageGetItems(filter)
        }
    }, [filter, stateLanguage])

    const RemoveDay = (day) => {
        setDaysList(daysList.filter(item => item.id != day.id))

    }
    const RemoveNoWorkDay = (day) => {
        setDaysNonWorkList(daysNonWorkList.filter(item => item.id != day.id))

    }
    const actionOnChange = (data) => {
        if (data.id === "name") {
            if (data.selectedItem !== undefined) {
                setNameCalendar(data.selectedItem)
            }
        }
        if (data.id === "day") {
            if (data.selectedItem !== undefined) {
                selectDay(data)
            }
        }
        if (data.id === "startTime1") {
            //         for (let element in data) {
            //     if(data[element] !== 'startTime' && data[element] !== false){
            //         strStartTime = strStartTime + data[element]
            //     }
            // }
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueStartTime1(data.selectedItem)
            }
        }
        if (data.id === "startTime2") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueStartTime2(data.selectedItem)
            }
        }
        if (data.id === "startTime3") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueStartTime3(data.selectedItem)
            }
        }
        if (data.id === "startTime4") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueStartTime4(data.selectedItem)
            }
        }
        if (data.id === "startTime5") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueStartTime5(data.selectedItem)
            }
        }
        if (data.id === "startTime6") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueStartTime6(data.selectedItem)
            }
        }
        if (data.id === "startTime7") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueStartTime7(data.selectedItem)
            }
        }
        if (data.id === "endTime1") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueEndTime1(data.selectedItem)
            }
        }
        if (data.id === "endTime2") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueEndTime2(data.selectedItem)
            }
        }
        if (data.id === "endTime3") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueEndTime3(data.selectedItem)
            }
        }
        if (data.id === "endTime4") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueEndTime4(data.selectedItem)
            }
        }
        if (data.id === "endTime5") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueEndTime5(data.selectedItem)
            }
        }
        if (data.id === "endTime6") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueEndTime6(data.selectedItem)
            }
        }
        if (data.id === "endTime7") {
            if (data.selectedItem != undefined && data.selectedItem !== "") {
                setValueEndTime7(data.selectedItem)
            }
        }
        if (data.id === "nonWorkingDay") {
            if (data.selectedItem !== undefined) {
                const date = data.selectedItem.toISOString().split("T")
                selectNonWorkDay(date[0])
            }
        }
    }
    const actionOnClick = (data) => {
        let arr = []
        let countIds = 0
        let ids = daysList.map((e) => { return e.id })
        let max = ids.length > 0 ? Math.max(...ids) : 0
        if (data.id == 1) {
            arr.push({ id: max+1, idWeekDay: data.id, day: data.day, "startTime": valueStartTime1, endTime: valueEndTime1 })
        } else if (data.id == 2) {
            arr.push({ id: max+1, idWeekDay: data.id, day: data.day, "startTime": valueStartTime2, endTime: valueEndTime2 })
        }
        else if (data.id == 3) {
            arr.push({ id: max+1, idWeekDay: data.id, day: data.day, "startTime": valueStartTime3, endTime: valueEndTime3 })
        }
        else if (data.id == 4) {
            arr.push({ id: max+1, idWeekDay: data.id, day: data.day, "startTime": valueStartTime4, endTime: valueEndTime4 })
        }
        else if (data.id == 5) {
            arr.push({ id: max+1, idWeekDay: data.id, day: data.day, "startTime": valueStartTime5, endTime: valueEndTime5 })
        }
        else if (data.id == 6) {
            arr.push({ id: max+1, idWeekDay: data.id, day: data.day, "startTime": valueStartTime6, endTime: valueEndTime6 })
        }
        else if (data.id == 7) {
            arr.push({ id: max+1, idWeekDay: data.id, day: data.day, "startTime": valueStartTime7, endTime: valueEndTime7 })
        }

        setDaysList([...daysList, ...arr])
        //setArrIdWorkDays([])
    }
    const selectDay = (data) => {
        if (data.selected && data.selectedItem != undefined) {
            let idSel = { idWeekDay: data.selectedItem.key, day: data.text }
            setArrIdWorkDays([...arrIdWorkDays, idSel])
        } else {
            setArrIdWorkDays(arrIdWorkDays.filter(item => item.idWeekDay != data.selectedItem.key))
        }
    }
    const selectNonWorkDay = (data) => {
        let ids = daysNonWorkList.map((e) => { return e.id })
        let max = ids.length > 0 ? Math.max(...ids) : 0
        if (data != undefined) {
            let idSel = { "nonWorkingDate": data, id: max + 1 }
            setDaysNonWorkList([...daysNonWorkList, idSel])
        } else {
        }
    }

    const onSubmitEventFormPanel = (data) => {
        let dataForm = {
            name: nameCalendar,
            calendar_WeekDay: daysList.map((day) => { return { idWeekDay: day.idWeekDay, startTime: day.startTime, endTime: day.endTime } }),
            nonWorkingDay: daysNonWorkList.map((day) => { return { nonWorkingDate: day.nonWorkingDate } }),
        }
        return dataForm;

    }
    useEffect(() => {
        calendarBase.then(data => {
            for (const [key, value] of Object.entries(data.data)) {
                if (key === "data") {
                    for (let index = 0; index < value.length; index++) {
                        const element = value[index];
                        arrayCalendarBase.push({
                            key: element["id"],
                            text: element["name"],
                        });
                    }
                }
            }
            setItemsCalendarBase(arrayCalendarBase);
        })
        promiseWeekDay.then(data => {
            for (const [key, value] of Object.entries(data.data)) {
                if (key === "data") {
                    for (let index = 0; index < value.length; index++) {
                        const element = value[index];
                        arrayWeekDay.push({
                            key: element["id"],
                            text: element["dayNameWeekDays"],
                        });
                    }
                }
            }
            setItemsWeekDay(arrayWeekDay)
        })
    }, [])
    const [campForm, setCampForm] = useState([
        {
            id: 0,
            key: "name",
            label: "Nombre del calendario",
            idHtml: "txtCalendarName",
            type: "text2",
            defaultValu: nameCalendar,
            disabled: false,
            multiline: false,
            resizable: false,
            typeInput: "text",
            description: "",
            required: true,
            onRenderLabel: null,
            isRequired: true,
            className: 'select2',
            sizeInput: 100,
            onGetErrorMessage: [{ messageValidateSize: "Nombre no valido" }]
        },
        {
            id: 1,
            key: "detailListkey",
            label: "Selecione los días",
            idHtml: "lblSelectDayAndTime",
            type: "detailList",
            disabled: false,
            isRequired: true,
        },
        {
            id: 2,
            key: 'nonWorkingDay',
            label: 'Festivos',
            idHtml: "lblSelectHolidays",
            type: 'date2',
            required: true,
            multiSelect: 'true',
            defaultValue: '',
            className: 'select2',
        },
    ])
    campForm[0] = Object.assign(campForm[0], { defaultValu: nameCalendar })


    // useEffect(() => {
    //     let controlsPage = {};
    //     if (stateLanguage.lenguaje !=0) {
    //         stateLanguage.lenguaje.map((pass) => {
    //             if (pass.path == 'controls'|| pass.path =='domain') {
    //               pass.webcontrolspages.map((data) => {
    //                 controlsPage[data.idHtml] = data.languagewebcontrols[0];
    //               })    
    //               setControlsPage(controlsPage)
    //             }              
    //         })          
    //     }
    //   }, [textOnPages, stateLanguage])


    /************************
    * @methods 
    ************************/
    const methodsList = {
        getDataItem: (object) => {
            const stateList = new Lists(itemsCalendar, campForm)
            //setIdQuestionSelect(stateList.getDataItem(object, idQuestionSelect))
            setIdQuestionSelect(object)
            const stateCurrent = stateList.updateCurrent(object)
            setCampForm(stateCurrent.object)
            setDeleteD(stateCurrent.delete)
            setEdit(stateCurrent.edit)
            setNameCalendar(object[0] ? object[0].name : 0)
            // setDaysList([{  idWeekDay: "2", startTime: "07:00", endTime: "18:00", day: "Martes" }, { idWeekDay: "1", startTime: "07:00", endTime: "18:00", day: "Lunes"}])
            // setDaysNonWorkList([{ nonWorkingDate: "2023-10-03"},{ nonWorkingDate: "2023-10-04"}])


        },
        voidCamps: () => {
            const stateList = new Lists(itemsCalendar, campForm)
            setCampForm(stateList.clearCamps())
        }
    }

    const methosRequests = {
        chageGetItems: async (filter) => {
            handleLoadingData()
            //setPromise(Requests.getItem({ param: filter, tenant: clientName }));
            let idNumber = 0;
            const response = await Requests.getItem({ param: filter, tenant: clientName })
            const {
                currentPage,
                pageZise,
                totalCount,
                totalPages
            } = response.data.meta;
            setPagination({
                currentPage: currentPage,
                pageSize: pageZise,
                totalItems: totalCount,
                totalPages: totalPages
            })
            if (currentPage > 1) {
                idNumber = (currentPage - 1) * pageZise
            }
            let itemsCalendarAux = response.data.data.map(
                item => {
                    idNumber++;
                    return (
                        {
                            ...item,
                            idNumber
                        }
                    )
                }
            )
            setItemsCalendar(itemsCalendarAux);
            handleLoadedData();

        },
        crateItem: (dataForm) => {
            dataForm = Object.assign(dataForm, { idClient: idClient })
            Requests.createItem({
                body: onSubmitEventFormPanel(), ct: 'json', tenant: clientName,
                infoModal: {
                    title: controlsPage?.lblSuccessfulProcess?.value,
                    description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                },
                op: "b"
            })
            //window.location.reload()
        },
        updateItem: (dataForm, id) => {
            dataForm = Object.assign(dataForm, { idClient: idClient })
            // const dataFormStr = JSON.stringify(dataForm)
            // const data2 = dataFormStr.substring(0, dataFormStr.length - 1) + ',"idClient":"1"}';
            // const data3 = JSON.parse(data2)
            Requests.confirmRequest({
                body: onSubmitEventFormPanel(), id: id, ct: 'json', tenant: clientName, option: 'updateItem',
                infoModal: {
                    title: controlsPage?.lblSuccessfulProcess?.value,
                    description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                },
                op: "b"
            });
        },
        deleteItem: () => {
            idQuestionSelect.forEach(element => Requests.confirmRequest({
                id: element.id, ct: element.ct, tenant: clientName, option: 'deleteItem',
                infoModal: {
                    title: controlsPage?.lblDisabled?.value,
                    description: controlsPage?.lblItHbeenSuccessfullyDisabled?.value
                },
                op: "b"
            }));
        }
    }

    /************************
     * @hooks 
     ************************/
    const [promiseWeekDay, setPromiseWeekDay] = useState(RequestsWeekDay.getItem({
        tenant: clientName
    }))
    // const [promise, setPromise] = useState(Requests.getItem({
    //     param: { pageSize: showElements, pageNumber: currentPages }, tenant: clientName
    // }))

    const [calendarBase, setCalendarBase] = useState(Requests.getItem({
        tenant: clientName
    }))

    // useEffect(async () => {
    //     const promise = await Requests.getItem({
    //         param: { pageSize: showElements, pageNumber: currentPages }, tenant: clientName
    //     })
    //     handleLoadingData()
    //     let idNumber = 0;
    //     const {
    //         currentPage,
    //         pageZise,
    //         totalCount,
    //         totalPages
    //     } = promise.data.meta;
    //     if (currentPage > 1) {
    //         idNumber = (currentPage - 1) * pageZise
    //     }
    //     let itemsCalendarAux = promise.data.data.map(
    //         item => {
    //             idNumber++;
    //             return (
    //                 {
    //                     ...item,
    //                     idNumber
    //                 }
    //             )
    //         }
    //     )
    //     setItemsCalendar(itemsCalendarAux);
    //     setPagination({
    //         currentPage: currentPage,
    //         pageSize: pageZise,
    //         totalItems: totalCount,
    //         totalPages: totalPages
    //     })
    //     handleLoadedData();
    // }, [])




    /************************
     * @resturns 
     ************************/
    if (itemsCalendar === null) {
        return (<ContainerBaseLoading />)
    }
    return (
        <>
            <SubMenu
                contentcontrols={
                    !deleteD && !edit ? [
                        {
                            text:controlsPage?.OnClickAddCalendar?.value,
                            iconProps:{ iconName: 'Add' },
                            onClick:() => { methodsList.voidCamps(); openPanel(); }
                        }
                    ]
                    :
                    [
                        edit?{
                            text:controlsPage?.OnclickEditCalendar?.value,
                            iconProps:{ iconName: 'Edit' },
                            onClick:()=>openPanel()
                        }:null,
                        {
                            text:controlsPage?.OnClickDeleteCalendar?.value,
                            iconProps:{ iconName: 'delete' },
                            onClick:()=>methosRequests.deleteItem()
                        }
                    ].filter(obj => obj !== null)
                }
                contentcontrolsTwo={
                    <Panel isOpen={isOpen}
                        closeButtonAriaLabel="Close"
                        isHiddenOnDismiss={true}
                        //type={PanelType.medium}
                        hasCloseButton={false}
                        type={PanelType.custom}
                        customWidth={'700px'}
                    >
                        {isOpen ? <ContainerForm
                            dataForm={(e) => {
                                edit ? methosRequests.updateItem(e, idQuestionSelect[0].id) : methosRequests.crateItem(e)
                            }}
                            formTitle={edit ? controlsPage?.lblEditCalendar?.value : controlsPage?.lblAddCalendar?.value}
                            Items={campForm}
                            closePanel={dismissPanel2}
                            actionOnChange={actionOnChange}
                            actionOnClick={actionOnClick}
                            isDisableOpt={isDisable}
                            onSubmitEventFormPanel={onSubmitEventFormPanel}
                            itemsComponentInformation={itemsComponentInformation}
                            defaultSelectedKeys={defaultSelectedKeys}
                            defaultValueInput={defaultValueInput}
                            keyForm="workDay"
                            DaysList={daysList}
                            NonDaysList={daysList}
                            DaysNonWorkList={daysNonWorkList}
                            RemoveDay={RemoveDay}
                            RemoveNoWorkDay={RemoveNoWorkDay}
                            editCalendar={edit}
                        /> : ''}
                    </Panel>
                }
            />
            <div className="admin--body-container">
                <ListHeader
                    showReport={false}
                    subRute={controlsPage?.OnClickAdministration?.value}
                    subRute3={controlsPage?.lblManagementOfWorkingHours?.value}
                    //title="Administración de jornadas laborales"
                    title={controlsPage?.lblManagementOfWorkingHours?.value}
                    hideSearch={true}
                    // dataForm={(e) => methosRequests.chageGetItems({
                    //     pageSize: showElements, pageNumber: currentPages,
                    //     idLanguage: e.idLanguage
                    // })}
                    onChange={(e) => {
                        setFilter({
                            ...filter,
                            pageSize: e.target.value,
                            pageNumber: filter.pageNumber === "0" ? "0" : "1"
                        });
                    }}
                    InputChange={(e) => {
                        setFilter({
                            ...filter,
                            search: e,
                            pageNumber: filter.pageNumber === "0" ? "0" : "1"
                        })
                    }}
                />
                {/* <ListBody 
                            spaceTitle={SpaceTitel}
                            meta={itemsCalendar.meta}
                            Items={itemsCalendar.data} 
                            numberPages={numberPage}
                            initRangeText={currentPages}
                            orditem={OrdItems}
                            finishRangeText={showElements}
                            totalRangeText={itemsCalendar.data.length}
                            typelist='listSelect'
                            selectdata={methodsList.getDataItem}
                            paginate={(number)=>{
                                setCurrentPage(number); 
                                methosRequests.chageGetItems({pageSize:showElements, pageNumber:number});
                            }}
                        /> */}

                <DetailsListBext
                    enableShimmer={isLoadingData}
                    listKey="listSelect"
                    selectionMode={1}
                    items={itemsCalendar}
                    //renderItemColumn={_defaultRenderItemColumn}
                    columnTitles={columnTitles}
                    onSelect={methodsList.getDataItem}
                    onPageChange={page => {
                        if (page !== +filter.pageNumber) {
                            setFilter({
                                ...filter,
                                pageNumber: String(page),
                            });
                        }
                    }}
                    pagination={pagination}
                />
            </div>
        </>
    );
}

export default Workday