import React, { useState, useEffect } from "react";
import {
  TagPicker,
  ITag,
  IBasePickerSuggestionsProps,
} from "@fluentui/react/lib/Pickers";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { useId } from "@fluentui/react-hooks";
import { RequestFetch } from "../../../../utils/Requests/requests.Controller";
import { FontIcon, Label } from "@fluentui/react";

let objectData = [];
let pickerId;
const iconClass = mergeStyles({
  fontSize: 8,
  height: 8,
  width: 8,
  margin: '0',
  position: 'absolute',
  color: 'var(--color-primary)' 
});
function resquestEntity(resquestConfiguraion, nameArrays, dataInto) {
  let keyArray = 0,
    name = "",
    filters = "";
  resquestConfiguraion.map((item) => {
    //#region  Petición
    let Requests = new RequestFetch(item.entitie);
    filters = `{"${item.query}" : {"${item.attribute}" : "${dataInto}"}, "tenant" : "${item.tenant}"}`;
    let promise = Requests.getItem(JSON.parse(filters));
    //#endregion

    promise.then((data) => {
      //#region Petición con param
      if (item.query === "param") {
        objectData = [];
        let dataint = Object.entries(data.data.data);
        for (let index = 0; index < dataint.length; index++) {
          const elementDataArray = dataint[index];
          for (let index = 0; index < elementDataArray.length; index++) {
            const elementData = elementDataArray[index];

            if (typeof elementData == "string") {
              const found = nameArrays.find(
                (element) => element.name === elementData
              );
              const listKey = found.key;

              for (const [key, value] of Object.entries(elementDataArray[1])) {
                for (const key in value) {
                  if (Object.hasOwnProperty.call(value, key)) {
                    for (let index = 0; index < listKey.length; index++) {
                      const elementKey = listKey[index];
                      keyArray = value["id"];
                      if (!objectData.find((elemt) => elemt.key === keyArray)) {
                        const element = value[elementKey.keyname];
                        name = name + `${element} `;
                      }

                      let sum = listKey.length - index;
                      if (sum === 1) {
                        if (
                          !objectData.find((elemt) => elemt.key === keyArray) &&
                          name !== ""
                        ) {
                          pickerId = elementDataArray[0] + keyArray;
                          objectData.push({
                            name: name,
                            key: keyArray,
                            entity: elementDataArray[0],
                          });
                          name = "";
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        promise = null;
      }
      //#endregion
      //#region Petición con filters
      if (item.query === "filters") {
        objectData = [];
        data.data.data.map((el, index) => {
          for (const [key, value] of Object.entries(el)) {
            if (key === "id") {
              keyArray = value;
            }
            if (key === item.attribute) {
              name = value;
            }
            if (
              !objectData.find((elemt) => elemt === value) &&
              keyArray > 0 &&
              name !== ""
            ) {
              objectData.push({ name: name, key: keyArray });
            }
          }
        });
      }
      //#endregion
    });
  });
  return objectData;
}

const LargeFormPickers = (props) => {
  let item = props.item;

  const listContainsTagList = (tag, tagList) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.filter((compareTag) => compareTag.key === tag.key);
  };

  const filterSuggestedTags = (filterText, tagList) => {
    if (filterText.length >= item.maxLengthRequest) {
      let arrayResponse = resquestEntity(
        item.queryEntities,
        item.nameArrays,
        filterText
      );

      return filterText
        ? arrayResponse.filter(
            (tag) =>
              tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 /*&& !listContainsTagList(tag, tagList),*/
          )
        : [];
    }
  };

  const getTextFromItem = (array) => {
    return array.name;
  };

  const rootClass = mergeStyles({
    maxWidth: 500,
  });

  let pickerSuggestionsProps = {
    suggestionsHeaderText: item.suggestionsHeaderText,
    noResultsFoundText: item.noResultsFoundText,
  };
  //#region  Buscar en un arreglo el id para habilitar y deshabilitar
  let found;
  if (props.isDisableOpt.length === 1) {
    found = props.isDisableOpt.find(
      (element) => element.key === "valueDefault"
    );
  } else {
    found = props.isDisableOpt.find((element) => element.key === item.key);
    if (found === undefined) {
      found = props.isDisableOpt.find(
        (element) => element.key === "valueDefault"
      );
    }
  }
  if (found === undefined) {
    found = props.isDisableOpt.find((element) => element.key === item.key);
  }
  //#endregion
    //#region Accion onChange
    const [selectedItem, setSelectedItem] = useState();
    const onChange = (item) => {
        setSelectedItem(item);
    };
    useEffect(() => {
      let objectFinal = {
        ...selectedItem,
        ...{ id: item.key,state: found.disable },
      };
      props.actionOnChange(objectFinal);
    }, [selectedItem,found]);
    //#endregion
  return (
    <div className={rootClass}>
        <Label htmlFor={item.key}>{item.label ? <div style={{position: "relative"}}> <span>{item.label}</span>{item.isRequired || item.required ? <FontIcon aria-label="requerido" iconName="SkypeCircleCheck" className={iconClass} /> : ''} </div> : ""}</Label>
      {/* <label htmlFor={item.key}>{item.label}</label> */}
      <TagPicker
        id={item.key}
        removeButtonAriaLabel="Remove"
        selectionAriaLabel={item.label}
        removeButtonIconProps={{ iconName: "Delete" }}
        {...(item.itemLimit > 0 ? { itemLimit: item.itemLimit } : "")}
        disabled={found.disable}
        onResolveSuggestions={filterSuggestedTags}
        getTextFromItem={getTextFromItem}
        defaultSelectedItems={[]}
        pickerSuggestionsProps={pickerSuggestionsProps}
        pickerCalloutProps={{ doNotLayer: true }}
        inputProps={{ id: item.key }}      
        onChange={onChange}
        required={item.isRequired}
      />
    </div>
  );
};
export default LargeFormPickers;
