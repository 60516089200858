import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useBoolean} from "@fluentui/react-hooks";

import ContainerLargeForm from "../../../components/containers/largeForm/containerLargeForm.component";
import {RequestFetch} from "../../../utils/Requests/requests.Controller";
import {CLIENT_FORM, OPTIONAL_FIELDS} from "./constants";
import {validateEmptyFields} from "../../../utils/validations/forms";

const PanelClient = (props) => {
  const {
    clientSelected,
    onClosePanel,
    optionsList
  } = props;

  const {clientName} = useParams();
  const [fieldsForm, setFieldsForm] = useState([]);
  const [formValues, setFormValues] = useState(getDefaultValues());
  const [isEdited, {
    setTrue: handleEdited
  }] = useBoolean(false);

  const RequestClient = new RequestFetch("Client");

  useEffect( () => {
    buildFields();
  }, []);

  function getDefaultValues() {
   return {
     idCaptcha: clientSelected?.idCaptcha || null,
     path: clientSelected?.path || null,
     clientLanguages: clientSelected?.clientLanguages?.[0]?.idLanguage || null,
     dynamicKeyLength: clientSelected?.dynamicKeyLength || null,
     dailyPasswordLimit: clientSelected?.dailyPasswordLimit || null,
     idDynamicKeyType: clientSelected?.idDynamicKeyType || null,
     supportEmail: clientSelected?.supportEmail || null,
     useVirtualKeyboard: clientSelected?.useVirtualKeyboard || null,
     useApp: clientSelected?.useApp || null,
     validateUpperLowerCase: clientSelected?.validateUpperLowerCase || null
   }
  }

  const buildFields = () => {
    const fieldOptions = {
      idCaptcha: optionsList.captcha,
      clientLanguages: optionsList.languages,
      idDynamicKeyType: optionsList.dynamicKeys
    }
    let defaultValues;
    if (clientSelected) {
      defaultValues = getDefaultValues();
      setFormValues(defaultValues);
    }
    const form = CLIENT_FORM.map(field => {
      let mappedField = field;
      if (fieldOptions[field.key]) {
        mappedField = {
          ...mappedField,
          options: fieldOptions[field.key]
        }
      }
      if (defaultValues?.[field.key] && clientSelected) {
        mappedField = {
          ...mappedField,
          defaultValu: defaultValues[field.key],
          defaultValue: defaultValues[field.key]
        }
      }
      return mappedField;
    })
    setFieldsForm(form);
  }

  const submitForm = async () => {
    if (validateEmptyFields(formValues, OPTIONAL_FIELDS)) {
      let payload = {
        ...formValues,
        supportEmail: formValues.supportEmail || "",
        clientLanguages: [{ idLanguage: formValues.clientLanguages}]
      }
      if(clientSelected){
        methodRequests.updateItem(payload, clientSelected.id, clientName);
        handleEdited();
      } else {
        await methodRequests.createItem(payload, clientName);
        onClosePanel(true);
      }
    }
  }


  const methodRequests = {
    createItem: async (payload, tenant) => {
      await RequestClient.createItem({body: payload, ct: "json", tenant});
    },
    updateItem: (payload, id) => {
       RequestClient.confirmRequest({
        body: payload,
        id: id,
        ct: "json",
        tenant: clientName,
        option: 'updateItem'
      })
    }
  };

  const actionOnChange = (data) => {
    const value = (function () {
      switch (data?.id) {
        case "idCaptcha":
          return data?.key;
        case "idDynamicKeyType":
        case "clientLanguages":
          return data?.selectedItem?.key;
        default:
          return data?.selectedItem;
      }
    })();
    if (formValues.hasOwnProperty(data.id) && formValues[data.id] !== value && value !== undefined) {
      setFormValues(currentValue => ({
        ...currentValue,
        [data.id]: value
      }));
    }
  };

  return (
    <>
      <ContainerLargeForm
        dataForm={_ => {
        }}
        formTitle={clientSelected ? "Configurar Cliente" : "Nuevo Cliente"}
        Items={fieldsForm}
        closePanel={() => onClosePanel(isEdited)}
        actionOnChange={actionOnChange}
        isDisableOpt={[
          {disable: false, key: "valueDefault"},
          {disable: true, key: "name"},
        ]}
        onSubmitEventFormPanel={submitForm}
        itemsComponentInformation={{}}
        defaultSelectedKeys={[]}
        defaultValueInput={[]}
      />
    </>
  );
};


export default PanelClient;
