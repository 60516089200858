import React, { useContext, useEffect, useState } from 'react';
import CookiesJS from 'js-cookie';
import { NavLink, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

//*****Componnents*****//
    import RouterPublic from '../../components/routers/public/publicRouter.component'
    import RouterPrivate from '../../components/routers/private/privateRouter.component'
    import ContainerBaseAdmin from '../../components/containers/baseAdmin/containerBaseAdmin.component'
    import ContainerBaseLoading from '../../components/containers/baseLoading/containerBaseLoading.component'


//*****Methods*****//
    import FetchAuth from '../../utils/validations/oauth.controller'


//*****Layouts*****//
    import Client from './client/client';
    import Inbox from './inbox'
    import Roles from './roles/roles'
    import Domain from './domain/domain'
    import Themes from './themes'
    import Queries from './queries'
    import Lenguaje from './lenguaje'
    import UpdateFile from './updateFile'
    import TyC from './TyC'
    import IpRestriction from './ipRestriction/ipRestriction'
    import FileTypes from './fileTypes'
    import Attributes from './attributes'
    import Workday from './Workday'
    import Files from './file'
    import Mfa from '../users/mfa';
    import NotificationChannel from './NotificationChannel'
    // import ManageMfa from '../users/manageMfa';
    import Messages from './messages'
    import Schemas from './schemas'
    import NotificationFlow from './notificationFlow'
    import Domainauthenticationfactors from './domainauthenticationfactors';
    import PasswordExpirationNotifications from './passwordExpirationNotifications';
    import SupportBsr from "./supportBsr/supportBsr";
    import UserLicences from "./userLicences/userLicences";
    import NotificationTemplate from './NotificationTemplate'
//*****Setting*****//
    import {BSR_URI, PATH_APPLICATION, PATH_SSO, SSO_URI} from '../../assets/settings/settinsrouters'
    import SecurityPolicy from './securityPolicy/SecurityPolicy'
    import Contentforlanguage from './contentforlanguage';
    import AssoQuestions from '../users/assoQuestions';
    import ChangePassword from '../users/changePassword';
    import UsersRoleAssignment from "./usersRoleAssignment/usersRoleAssignment";
    import FetchOAuth from '../../utils/validations/oauth.controller';
    import ModalFluent from '../../components/tools/modalFluent/ModalFluent';
    import { render } from '@testing-library/react';
import { validateRoute } from '../../helpers/validateRoute';
import AvisoError from '../../components/containers/containerError/avisoError';
import DataUser from '../users/dataUser';
import { consulta, getText, RequestFetch } from '../../utils/Requests/requests.Controller';
import LenguajeContext from '../../context/Lenguaje/lenguajeContext';



const Admin =  () => {
    /************************
     * @arguments 
     ************************/
        const userText = new RequestFetch('Pages/userTexts')
        const requestLang = new RequestFetch(`Languages`)
        const requestUserSecurityQuestion = new RequestFetch('UserSecurityQuestion');
        const {pathname} = useLocation()
        const navigate = useNavigate()
        const nameClient = CookiesJS.get('nameClient');
        const pages = CookiesJS.get('pages');
        const [listPages, setListPages] = useState([])
        const token = CookiesJS.get('token')
        const { clientName } = useParams();
        const [currentLocation, setCurrentLocation] = useState();
        const dataUserSession = JSON.parse(CookiesJS.get("dataUser"));
        const { idClient } = JSON.parse(CookiesJS.get("dataClient"));
        const [styleText, setStyleText] = useState('')
        const [idiom, setIdiom] = useState({})
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        const [controlsPage, setControlsPage] = useState({});
        const [dataError, setDataError] = useState({})
        const [required, setRequired] = useState(false)
        const [func, setFunc] = useState()
        const [funcAcept, setFuncAcept] = useState()
        const [textButton, setTextButton] = useState({})
        const [questionReq, setQuestionReq] = useState(Boolean)
        const [textOnPages, setTextOnPages] = useState({})
        let url = window.location.href
        const ubicationUrl = url.split("/")

        useEffect(() => {
            setStyleText('')
        }, [])
        
    /************************
      * @methods 
      ************************/
    const openDB = () => {
        return new Promise((resolve, reject) => {
          const request = indexedDB.open('textListApp',1);
          
          request.onsuccess = (event) => {
            const db = event.target.result;
            resolve(db);
          };
          request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains("textF")) {
              db.createObjectStore("textF", { keyPath: "clave" });
            }
          };
          request.onerror = (event) => {
            reject(event.target.error);
          };
        });
    };
  
    const deleteDB = () => {
        return new Promise((resolve, reject) => {
          const dbName = "textList";
          const requestSso = indexedDB.open(dbName, 1);
      
          requestSso.onsuccess = (event) => {
            const db = event.target.result;
            db.close();
      
            const deleteRequest = indexedDB.deleteDatabase(dbName);
      
            deleteRequest.onsuccess = event => {
              console.log(`Se borra.`);
              resolve();
            };
      
            deleteRequest.onerror = event => {
              console.error(`Error al eliminar`);
              reject(event.target.error);
            };
          };
      
          requestSso.onerror = (event) => {
            reject(event.target.error);
          };
        });
    };
    
     
    const submitText = async() =>{
        openDB().then((info) => {
            let controlsPage = {}
            let db = info
            const textos = consulta(db)
            textos.ssoPagesData.map((spd) =>{
                if(spd.path == 'controls' || spd.path == 'notifications-expiry-passwords') {
                    spd.webcontrolspages.map((data)=>{
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                    })
                }
            })
            setControlsPage(controlsPage)
            getLenguaje(textos.ssoPagesData)
            getText(textos.ssoPagesData)
        })
        .catch((error) => {
        console.error("Error al abrir la base de datos:", error);
        });
            
    }
     //prueba mensaje 
     const actionOnClick = (func) =>{
        if(func == 'a'){
          CookiesJS.remove('token')
          window.location.href = `${SSO_URI}/${PATH_SSO}/${nameClient}/home`
        }else if(func == 'b'){
          setRequired(true)
          setDataError({})
          navigate(`${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/${nameClient}/administration/AssosiateQuestions`)
        }
      }
     useEffect(async() => {
        dataUserSession.user.idLanguage = CookiesJS.get('idLng')?CookiesJS.get('idLng'):dataUserSession.user.idLanguage
            const newCookie = JSON.stringify(dataUserSession)
            CookiesJS.set("dataUser",newCookie)
            let controlsPage = {}
            const res = await userText.getItem({tenant:nameClient,
                param:{
                    idUser:dataUserSession.user.id,
                    idLanguage:dataUserSession.user.idLanguage
                    },
                    headerEx:{idLanguageUser:dataUserSession.user.idLanguage}
                })
            if (res) {
                    res.data.data.map((spd) =>{
                        if(spd.path == 'controls' || spd.path == 'notifications-expiry-passwords') {
                            spd.webcontrolspages.map((data)=>{
                                controlsPage[data.idHtml] = data.languagewebcontrols[0];
                            })
                        }
                    })
                    openDB().then((info) => {
                        let db = info
                        // addData({clave:'textUsr' ,ssoPagesData:res.data.data})
                        const transaction = db.transaction(['textF'],'readwrite')
                        const objectStore = transaction.objectStore('textF')
                        const request = objectStore.add({clave:'textUsr' ,ssoPagesData:res.data.data})
                    })
                    .catch((error) => {
                    console.error("Error al abrir la base de datos:", error);
                    });
                
                
                setControlsPage(controlsPage)
                getLenguaje(res.data.data)
                getText(res.data.data)
            }else{
                submitText()
            }
            
          
        const lang = await requestLang.getItem({
            id:'',
            tenant:nameClient,
            param: {idClient:idClient}
        })
        setIdiom(lang.data.data)
        openDB().then((info) => {
            let db = info
            const transaction = db.transaction(['textF'],'readwrite')
            const objectStore = transaction.objectStore('textF')
            const request = objectStore?.add({clave:'textUsr' ,ssoPagesData:res.data.data})
        })
        .catch((error) => {
        console.error("Error al abrir la base de datos:", error);
        });
        deleteDB().then((info) => {
            console.log(info)
        })
        res.data.data.map((spd) =>{
            if(spd.path == 'controls' || spd.path == 'notifications-expiry-passwords') {
                spd.webcontrolspages.map((data)=>{
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                })
            }
        })
        setControlsPage(controlsPage)
        getLenguaje(res.data.data)
        getText(res.data.data)
    }, [])

    useEffect(async() => {
        const resp = await requestUserSecurityQuestion.getItem({
          param:{
              idUser: dataUserSession.user.id
          },
          tenant: nameClient
      })
      if (resp.data.data.length == 0) {
        if (ubicationUrl[ubicationUrl.length-1] == 'AssosiateQuestions') {
          setRequired(true)
        }else{
        let info = {
            title:controlsPage?.lblWarning?.value,
            description:controlsPage?.lblMrUserToAssociateQuestionsOrderToContinue?.value,
            type:'warning',
        }
        setFunc('a')
        setFuncAcept('b')
        setQuestionReq(true)
        if (Object.entries(controlsPage).length>0) {
          const {smtAccept, smtClose} =controlsPage
          setTextButton({smtAccept, smtClose})
          setDataError(info)
        }
      }
      }else{
        setRequired(false)
      }
      }, [url,controlsPage])
    /************************
      * @hooks 
      ************************/

        useEffect(() => {
            setListPages(pages.split(','))
            validateRoute().then(isValid =>{
                if(!isValid){
                  window.location.redirect(`${BSR_URI}${nameClient}/administration/error`)
                }
            })
        }, [])

        useEffect(()=>{
            if(!CookiesJS.get('token')){
                window.location = `${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${nameClient}/home`
            }
        },[])

        useEffect(async () => {
            validateRoute().then(isValid =>{
                if(!isValid){
                  window.location.redirect(`${BSR_URI}${nameClient}/administration/error`)
                }
            })
            if(currentLocation != window.location.href){
                let isvalid = await FetchOAuth({
                    accessToken: token,
                    type: 'introspect',
                    tenant: nameClient
                })
                if(isvalid.data.data.data.active == 'false') {
                    // let info ={
                    //     title: "Token vencido",
                    //     description: "Vuelva a iniciar sesión.",
                    //     type: "danger",
                    //   }
                    // render(
                    //     <ModalFluent
                    //         props={info}
                    //         actionOnClick={(e)=>{
                    //             CookiesJS.remove('token')
                    //             /* window.location.href = `${SSO_URI}/${nameClient}/home` */}
                    //     }
                    //     />,
                    //     document.getElementById("modal")
                    // );
                }
                setCurrentLocation(window.location.href)
            }
        },[window.location.href])


    /************************
      * @resturns 
      ************************/

        return(
            <>
                <div id="modal"></div>
            {Object.entries(dataError).length>0?
                <ModalFluent
                    props={dataError} 
                    objectRequest={()=>{actionOnClick(funcAcept);setDataError({});setQuestionReq(false)}} 
                    actionOnClick={()=>{actionOnClick(func);setDataError({})}}
                    text={textButton}
                    hideCancel={questionReq}
                    hideIcon={questionReq}
                    />
                :''
            }
            <ContainerBaseAdmin
                client={clientName}
                style={styleText}
                content={
                    <Routes>
                        <Route exact path={`/`} element={<Inbox />} />
                        <Route exact path={`/domain`} element={ <Domain /> } />
                        <Route exact path={`/roles-permissions`} element={ <Roles /> } />
                        <Route exact path={`/security-questions`} element={ <Queries /> } />
                        <Route exact path={`/themes`} element={ <Themes /> } />
                        <Route exact path={`/ConfigLenguaje`} element={ <Lenguaje /> } />
                        {/* <Route exact path={`/upload-files`} element={ <UpdateFile /> } /> */}
                        <Route exact path={`/terms-conditions`} element={ <TyC /> } />
                        <Route exact path={`/ip-restriction`} element={ <IpRestriction /> } />
                        <Route exact path={`/access-hours`} element={ <Workday /> } />
                        <Route exact path={`/type-file`} element={<FileTypes />}/>
                        <Route exact path={`/attributes`} element={<Attributes />}/>
                        <Route exact path={`/upload-files`} element={<Files />}/>
                        <Route exact path={`/delivery-channels`} element={<NotificationChannel />}/>
                        <Route exact path={`/messages-error`} element={<Messages />}/>
                        <Route exact path={`/schemas`} element={<Schemas />}/>
                        <Route exact path={`/notification-flow`} element={<NotificationFlow />}/>
                        <Route exact path={`/challenges`} element={<Domainauthenticationfactors />}/>
                        <Route exact path={`/client`} element={<Client />}/>
                        <Route exact path={`/PasswordExpirationNotifications`} element={<PasswordExpirationNotifications />}/>
                        <Route exact path={`/notification-template`} element={<NotificationTemplate />}/>
                        <Route exact path={`/password-policies`} element={<SecurityPolicy />}/>
                        <Route exact path={`/language-content`} element={<Contentforlanguage />}/>
                        <Route exact path={`/support`} element={<SupportBsr />}/>
                        <Route exact path={`/AssosiateQuestions`} element={<AssoQuestions onChange={(e)=>setStyleText(e)}/>}/>
                        <Route exact path={`/PasswordChange`} element={<ChangePassword onChange={(e)=>setStyleText(e)}/>}/>
                        <Route exact path={`/user-licences`} element={<UserLicences />}/>
                        <Route exact path={`/manage-mfa`} element={<Mfa onChange={(e)=>setStyleText(e)} renderAdmin={true}/>}/>
                        <Route exact path={`/user-role-assignments`} element={<UsersRoleAssignment />}/>
                        <Route exact path={`/DataUserAdmin`} element={ <DataUser isAdmin={true} onChange={(e)=>setStyleText(e)}/> } />
                        <Route path="*" element={<AvisoError />} />
                    </Routes>
                }
            />
            </>
        )
    //}
}

export default Admin
