import React from 'react';
import { useNavigate } from "react-router-dom";
import CookiesJS from "js-cookie";
import { PATH_APPLICATION } from '../../../assets/settings/settinsrouters';

const ButtonFloat = (props) =>{
    /************************
     * @arguments 
     ************************/
     const navigate = useNavigate();
     const nameClient = CookiesJS.get("nameClient");
     /************************
     * @methods 
     ************************/
const func = () =>{
    navigate(`${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/${nameClient}/user`)
}
    /************************
     * @hooks 
     ************************/

    /************************
     * @resturns 
     ************************/
        return(
            <div className={` ${props.typef}`} 
                onClick={props.type == 1?props.onClick:undefined}
            >
                {/* {props.icon?<a className={props.type == 1?props.icon:undefined} onClick={func}></a>:''} */}
                {props.icon?(props.required?'':<a className={(props.type == 1?props.icon:undefined)} onClick={func}>{props.special ?props.icon:undefined}</a>):''}
                {/* {props.icon && !props.required?<a className={(props.type == 1?props.icon:undefined)} onClick={func}>{props.special ?props.icon:undefined}</a>:''} */}
                <p className={`buttonFloat--text izq-p ${props.style}`}>{props.text}</p>
                {props.text2?<p className={`buttonFloat--text der-p ${props.style}`}>{props.text2}</p>:''}
            </div>
        );
}

export default ButtonFloat;