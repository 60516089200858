import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import CookiesJS from 'js-cookie';
//import {DefaultButton, Panel, PanelType, } from 'office-ui-fabric-react'
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { ActionButton, DefaultButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks';
import { useParams } from 'react-router-dom';

//*****Componnents*****//
import SubMenu from '../../../components/navigation/subMenu/subMenu.component';
import ContainerLargeForm from '../../../components/containers/largeForm/containerLargeForm.component';
import ContainerBaseLoading from '../../../components/containers/baseLoading/containerBaseLoading.component';
import ListHeader from '../../../components/lists/listHeader/listHeader.component';
import ListBody from'../../../components/lists/listBody/listBody.component';

//*****Methods*****//
import Lists from '../../../utils/logicRendering/lists.controller';
import { RequestFetch } from '../../../utils/Requests/requests.Controller';
import CountPages from '../../../utils/logicRendering/countPages.controller';
import DetailsListBext from '../../../components/lists/DetailsList/detailsList.component';
import { TablePolicy, TablePolicyTwo } from './tablePolicy';
import LenguajeContext from '../../../context/Lenguaje/lenguajeContext';
import { changeLang, changeLangForm } from '../../../utils/logicRendering/changeIdiom';
    
const SecurityPolicy = ()=>{

   
    /************************
     * @arguments 
     ************************/
    
        //***Propertise***//
        const [edit, setEdit] = useState(false);
        const [deleteD, setDeleteD] = useState(false);
        const [idSecurityPolicySelect, setIdSecurityPolicySelect] = useState([]);
        const [idSecurityPolicyLanguageSelect, setIdSecurityPolicyLanguageSelect] = useState([]);
        const [currentPages, setCurrentPage]= useState(1);
        const [showElements, setShowElements]= useState(5);
        const [itemsType, setItemsType] = useState(null);
        const [typePolices, setTypePolices] = useState(null)
        const [controlsPage, setControlsPage] = useState({});
        const [textOnPages, setTextOnPages] = useState({})
        const [atributesWarn, setAtributesWarn] = useState([])
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        const [itemsSecurityPolicy, setItemsSecurityPolicy] = useState(null);
        const [itemsSecurityPolicyLanguage, setItemsSecurityPolicyLanguage] = useState(null);
        const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
        const { clientName } = useParams();
         const {idClient} = JSON.parse(CookiesJS.get('dataClient'));
         const {user} = JSON.parse(CookiesJS.get('dataUser'));
         const [isDisable, setDisable] = React.useState([
            { disable: false, key: "valueDefault" },
            { disable: false, key: "name" },
          ]);
         const [defaultValueInput, seDefaultValueInput] = useState([]);
         const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
         const [selectedKeysInfo, setSelectedKeysInfo] = useState([])
         const [selectDom, setSelectDom] = useState(user.idDomain)
         const [dataTable, setDataTable] = useState([])
         const [filtersList, setFiltersList] = useState([]);
         const [columnTitles, setColumnTitles] = useState([{
            name: {
                name: "Política de contraseña",
                maxWidth: 180,
                idHtml:'coluPasswordPolicies',
                isMultiline: true
            },
            numberOfValues: {
                name:"N°de restricciones por Política",
                maxWidth: 180,
                idHtml:'coluNumberPoliciesWithRestriction'
            },
            creatingDate: {
                name: "Fecha de creación",
                minWidth: 50,
                isResizable: true,
                maxWidth: 150,
                idHtml: "coluCreationDate",
            },
            updatingDate: {
                name: "Última Modificación",
                minWidth: 50,
                isResizable: true,
                maxWidth: 150,
                idHtml: "coluLastModification",
            },
            creatorUser: {
              name: "Creado por",
              maxWidth: 150,
              isResizable: true,
              idHtml: "coluCreatedBy",
            },
            updateUser: {
              name: "Modificado por",
              maxWidth: 150,
              isResizable: true,
              idHtml: "lblModifiedBy"
            },
            
        }])
        const [columnTitlesTwo, setColumnTitlesTwo] = useState([{
            language:{
                name:"Idioma",
                maxWidth: 180,
                idHtml:'coluLanguage'
            },
            descPoli: {
                name: "Política",
                maxWidth: 180,
                isMultiline: true,
                // idHtml:''
            },
            namePoli: {
                name:"Tipo politica",
                maxWidth: 180,
                // idHtml:''
            },
            value:{
                name:"Valor",
                maxWidth: 100,
                idHtml:'coluValue'
            },
            actions: {
              name: "Eliminar",
              maxWidth: 10,
              idHtml:'coluDelete'
            },
        }])
        //***Data Propertise***//
        const Requests = new RequestFetch('SecurityPolicy');
        const ResquetsSecurityPoliciesValue = new RequestFetch("SecurityPoliciesValue");
        const ResquetsSecurityPoliciesTypes = new RequestFetch("SecurityPoliciesTypes");
        const ResquetsSecurityPolicyLanguage = new RequestFetch("SecurityPolicyLanguage");
        const RequestsLanguages = new RequestFetch("Languages")
        const ResquetsDomain = new RequestFetch('Domains');
        const infoWarn = new RequestFetch(`Attribute/schema/${selectDom}`);
        const [isLoadingData, {
            setTrue: handleLoadingData,
            setFalse: handleLoadedData
        }] = useBoolean(false);
        const [domainForm, setDomainForm] = useState([]);
        const [restringInfo, setRestringInfo] = useState([])
        const [securityPoliciesValue, setSecurityPoliciesValue] = useState([])
        const [valuesAtribute, setValuesAtribute] = useState({})
        const [lngTable, setLngTable] = useState([])
        const [daySelect, setDaySelect] = useState()
        const [typeOpt, setTypeOpt] = useState()
        const [pagination, setPagination] = useState(null);
        const [domainauthenticationfactors, setdomainauthenticationfactors] =
        useState([]);
        let selectLng = [];
        let lngFilter = [];
        let optDomain = [];

        let lngArray = [];
        let domFilter = [];


        useEffect(async()=>{
            const resp = await ResquetsSecurityPoliciesTypes.getItem({tenant: clientName})
            const respLng = await RequestsLanguages.getItem({tenant: clientName})
            const lngTypes = await ResquetsSecurityPolicyLanguage.getItem({tenant: clientName})
            const respDomain = await ResquetsDomain.getItem({tenant: clientName})
            const attributes = await infoWarn.getItem({tenant: clientName})
            // promiseSecurityPoliciesTypes.then(data => {select2
            
            setTypePolices(resp.data)

            attributes.data.data.map(atr=>(
                setAtributesWarn(prevState => [...prevState, ...[{key: atr.id, text: atr.name}]])
            ))

            resp.data.data.map(data =>{
                lngArray.push({value: data.id, key: data.id, text: data.name})
                campForm[4].options.push({value: data.id, key: data.id, text: data.name})
            })

            setIdSecurityPolicyLanguageSelect(lngArray)

            respLng.data.data.map(data =>{
                selectLng.push({value: data.id, key: data.id, text: data.name})
                campForm[2].options.push({value: data.id, key: data.id, text: data.name})
            })
            setLngTable(selectLng)

            respDomain.data.data.map(data =>{
                optDomain.push({value: data.id, key: data.id, text: data.alias})
                campForm[1].options.push({value: data.id, key: data.id, text: data.alias})
                domFilter.push({value: data.id, key: data.id, text: data.alias})
            })
            setFiltersList(changeLangForm([
                {
                    id:0,
                    key:'idDomain',
                    label:'Dominio',
                    type:'select',
                    idHtml: "lblFilterDominio",
                    options: respDomain.data.data.map(
                        domain => ({
                            id: domain.id,
                            text: domain.alias
                        })
                    )
                },
                {
                    id:1,
                    key:'search',
                    label:'',
                    type:'text',
                }
            ],controlsPage));
            setDomainForm(optDomain)
        },[])
        const defaultRenderItemColumn = (item, index, column) => {
  
            const fieldContent = item[column.fieldName];
            switch (column.key) {
                case 'actions':
                    return (
                        <ActionButton
                            text=""
                            iconProps={{iconName: 'Cancel'}}
                            onClick={() => 
                              console.log(column.key)
                            }
                        />
                    );
                default:
                    return <span>{fieldContent}</span>;
            }
        }

        const [filter, setFilter] = useState({
            pageSize: 5,
            pageNumber: "0",
            idDomain: ""
        });

        useEffect(() => {
            let controlsPage = {};
            if (stateLanguage.lenguaje !=0) {
                stateLanguage.lenguaje.map((pass) => {
                    if (pass.path == 'controls'|| pass.path =='domain' || pass.path =='password-policies') {
                      pass.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                      })    
                      setControlsPage(controlsPage)
                      setColumnTitles(current => changeLang(current, controlsPage))
                      setColumnTitlesTwo(current => changeLang(current, controlsPage))
                    //   setCampForm(current => changeLangForm(current, controlsPage))
                    }              
                })          
            }
        }, [textOnPages, stateLanguage])


        useEffect(async() => {
            if (selectDom) {
                setSelectedKeysInfo([])
                let arr = []
                const retring = await infoWarn.getItem({tenant:clientName, headerEx:{idLanguageUser:user.idLanguage}})
                retring.data.data.map(opt=>{
                    arr.push({key:opt.idAttributeScim, id:opt.idAttributeScim, text:opt.name})
                })
                setRestringInfo(arr)
            }
          
        }, [selectDom])
        
        

        const actionOnChange = (data) => {
            
            if (data.id == 'securityPoliciesValue') {
                if (data.selectedItem instanceof Object) {
                    // console.log(data.selectedItem)         
                    // setTypeOpt(data.selectedItem.key)
                    if (data.selectedItem.key == 3) {
                        // atributesWarn.map(cont=>{
                        //     console.log(cont)
                        // })
                        // const campforms = campForm.filter(opt=>opt.key != 'securityPoliciesValue')
                        // console.log(campforms)
                        // setCampForm([])
                        setCampForm(prevState=>[...prevState,...[{  
                            id:0, 
                            key: 'name',
                            label: 'Nombre de Política',
                            type:'text2', 
                            onRenderLabel: null,
                            placeholder:'Ingrese un nombre de Política', 
                            required: 1,
                            className:'select2',
                            defaultValu:null,
                            idHtml:'lblPolicyName'
                        },]])
                    }
                    if (data.selectedItem.key != 3) {
                        console.log('entra diferente 3')
                        setSelectedKeysInfo([]) 
                        setCampForm([])
                        // addNewObjects([
                        // {
                        //     id: 5,
                        //     key: "selectAttribute",
                        //     label: "Atribute ",
                        //     type: "text2",
                        //     onRenderLabel: null,
                        //     required: true,
                        //     placeholder: "Valor a restringir",
                        //     multiSelect: true,
                        //     disabled: isDisable,
                        //     className:'select2',
                        //     defaultSelectedKeys:defaultSelectedKeys
                        // }
                        // ])
                    }
                }
            }
            if (data.id == 'idDomain') {                
                setSelectDom(data.selectedItem?data.selectedItem.id:user.idDomain)
                setValuesAtribute({...valuesAtribute,...{domain:data.selectedItem?data.selectedItem.value:user.idDomain}})
            }
            if (data.id == 'idLanguage') { 
                if (data.selectedItem instanceof Object) {
                setValuesAtribute({...valuesAtribute,...{idLanguage:data.selectedItem.key}})
                }
            }
            if (data.id == 'selectAttribute') {
                if (data.selectedItem instanceof Object) {
                    if (data.key !== null && data.selectedItem != undefined) {
                        setDaySelect(data)
                    }
                } else{
                    setSelectedKeysInfo([])
                    if (data.key !== null && data.selectedItem != undefined) {
                    let daySel = {value: data.selectedItem, idSecurityPolicyType:typeOpt}
                    setSelectedKeysInfo([daySel])
                    }
                    // setSecurityPoliciesValue()
                }               
            }
            if (data.id == 'name') {                
                // Object.assign(valuesAtribute,{name:data.selectedItem})
                setValuesAtribute({...valuesAtribute,...{name:data.selectedItem}})
            }
            if (data.id == 'description') {                
                // Object.assign(valuesAtribute,{name:data.selectedItem})
                setValuesAtribute({...valuesAtribute,...{description:data.selectedItem}})
            }
        }

        const _defaultRenderItemColumn = (item, index, column) => {
            const fieldContent = item[column.fieldName];
            
            return <span>{fieldContent}</span>;
          }
        useEffect(() => {
            if (daySelect) {
              if (daySelect.selected) {
              let daySel = {value: daySelect.selectedItem.key, idSecurityPolicyType:typeOpt}
              setSelectedKeysInfo([...selectedKeysInfo,daySel])
              
              }else{
                setSelectedKeysInfo(selectedKeysInfo.filter(item=>item.value != daySelect.selectedItem.key))
              }
            }
            
        }, [daySelect])
        
        const onSubmitEventFormPanel = (data) => {
            let dataForm = {
                idDomain: valuesAtribute.domain,
                name: valuesAtribute.name,
                isEnable: true,
                securityPoliciesValue: selectedKeysInfo,
                securityPolicyLanguages: [
                    {
                    idLanguage: valuesAtribute.idLanguage,
                    description: valuesAtribute.description
                    }
                ]

            }
         
             return dataForm
           };

        const [campForm, setCampForm]= useState([
            {  
                id:0, 
                key: 'name',
                label: 'Nombre de Política',
                type:'text2', 
                onRenderLabel: null,
                placeholder:'Ingrese un nombre de Política', 
                required: 1,
                className:'select2',
                defaultValu:null,
                idHtml:'lblPolicyName'
            },
            {
                id:1, 
                key:'idDomain',
                defaultValue:'',
                label: 'Dominio', 
                type:'select2', 
                options: domainForm,
                required: 1,
                className:'select2',
                placeholder: "Seleccione...",
                idHtml:'lblDomain'
            },
            {
                id:2, 
                key:'idLanguage', 
                label: 'Idioma descripción', 
                type:'select2', 
                options: lngTable,
                required: 1,
                className:'select2',
                placeholder: "Seleccione..."
            },
            {  
                id:3, 
                key: 'description',
                label: 'Descripción',
                type: 'text2',
                onRenderLabel: null,
                placeholder:'Ingrese una descripción',
                required: 1,
                className:'select2',
                placeholder: "Seleccione...",
                defaultValu:null,
                idHtml:'lblDescription'
            },
            {  
                id:4, 
                key: 'securityPoliciesValue',
                label: 'Tipo',
                type: 'select2',
                options: idSecurityPolicyLanguageSelect,
                required: 1,
                className:'select2',
                placeholder: "Seleccione...",
                idHtml:'slcType'
            },
            {
                id: 5,
                key: "selectAttribute",
                label: "Atribute ",
                type: "text2",
                onRenderLabel: null,
                required: true,
                placeholder: "Valor a restringir",
                multiSelect: true,
                disabled: isDisable,
                className:'select2',

            },
            // {
            //     id:6,
            //     className:"ha w10 mt-tgl",
            //     type:"div",
            //     key:"specediv",
            //     component:
            //     <DetailsListBext
            //     enableShimmer={isLoadingData}
            //     listKey="domainAsociate"
            //     selectionMode={0}
            //     items={dataTable}
            //     renderItemColumn={defaultRenderItemColumn}
            //     columnTitles={columnTitlesTwo}
            //     />
            // }

        ])

        const addNewObjects = (nuevosObjetos) => {
            const nuevoCampForm = [...campForm];
        
            nuevosObjetos.forEach((nuevoObjeto) => {
              const index = nuevoCampForm.findIndex(
                (objeto) => objeto.id === nuevoObjeto.id
              );
              if (index !== -1) {
                nuevoCampForm[index] = nuevoObjeto;
              } else {
                nuevoCampForm.push(nuevoObjeto);
              }
            });
            console.log(nuevoCampForm)
            // setCampForm(nuevoCampForm);
        };
    /************************
    * @methods 
    ************************/
        const methodsList = {
            getDataItem:(object)=>{
                const stateList = new Lists(itemsSecurityPolicy, campForm)
                setIdSecurityPolicySelect(stateList.getDataItem(object, idSecurityPolicySelect))
                const stateCurrent = stateList.updateCurrent(idSecurityPolicySelect)
                setCampForm(stateCurrent.object)
                setDeleteD(stateCurrent.delete)
                setEdit(stateCurrent.edit)
            },
            voidCamps:()=>{
                const stateList = new Lists(itemsSecurityPolicy, campForm)
                setCampForm(stateList.clearCamps())
            }
        }
        
        const selectSchema = (items) => {
            if (items.length>0) {
              const stateList = new Lists(itemsSecurityPolicy, campForm);
              
              setdomainauthenticationfactors(
                stateList.getDataItem2(items[0], domainauthenticationfactors)
              );
              const stateCurrent = stateList.updateCurrent(domainauthenticationfactors);
              setCampForm(stateCurrent.object);
              setEdit(stateCurrent.edit);
            } else {
            //   setDefaultSelectedKeys([])
              const stateList = new Lists(itemsSecurityPolicy, campForm);
              setCampForm(stateList.clearCamps());
              setEdit(false);
            }
        }
        const methosRequests = {
            chageGetItems:async(filters)=>{
                handleLoadingData()
                const promiseSecurityPoliciesValue = await ResquetsSecurityPoliciesValue.getItem({tenant: clientName, param:filters})
                setItemsType(promiseSecurityPoliciesValue.data)
                // setPromiseSecurityPoliciesValue(ResquetsSecurityPoliciesValue.getItem({param:filters}));
                const promise = await Requests.getItem({param:filters, tenant: clientName})
                setItemsSecurityPolicy(promise.data);
                const {
                    currentPage,
                    pageZise,
                    totalCount,
                    totalPages
                } = promise.data.meta;
                setPagination({
                    currentPage: currentPage,
                    pageSize: pageZise,
                    totalItems: totalCount,
                    totalPages: totalPages
                })
                // setPromise(Requests.getItem({param:filters, tenant: clientName}));
            handleLoadedData();
        },
            crateItem:(dataForm)=>{
                // dataForm = Object.assign(dataForm, {
                //     idClient: idClient,
                //     securityPoliciesValue:defaultSelectedKeys
                // });
                console.log(onSubmitEventFormPanel())
                Requests.createItem({ body: onSubmitEventFormPanel(), ct: "json" , tenant:clientName, 
                    infoModal:{
                    title:controlsPage?.lblSuccessfulProcess?.value,
                    description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                    }
                })
                
            },
            updateItem:(dataForm, id)=>{
               
              Requests.confirmRequest({ body: dataForm, id: id, ct: "json", tenant: clientName, option:'updateItem',
              infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                } 
              })
            },
            deleteItem:()=>{
                Requests.confirmRequest({ id:domainauthenticationfactors.id, tenant: clientName, option:'deleteItem',
                infoModal:{
                    title:'controlsPage?.lblDisabled?.value', 
                    description:'controlsPage?.lblItHbeenSuccessfullyDisabled?.value'
                }
                })
            }
        }

    /************************
     * @hooks 
     ************************/
        
        useEffect(async() => {
            const promise = await Requests.getItem({
                param: {pageSize: showElements, pageNumber: currentPages, idClient: idClient },tenant: clientName
            })
            setItemsSecurityPolicy(promise.data);

            const promiseSecurityPoliciesValue = ResquetsSecurityPoliciesValue.getItem({tenant: clientName})
            setItemsType(promiseSecurityPoliciesValue.data)
            
            const promiseSecurityPolicyLanguage = ResquetsSecurityPolicyLanguage.getItem({tenant: clientName})
            setItemsSecurityPolicyLanguage(promiseSecurityPolicyLanguage.data)
        }, [controlsPage])
        
        
        const [update, setUpdate] = useState(null);
        const editacion=(objectArr)=>{
            let arr = []
            campForm[6].component.props.items.length=0
            let object = objectArr[0]
            if (!edit) {
                object.securityPoliciesValue.map(pol=>{
                    typePolices.data.map(type=>{
                        if (type.id == pol.idSecurityPolicy) {
                            pol = Object.assign(pol,{namePoli:type.name})
                        }
                        if (type.id == pol.idSecurityPolicyType) {
                            pol = Object.assign(pol,{descPoli:type.description})
                            
                        }
                    })
                    arr.push(pol)
                    console.log(pol)
                })
                campForm[6].component.props.items.push(...arr)
                setDataTable(arr)
            }
        }
 
    /************************
     * @resturns 
     ************************/
        if(itemsType === null || itemsSecurityPolicy === null || typePolices == null){
            return (<ContainerBaseLoading/>)
        }
        const numberPage = CountPages(itemsSecurityPolicy.meta.totalPages)

        return(
            <>
                <SubMenu 
                    contentcontrols={
                        !deleteD && !edit ?
                        [
                            {
                                text:controlsPage?.OnClickNewPsswrdPolicy.value,
                                iconProps:{iconName: 'Add'},
                                onClick:()=>{methodsList.voidCamps(); openPanel();}
                            }
                        ]
                        :
                        [
                            {
                                text:"Editar política",
                                iconProps:{iconName: 'Edit'},
                                onClick:()=>openPanel()
                            },
                            {
                                text:"Inhabilitar política",
                                iconProps:{iconName: 'delete'},
                                onClick:()=>methosRequests.deleteItem()
                            }
                        ]
                           
                    }
                    contentcontrolsTwo={
                        <> 
                        <Panel 
                            isOpen={isOpen}
                            closeButtonAriaLabel="Close"
                            isHiddenOnDismiss={true}
                            type={PanelType.large}
                            hasCloseButton={false}
                        >   
                            {isOpen?<ContainerLargeForm
                                dataForm={(e)=>{
                                    edit ? methosRequests.updateItem(e, idSecurityPolicySelect[0].id): methosRequests.crateItem(e)
                                }}
                                formTitle={edit?controlsPage?.lblNewPsswrdPolicy.value:controlsPage?.lblNewPsswrdPolicy.value}
                                Items={campForm}
                                closePanel={(e)=>{dismissPanel(e);setSelectedKeysInfo([])}}
                                defaultSelectedKeys={selectedKeysInfo}
                                defaultValueInput={defaultValueInput}
                                isDisableOpt={isDisable}
                                actionOnChange={actionOnChange}
                                onSubmitEventFormPanel={onSubmitEventFormPanel}
                                controlsPage={controlsPage}
                            />:''}
                        </Panel>
                        </>
                    }
                />
                <div className="admin--body-container">
                    <ListHeader 
                        filters={filtersList}
                        showReport={false} 
                        subRute={controlsPage?.lblUrlPasswordPolicySettingsPolicy.value}
                        subRute3= {controlsPage?.lblPasswordPolicies.value}
                        title={controlsPage?.lblPasswordPolicies.value}
                        dataForm={(e)=>methosRequests.chageGetItems({
                            pageSize:showElements, pageNumber:currentPages,
                            idDomain:e.idDomain, idLanguage:e.idLanguage, search:e.search
                        })}
                        onChange={(e)=>{
                            setShowElements(e.target.value); 
                            setFilter({
                                ...filter,
                                pageSize: e.target.value,
                                pageNumber: filter.pageNumber === "0" ? "0" : "1"
                            });
                            methosRequests.chageGetItems({pageSize:e.target.value, pageNumber:currentPages});
                        }}
                        InputChange={(e)=>{
                            setFilter({
                                ...filter,
                                search: e,
                                pageNumber: filter.pageNumber === "0" ? "0" : "1"
                              })                            
                            methosRequests.chageGetItems({search:e, pageSize:showElements, pageNumber:currentPages});
                        }}
                    />
                    <DetailsListBext
                        enableShimmer={isLoadingData}
                        listKey="supportBsrList"
                        selectionMode={1}
                        items={itemsSecurityPolicy.data}
                        renderItemColumn={_defaultRenderItemColumn}
                        columnTitles={columnTitles}
                        onSelect={(e)=>{selectSchema(e);editacion(e)}}
                        onPageChange={page => {
                            if(page !== +filter.pageNumber){
                                setFilter({
                                    ...filter,
                                    pageNumber: String(page),
                                });
                            }
                        }}
                        pagination={pagination}
                    />
                </div>
            </>    
        );
}

export default SecurityPolicy