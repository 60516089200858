import React, { useState, useEffect } from 'react';
import CookiesJS from 'js-cookie';
import { getElementError } from '@testing-library/react';

//**Components**//

//**Methods**//

/**
 *@author Yeison Quiroga(14-04-2021)
 *@version 1.0
 *@lastCommit  Sebastián Ocampo Roa(06-03-2022)
 */

const ListItemDefault = (props) => {
  for (let index = 0; index < props.orditem.length; index++) {
    if (typeof props.orditem[index] === "object") {
    } else {
      <li key={index}>{props.properties[index]}</li>;
    }
  }
  return (
    <ul className="list--body-item">
      {props.orditem.map((item, index) =>
        typeof props.orditem[index] === "object" ? (
          props.orditem[index].map((item2, index2) => (
            <li key={index}>{props.properties[item2]}</li>
          ))
        ) : (
          <li key={index}>{props.properties[item]}</li>
        )
      )}
      <li>
        <a>&#x1f7a8;</a>
      </li>
    </ul>
  );
};

const ListItemSelect = (props) => {
  const [isChecked, setIsChecked] = useState(false)
  let info = props.info
  useEffect(() => {
   
   //document.getElementById(`Item${props.id}`).checked = false
   //console.log(document.getElementById(`Item${props.id}`))
  }, [info])
  
  const busqueda = (props) => {
    const language = CookiesJS.get("languages");
    const lng = JSON.parse(language);
    const nameLanguage = lng.find(
      (nombre) => nombre.id == props.properties.idLanguage
    );
    if (nameLanguage) {
      const nameLng = nameLanguage.name;
      return nameLng;
    }
  };
  // const camp =() =>{
  //   setIsChecked(true)
  // }
  //06/12/2021 - Sebastián Ocampo - Se crea función para mapear objetos internos en las respuestas de los servicios.
  const findValueInObject = (props, item) => {
    const splItem = item.split(".");
    if (splItem.length > 1) {
      let result = props;

      for (let i = 0; i < splItem.length; i++) {
        result = result[splItem[i]];
      }
      return result;
    } else {
      return props[item];
    }
  };
  for (let index = 0; index < props.orditem.length; index++) {
    if (typeof props.orditem[index] === "object") {
      props.orditem[index].forEach((object) => {
        if (typeof object === "object") {
        }
      });
    } else {      
    }
  }

  return (
    <div key={props.id} className="list--item-select">
      <label htmlFor={`Item${props.id}`}>
        <input
          type="checkbox"
          id={`Item${props.id}`}
          onClick={props.onClickL}
        />

        {props.orditem.map((item, index) =>
          typeof props.orditem[index] === "object" ? (
            <li
              key={index}
            >
              <ul
                style={{
                  "justify-content": "center",
                  display: "flex",
                  "align-items": "center",
                  display: "inline-block",
                }}
              >
                  <>
                    <li>0% - {props.properties["mildIndicator"]}%</li> 
                    <li>{props.properties["mildIndicator"]-1}% - {props.properties["averageIndicator"]}%</li>
                    <li>{props.properties["averageIndicator"]-1}% - {props.properties["criticalIndicator"]}%</li>
                  </>
              </ul>
            </li>
          ) : (
            <>
              <li key={index}>
                {item === "preview" ? (
                  <div>
                    <p>{props.properties["primaryFont"]}</p>
                    <ul className="w10 container--flex ">
                      <li
                        className="wr1 h1 "
                        style={{
                          backgroundColor: `#${props.properties["darkColor"]}`,
                        }}
                      ></li>
                      <li
                        className="wr1 h1 mg2-w"
                        style={{
                          backgroundColor: `#${props.properties["semidarkColor"]}`,
                        }}
                      ></li>
                      <li
                        className="wr1 h1 mg2-w"
                        style={{
                          backgroundColor: `#${props.properties["primaryColor"]}`,
                        }}
                      ></li>
                      <li
                        className="wr1 h1 mg2-w"
                        style={{
                          backgroundColor: `#${props.properties["semiligthColor"]}`,
                        }}
                      ></li>
                      <li
                        className="wr1 h1 "
                        style={{
                          backgroundColor: `#${props.properties["ligthColor"]}`,
                        }}
                      ></li>
                    </ul>
                  </div>
                ) : item === "nameLanguage" ? (
                  busqueda(props)
                ) : (
                  findValueInObject(props.properties, item)
                )}
              </li>
                {item === "colorPasswordExpirationNotification" ? (
                  <li
                    key={index}
                    style={{ height: "100px", margin: "5px 0 0 -25px" }}
                  >
                    <ul
                      style={{
                        height: "80px",
                        width: "100%",
                        "justify-content": "center",
                        display: "flex",
                        "align-items": "center",
                        display: "inline-block",
                      }}
                    >
                      <li
                        className="wr1 h1 "
                        style={{
                          backgroundColor: "#33ff3f",
                          height: "20px",
                          width: "70px",
                          "border-radius": "5px",
                          position: "center",
                          "margin-top": "5px",
                        }}
                      ></li>
                      <li
                        className="wr1 h1 "
                        style={{
                          backgroundColor: "#fcff33",
                          height: "20px",
                          width: "70px",
                          "border-radius": "5px",
                          position: "center",
                          "margin-top": "5px",
                        }}
                      ></li>
                      <li
                        className="wr1 h1 "
                        style={{
                          backgroundColor: "#ff3f33",
                          height: "20px",
                          width: "70px",
                          "border-radius": "5px",
                          position: "center",
                          "margin-top": "5px",
                        }}
                      ></li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}
            </>
          )
        )}
      </label>
    </div>
  );
};
const ListBody =(props)=>{
    /************************
     * @arguments 
     ************************/
        const [typeList, setTypeList] = useState(props.typelist);
        const [numSelect, setNumSelect] = useState(props.initRangeText)
        const [isChecked, setIsChecked] = useState(false)
        let pageZise = props.meta.pageZise;
        let totalCount = props.meta.totalCount;
        let currentPage = props.meta.currentPage;
        let firstRegister = ((currentPage * pageZise) + 1) - pageZise;
        let lastRegister = (currentPage * pageZise)
        if(lastRegister > totalCount){
          lastRegister = totalCount;
        }
    /************************
     * @methods 
     ************************/
        function addItems(item){
            if(typeList == 'listSelect'){
                return <ListItemSelect 
                            key={item.id} 
                            id={item.id} 
                            properties={item}
                            orditem={props.orditem}
                            onClickL={()=>props.selectdata(item)}
                            language={props.language}
                            info={props.initRangeText}
                        />;
            }else{
                return <ListItemDefault 
                            key={item.id} 
                            id={item.id} 
                            properties={item}
                            orditem={props.orditem}
                        />;
            }
        }

        const paginateNum = (number) =>{

            let elem = document.getElementById(number);
            if(!number){
                elem.style.opacity = '0.5';
            }else{
                elem.style.opacity = '1';
            }
            if (elem.id == number) {
                elem.style.fontWeight = 700;
                setNumSelect(props.initRangeText)
            }
            
            props.paginate(number)
        }
    /************************
     * @hooks
     ************************/
     useEffect(() => {
        let elem = document.getElementById(numSelect);
        if(elem){
          elem.style.fontWeight = '';
          elem.style.opacity = '0.5';
        }
        
    }, [numSelect])

    /************************
     * @resturns 
     ************************/
        return(
            <div className="list--container-body">
                <div className={typeList=='listSelect'?"list--body-headerI": "list--body-header"} >
                    {props.spaceTitle.map(title=>
                        <div className="list--body-subintems" key={title}>
                            <i>&#x21c5;</i>
                            <p>{title}</p>
                        </div>
                    )}
                </div>
                <div className="list--body-content">
                    {props.Items?.length > 0 || props.Items != null
                        ? props.Items.map((item)=>addItems(item))   
                        :<h3 className="txt--prymary clr--base mg10-h txt--aling-c">No existen items</h3> 
                    }
                </div>
                <div className="list--body-footer">
                    <div className="list--body-subitem">
                        {/* <p>Mostrar {props.initRangeText} de {props.finishRangeText} entradas de {props.totalRangeText}</p> */}
                        <p>{firstRegister} - {lastRegister} de {totalCount}</p>
                    </div>
                    <div className="list--body-subitem">
                        <ul>
                            {props.initRangeText === 1?'':<li><a onClick={()=> props.paginate(props.initRangeText - 1)}>&#x3c;</a></li>}
                            {props.numberPages.map((numberPage)=>
                                <li id={numberPage} key={numberPage}><a data-title={numberPage} onClick={()=> paginateNum(numberPage)}>{numberPage}</a></li>    
                            )}
                            {props.initRangeText === props.numberPages.length?'':<li><a onClick={()=> {props.paginate(props.initRangeText + 1)}}>&#x3e;</a></li>}
                            
                        </ul>
                    </div>
                </div>
            </div>
        );
}

export default ListBody;
