import React, { useContext, useEffect, useState } from "react";
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useBoolean } from "@fluentui/react-hooks";
import { useParams } from "react-router-dom";
import CookiesJS from "js-cookie";
//**Components**//
import SubMenu from "../../components/navigation/subMenu/subMenu.component";
import ContainerLargeForm from "../../components/containers/largeForm/containerLargeForm.component";
import ContainerBaseLoading from "../../components/containers/baseLoading/containerBaseLoading.component";
import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';
import ListHeader from "../../components/lists/listHeader/listHeader.component";
import ListBody from "../../components/lists/listBody/listBody.component";
//**Methods**//
import Lists from "../../utils/logicRendering/lists.controller";
import { RequestFetch } from "../../utils/Requests/requests.Controller";
import CountPages from "../../utils/logicRendering/countPages.controller";
import Modal from "../../components/tools/modal/modal.component";
import LenguajeContext from "../../context/Lenguaje/lenguajeContext";
import { changeLang, changeLangForm } from '../../utils/logicRendering/changeIdiom';

/**
 *@author Jhon Beltran(26-05-2022)
 *@version 1.0
 *@lastCommit 
 */

const Attributes = () => {
  /************************
   * @arguments
   ************************/
  //***Propertise***//

  const [changeCheck, setchangeCheck] = useState(true)
  const [currentPages, setCurrentPage] = useState(1);
  const { clientName } = useParams();
  const [deleteD, setDeleteD] = useState(false);
  const [edit, setEdit] = useState(false);
  const [idAttributeSelect, setIdAttributeSelect] = useState([]);
  const [itemsAttribute, setItemsAttribute] = useState(null);
  const [itemsSchema, setItemsSchema] = useState(null);
  const [sendNotification, setSendNotification] = useState(false)
  const [isForDisplayToUser, setIsForDisplayToUser] = useState(false)
  const [isForLogin, setIsForLogin] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [isForRecovery, setIsForRecovery] = useState(false)
  const { getLenguaje, stateLanguage } = useContext(LenguajeContext);
  const [itemsAttributeScim, setItemsAttributeScim] = useState(null);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [option, setOption] = useState()
  const [controlsPage, setControlsPage] = useState({});
  const [controlsPageValue, setControlsPageValue] = useState({});
  const [textOnPages, setTextOnPages] = useState({})
  const [showElements, setShowElements] = useState(5);
  const [valueKey, setValueKey] = useState();
  const [isDisable, setDisable] = React.useState([
    { disable: false, key: "valueDefault" },
    { disable: true, key: "name" },
    { disable: changeCheck, key: "maxLength" },

  ]);

  const openPanel2 = () => {
    setCampForm(changeLangForm([
      {
        id: 0,
        key: "name",
        label: "Nombre",
        type: "text2",
        placeholder: "LoginName",
        required: true,
        onRenderLabel: null,
        className: 'select2',
        defaultValu: rename,
        max: controlsPageValue?.txtLoginName?.maxlength,
        idHtml: "coluAttributeName",
      },
      {
        id: 1,
        key: "description",
        label: "Descripción",
        type: "text2",
        placeholder: "Descripción",
        required: true,
        onRenderLabel: null,
        max: controlsPageValue?.txtDescription?.maxlength,
        className: 'select2',
        defaultValu: description,
        idHtml: "lblDescription"
      },
      {
        id: 2,
        key: "idLanguage",
        label: "Idioma a asociar",
        type: "select2",
        options: optLanguage,
        action: true,
        required: 1,
        className: 'select2',
        placeholder: 'Seleccione',
        defaultValue: languageId,
        idHtml: "lblLanguage"
      },
      {
        id: 3,
        key: "idSchema",
        label: "Esquema",
        type: "select2",
        options: optSchemaData,
        required: 1,
        className: 'select2',
        placeholder: 'Seleccione',
        defaultValue: schemaType,
        idHtml: "lblScheme"
      },
      {
        id: 4,
        key: "idAttributeSCIM",
        label: "Propiedad SCIM",
        type: "SelectSearch",
        options: opciones,
        placeholder2: controlsPage?.slcSelect?.value,
        placeholder3: controlsPage?.lblSearch?.value,
        required: 1,
        className: 'select2',
        defaultValue: attribScim,
        idHtml: "lblPropertySCIM"
      },
      {
        id: 5,
        label: '¿Enviar notificación?',
        type: 'select2',
        placeholder: 'Selecciona',
        defaultValue: sendNotification,
        isRequired: false,
        key: 'sendNotification',
        className: 'select2',
        value: sendNotification,
        options: [{ key: false, text: controlsPage?.lblNo?.value },
        { key: true, text: controlsPage?.lblYes?.value }],
        idHtml: "lblSendNotification"
      },
      {
        id: 6,
        key: "isForDisplayToUser",
        type: "checkbox",
        defaultValue: isForDisplayToUser,
        label: "Consultar en Directorio",
        className: 'mg8-h select2',
        idHtml: "ckbShowInDirectory"
      },
      {
        id: 7,
        key: "isForLogin",
        type: "checkbox",
        label: "Usar en login",
        defaultValue: isForLogin,
        className: 'mg8-h select2',
        idHtml: "chkUserLogin"
      },
      {
        id: 8,
        key: "isEditable",
        type: "checkbox",
        label: "Permitir modificación de usuario",
        defaultValue: isEditable,
        className: 'mg8-h select2',
        idHtml: "chkUserModification"
      },
      {
        id: 9,
        key: "isForRecovery",
        type: "checkbox",
        label: "Recuperación de usuario",
        defaultValue: isForRecovery,
        className: 'mg8-h select2',
        idHtml: "chkUserRecovery"
      },
      {
        id: 10,
        key: "maxLength",
        type: 'text2',
        typeInput: "number",
        label: "Máximo de caracteres",
        isNumeric: true,
        required: false,
        onRenderLabel: null,
        disable: changeCheck,
        defaultValu: null,
        className: "select2",
        max: controlsPageValue?.slcMaximumCharacter?.maxlength,
        defaultValue: defaultMax,
        idHtml: "lblMaximumCharacter"
      },
      {
        id: 11,
        type: "radius",
        key: "attributeId",
        label: controlsPage?.lblAttributeIdentifier?.value,
        classNameLb: "txt--tertiary",
        options: [
          { id: 1, value: 'isPhone', label: controlsPage?.rbtPhone?.value },
          { id: 2, value: 'isInstitutionalEmail', label: controlsPage?.rbtInstitutionalEmail?.value },
          { id: 2, value: 'isPersonalEmail', label: controlsPage?.rbtPersonalEmail?.value },
        ],
        required: false,
        //style: 'radius',
        className: 'select2',
        defaultValue: radioOpt
      }

    ], controlsPage))
    openPanel()
  }

  const dismissPanel2 = () => {
    setRename("")
    setDescription("")
    setOption(0)
    setschemaType(0)
    setAttribScim(0)
    setLanguageId()
    setSendNotification(false)
    setIsForDisplayToUser(false)
    setIsForLogin(false)
    setIsEditable(false)
    setIsForRecovery(false)
    setchangeCheck(true)
    dismissPanel()
  }
  // const [selectedDomain, setSelectedDomain] =
  //   useState(idAttributeSelect.length > 0 ? idAttributeSelect[0].idSchema : '')
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
  const changeFontValue = (data) => {
    setValueKey(data);
  };
  const changeCheckValue = (data) => {
    if (data.boxname == "isEditable") {
      setchangeCheck(!data.boxstatus);
    }
  };
  const optionSelected = (data) => {
    if (data) {
      setAttribScim(data)
    }
  }
  const [buttonDisable, setButtonDisable] = useState(true)

  const [filter, setFilter] = useState({
    pageSize: 5,
    pageNumber: "0",
    idDomain: "",
    idLanguage: JSON.parse(CookiesJS.get("dataUser")).user.idLanguage
  });
  const [pagination, setPagination] = useState(null);
  const [isLoadingData, {
    setTrue: handleLoadingData,
    setFalse: handleLoadedData
  }] = useBoolean(false);

  const _defaultRenderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    if (column.fieldName == "attribute") {

      return <span >{item.idAtributesScimNavigation.atribute}</span>;
    } else if (column.fieldName == "domain") {

      return <span >{item.idSchemaNavigation.domainAddress}</span>;
    } else {
      return <span>{fieldContent}</span>;
    }
  }

  const COLUMN_TITLES = [{
    name: {
      name: "Nombre Atributo",
      minWidth: 50,
      isResizable: true,
      idHtml: "coluAttributeName",
      maxWidth: 100
    },
    attribute: {
      name: "Propiedad SCIM",
      minWidth: 50,
      isResizable: true,
      idHtml: "coluPropertySCIM",
      maxWidth: 100
    },
    domain: {
      name: "Dominio asociado",
      minWidth: 50,
      isResizable: true,
      idHtml: "coluAssociatedDomain",
      maxWidth: 100
    },
    creatingDate: {
      name: "Fecha de creación",
      minWidth: 100,
      isResizable: true,
      maxWidth: 200,
      idHtml: "coluCreationDate",
    },
    creatorUser: {
      name: "Creado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "coluCreatedBy",
    },
    updatingDate: {
      name: "Última Modificación",
      minWidth: 100,
      isResizable: true,
      maxWidth: 200,
      idHtml: "coluLastModification",
    },
    updateUser: {
      name: "Modificado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "lblModifiedBy"
    },
  }];
  const [columnTitles, setColumnTitles] = useState(COLUMN_TITLES)

  //***Data Propertise***//
  const [attributeData, setAttributeData] = useState()
  const [attributeDataScim, setAttributeDataScim] = useState()
  const [attribScim, setAttribScim] = useState(null)
  const Requests = new RequestFetch("Attribute");
  const requestsSchemas = new RequestFetch("Domains/schemas");
  const requestsLanguages = new RequestFetch("Languages");
  const requestsSchemasIds = new RequestFetch("Schemas");
  const requestsAttributeScim = new RequestFetch("AttributeScim");
  const SpaceTitel = ["Nombre Atributo", "Propiedad SCIM", "Dominio asociado"];
  const OrdItems = ["name", "idAtributesScimNavigation.atribute", "idSchemaNavigation.domainAddress"];
  const [schemaData, setSchemaData] = useState()
  const [esquema, setEsquema] = useState(null)
  const [domainAso, setDomainAso] = useState(null)
  const [schemaId, setSchemaId] = useState(null)
  const [languageId, setLanguageId] = useState(null)
  const [defaultValueInput, setDefaultValueInput] = useState([]);
  const [schemaType, setschemaType] = useState(null)
  const [rename, setRename] = useState(null)
  const [description, setDescription] = useState(null)
  const [radioOpt, setRadioOpt] = useState(null)
  const [defaultMax, setDefaultMax] = useState(null)
  const [maxL, setMaxL] = useState(null)
  const [optSchemaData, setOptSchemaData] = useState([])
  const [optLanguage, setOptLanguage] = useState([])

  const actionOnChange = (data) => {

    if (data.id === "idSchema") {
      if (data.selectedItem !== undefined) {
        setschemaType(data.selectedItem.key)
      }
      //setschemaType(data.selectedItem ? data.selectedItem.value : data.defaultValue)
    }
    if (data.id === "maxLength") {
      if (data.selectedItem) {
        setMaxL(data.selectedItem)
      }
    }
    if (data.id === "name") {
      if (data.selectedItem) {
        setRename(data.selectedItem ? data.selectedItem : data.defaultValue)
      }
    }
    if (data.id === "description") {
      if (data.selectedItem) {
        setDescription(data.selectedItem ? data.selectedItem : data.defaultValue)
      }
    }
    if (data.id === "idLanguage") {
      if (data.selectedItem !== undefined) {
        setLanguageId(data.selectedItem.key)
      }
    }
    if (data.id === "sendNotification") {
      if (data.selectedItem !== undefined) {
        setSendNotification(data.selectedItem.key)
      }
    }
  }
  useEffect(() => {
    let controlsPage = {};
    let controlsPageValue = {};
    if (stateLanguage.lenguaje != 0) {
      stateLanguage.lenguaje.map((pass) => {
        if (pass.path == 'controls' || pass.path == 'attributes') {
          pass.webcontrolspages.map((data) => {
            controlsPage[data.idHtml] = data.languagewebcontrols[0];
            controlsPageValue[data.idHtml] = data;
          })
          setColumnTitles(current => changeLang(current, controlsPage))
          setCampForm(current => changeLangForm(current, controlsPage))
          setControlsPage(controlsPage)
          setControlsPageValue(controlsPageValue)
        }
      })
    }

  }, [textOnPages, stateLanguage])
  useEffect(() => {
    if (description !== undefined && description !== null && description !== "") {
      if (rename !== undefined && rename !== null && rename.length > 4) {
        if (schemaType !== undefined && schemaType !== null) {
          if (attribScim !== undefined && attribScim !== null) {
            if (languageId !== undefined && languageId !== null) {
              setButtonDisable(false)
            }
            else {
              setButtonDisable(true)
            }
          }
          else {
            setButtonDisable(true)
          }
        }
        else {
          setButtonDisable(true)
        }
      }
      else {
        setButtonDisable(true)
      }
    }
    else {
      setButtonDisable(true)
    }
  }, [rename, description, schemaType, attribScim, languageId])

  const getSchemas = async () => {
    const responseSchemaIds = await requestsSchemasIds.getItem({ param: filter, tenant: clientName })
    let arrSchemas = responseSchemaIds.data?.data?.map(optionSchema => {
      let optSchema = { key: optionSchema.id, value: optionSchema.id, text: optionSchema.domainAddress }
      return optSchema
    })
    setOptSchemaData(arrSchemas)
  }

  useEffect(() => {
    getSchemas()

  }, [])
  useEffect(() => {
    if (idAttributeSelect.length > 0) {
      setRadioOpt(idAttributeSelect[0].isPhone ? 'isPhone' : (idAttributeSelect[0].isInstitutionalEmail ? 'isInstitutionalEmail' : idAttributeSelect[0].isPersonalEmail ? "isPersonalEmail" : ""))
      setRename(idAttributeSelect[0].name)
      setDescription(idAttributeSelect[0].description)
      setDefaultMax(idAttributeSelect[0].maxLength)
      setMaxL(idAttributeSelect[0].maxLength)
      setIsEditable(idAttributeSelect[0].isEditable)
      setIsForDisplayToUser(idAttributeSelect[0].isForDisplayToUser)
      setIsForLogin(idAttributeSelect[0].isForLogin)
      setIsForRecovery(idAttributeSelect[0].isForRecovery)
      setSchemaId(idAttributeSelect[0].idSchema)
      setLanguageId(idAttributeSelect[0].idLanguage)
      //setSelectedDomain(idAttributeSelect[0].idSchema)
      setAttribScim(idAttributeSelect[0].idAtributesScimNavigation.id)
      setOption(idAttributeSelect[0].idAtributesScimNavigation.id)
      setschemaType(idAttributeSelect[0].idSchemaNavigation.idSchemaType)
      setSendNotification(idAttributeSelect[0].sendNotification)
    } else {
      setSchemaId()
      setRadioOpt()
      setDefaultMax()
      //setSelectedDomain()
      setAttribScim()
      setschemaType()
    }
  }, [idAttributeSelect[0]])

  let domain = []
  const opciones = [];

  let optAttributeData = []
  if (attributeDataScim) {
    for (let i = 0; i < attributeDataScim.length; i++) {
      if (attributeDataScim[i].inverseIdAtributesScimNavigation.length > 0) {
        let atributeArray = attributeDataScim[i].inverseIdAtributesScimNavigation;
        opciones.push({ key: attributeDataScim[i].id, text: attributeDataScim[i].atribute, idFather: attributeDataScim[i].id })
        for (let x = 0; x < atributeArray.length; x++) {
          if (atributeArray[x].inverseIdAtributesScimNavigation.length > 0) {
            atributeArray[x].inverseIdAtributesScimNavigation.map(attr => {
              let optAttribute3 = { key: attr.id, text: attr.atribute, type: attr.typeProperty }
              opciones.push(optAttribute3)
            }
            )
          }
          let optAttribute2 = { key: atributeArray[x].id, text: atributeArray[x].atribute, type: atributeArray[x].typeProperty }
          opciones.push(optAttribute2)
        }
        let relleno = { label: attributeDataScim[i].atribute, options: optAttributeData }
      } else {
        let optAttribute = { key: attributeDataScim[i].id, text: attributeDataScim[i].atribute }
        opciones.push(optAttribute);
      }
    }
  }
  const [campForm, setCampForm] = useState([
    {
      id: 0,
      key: "name",
      label: "Nombre",
      type: "text2",
      placeholder: "LoginName",
      required: true,
      onRenderLabel: null,
      className: 'select2',
      defaultValu: rename,
      max: controlsPageValue?.txtLoginName?.maxlength,
      idHtml: "coluAttributeName",
    },
    {
      id: 1,
      key: "description",
      label: "Descripción",
      type: "text2",
      placeholder: "Descripción",
      required: true,
      max: controlsPageValue?.txtDescription?.maxlength,
      onRenderLabel: null,
      className: 'select2',
      defaultValu: description,
      idHtml: "lblDescription"
    },
    {
      id: 2,
      key: "idLanguage",
      label: "Idioma a asociar",
      type: "select2",
      options: optLanguage,
      action: true,
      defaultValue: languageId,
      className: 'select2',
      required: 1,
      idHtml: "lblLanguage"
    },
    {
      id: 3,
      key: "idSchema",
      label: "Esquema",
      type: "select2",
      options: optSchemaData,
      required: 1,
      className: 'select2',
      defaultValue: schemaType,
      idHtml: "lblScheme"
    },
    {
      id: 4,
      key: "idAttributeSCIM",
      label: "Propiedad SCIM",
      type: "SelectSearch",
      placeholder2: controlsPage?.slcSelect?.value,
      placeholder3: controlsPage?.lblSearch?.value,
      options: opciones,
      required: 1,
      defaultValue: attribScim,
      className: 'select2',
      idHtml: "lblPropertySCIM"
    },
    {
      id: 5,
      label: '¿Enviar notificación?',
      type: 'select2',
      placeholder: 'Selecciona',
      //selectedKey: false,
      defaultValue: sendNotification,
      isRequired: false,
      key: 'sendNotification',
      className: 'select2',
      value: sendNotification,
      options: [{ key: false, text: controlsPage?.lblNo?.value },
      { key: true, text: controlsPage?.lblYes?.value }],
      idHtml: "lblSendNotification"
    },
    // {
    //   id: 5,
    //   className: "h7 w4",
    //   type: "div",
    //   key: "specediv"
    // },
    {
      id: 6,
      key: "isForDisplayToUser",
      type: "checkbox",
      label: "Consultar en Directorio",
      defaultValue: isForDisplayToUser,
      className: 'mg8-h select2',
      idHtml: "ckbShowInDirectory"
    },
    {
      id: 7,
      key: "isForLogin",
      type: "checkbox",
      label: "Usar en login",
      defaultValue: isForLogin,
      className: 'mg8-h select2',
      idHtml: "chkUserLogin"
    },
    {
      id: 8,
      key: "isEditable",
      type: "checkbox",
      label: "Permitir modificación de usuario",
      defaultValue: isEditable,
      className: 'mg8-h select2',
      idHtml: "chkUserModification"
    },
    {
      id: 9,
      key: "isForRecovery",
      type: "checkbox",
      label: "Recuperación de usuario",
      defaultValue: isForRecovery,
      className: 'mg8-h select2',
      idHtml: "chkUserRecovery"
    },
    {
      id: 10,
      key: "maxLength",
      type: 'text2',
      typeInput: "number",
      label: "Máximo de caracteres",
      isNumeric: true,
      required: false,
      onRenderLabel: null,
      disable: changeCheck,
      max: controlsPageValue?.slcMaximumCharacter?.maxlength,
      defaultValu: null,
      className: "select2",
      defaultValue: defaultMax,
      idHtml: "lblMaximumCharacter"
    },
    {
      id: 11,
      type: "radius",
      key: "attributeId",
      label: controlsPage?.lblAttributeIdentifier?.value,
      classNameLb: "txt--tertiary",
      options: [
        { id: 1, value: 'isPhone', label: controlsPage?.rbtPhone?.value },
        { id: 2, value: 'isInstitutionalEmail', label: controlsPage?.rbtInstitutionalEmail?.value },
        { id: 2, value: 'isPersonalEmail', label: controlsPage?.rbtPersonalEmail?.value },
      ],
      className: 'select2',
      style: 'radius'
    }

  ]);
  campForm[10] = Object.assign(campForm[10], { disable: changeCheck })

  /************************
   * @methods
   ************************/
  const methodsList = {
    getDataItem: (object) => {
      const stateList = new Lists(itemsAttribute, campForm);
      setIdAttributeSelect(object);
      const stateCurrent = stateList.updateCurrent(object);
      setCampForm(stateCurrent.object);
      setDeleteD(stateCurrent.delete);
      setEdit(stateCurrent.edit);

    },
    voidCamps: () => {
      const stateList = new Lists(itemsAttribute, campForm);
      setCampForm(stateList.clearCamps());
    },
  };
  const editacion = (object) => {
    if (!edit) {
      setchangeCheck(object[0] ? !object[0].isEditable : [])
    }
    else {
      setchangeCheck(true)
    }
  }
  useEffect(() => {
    if (Object.entries(controlsPage).length > 0) {
      methosRequests.getItems(filter)
    }

  }, [filter, controlsPage])
  const methosRequests = {
    getItems: async (filter) => {
      handleLoadingData()
      const response = await Requests.getItem({ param: filter, tenant: clientName })
      setItemsAttribute(response.data);
      setAttributeData(response.data.data)
      const {
        currentPage,
        pageZise,
        totalCount,
        totalPages
      } = response.data.meta;
      setPagination({
        currentPage: currentPage,
        pageSize: pageZise,
        totalItems: totalCount,
        totalPages: totalPages
      })
      const responseLanguages = await requestsLanguages.getItem({ param: filter, tenant: clientName })
      let arrLanguages = responseLanguages.data?.data?.map(optionLanguage => {
        let optSchema = { key: optionLanguage.id, value: optionLanguage.id, text: optionLanguage.name }
        return optSchema
      })
      setOptLanguage(arrLanguages)
      const responseSchema = await requestsSchemas.getItem({ param: filter, tenant: clientName })
      setItemsSchema(responseSchema.data);
      setSchemaData(responseSchema.data.data)
      const responseAttributeScim = await requestsAttributeScim.getItem({ param: filter, tenant: clientName })
      setItemsAttributeScim(responseAttributeScim.data);
      setAttributeDataScim(responseAttributeScim.data.data)
      handleLoadedData();
    },
    crateItem: (dataForm) => {
      if (dataForm.attributeId === 'isPhone') {
        dataForm = Object.assign(dataForm, {
          maxLength: dataForm.isEditable ? maxL : null,
          name: rename,
          description: description,
          isPhone: true,
          idAttributeSCIM: attribScim,
          idLanguage: languageId,
          idSchema: schemaType,
          sendNotification: sendNotification
        });
        Requests.createItem({
          body: dataForm, ct: "json", tenant: clientName,
          infoModal: {
            title: controlsPage?.languageControl.lblSuccessfulProcess?.value,
            description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          },
          op: "b"
        })

      } else if (dataForm.attributeId === 'isInstitutionalEmail') {
        dataForm = Object.assign(dataForm, {
          maxLength: dataForm.isEditable ? maxL : null,
          name: rename,
          description: description,
          isInstitutionalEmail: true,
          idAttributeSCIM: attribScim,
          idLanguage: languageId,
          idSchema: schemaType,
          sendNotification: sendNotification
        });
        Requests.createItem({
          body: dataForm, ct: "json", tenant: clientName,
          infoModal: {
            title: controlsPage?.lblSuccessfulProcess?.value,
            description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          },
          op: "b"
        })
      } else if (dataForm.attributeId === 'isPersonalEmail') {
        dataForm = Object.assign(dataForm, {
          maxLength: dataForm.isEditable ? maxL : null,
          name: rename,
          description: description,
          isPersonalEmail: true,
          idAttributeSCIM: attribScim,
          idLanguage: languageId,
          idSchema: schemaType,
          sendNotification: sendNotification
        });
        Requests.createItem({
          body: dataForm, ct: "json", tenant: clientName,
          infoModal: {
            title: controlsPage?.lblSuccessfulProcess?.value,
            description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          },
          op: "b"
        })
      } else {
        dataForm = Object.assign(dataForm, {
          maxLength: dataForm.isEditable ? maxL : null,
          name: rename,
          description: description,
          idAttributeSCIM: attribScim,
          idLanguage: languageId,
          idSchema: schemaType,
          sendNotification: sendNotification
        });
        Requests.createItem({
          body: dataForm, ct: "json", tenant: clientName,
          infoModal: {
            title: controlsPage?.lblSuccessfulProcess?.value,
            description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          },
          op: "b"
        })
      }
    },
    updateItem: async (dataForm, id) => {
      if (dataForm.attributeId === 'isPhone') {
        dataForm = Object.assign(dataForm, {
          maxLength: dataForm.isEditable ? maxL : null,
          name: rename,
          description: description,
          idLanguage: languageId,
          isPhone: true,
          idAttributeSCIM: attribScim,
          idSchema: schemaType,
          sendNotification: sendNotification
        });
        Requests.confirmRequest({
          body: dataForm,
          id: id,
          ct: "json",
          tenant: clientName,
          option: 'updateItem',
          infoModal: {
            title: controlsPage?.lblSuccessfulProcess?.value,
            description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          },
          op: "b"
        })
      } else if (dataForm.attributeId === 'isInstitutionalEmail') {
        dataForm = Object.assign(dataForm, {
          maxLength: dataForm.isEditable ? maxL : null,
          name: rename,
          description: description,
          isInstitutionalEmail: true,
          idLanguage: languageId,
          idAttributeSCIM: attribScim,
          idSchema: schemaType,
          sendNotification: sendNotification
        });

        Requests.confirmRequest({
          body: dataForm,
          id: id,
          ct: "json",
          tenant: clientName,
          option: 'updateItem',
          infoModal: {
            title: controlsPage?.lblSuccessfulProcess?.value,
            description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          },
          op: "b"
        })
      } else if (dataForm.attributeId === 'isPersonalEmail') {
        dataForm = Object.assign(dataForm, {
          maxLength: dataForm.isEditable ? maxL : null,
          name: rename,
          description: description,
          isPersonalEmail: true,
          idLanguage: languageId,
          idAttributeSCIM: attribScim,
          idSchema: schemaType,
          sendNotification: sendNotification
        });

        Requests.confirmRequest({
          body: dataForm,
          id: id,
          ct: "json",
          tenant: clientName,
          option: 'updateItem',
          infoModal: {
            title: controlsPage?.lblSuccessfulProcess?.value,
            description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          },
          op: "b"
        })
      } else {
        dataForm = Object.assign(dataForm, {
          maxLength: dataForm.isEditable ? maxL : null,
          name: rename,
          description: description,
          idLanguage: languageId,
          idAttributeSCIM: attribScim,
          idSchema: schemaType,
          sendNotification: sendNotification
        });

        Requests.confirmRequest({
          body: dataForm,
          id: id,
          ct: "json",
          tenant: clientName,
          option: 'updateItem',
          infoModal: {
            title: controlsPage?.lblSuccessfulProcess?.value,
            description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          },
          op: "b"
        })
      }
    },

    deleteItem: () => {
      idAttributeSelect.forEach((element) => Requests.confirmRequest({
        id: element.id, tenant: clientName,
        infoModal: {
          title: controlsPage?.lblDisabled?.value,
          description: controlsPage?.lblItHbeenSuccessfullyDisabled?.value
        },
        option: 'deleteItem',
        op: "b"
      }));
    },


  };
  /************************
   * @hooks
   ************************/

  /************************
   * @resturns
   ************************/
  if (itemsAttribute === null) {
    return <ContainerBaseLoading />;
  }
  const numberPage = CountPages(itemsAttribute.meta.totalPages);
  return (
    <>
      <SubMenu
        contentcontrols={
          !deleteD && !edit ? [
            {
              text: controlsPage?.OnClickclikNewAttribute?.value,
              iconProps: { iconName: "Add" },
              onClick: () => {
                methodsList.voidCamps();
                openPanel2();
              }
            }
          ]
            :
            [
              edit ? {
                text: controlsPage?.OnClickEditAttribute?.value,
                iconProps: { iconName: "Edit" },
                onClick: () => openPanel2()
              } : null,
              {
                text: controlsPage?.OnClickDisableAttribute?.value,
                iconProps: { iconName: "delete" },
                onClick: () => methosRequests.deleteItem()
              }
            ].filter(obj => obj !== null)
        }
        contentcontrolsTwo={
          <>
            <Panel
              isOpen={isOpen}
              closeButtonAriaLabel="Close"
              isHiddenOnDismiss={true}
              type={PanelType.largeFixed}
              hasCloseButton={false}
            >
              {isOpen ? (
                <ContainerLargeForm
                  dataForm={(e) => {
                    edit
                      ? methosRequests.updateItem(e, idAttributeSelect[0].id)
                      : methosRequests.crateItem(e);
                  }}
                  formTitle={edit ? controlsPage?.lblEditAttribute?.value : controlsPage?.lblNewAttribute?.value}
                  idAttribute={idAttributeSelect[0]}
                  Items={campForm}
                  closePanel={dismissPanel2}
                  changeFontValue={changeFontValue}
                  changeCheckValue={changeCheckValue}
                  changeCheck={changeCheck}
                  optionSelected={optionSelected}
                  actionOnChange={actionOnChange}
                  onSubmitEventFormPanel={() => { }}
                  isDisableOpt={isDisable}
                  defaultValueInput={defaultValueInput}
                  defaultSelectedKeys={defaultSelectedKeys}
                  buttonDisable={buttonDisable}
                  controlsPage={controlsPage}
                />
              ) : (
                ""
              )}
            </Panel>
          </>
        }
      />
      <div className="admin--body-container">
        <ListHeader
          showReport={false}
          subRute={controlsPage?.lblUrlAdminitrationConfigAttribute.value}
          subRute3={controlsPage?.lblAttributeManagement.value}
          title={controlsPage?.lblAttributeManagement.value}
          hideSearch={true}
          onChange={(e) => {
            setFilter({
              ...filter,
              pageSize: e.target.value,
              pageNumber: filter.pageNumber === "0" ? "0" : "1"
            });
          }}
          InputChange={(e) => {
            setFilter({
              ...filter,
              search: e,
              pageNumber: filter.pageNumber === "0" ? "0" : "1"
            })
          }}
        />
        <DetailsListBext
          enableShimmer={isLoadingData}
          listKey="listSelect"
          selectionMode={1}
          items={itemsAttribute.data}
          renderItemColumn={_defaultRenderItemColumn}
          columnTitles={columnTitles}
          onSelect={(e) => { methodsList.getDataItem(e); editacion(e) }}
          onPageChange={page => {
            if (page !== +filter.pageNumber) {
              setFilter({
                ...filter,
                pageNumber: String(page),
              });
            }
          }}
          pagination={pagination}
        />
      </div>
    </>
  );
};

export default Attributes;