import React, {useContext, useEffect, useState} from "react";

import {DefaultButton} from "@fluentui/react/lib/Button";
import {mergeStyles} from "@fluentui/react/lib/Styling";
import {FontIcon} from "@fluentui/react/lib/Icon";
import {useBoolean} from "@fluentui/react-hooks";

import {RequestFetch} from "../../../utils/Requests/requests.Controller";
import LenguajeContext from "../../../context/Lenguaje/lenguajeContext";

const iconStyles = mergeStyles({
    width: 25,
    height: 25,
    fontSize: 25,
    margin: '0 10px',
});

const MassiveAssignment = ({
                               onCancel,
                               clientName
                           }) => {

    const usersMetadataRequest = new RequestFetch('Users/metadata');
    const usersPostRequest = new RequestFetch('Users/Post');
    const [selectedFile, setSelectedFile] = useState();
    const [controlsPage, setControlsPage] = useState({});
    const [textOnPages, setTextOnPages] = useState({})
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
    const [isDownloadingDocument, {
        setTrue: handleDownloadingDocument,
        setFalse: handleDownloadedDocument
    }] = useBoolean(false);

    const [isUploadingDocument, {
        setTrue: handleUploadingDocument,
        setFalse: handleUploadedDocument
    }] = useBoolean(false);

    const _handleOnCancel = () => {
        onCancel();
    }

    useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            //console.log(stateLanguage);
            stateLanguage.lenguaje.map((pass) => {
              // console.log(pass)
                if (pass.path == 'controls'|| pass.path =='domain') {
                  pass.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  })    
                  setControlsPage(controlsPage)
                }              
            })          
        }
      }, [textOnPages, stateLanguage])


    const _handleDownloadDocument = async () => {
        await requests.getDocument();
    }

    const _handleSubmitForm = () => {
        const formData = new FormData();
        formData.append("fileMetaData", selectedFile);
        requests.uploadDocument(formData);
    }

    const requests = {
        getDocument: async () => {
            handleDownloadingDocument()
            const response = await usersMetadataRequest.getFile({
                tenant: clientName,
                filename: "UsersLicensesMetadata.xlsx"
            });
            handleDownloadedDocument();
            return response;
        },
        uploadDocument: async (bodyFormData) => {
            handleUploadingDocument()
            const response = await usersPostRequest.createItem(
            {
                body:bodyFormData,
                ct: "form" ,
                tenant:clientName,
                infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                }
            });
            handleUploadedDocument();
            return response;
        }

    }

    return (
        <form className="container--flex c--flex-dc">
            <div className="mg10-h">
                <h1>Asignación y/o Denegación masiva de licencia</h1>
            </div>
            <div className="mg10-h container--flex c--flex-dc bkgn--base pg8">
                <h3 className="mg10-h">Seleccione</h3>

                <label className="mg5-h">Descargar</label>
                <div className="container--flex c--flex-hc">
                    <DefaultButton
                        id="file-download"
                        text={<b>{isDownloadingDocument ? 'Descargando...' : 'Descargar Formato'}</b>}
                        onClick={_handleDownloadDocument}
                        className="button--primary clr--light-III w3 container--flex c--flex-wc"
                        disabled={isDownloadingDocument}>

                    </DefaultButton>

                    <FontIcon aria-label="Compass" iconName="DownloadDocument" className={iconStyles}/>
                </div>
                <div className="spinner"/>

                <h3 className="mg5-h">Importar</h3>
                <div className="container--flex c--flex-hc">
                    {
                        selectedFile ?
                            <p className="clr--dark-III"><b>{selectedFile.name}</b></p>
                        :
                        <>
                            <label
                                htmlFor="file-upload"
                                className="button--primary container--flex clr--light-III w3 h2 c--flex-wc  c--flex-hc"
                            >
                                <b>{isUploadingDocument ? 'Cargando...' : 'Inportar datos'}</b>

                            </label>
                            <FontIcon aria-label="Compass" iconName="DocumentSearch" className={iconStyles}/>
                            <input className="hide" id="file-upload" type="file"
                                   onChange={e => setSelectedFile(e.target.files[0])}/>
                        </>

                    }

                </div>

            </div>

            <div className="c--flex-dc">
                <button className="button--primary clr--light-III w3 h2" type="button" onClick={_handleSubmitForm}>GUARDAR</button>
                <DefaultButton className="button--second w3 mg10-w" onClick={_handleOnCancel}>CANCELAR</DefaultButton>
            </div>

        </form>
    )
}

export default MassiveAssignment;
