import React, {useContext, useEffect, useState} from "react";
import {useBoolean} from "@fluentui/react-hooks";
import {useParams} from "react-router-dom";

import ListHeader from "../../../components/lists/listHeader/listHeader.component";
import DetailsListBext from "../../../components/lists/DetailsList/detailsList.component";
import {RequestFetch} from "../../../utils/Requests/requests.Controller";

import {USER_ROLE_ASSIGNMENT_TITLE, USER_SELECTED_ROLE_ASSIGNMENT_TITLE} from "./constants";
import {ActionButton, DefaultButton} from "@fluentui/react/lib/Button";
import LenguajeContext from "../../../context/Lenguaje/lenguajeContext";
import { changeLang, changeLangForm } from "../../../utils/logicRendering/changeIdiom";

const UserRole = ({domainForm, userId, controlsPage}) => {
    const usersRequest = new RequestFetch('Users');

    const {clientName} = useParams();
    const [userSelected, setUserSelected] = useState(null);
    const [filtersList, setFiltersList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [columnTitles, setColumnTitles] = useState(USER_ROLE_ASSIGNMENT_TITLE)
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
    const [filter, setFilter] = useState({
        pageSize: 5,
        pageNumber: "0",
        idDomain: ""
    });

    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);


    useEffect(() => {
        if (filter.pageNumber > 0) {
            requests.getUsers(filter, clientName);
        }
    }, [filter])

    useEffect(async () => {
        if(userId){
            const user = await requests.getUserById(userId, clientName);
            if(user.data.data){
                setUserSelected(user.data.data);
            }
        }
    }, []);

    useEffect(() => {
        setColumnTitles(current => changeLang(current, controlsPage))
    }, [controlsPage])
    

    useEffect(() => {
        if (domainForm) {
            setFiltersList(changeLangForm([
                {
                    id: "search-value",
                    key: 'searchValue',
                    type: 'text',
                    label: '',
                    idHtml: "txtUserName"
                },
                {
                    id: "domain-select",
                    key: 'idDomain',
                    label: '',
                    type: 'select',
                    placeholder: "slcSelectDomain",
                    idHtmlPlaceholder: "slcSelectDomain",
                    idHtml:'slcSelectDomain',
                    options: domainForm.map(
                        domain => ({
                            id: domain.id,
                            text: domain.alias
                        })
                    )
                }
            ],controlsPage));
        }
    }, [domainForm, controlsPage])


    const _selectUser = (item) => {
        setUserSelected(item);
    }

    const _defaultRenderItemColumn = (item, index, column) => {
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case 'actions':
                return (
                    <DefaultButton
                        className="button--primary clr--light-III w3 mg10-w"
                        onClick={() => _selectUser(item)}
                        text="Seleccionar"/>
                );

            default:
                return <span>{fieldContent}</span>;
        }
    }

    const requests = {
        getUsers: async (filters, tenant) => {
            setPagination(null);
            handleLoadingData()
            const response = await usersRequest.getItem({param: filters, tenant});
            let idNumber = 0;
            setUsersList(
                response.data.data.map(
                    user => {
                        idNumber++;
                        return (
                            {
                                ...user,
                                domain: domainForm.find(domain => domain.id === user.idDomain).alias || "",
                                idNumber,
                                actions: ""
                            }
                        )
                    }
                )
            );
            const {
                currentPage,
                pageZise,
                totalCount,
                totalPages
            } = response.data.meta;
            setPagination({
                currentPage: currentPage,
                pageSize: pageZise,
                totalItems: totalCount,
                totalPages: totalPages
            });
            handleLoadedData();
        },

        getUserById: async (id, tenant) => {
            return await usersRequest.getItemById({id, tenant});
        }
    }

    return (
        <>
            <div className="admin--body-container">
                {
                    userSelected ? (
                        <>
                            <div className="h1"/>
                            <h2 className="txt--prymary mg4-h">Datos de usuario</h2>
                            <div className="c--flex-dc clr--dark-II mg10-w">
                                <p>Nombre: <span className="clr--dark-III">{userSelected.username}</span></p>
                                <p>Dominio: <span
                                    className="clr--dark-III">{domainForm.find(domain => domain.id === userSelected.idDomain).alias || ""}</span>
                                </p>
                                <p>Correo: <span className="clr--dark-III">{userSelected.institutionalEmail}</span></p>
                                { /*TODO: agregar cargo*/}
                            </div>
                            <h2 className="txt--prymary mg4-h">Asignar roles</h2>
                            <UserRoleAssignmentListRoles domains={domainForm} user={userSelected}
                                                         clientName={clientName}/>
                        </>
                    ) : (
                        <>
                        <div className="w10">
                            <ListHeader
                                showReport={false}
                                filters={filtersList}
                                title={controlsPage?.lblAssignRoleUserAccount.value}
                                hideSearch={true}
                                hideVisit={true}
                                onChange={(event) => {
                                    setFilter({
                                        ...filter,
                                        pageSize: event.target.value,
                                        pageNumber: filter.pageNumber === "0" ? "0" : "1"
                                    });
                                }}
                                dataForm={({idDomain, searchValue, statusValue}) => {
                                    setFilter({
                                        ...filter,
                                        pageNumber: "1",
                                        idDomain,
                                        // TODO: add status to the filter when the service supports it : {statusValue}
                                        search: searchValue
                                    });
                                }}
                            />
                            <DetailsListBext
                                enableShimmer={isLoadingData}
                                listKey="userRoleAssignmentDetailsList"
                                selectionMode={0}
                                items={usersList}
                                columnTitles={columnTitles}
                                renderItemColumn={_defaultRenderItemColumn}
                                onPageChange={page => {
                                    if (page !== +filter.pageNumber) {
                                        setFilter({
                                            ...filter,
                                            pageNumber: String(page),
                                        });
                                    }
                                }}
                                pagination={pagination}
                            />
                            </div>
                        </>
                    )

                }
            </div>


        </>
    );
}

const UserRoleAssignmentListRoles = (
    {
        user,
        clientName
    }
) => {
    const rolesRequest = new RequestFetch('Roles');
    const userRoleRequest = new RequestFetch('User_Role');

    const [rolesList, setRolesList] = useState([]);
    const [usersRoleList, setUsersRoleList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [roleSelected, setRoleSelected] = useState("");
    const [controlsPage, setControlsPage] = useState({});
    const [textOnPages, setTextOnPages] = useState({})
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
    const [filter, setFilter] = useState({
        pageSize: 10,
        pageNumber: "0"
    });
    const [loadingRolesList, setLoadingRolesList] = useState(true);

    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);


    useEffect(async () => {
        await requests.getRoles(clientName);
    }, []);

    useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            //console.log(stateLanguage);
            stateLanguage.lenguaje.map((pass) => {
              // console.log(pass)
                if (pass.path == 'controls'|| pass.path =='domain' || pass.path =='assign-roles') {
                  pass.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  })    
                  setControlsPage(controlsPage)
                }              
            })          
        }
      }, [textOnPages, stateLanguage])
      

    useEffect(async () => {
        if(!loadingRolesList) {
         _loadUserRoles();
        }
    }, [loadingRolesList]);

    const _loadUserRoles = () => {
        requests.getUserRoles(filter, clientName);
    }

    const _handleRoleChange = (event) => {
        setRoleSelected(event.target.value);
    }

    const _handleAddRole = () => {
        if(roleSelected){
            requests.addRole({
                idRole: roleSelected,
                idUser: user.id,
                enable: true,
            })
        }
    }

    const _handleRemoveRole = (role) => {
        requests.removeRole(role.id);
    }

    const requests = {
        getRoles: async (tenant) => {
            setPagination(null);
            const response = await rolesRequest.getItem({tenant});
            setRolesList(
                response.data.data.map(
                    role => (
                        {
                            id: role.id,
                            text: role.name
                        }
                    )
                )
            );
            setLoadingRolesList(false);
        },
        getUserRoles: async (filters, tenant) => {
            setPagination(null);
            handleLoadingData();
            const response = await userRoleRequest.getItem({
                param: {
                    ...filters,
                    idUser: user.id
                },
                tenant
            });
            if(response.data){
                let idNumber = 0;
                setUsersRoleList(
                    response.data.data.map(
                        userRole => {
                            idNumber ++;
                            return (
                                {
                                    username: user.username,
                                    date: userRole.creatingDate,
                                    role: rolesList.find(role => role.id === userRole.idRole).text || "No encontrado",
                                    actions: '',
                                    id: userRole.id,
                                    idNumber
                                }
                            )
                        }
                    )
                );
                const {
                    currentPage,
                    pageZise,
                    totalCount,
                    totalPages
                } = response.data.meta;
                setPagination({
                    currentPage: currentPage,
                    pageSize: pageZise,
                    totalItems: totalCount,
                    totalPages: totalPages
                });

            } else {
                setUsersRoleList([]);
            }

            handleLoadedData();
        },
        addRole: async (body) => {
            await userRoleRequest.createItem({
                body,
                ct: 'json',
                tenant: clientName,
                infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                }
            });
        },
        removeRole: async(id) => {
            await userRoleRequest.confirmRequest({id, tenant: clientName, option:'deleteItem',
            infoModal:{
                title:controlsPage?.lblDisabled?.value, 
                description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
            }
            })
        }
    }

    const _defaultRenderItemColumn = (item, index, column) => {
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case 'actions':
                return (
                    <ActionButton
                        text=""
                        iconProps={{iconName: 'Cancel'}}
                        onClick={_ => {
                            _handleRemoveRole(item);
                        }}
                    />
                );

            default:
                return <span>{fieldContent}</span>;
        }
    }

    return <>
        <div className="h1"/>
        <div className="container--flex c--flex-hc mg5-w">
            <label className="mg10-w">Rolc</label>
            <select name="rol-select" className="clr--base bdr--base mg10-w w3" onChange={_handleRoleChange}>
                <option value="">{controlsPage?.slcSelectRole.value}</option>
                {rolesList.map(option=>
                    <option key={option.id} value={option.id}>{option.text}</option>
                )}
            </select>
            <DefaultButton
                disabled={roleSelected === ""}
                className="button--primary clr--light-III w2 mg10-w"
                onClick={_handleAddRole}
                text="Agregar rol"/>
        </div>
        <div className="h2"/>
        <DetailsListBext
            enableShimmer={isLoadingData}
            listKey="userRoleAssignmentDetailsListRoles"
            selectionMode={0}
            items={usersRoleList}
            columnTitles={USER_SELECTED_ROLE_ASSIGNMENT_TITLE}
            renderItemColumn={_defaultRenderItemColumn}
            onPageChange={page => {
                if (page !== +filter.pageNumber) {
                    setFilter({
                        ...filter,
                        pageNumber: String(page),
                    });
                }
            }}
            pagination={pagination}
        />
    </>
}

export default UserRole;
