import React, { useState, useEffect, useMemo, useContext } from "react";
import { HexColorPicker, RgbColor } from "react-colorful";
import { RequestFetch } from "../../../utils/Requests/requests.Controller";
import InputFile from "../../Forms/inputFile/inputFile.component";
import ConvColors from "../../../utils/logicRendering/convColor.controller";
import Select from "react-select";
import LargeFormInput from "./components/LargeFormInput";
import LargeFormCheckbox from "./components/LargeFormCheckbox";
import LargeFormDate from "./components/LargeFormDate";
import LargeFormSelect from "./components/LargeFormSelect";
import LargeFormPickers from "./components/LargeFormPickers";
import { useParams } from "react-router-dom";
import LargeFormSlider from "./components/LargeFormSlider";
import LargeFormToggle from "./components/LargeFormToggle";
//import { Form} from '@fluentui/react-northstar';

import { SearchableDropdown, IStackTokens, Stack, Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption, ThemeProvider, initializeIcons, SearchBox, Slider, Label, FontIcon, mergeStyles } from "@fluentui/react"
import LargeFormMaskedTextField from "./components/LargeFormMaskedTextField";
import LargeFormButtonTable from "./components/LargeFormTable";
import LargeFormTable from "./components/LargeFormTable";
// import LargeFormTable2 from "./components/LargeFormTable2";
import LargeFormButton from "./components/LargeFormButton";
import LargeFormLabel from "./components/LargeFormLabel";
import RadioButton from "./components/LargeFormRadioButton";
import { render } from "@testing-library/react";
import ModalFluent from "../../tools/modalFluent/ModalFluent";
import LenguajeContext from "../../../context/Lenguaje/lenguajeContext";

const iconClass = mergeStyles({
  fontSize: 8,
  height: 8,
  width: 8,
  margin: '0',
  position: 'absolute',
  color: 'var(--color-primary)'
});
const SelectField = (item, stateWidth, props) => {
  const [check, setCheck] = useState({ boxname: '', boxstatus: false })
  const [isCheck, setIsCheck] = useState(props.Items[6] && item.id === 8 && item.key === 'maxLength' ? !props.Items[6].defaultValue : false)
  useEffect(() => {
    if (props.changeCheck) {
      setCheck(props.changeCheck)
    }
  }, [props.changeCheck])

  useEffect(() => {
    if (check.boxstatus == true && item.id === 8 && item.key === 'maxLength' && check.boxname === 'isEditable') {
      setIsCheck(false)
    } else if (check.boxstatus == false && item.id === 8 && item.key === 'maxLength' && check.boxname === 'isEditable') {
      setIsCheck(true)
    }
  }, [check.boxstatus])
  let options = item.options;
  const enviaProps = (dta) => {
    props.selectedKey(dta)
  }
  const functionTest = () => { }
  return (
    <div
      key={item.id}
      className={`form--content-select ${stateWidth < 300 ? "w10" : "w4"}`}
    >
      <div className="icon-position">
        <label>{item.label}
        </label>
        {item.required ? <FontIcon aria-label="requerido" iconName="SkypeCircleCheck" className={iconClass} /> : undefined}</div>
      <select defaultValue={item.defaultValue} name={item.key} disabled={isCheck} required onChange={item.action ? (e) => enviaProps(e.target.value) : () => functionTest()}>
        <option value="">Seleccione...</option>
        {options.map((opt) => (
          <option key={opt.value} value={opt.value} required>
            {opt.text}
          </option>
        ))}
      </select>
    </div>
  );
};

const SpaceDiv = (item) => {
  return (
    <div className={`${item.item.className}`}>
      {item.item.component ? item.item.component : undefined}
    </div>
  )
}
const sliderItem = (item, stateWidth) => {

  const style = {
    activeSection: {
      backgroundColor: 'green',
      hover: 'yellow'
    },
    line: {
      color: 'red',
    },
    hover: {
      backgroundColor: 'green',
    }
  }
  return (
    <div
      key={item.id}
      className={`${stateWidth < 300 ? "w10" : "w4"}`}
    >

      <Slider
        label={item.label}
        min={item.min}
        max={item.max}
        defaultValue={item.defaultValue ? item.defaultValue : 0}
        styles={style}
        className={''}
      />

    </div>
  )
}

const SelectSearch = (item, stateWidth, props) => {
  const [optSelected, setOptSelected] = useState({ key: '', text: 'Seleccione' })
  const [dropdownValue, setDropdownValue] = useState(null)
  const onChange = (e, item) => {
    setDropdownValue(item.key)
    setOptSelected(item)
  };
  const iconClass = mergeStyles({
    fontSize: 8,
    height: 8,
    width: 8,
    margin: '0',
    position: 'absolute',
    color: 'var(--color-primary)'
  });
  useEffect(() => {
    props.optionSelected(optSelected.key)
  }, [optSelected])

  useEffect(() => {
    if (item.defaultValue) {
      setDropdownValue(item.defaultValue)
    } else if (item.defaultValu) {
      setDropdownValue(item.defaultValu)
    }
  }, [item])

  const dropdownStyles = {
    dropdown: {
      width: '100%',
      height: '38px',
    },
    title: { height: '38px', borderRadius: '4px', borderColor: '#cccccc', paddingTop: '4px' },
    caretDownWrapper: { paddingTop: '5px' }
  };
  const SearchableDropdown = (props) => {
    const [searchText, setSearchText] = useState('');

    function renderOption(option) {
      return (option.itemType === DropdownMenuItemType.Header && option.key === "FilterHeader") ?
        <SearchBox onChange={(ev, newValue) => setSearchText(newValue)} underlined={true} placeholder={item.placeholder3} /> : <>{option.text}</>;
    }
    const Options = [
      { key: 'FilterHeader', text: '-', itemType: DropdownMenuItemType.Header },
      { key: 'divider_filterHeader', text: '-', itemType: DropdownMenuItemType.Divider },
      ...props.options.map(option => !option.disabled && option.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ?
        option : { ...option, hidden: true }
      ),
    ]
    const styleSelect = {
      errorMessage: {
        background: '#F5D4D4 0% 0% no-repeat padding-box',
        'border-radius': '4px',
        padding: '7px 7px',
        'margin-top': '2px',
        color: 'var(--color-danger)',
        ':before': {
          content: "",
          position: 'absolute',
          left: '10%',
          top: '-20px',
          width: 0,
          height: 0,
          'border-left': '13px solid blue',
          'border-top': '26px solid red',
          'border-right': '13px solid green'
        }

      }
    }
    return (
      <Dropdown
        {...props}
        options={Options}
        calloutProps={{ shouldRestoreFocus: false, setInitialFocus: false }} //not working
        onRenderOption={renderOption}
        placeholder={item.placeholder2}
        onDismiss={() => setSearchText('')}
        onChange={onChange}
        styles={styleSelect}
        errorMessage={!dropdownValue ? 'Debe seleccionar una opción' : undefined}
      //defaultSelectedKey={item.de}
      />
    );

  };
  let optionSelect = []
  const options = item.options
  options.map(option => {
    if (option.idFather) {
      option = Object.assign(option, { itemType: DropdownMenuItemType.Header })
      optionSelect.push({ key: option.name, text: '-', itemType: DropdownMenuItemType.Divider })
      optionSelect.push(option)
    }
    if (!option.idFather) {
      let opt = { key: option.key, text: option.type ? option.text + ' - ' + option.type : option.text }
      optionSelect.push(opt)
    }
  })

  return (
    <div className={`select2`}>
      <div className='topComponent'><Label>{item.label ? <div style={{ position: "relative" }}> <span>{item.label}</span>{item.isRequired || item.required ? <FontIcon aria-label="requerido" iconName="SkypeCircleCheck" className={iconClass} /> : ''} </div> : ""}</Label></div>
      <SearchableDropdown
        placeholder={optSelected ? optSelected.text : 'Seleccione'}
        options={optionSelect}
        styles={dropdownStyles}
        multiSelect={false}
        defaultSelectedKey={item.defaultValue}
      />
    </div>
  );


};

const Boxcheck = (item, stateWidth, props) => {
  //if(item.id == 6 && item.defaultValue == true){


  //}
  const [checked, setChecked] = useState(item.defaultValue);

  const camp = (e) => {
    setChecked(e.target.checked);
    let box = {
      boxname: e.target.name,
      boxstatus: e.target.checked
    }
    props.changeCheckValue(box);

  };

  let id = item.id;
  return (
    <div
      key={item.id}
      className={`form--content-select ${stateWidth < 300 ? "w10" : "w4"} ${item.className}`}
    >
      <input
        onChange={camp}
        id={id}
        name={item.key}
        type="checkbox"
        checked={checked ? checked : false}
        value={checked ? checked : false}
      />
      {item.label}
    </div>
  );
};




const ListRadious = ({ item, stateWidth, props }) => {
  const [selectFont, setSelectFont] = useState(item.defaultValue);
  const camp = (e) => {
    setSelectFont(e.target.value);
    props.changeFontValue(e.target.value);
  };
  let options = item.options;
  let valueFontDef = selectFont;

  if (item.key != "primaryFont") {
    return (
      <div className={`form--content-select ${item.className} ${stateWidth < 300 ? "w10" : "w4"}`}>
        {item.key == "attributeId"?
          <div className="ms-label label root-212"><span>{item.label}</span></div>
        :
          <h6 className="w10 clr--primary txt--secondary">{item.label}</h6>
        }{options.map((opt) => (
          <label key={opt.id} className={`container--flex ${opt.center ? "c--flex-wc" : ""} gg`}>
            {item.img
              ? <div className="w10 h4 mg8-h bdr--light-I bkgn--light-III">
                <img src="" alt="imagen captcha" />
              </div>
              : ''}
            <input
              id={opt.value}
              name={item.key}
              type="radio"
              value={opt.value ? opt.value : opt.id}
              checked={selectFont == opt.value ? true : false}
              onChange={camp}
              required={item.required}
            />
            {opt.label}
          </label>
        ))}
      </div>
    );
  } else {
    return (
      <div
        className="form--content-radio container--flex c--flex-wsb w10"
        key={item.name}
      >
        <h6 className="w10 clr--primary txt--secondary">{item.title}</h6>
        {options.map((opt) => (
          <label
            key={opt.id}
            style={{ fontFamily: `${opt.label}` }}
            className="container--flex c--flex-wc"
          >
            <input
              name={item.key}
              type="radio"
              value={opt.label}
              checked={selectFont == opt.label ? true : false}
              onChange={camp}
              required={item.required}

            />
            <p className="w5">ABCD abcd 123456</p>
            <span className="w9">{opt.label}</span>
          </label>
        ))}
      </div>
    );
  }
};

const FileField = (props) => {
  if (props.item.documen) {
    let item = props.item;
    let stateWi = props.stateWidth
    return (
      <div
        className={`form--content-file container-flex c--flex-wsb ${stateWi < 300 ? "w10" : "w4"}`}
        key={item.name}
      >
        <h6 className="w10 clr--primary mg5-h txt--secondary">{item.title}</h6>
        <div className="W10 h7 mg8-h bdr--light-I bkgn--light-III">
          <img
            src={item.defaultValue ? item.defaultValue : props.Image}
            className="h7"
            alt=""
            onError="this.onerror=null;"
          />
        </div>
        <div className="container-flex">
          <input
            //accept=".png, .svg, .webp"
            type="file"
            name={item.key}
            required
            id={`inputFile${item.id}`}
            onChange={(e) => props.imageHandler(e, item.key)}
          />{" "}
          <label htmlFor={`inputFile${item.id}`}></label>
        </div>
      </div>
    );
  } else {

    const useUpdateImage = () => {
      const idTheme = props.props.idTheme.id;
      const Requests = new RequestFetch("Template");
      if (props.Image) {
        // if(props.typeFile== 'png' || props.typeFile== 'svg' || props.typeFile== 'webp' ){
          if (props.item.id === 4) {
            const imagen= props.Image.split(',');
            const dataImg = [
              {
                op: "replace",
                path: props.item.key,
                value: imagen[1],
              },
            ];
            const requestPatch = Requests.confirmRequest({
              body: dataImg,
              id: idTheme,
              ct: "json",
              tenant: props.tenant,
              option:'patchItem',
              op: "b",
              infoModal:{
              title:controlsPage?.lblSuccessfulProcess?.value,
              description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value}
            });
          }
          if (props.item.id === 5) {
            const imagen= props.Image.split(',');

            const dataImg = [
              {
                op: "replace",
                path: props.item.key,
                value: imagen[1],
              },
            ];
            const requestPatch = Requests.confirmRequest({
              body: dataImg,
              id: idTheme,
              ct: "json",
              tenant: props.tenant,
              option:'patchItem',
              op: "b",
              infoModal:{
              title:controlsPage?.lblSuccessfulProcess?.value,
              description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value}
            });
          }
        // }else{
        //   let info = {title:'error',description:'El archivo cargado no es valido',type:'danger'}
        //   render(<ModalFluent props={info} 
        //     // actionOnClick={actionOnClick} 
        //     // objectRequest={objectRequest}
        //     />,
        //     document.getElementById("modal")
        //   );
        // }
      } 
    };
    let item = props.item;
    const controlsPage = props.controlsPage
    if (item.defaultValue == "") {
      return (
        <div
          className="form--content-file container-flex c--flex-wsb w4"
          key={item.name}
        >
          <h6 className="w10 clr--primary mg5-h txt--secondary">{item.title}</h6>
          <div className="W10 h7 mg8-h bdr--light-I bkgn--light-III">
            <img
              src={item.defaultValue ? item.defaultValue : props.Image}
              className="h7"
              alt=""
              onError="this.onerror=null;"
            />
          </div>
          <div className="container-flex">
            <input
              accept=".png, .svg, .webp"
              type="file"
              name={item.key}
              required
              id={`inputFile${item.id}`}
              onChange={(e) => props.imageHandler(e, item.key)}
            />{" "}
            <label htmlFor={`inputFile${item.id}`}></label>
          </div>
        </div>
      );
    }
    else if(item.key == "logoPath") {

      return (
        <div
          className="form--content-file container-flex c--flex-wsb w4"
          key={item.name}
        >
          <h6 className="w10 clr--primary mg5-h txt--secondary">{item.title}</h6>
          <div className="W10 h7 mg8-h bdr--light-I bkgn--light-III">
            <img
              src={
                props.Image
                  ? props.Image
                  : props.item.defaultValue + "?" + Math.random(0, 10)
              }
              className="h7"
              alt=""
              onError="this.onerror=null;"
            />
          </div>
          <div className="container-flex">
            <input
              accept=".png, .svg, .webp"
              type="file"
              name={item.key}
              id={`inputFile${item.id}`}
              onChange={(e) => props.imageHandler(e, item.key)}
            />{" "}
            {props.showButtonLogo  &&
            <button type="button" className="actualiza" onClick={useUpdateImage}>
            {controlsPage?.smtUpdate?.value}
            </button>
            }
            <label htmlFor={`inputFile${item.id}`}></label>
          </div>
        </div>
      );
    }
    else if(item.key == "imageBackgroundPath") {

      return (
        <div
          className="form--content-file container-flex c--flex-wsb w4"
          key={item.name}
        >
          <h6 className="w10 clr--primary mg5-h txt--secondary">{item.title}</h6>
          <div className="W10 h7 mg8-h bdr--light-I bkgn--light-III">
            <img
              src={
                props.Image
                  ? props.Image
                  : props.item.defaultValue + "?" + Math.random(0, 10)
              }
              className="h7"
              alt=""
              onError="this.onerror=null;"
            />
          </div>
          <div className="container-flex">
            <input
              accept=".png, .svg, .webp"
              type="file"
              name={item.key}
              id={`inputFile${item.id}`}
              onChange={(e) => props.imageHandler(e, item.key)}
            />{" "}
            {props.showButtonBackground  &&
            <button type="button" className="actualiza" onClick={useUpdateImage}>
            {controlsPage?.smtUpdate?.value}
            </button>
            }
            <label htmlFor={`inputFile${item.id}`}></label>
          </div>
        </div>
      );
    }
    else {

      return (
        <div
          className="form--content-file container-flex c--flex-wsb w4"
          key={item.name}
        >
          <h6 className="w10 clr--primary mg5-h txt--secondary">{item.title}</h6>
          <div className="W10 h7 mg8-h bdr--light-I bkgn--light-III">
            <img
              src={
                props.Image
                  ? props.Image
                  : props.item.defaultValue + "?" + Math.random(0, 10)
              }
              className="h7"
              alt=""
              onError="this.onerror=null;"
            />
          </div>
          <div className="container-flex">
            <input
              accept=".png, .svg, .webp"
              type="file"
              name={item.key}
              id={`inputFile${item.id}`}
              onChange={(e) => props.imageHandler(e, item.key)}
            />{" "}
            <button type="button" className="actualiza" onClick={useUpdateImage}>
            {controlsPage?.smtUpdate?.value}
            </button>
            
            <label htmlFor={`inputFile${item.id}`}></label>
          </div>
        </div>
      );
    }
  }
};

const ColorField = (props) => {
  let item = props.item;
  const controlsPage = props.controlsPage
  const [color, setColor] = useState(props.color)
  const [colorTxt, setColorTxt] = useState(props.color)
  useEffect(() => {
    let regHexColor = /^#([0-9a-f]{3}){1,2}$/i;
    if (regHexColor.test(colorTxt)) {
      setColor(colorTxt)

      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorTxt);
      setRed(result ? parseInt(result[1], 16) : 0)
      setGreen(result ? parseInt(result[2], 16) : 0)
      setBlue(result ? parseInt(result[3], 16) : 0)
    }

  }, [colorTxt]);

  const _handleColorTxtValue = (item) => {
    setColorTxt(item.target.value);
  }

  useEffect(() => {
    if (item.defaultValue.length > 0) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(item.defaultValue);
      setRed(parseInt(result[1], 16))
      setGreen(parseInt(result[2], 16))
      setBlue(parseInt(result[3], 16))
    }
  }, []);


  const [red, setRed] = useState(item.defaultValue ? 0 : 30)
  const [green, setGreen] = useState(item.defaultValue ? 0 : 50)
  const [blue, setBlue] = useState(item.defaultValue ? 0 : 115)

  const defRed = (item) => {
    const newValue = item.target.value > 255 ? 255 : item.target.value;
    setRed(newValue);
    buildHexFromRgb(newValue, green, blue);
  }
  const defGreen = (item) => {
    const newValue = item.target.value > 255 ? 255 : item.target.value;
    setGreen(newValue);
    buildHexFromRgb(red, newValue, blue);
  }
  const defBlue = (item) => {
    const newValue = item.target.value > 255 ? 255 : item.target.value;
    setBlue(newValue);
    buildHexFromRgb(red, green, newValue);
  }

  const buildHexFromRgb = (redColor, greenColor, blueColor) => {
    const rgbToHex = (rgb) => {
      let hex = Number(rgb).toString(16);
      if (hex.length < 2) {
        hex = "0" + hex;
      }
      return hex.toUpperCase('');
    };

    const fullColorHex = (r, g, b) => {
      let red = rgbToHex(r);
      let green = rgbToHex(g);
      let blue = rgbToHex(b);
      return red + green + blue
    }
    const colorHexa = `#${fullColorHex(redColor, greenColor, blueColor)}`
    setColor(colorHexa);
    setColorTxt(colorHexa);
  }

  const _handleColorPicker = (colorSelected) => {
    setColor(colorSelected);
    setColorTxt(colorSelected);
  }

  return (
    <>
      <div id="lado" className="w4 container--flex c--flex-wsb" key={item.name}>
        <h6 className="w10 clr--primary mg5-h txt--secondary">{item.title}</h6>
        <HexColorPicker color={color} onChange={_handleColorPicker} />
        <div id="first" className="w2">
          {item.ranges.map((range) => (
            <div>
              <input
                key={range}
                type="color"
                className="mg3 wr2 h2"
                name={range.name}
                value={props.convColor(color, range.ran)}
                onClick={(event => event.preventDefault())}
              />
            </div>
          ))}
        </div>
        <div>
          <p className="pForm1">
            <label className="preview1">{controlsPage?.lblHex?.value}</label>
            <input
              className="inpe"
              type="text"
              color={props.color}
              onChange={_handleColorTxtValue}
              value={colorTxt}
            />
          </p>
          <p className="pForm">
            <label className="preview1">{controlsPage?.lblRed?.value}</label>
            <input
              className="inp"
              type="text"
              color={props.color}
              onChange={defRed}
              value={red}
            />
          </p>
          <p className="pForm">
            <label className="preview1">{controlsPage?.lblGreen?.value}</label>
            <input
              className="inp"
              type="text"
              color={props.color}
              onChange={defGreen}
              value={green}
            />
          </p>
          <p className="pForm">
            <label className="preview1">{controlsPage?.lblBlue?.value}</label>
            <input
              className="inp"
              type="text"
              color={props.color}
              onChange={defBlue}
              value={blue}
            />
          </p>
        </div>
      </div>
      <div id="lado" className="" key={item.name}>
        <h6 className="w10 clr--primary mg5-h txt--secondary">{controlsPage?.lblPreview?.value}</h6>
        <div id="second" className="w10 container--flex prev">
          <h6
            style={{ fontFamily: `${props.props.valueFont}` }}
            className="w10 clr--primary txt--secondary"
          >
            {controlsPage?.lblNewTheme?.value}
          </h6>
          {item.ranges.map((range) => {
            if (range.id === 7 || range.id === 9) {
              return (
                <div id="lado">
                  <div
                    className=" h1 W10 h2  bdr--light-I bkgn--light-III color_div color_boton"
                    style={{
                      backgroundColor: `${props.convColor(
                        color,
                        range.ran
                      )}`,
                    }}
                  >
                    {" "}
                    {controlsPage?.divText?.value}
                  </div>

                  <div
                    className=" h1 W10 h2  bdr--light-I bkgn--light-III color_div"
                    style={
                      ({
                        fontFamily: `${!props.props.valueFont
                          ? props.props.valueFont
                          : "Dosis"
                          }`,
                      },
                        { color: `${props.convColor(color, range.ran)}` })
                    }
                  >
                    {" "}
                    {controlsPage?.divText?.value}
                  </div>
                </div>
              );
            }
          })}
          <div className="c--flex-wsb">
            <p
              className="w4 preview"
              style={{ fontFamily: `${props.props.valueFont}` }}
            >
              {controlsPage?.lbl18Px?.value}
            </p>
            <p
              className=" preview"
              style={{ fontFamily: `${props.props.valueFont}` }}
            >
              Do aute veniam aute proident consequat dolor veniam labore.
            </p>
            <hr className="w9_5" color={`${props.convColor(color, 0.5)}`} />
            <p
              className="w4 preview1"
              style={{ fontFamily: `${props.props.valueFont}` }}
            >
              {controlsPage?.lbl12Px?.value}
            </p>
            <p
              className=" preview1"
              style={{ fontFamily: `${props.props.valueFont}` }}
            >
              Dolor exercitation id ipsum consequat reprehenderit consectetur
              sit.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const ContainerLargeForm = (props) => {
  const [stateWidth, setStateWidth] = useState();
  const [base64Fond, setBase64Fond] = useState();
  const [base64Logo, setBase64Logo] = useState();
  const [showButtonLogo, setShowButtonLogo] = useState(false);
  const [showButtonBackground, setShowButtonBackground] = useState(false);
  const [check, setCheck] = useState(false)
  const controlsPage = props.controlsPage
  const { clientName } = useParams()
  useEffect(() => {
    if (props.Items[6]) {
      if (props.Items[6].key === "isEditable" && props.Items[6].defaultValue === false) {
        setCheck(props.changeCheck)
      }
    }
  }, [check])

  const calcWidth = () => {
    setTimeout(() => {
      let stateW = document.querySelector(".form--container-body");
      setStateWidth(stateW.clientWidth);
    }, 200);
  };
  const [color, setColor] = useState(
    props.idTheme ? props.Items[4].defaultValue : "0C3273");

  const [colorState, setColorState] = useState(`#${color ? color : "0C3273"}`);
  const [extensionType, setExtensionType] = useState()

  const imageHandler = (e, type) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        if (type === "logoPath") {
          setShowButtonLogo(true)
          setBase64Logo(reader.result);
        } else {
          setBase64Fond(reader.result);
        }
      }
    };
    if (type == "imageBackgroundPath"){
      setShowButtonBackground(true)
    }
    reader.readAsDataURL(e.target.files[0]);
  };
  const onSubmitEvent = (event) => {
    const formData = new FormData(event.target);
    const user = {};
    event.preventDefault();

    for (let entry of formData.entries()) {
      user[entry[0]] = entry[1];
    }
    if (props.keyForm == "themes") {
      if (user.logoPath.name === "") {
        user.logoPath = "";
        props.dataForm(user);
      } else {
        const base64Log = base64Logo.split(',');
        user.logoPath = base64Log[1];
        //user.logoBase64 = base64Log[1];
        // props.dataForm(user);
      }
      if (user.imageBackgroundPath.name === "") {
        user.imageBackgroundPath = "";
        props.dataForm(user);
      } else {
        const base64Fondo = base64Fond.split(',');
        user.imageBackgroundPath = base64Fondo[1];
        //user.imageBackgroundBase64 = base64Fondo[1];
        props.dataForm(user);
      }
    }else{
    props.dataForm(user);
    props.onSubmitEventFormPanel(event);
  }
  };
  //SelectSearch
  const addForm = (item, props) => {

    if (item.type === "select") {
      return SelectField(item, stateWidth, props);
    } else if (item.type === "select2") {
      return <LargeFormSelect item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} itemsComponentInformation={props.itemsComponentInformation} defaultSelectedKeys={props.defaultSelectedKeys} />
    } else if (item.type === "checkbox") {
      return Boxcheck(item, stateWidth, props);
    } else if (item.type === "checkbox2") {
      return <LargeFormCheckbox item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    } else if (item.type === "radio2") {
      return <RadioButton item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    } else if (item.type == "SelectSearch") {
      return SelectSearch(item, stateWidth, props);
    }
    else if (item.type === "maskedTextField") {
      return <LargeFormMaskedTextField item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    }
    else if (item.type === "div") {
      return <SpaceDiv item={item} />
    }
    else if (item.type === "label") {
      return <LargeFormLabel item={item} props={props} isDisableOpt={props.isDisableOpt} defaultValue={props.defaultValue} defaultValueInput={props.defaultValueInput} />
    }
    else if (item.type === "table") {
      return <LargeFormTable item={item} props={props} actionOnClick={props.actionOnClick} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    }
    else if (item.type === "table2") {
      return <LargeFormTable item={item} props={props} actionOnClick={props.actionOnClick} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    }
    else if (item.type === "tagpicker") {
      return <LargeFormPickers item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    } else if (item.type === "slider") {
      return <LargeFormSlider item={item} props={props} actionOnChange={props.actionOnChange} isDisableOpt={props.isDisableOpt} />
    } else if (item.type === "toggle") {
      return <LargeFormToggle item={item} props={props} actionOnChange={props.actionOnChange} actionOnClick={props.actionOnClick} isDisableOpt={props.isDisableOpt} onText={item.onText} offText={item.offText} />
    } else if (item.type === "radius") {
      return (
        <ListRadious item={item} props={props} required={item.required} />
      );
    } else if (item.type === "file") {
      return (
        <FileField
          item={item}
          props={props}
          imageHandler={imageHandler}
          Image={item.key === "logoPath" ? base64Logo : base64Fond}
          required={item.required}
          tenant={clientName}
          stateWidth={stateWidth}
          controlsPage={controlsPage}
          showButtonLogo = {showButtonLogo}
          showButtonBackground = {showButtonBackground}
        />
      );
    } else if (item.type === "color") {
      const MethodColor = new ConvColors();
      return (
        <ColorField
          item={item}
          props={props}
          color={!item.defaultValue ? colorState : colorState}
          onChange={setColorState}
          convColor={MethodColor.convLight}
          controlsPage={controlsPage}
        />
      );
    } else if (item.type === "line"){
      return(
        <>
          <hr className={item.className} color={item.color} style={item.styleStack ? item.styleStack : {}}/>
          </>
      )
    }
    else {
      if (item.type === "text2") {
        return (
          <LargeFormInput
            item={item}
            props={props}
            actionOnChange={props.actionOnChange}
            isDisableOpt={props.isDisableOpt}
            defaultValueInput={props.defaultValueInput}
            textFileType={props.textFileType}
          />
        );
      } else if (item.type === "date2") {
        return (
          <LargeFormDate
            item={item}
            props={props}
            actionOnChange={props.actionOnChange}
            isDisableOpt={props.isDisableOpt}
          />
        )
      } else if (item.type === "button2") {
        return (
          <LargeFormButton
            item={item}
            props={props}
            actionOnClick={props.actionOnClick}
            isDisableOpt={props.isDisableOpt}
          />
        )
      } else {
        return (

          <InputFile
            name={item.key}
            defaultValue={
              !item.defaultValue ? "" : `${item.defaultValue}`
            }
            key={item.id}
            label={item.label}
            type={item.type}
            style={stateWidth < 300 ? "w10" : "select2"}
            placeholder={item.placeholder}
            required={item.required}
            disable={item.disable}
            maxLength={item.max}
            minLength={item.min}
            onChange={()=>{}}
          />
        );
      }
    }
  };

  const disables = props.buttonDisable
  return (
    <div>
      <form
        name="formulario"
        className="form--container-panel"
        onSubmit={(e) => onSubmitEvent(e)}
      >
        <div className="form--container-title">
          <h4>{props.formTitle}</h4>
        </div>
        <div
          onLoad={calcWidth("dsf")}
          className={`form--container-body ${stateWidth < 300 ? "form--container-body-small" : ""
            }`}
          {...props.style ? { style: props.style } : ""}
        >
          {props.Items.map((item) => addForm(item, props))}
        </div>

        <button
          // className="button--default button--disable mg10-w pala"
          className={`button--default ${disables ? 'button--disable' : 'button--primary'}`}
          type="submit"
          disabled={disables}
        >
          {controlsPage?.smtSave.value}
        </button>
        <button
          className="button--default button--second mg10 pala"
          type="button"
          onClick={props.closePanel}
        >
          {controlsPage?.smtCancel?.value}
        </button>
      </form>
    </div>
  );
  // }
};

export default ContainerLargeForm;
