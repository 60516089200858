export const COLUMN_TITLES = [{
  idNumber: {
    name: "Nº",
    maxWidth: 30
  },
  name: {
    name:"Rol",
    isResizable: true,
    maxWidth: 100,
    isMultiline: true
  },
  modules: {
    name: "Módulos",
    isResizable: true,
    isMultiline: true
  },
  domains: {
    name: "Dominios",
    isMultiline: true,
    isResizable: true,
    maxWidth: 100
  },
  isEnabledLabel: {
    name: "Estado",
    isResizable: true,
    maxWidth: 100
  },
  calendarLabel: {
    name: "Calendario",
    isResizable: true,
    maxWidth: 150
  },
  creatingDate: {
    name: "Fecha de creación",
    minWidth: 50,
    isResizable: true,
    maxWidth: 150,
    idHtml: "coluCreationDate",
  },
  updatingDate: {
      name: "Última Modificación",
      minWidth: 50,
      isResizable: true,
      maxWidth: 150,
      idHtml: "coluLastModification",
  },
  creatorUser: {
    name: "Creado por",
    maxWidth: 150,
    isResizable: true,
    idHtml: "coluCreatedBy",
  },
  updateUser: {
    name: "Modificado por",
    maxWidth: 150,
    isResizable: true,
    idHtml: "lblModifiedBy"
  },
}];


export const PANEL_COLUMN_TITLES = [{
  name: {
    name: "Modulos",
    maxWidth: 240,
    minWidth: 120,
    isMultiline: true
  },
  actions: {
    name: "Permisos",
    width: 240
  },
}];
