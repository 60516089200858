import React, {useState, useEffect, useRef, useContext} from "react";
import {useParams} from "react-router-dom";
import CookiesJS from 'js-cookie';
import {useBoolean} from '@fluentui/react-hooks';

import {RequestFetch} from "../../../utils/Requests/requests.Controller";
import LargeFormSelect from "../../../components/containers/largeForm/components/LargeFormSelect";
import DetailsListBext from "../../../components/lists/DetailsList/detailsList.component";

import {PANEL_COLUMN_TITLES} from "./constants";
import LenguajeContext from "../../../context/Lenguaje/lenguajeContext";

const PanelRole = ({formTitle, calendarList, closePanel, roleSelected = null}) => {
  const {clientName} = useParams()
  const {idClient} = JSON.parse(CookiesJS.get("dataClient"));

  const requestPages = new RequestFetch('Pages');
  const requestCalendar = new RequestFetch('Calendar');
  const requestRoles = new RequestFetch('Roles');

  const roleNameElement = useRef();
  const calendarElement = useRef();

  const [pagination, setPagination] = useState(null);
  const [modules, setModules] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [controlsPage, setControlsPage] = useState({});
  const [textOnPages, setTextOnPages] = useState({})
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const [isLoadingData, {
    setTrue: handleLoadingData,
    setFalse: handleLoadedData
  }] = useBoolean(false);
  const [domainsList, setDomainsList] = useState([])
  const [calendarsList, setCalendarsList] = useState([])
  const [filter, setFilter] = useState({
    pageSize: 5,
    pageNumber: "0"
  });
  const [isDisabled, setDisable] = useState([
    {disable: false, key: "valueDefault"},
    {disable: true, key: "name"},
  ]);
  useEffect(() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        //console.log(stateLanguage);
        stateLanguage.lenguaje.map((pass) => {
          // console.log(pass)
            if (pass.path == 'controls'|| pass.path =='domain') {
              pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })    
              setControlsPage(controlsPage)
            }              
        })          
    }
}, [stateLanguage])

  useEffect(async () => {
    if (roleSelected) {
      setSelectedOptions(roleSelected.rolesoperationspages.map(item => item.idOperationPage));
      setSelectedDomains(roleSelected.domainsroles.map(item => item.idDomain));
    }
    methodsRequests.getPages(filter, clientName);
    methodsRequests.getDomains();
    methodsRequests.getCalendars();
  }, [])

  const _actionOnChange = (data) => {
    if (data.key >= 0 && data.selectedItem) {
      if (data.selected) {
        setSelectedOptions(current => {
          return [...current, data.selectedItem.key]
        })
      } else {
        setSelectedOptions(current => {
          return current.filter(item => item !== data.selectedItem.key)
        });
      }
    }
  }

  const _handleDomainSelect = (data) => {
    if (data.selectedItem) {
      if (data.selected) {
        setSelectedDomains(current => {
          return [...current, data.selectedItem.key]
        })
      } else {
        setSelectedDomains(current => {
          return current.filter(item => item !== data.selectedItem.key)
        });
      }
    }
  }

  const onSubmitEvent = (event) => {
    const formData = new FormData(event.target);
    const user = {};
    event.preventDefault();
    const body = {
      idCalendar: parseInt(calendarElement.current.value),
      name: roleNameElement.current.value,
      isEnable: true,
      domainsroles: selectedDomains.map(item => ({idDomain: item})),
      rolesoperationspages: selectedOptions.map(item => ({idOperationPage: item}))
    }
    if (roleSelected) {
      // TODO: build the rigth body
      methodsRequests.updateRole(body, roleSelected.id);
    } else {
      methodsRequests.createRole(body);
      methodsRequests.deleteItem(body)
    }
  };

  const methodsRequests = {
    getPages: async (filters, tenant) => {
      setPagination(null);
      handleLoadingData()
      const promises = await requestPages.getItem(
        {
          param: filters, tenant
        }
      )
      _buildModules(promises.data.data);

      const {
        currentPage,
        pageZise,
        totalCount,
        totalPages
      } = promises.data.meta;
      setPagination({
        currentPage: currentPage,
        pageSize: pageZise,
        totalItems: totalCount,
        totalPages: totalPages
      });
      handleLoadedData();
    },
    getDomains: async () => {
      const domainRequest = new RequestFetch(`Domains`);
      const domainsData = await domainRequest.getItem({
        tenant: clientName
      });
      if (domainsData.data.data)
        setDomainsList(domainsData.data.data.map(item => ({key: item.id, text: item.alias})))
    },
    getCalendars: async () => {
      const calendarsData = await requestCalendar.getItem({
        tenant: clientName
      });
      if (calendarsData.data.data)
        setCalendarsList(calendarsData.data.data.map(item => ({key: item.id, text: item.name})))
    },
    createRole: async(body) => {
      await requestRoles.createItem({
        body,
        ct: 'json',
        tenant: clientName,
        infoModal:{
          title:controlsPage?.lblSuccessfulProcess?.value,
          description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
          },
          op:"b"
      });
      // TODO: Refresh the role screen
    },
    updateRole: async(body, id) => {
      await requestRoles.confirmRequest({body, id, ct:'json', tenant: clientName, option:'updateItem'})
      // TODO: Refresh the role screen
    },
    }
  
  useEffect(() => {
    if (filter.pageNumber > 0 || filter.search) {
      methodsRequests.getPages(filter, clientName);
    }
  }, [filter])

  const _defaultRenderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'actions':
        const options = item.operationspages.map(
          page => ({
            key: page.id,
            text: page.operation,
            idPage: page.idPage,
            idOperation: page.id,
            paguesoperation: page.name
          })
        );
        return (
          <LargeFormSelect
            item={{
              id: item.id,
              key: item.id,
              options: options.length ? options : [{text: 'No tiene permisos'}],
              multiSelect: item.operationspages.length > 0
            }}
            isDisableOpt={isDisabled}
            defaultSelectedKeys={selectedOptions.filter(option => options.map(item => item.key).includes(option))}
            actionOnChange={_actionOnChange}
          />
        )

      default:
        return <span>{fieldContent}</span>;
    }
  }

  const _buildModules = async (modulesApi) => {
    setModules(modulesApi
      .map(module => ({
          ...module,
          multiSelectOptions: _buildOptions(module)
        }
      ))
    );
  }

  const _buildOptions = (item) => {
    return {
      id: item.id > 0 ? item.id : 0,
      className: 'select2',
      key: item.id,
      options: item.operationspages.map(
          page => ({
            key: page.id,
            text: page.operation,
            idPage: page.idPage,
            idOperation: page.id,
            paguesoperation: page.name
          })
        ) ||
        [{id: 0, text: 'no tiene permisos'}]
      ,
      multiSelect: item.operationspages.length > 0
    }
  }

  return (
    <div>
      <form
        name="formulario"
        className="form--container-panel"
        onSubmit={(e) => onSubmitEvent(e)}
      >
        <div className="form--container-title">
          <h4>{formTitle}</h4>
        </div>
        <div
          className={`form--container-body`}
        >
          <div className="container--flex c--flex-dc w10">
            <div className="container--flex c--flex-hc mg6-w w10">
              <div>
                <label className="mg10-w wr3">Nombre del rol</label>
                <input name="rol-select" className="clr--dark-I bdr--base mg10-w" ref={roleNameElement} required defaultValue={roleSelected ? roleSelected.name : null}/>
              </div>
              <div>
                <label className="mg10-w wr3">Calendario </label>
                <select name="rol-select" className="clr--dark-I bdr--base mg10-w" ref={calendarElement} required  defaultValue={roleSelected ? roleSelected.idCalendar : null}>
                  <option value="">Seleccione</option>
                  {calendarList.map(option =>
                    <option key={option.key} value={option.key}>{option.text}</option>
                  )}
                </select>
              </div>

            </div>
            <div className="container--flex c--flex-hc">
              <label className="mg10-w wr3 ">Dominios</label>
              <LargeFormSelect
                item={{
                  className: "wr10 mg10-w",
                  id: "domainsSelect",
                  key: "domainsSelect",
                  options: domainsList,
                  multiSelect: true,
                  required: true
                }}
                isDisableOpt={isDisabled}
                defaultSelectedKeys={selectedDomains}
                actionOnChange={_handleDomainSelect}
              />
            </div>
          </div>
          <div className="h5"/>
          {(isLoadingData || (!isLoadingData && modules.length > 0)) ?
            <div className="w10">
              <DetailsListBext
                className='header-panel'
                enableShimmer={isLoadingData}
                selectionMode={0}
                items={modules}
                renderItemColumn={_defaultRenderItemColumn}
                columnTitles={PANEL_COLUMN_TITLES}
                onPageChange={page => {
                  if (page !== +filter.pageNumber) {
                    setFilter({
                      ...filter,
                      pageNumber: String(page),
                    });
                  }
                }}
                pagination={pagination}
              /></div> : ''}

        </div>

        <button
          className="button--default button--primary mg-hw pala"
          type="submit"
        > {roleSelected ? 'Actualizar' : 'Agregar'}
        </button>
        <button
          className="button--default button--second mg-hw pala"
          type="button"
          onClick={closePanel}
        >
          Cerrar
        </button>
      </form>
    </div>
  );
};


export default PanelRole;
