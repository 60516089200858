import React, { Fragment, useContext, useEffect, useState } from "react";
import CookiesJS from "js-cookie";
//import {DefaultButton, Panel, PanelType, } from 'office-ui-fabric-react'
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { useBoolean } from "@fluentui/react-hooks";
import { useParams } from "react-router-dom";

//*****Componnents*****//
import SubMenu from "../../components/navigation/subMenu/subMenu.component";
import ContainerLargeForm from "../../components/containers/largeForm/containerLargeForm.component";
import ContainerBaseLoading from "../../components/containers/baseLoading/containerBaseLoading.component";
import ListHeader from "../../components/lists/listHeader/listHeader.component";
import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';
//*****Methods*****//
import Lists from "../../utils/logicRendering/lists.controller";
import { RequestFetch } from "../../utils/Requests/requests.Controller";
import LenguajeContext from "../../context/Lenguaje/lenguajeContext";



const NotificationFlow = () => {
  /************************
   * @arguments
   ************************/
  
  //***Propertise***//
  const [arrayAttribute, setArrayAttribute] = useState();
  const [arrayDataAddressee, setArrayDataAddressee] = useState();
  const [arrayDataCc, setArrayDataCc] = useState();
  const [arrayDataCco, setArrayDataCco] = useState();
  const [arrayNotificationTypes, setArrayNotificationTypes] = useState(null)
  const [currentPages, setCurrentPage] = useState(1);
  const { clientName } = useParams();
  const [controlsPage, setControlsPage] = useState({});
  const [textOnPages, setTextOnPages] = useState({})
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const [onSelect, setOnSelected] = useState();
  const [defaultValueInput, seDefaultValueInput] = useState([]);
  const [deleteD, setDeleteD] = useState(false);
  const [edit, setEdit] = useState(false);
  const { idClient } = JSON.parse(CookiesJS.get("dataClient"));
  const [idNotificationSelect, setidNotificationSelect] = useState([]);
  const [isLoadingData, {
    setTrue: handleLoadingData,
    setFalse: handleLoadedData
  }] = useBoolean(false);
  const [isDisable, setDisable] = useState([
    { disable: false, key: "valueDefault" },
    { disable: true, key: "name" },
  ]);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [itemsComponentInformation, setItemsComponentInformation] = useState();
  const [itemsNotification, setitemsNotification] = useState(null);
  const [ObjectPetition, setObjectPetition] = useState();
  const [showElements, setShowElements] = useState(5);
  const[defaultSelectedKeys,setDefaultSelectedKeys] = useState([])
  const [filter, setFilter] = useState({
    pageSize: 5,
    pageNumber: "0"
    });
  const [pagination, setPagination] = useState(null);
  const [promise, setPromise] = useState([]);
  const Requests = new RequestFetch("Notification");
  const RequestsNotificationFlow = new RequestFetch("NotificationFlow");
  const RequestsNotificationFlowIdNotification = new RequestFetch(
    "NotificationFlow/idNotification"
  );
  const RequestsNotificationTypes = new RequestFetch("Notification/types");
  const RequestsAttribute = new RequestFetch("Attribute");
  //#region Consulta de tipos de notificacion
  const [notificationTypes, setPromiseNotificationTypes] = useState(
    RequestsNotificationTypes.getItem({ tenant: clientName })
  );
    const COLUMN_TITLES = [{
      name: {
        name:"Notificacion",
        maxWidth: 300,
        isMultiline: true
    },
    nameNotiType: {
        name:"Tipo de notificación",
        minWidth: 300,
        isMultiline: true
    },
    creatingDate: {
        name: "Fecha de creación",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
        idHtml: "coluCreationDate",
    },
    updatingDate: {
        name: "Última Modificación",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
        idHtml: "coluLastModification",
    },
    creatorUser: {
      name: "Creado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "coluCreatedBy",
    },
    updateUser: {
      name: "Modificado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "lblModifiedBy"
    },
    }]

    useEffect(() => {
      let controlsPage = {};
      if (stateLanguage.lenguaje !=0) {
          //console.log(stateLanguage);
          stateLanguage.lenguaje.map((pass) => {
            // console.log(pass)
              if (pass.path == 'controls'|| pass.path =='domain') {
                pass.webcontrolspages.map((data) => {
                  controlsPage[data.idHtml] = data.languagewebcontrols[0];
                })    
                setControlsPage(controlsPage)
              }              
          })          
      }
  
  }, [textOnPages, stateLanguage])

    

  useEffect(() => {
    notificationTypes.then((data) => {
      for (const [key, value] of Object.entries(data.data)) {
        if (key === "data") {
          for (let index = 0; index < value.length; index++) {
            const element = value[index];
            setArrayNotificationTypes(arrayNotificationTypes, 
              { 
                key: element["id"],
                text: element["name"] 
              } )
          }
        }
      }
    });
  }, [notificationTypes]);
  
  //#endregion
  //#region Consulta atributos
  const [promiseAttribute, setPromiseAttribute] = useState();

  let arrayAttributes = [];
  useEffect(() => {
    if (promiseAttribute !== null && promiseAttribute != undefined) {
      promiseAttribute.then((data) => {
        for (const [key, value] of Object.entries(data.data)) {
          if (key === "data") {
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              arrayAttributes.push({
                key: element["id"],
                text: element["name"],
              });
            }
          }
        }
        setItemsComponentInformation(arrayAttributes);
      });
    }
  }, [promiseAttribute]);
  //#endregion
  //#region Acciones de los componentes
  const actionOnChange = (data) => {
    //#region Se llenan los datos de los controles
    if (data.id === "selectAttribute") {
      setArrayAttribute(data);
    }
    if (data.id === "addressee") {
      for (let element in data) {
        if (data[element].name !== undefined) {
          setArrayDataAddressee(data);
        }
      }
    }
    if (data.id === "cc") {
      for (let elementcc in data) {
        if (data[elementcc].name !== undefined) {
          setArrayDataCc(data);
        }
      }
    }
    if (data.id === "cco") {
      for (let elementcco in data) {
        if (data[elementcco].name !== undefined) {
          setArrayDataCco(data);
        }
      }
    }
    //#endregion
  };
  const actionOnClick = (data) => {
  };
  const onSubmitEventFormPanel = (data) => {
    let dataForm = {
      idClient: idClient,
      idNotification: idNotificationSelect[0].id,
      idAttribute: arrayAttribute.state ? null : arrayAttribute.key,
      notificationsflow_users_roles: [],
    };
    if (arrayDataAddressee != undefined) {
      if (arrayDataAddressee.state === false) {
        delete arrayDataAddressee.state;
        delete arrayDataAddressee.id;
        let dataint = Object.entries(arrayDataAddressee);
        for (const vallue in dataint) {
          if (arrayDataAddressee[vallue].entity == "users") {
            dataForm.notificationsflow_users_roles.push({
              idUser: arrayDataAddressee[vallue].key,
              isCc: false,
              isCco: false,
            });
          } else {
            dataForm.notificationsflow_users_roles.push({
              idRole: arrayDataAddressee[vallue].key,
              isCc: false,
              isCco: false,
            });
          }
        }
      }
    }
    if (arrayDataCc != undefined) {
      if (arrayDataCc.state === false) {
        delete arrayDataCc.state;
        delete arrayDataCc.id;
        let dataint = Object.entries(arrayDataCc);
        for (const vallue in dataint) {
          if (arrayDataCc[vallue].entity == "users") {
            dataForm.notificationsflow_users_roles.push({
              idUser: arrayDataCc[vallue].key,
              isCc: true,
              isCco: false,
            });
          } else {
            dataForm.notificationsflow_users_roles.push({
              idRole: arrayDataCc[vallue].key,
              isCc: true,
              isCco: false,
            });
          }
        }
      }
    }
    if (arrayDataCco != undefined) {
      if (arrayDataCco.state === false) {
        delete arrayDataCco.state;
        delete arrayDataCco.id;
        let dataint = Object.entries(arrayDataCco);
        for (const vallue in dataint) {
          if (
            arrayDataCco[vallue].entity != undefined &&
            arrayDataCco[vallue].entity == "users"
          ) {
            dataForm.notificationsflow_users_roles.push({
              idUser: arrayDataCco[vallue].key,
              isCc: false,
              isCco: true,
            });
          } else {
            dataForm.notificationsflow_users_roles.push({
              idRole: arrayDataCco[vallue].key,
              isCc: false,
              isCco: true,
            });
          }
        }
      }
    }
    return dataForm
  };

  //#endregion
  //#region Configuracion de componentes
  const [campForm, setCampForm] = useState([
    
    {
      id: 0,
      key: "name",
      label: "Notificacion",
      ariaLabel: "Solo se pueden insertar nuemros",
      type: "text2",
      defaultValu: null,
      disabled: true,
      required: true,
      multiline: true,
      resizable: false,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: { float: "right", "padding-right": "3rem" },
      styleStack: {
        width: "100%",
        position: "center",
      },
      suffix: "",
      title: "Solo se acpetan 3 numeros consecutivos",
      onRenderLabel:
        "Notificación que seleccionaste",
      isRequired: false,
      onGetErrorMessage: [
        { messageValidateFormat: "Solo se aceptan datos numéricos" },
      ],
    },
    {
      id: 1,
      key: "addressee",
      label: "Destinatarios ",
      type: "tagpicker",
      required: true,
      queryEntities: [
        {
          entitie: "Notification/values",
          attribute: "value",
          query: "param",
          tenant: clientName,
        },
        /* {
          entitie: "Users",
          attribute: "firstName",
          query: "filters",
          tenant: clientName,
        },*/
      ],
      nameArrays: [
        {
          name: "users",
          key: [
            { keyname: "firstName" },
            { keyname: "secondName" },
            { keyname: "lastName" },
            { keyname: "secondSurname" },
          ],
        },
        { name: "roles", key: [{ keyname: "name" }] },
      ],
      itemLimit: 0,
      disabled: isDisable,
      maxLengthRequest: 2,
      suggestionsHeaderText: "sugerencias",
      noResultsFoundText: "No se encontraron sugerencias",
    },
    {
      id: 2,
      key: "cc",
      label: "CC ",
      type: "tagpicker",
      required: true,
      queryEntities: [
        {
          entitie: "Notification/values",
          attribute: "value",
          query: "param",
          tenant: clientName,
        },
        /*  {
          entitie: "Users",
          attribute: "firstName",
          query: "filters",
        },*/
      ],
      nameArrays: [
        {
          name: "users",
          key: [
            { keyname: "firstName" },
            { keyname: "secondName" },
            { keyname: "lastName" },
            { keyname: "secondSurname" },
          ],
        },
        { name: "roles", key: [{ keyname: "name" }] },
      ],
      itemLimit: 0,
      disabled: isDisable,
      maxLengthRequest: 2,
      suggestionsHeaderText: "sugerencias",
      noResultsFoundText: "No se encontraron sugerencias",
    },
    {
      id: 3,
      key: "cco",
      label: "CCO ",
      type: "tagpicker",
      required: true,
      queryEntities: [
        {
          entitie: "Notification/values",
          attribute: "value",
          query: "param",
          tenant: clientName,
        },
        /*  {
          entitie: "Users",
          attribute: "firstName",
          query: "filters",
        },*/
      ],
      nameArrays: [
        {
          name: "users",
          key: [
            { keyname: "firstName" },
            { keyname: "secondName" },
            { keyname: "lastName" },
            { keyname: "secondSurname" },
          ],
        },
        { name: "roles", key: [{ keyname: "name" }] },
      ],
      itemLimit: 0,
      disabled: isDisable,
      maxLengthRequest: 2,
      suggestionsHeaderText: "sugerencias",
      noResultsFoundText: "No se encontraron sugerencias",
    },
    {
      id: 5,
      key: "selectAttribute",
      label: "Atribute ",
      type: "select2",
      required: true,
      options: itemsComponentInformation,
      placeholder: "Seleccione...",
      multiSelect: false,
      disabled: isDisable,
    },
  ]);

  const [campFilters, setCampFilters] = useState([
    {
      id: 6,
      key: "isToAdmin",
      label: "Es administrador",
      type: "select",
      required: true,
      options: [
        { id: 0, text: "true" },
        { id: 1, text: "false" },
      ],
    },
    {
      id: 7,
      key: "idNotificationType",
      label: "Tipo de notificacion",
      type: "select",
      required: true,
      options: arrayNotificationTypes,
    },
  ]);
  //#endregion
  /************************
   * @methods
   ************************/
  const methodsList = {
    getDataItem: (object) => {
      const stateList = new Lists(itemsNotification, campForm);
      setidNotificationSelect(stateList.getDataItem(object, idNotificationSelect));
      const stateCurrent = stateList.updateCurrent(idNotificationSelect);
      setCampForm(stateCurrent.object);
      setDeleteD(stateCurrent.delete);
      setEdit(stateCurrent.edit);
      //#region Se cosultan los atributos dependiendo de la notificacion, Habilitar Deshabilitar Componentes
      setItemsComponentInformation([]);
      if (idNotificationSelect.length === 1) {
        let filters = "";
        if (idNotificationSelect[0].nameNotiType === "sms") {
          setDisable([
            { disable: true, key: "name" },
            { disable: false, key: "addressee" },
            { disable: true, key: "cc" },
            { disable: true, key: "cco" },
            { disable: false, key: "selectAttribute" },
          ]);
          filters = `{"param" : {"isPhone" : "true"},"tenant" : "${clientName}"}`;
          setPromiseAttribute(RequestsAttribute.getItem(JSON.parse(filters)));
        }
        if (idNotificationSelect[0].nameNotiType === "email") {
          setDisable([
            { disable: true, key: "name" },
            { disable: false, key: "addressee" },
            { disable: false, key: "cc" },
            { disable: false, key: "cco" },
            { disable: false, key: "selectAttribute" },
          ]);
          filters = `{"param" : {"isEmail" : "true"}, "tenant" : "${clientName}"}`;
          setPromiseAttribute(RequestsAttribute.getItem(JSON.parse(filters)));
        }
        if (idNotificationSelect[0].nameNotiType === "push") {
          setDisable([
            { disable: true, key: "name" },
            { disable: false, key: "addressee" },
            { disable: true, key: "cc" },
            { disable: true, key: "cco" },
            { disable: true, key: "selectAttribute" },
          ]);
        }
      }
      //#endregion
    },
    voidCamps: () => {
      const stateList = new Lists(itemsNotification, campForm);
      setCampForm(stateList.clearCamps());
    },
  };

  const methosRequests = {
    chageGetItems: (filters) => {
       setPromise(Requests.getItem({ param: filters, tenant: clientName }));
   
    },
    crateItem: (dataForm) => {
      RequestsNotificationFlow.createItem({
        body: onSubmitEventFormPanel(),
        ct: "json",
        tenant: clientName, 
        infoModal:{
        title:controlsPage?.lblSuccessfulProcess?.value,
        description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
        }
      });
    },
    updateItem: (dataForm, id) => {},
    deleteItem: () => {
      idNotificationSelect.forEach((element) =>
        RequestsNotificationFlowIdNotification.deleteItem({id: element.id,tenant: clientName,
          infoModal:{
          title:controlsPage?.lblDisabled?.value, 
          description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
          }
        })
      );
      window.location.reload();
    },
    getNoti: async (filters,tenant) =>{
      setPagination(null);
      handleLoadingData()

      const promise = await Requests.getItem({ param: filters, tenant, clientName})
      setitemsNotification(promise.data.data);
      const {
        currentPage,
        pageZise,
        totalCount,
        totalPages
    } = promise.data.meta;
    setPagination({
        currentPage: currentPage,
        pageSize: pageZise,
        totalItems: totalCount,
        totalPages: totalPages
    });
    handleLoadedData()
    
    }
  };

  const _defaultRenderItemColumn = (item, index, column) => 
        {
            const fieldContent = item[column.fieldName];
            return <span>{fieldContent}</span>;           
        }

  const _selectUser = (items) => 
  {
      if (items.length > 0){     
          setOnSelected(items[0]);
      } else {
          setOnSelected();
      }       
  }


  /************************
   * @hooks
   ************************/  
  useEffect(async()=>{
    handleLoadingData()
    const promise = await Requests.getItem({ param: { pageSize: showElements, pageNumber: currentPages }, tenant: clientName })
    setitemsNotification(promise.data.data);
    const {
      currentPage,
      pageZise,
      totalCount,
      totalPages
  } = promise.data.meta;
  setPagination({
      currentPage: currentPage,
      pageSize: pageZise,
      totalItems: totalCount,
      totalPages: totalPages
  });
  handleLoadedData()
  }, [])

    useEffect(() => {
      if(filter.pageNumber >0){
        methosRequests.getNoti(filter,clientName)
      }
    }, [filter])
    
  /************************
   * @return
   ************************/
  if (itemsNotification === null) {
    return <ContainerBaseLoading />;
  }
  // const numberPage = CountPages(itemsNotification.meta.totalPages);

  return (
    <>
      <SubMenu
        contentcontrols={
          onSelect?[
            {
              text:"Habilitar | Deshabilitar ",
              iconProps:{ iconName: "delete" },
              onClick:()=>methosRequests.deleteItem()
            }
          ]
          :
          [
            {
              text:"Configurar Notificación",
              iconProps:{ iconName: "Add" },
              onClick: ()=>openPanel()
            }
          ]
        }
        contentcontrolsTwo={
          <>
            {
              <Panel
                isOpen={isOpen}
                closeButtonAriaLabel="Close"
                isHiddenOnDismiss={true}
                type={PanelType.smallFixedFar}
                hasCloseButton={false}
              >
                {isOpen ? (
                  <ContainerLargeForm
                    dataForm={(e) => {
                      edit
                        ? methosRequests.crateItem(e, idNotificationSelect)
                        : methosRequests.updateItem(e, idNotificationSelect[0].id);
                    }}
                    formTitle={edit ? "Configurar Notificación" : ""}
                    closePanel={dismissPanel}
                    Items={campForm}
                    case="notificationFlow"
                    actionOnChange={actionOnChange}
                    actionOnClick={actionOnClick}
                    isDisableOpt={isDisable}
                    onSubmitEventFormPanel={onSubmitEventFormPanel}
                    itemsComponentInformation={itemsComponentInformation}
                    defaultValueInput={defaultValueInput}
                    defaultSelectedKeys={defaultSelectedKeys}
                  />
                ) : (
                  ""
                )}
              </Panel>
            }
          </>
        }
      />
      <div className="admin--body-container">
        <ListHeader
          filters={campFilters}
          showReport={false}
          subRute="Notificaciones/Administración | "
          subRute3= " Administración de flujo de notificaciones"
          title="Administración de flujo de notificaciones"
          dataForm={(e) =>
            methosRequests.chageGetItems({
              pageSize: showElements,
              pageNumber: currentPages,
              idDomain: e.idDomain,
            })
          }
          onChange={(event)=>{
                      setFilter({
                          ...filter,
                          pageSize: event.target.value,
                          pageNumber: filter.pageNumber === "0" ? "0" : "1"
                      });
                  
                  }}
                  InputChange={(e)=>{
                    
                      setFilter({
                          ...filter,
                          search: e
                      })
                  
                  }}

        />
    
        <DetailsListBext
          enableShimmer={isLoadingData}
          listKey="FlowList"
          selectionMode={1}
          items={itemsNotification}
          renderItemColumn={_defaultRenderItemColumn}
          columnTitles={COLUMN_TITLES}
          onSelect={_selectUser}
          onPageChange={page => {
              if(page !== +filter.pageNumber){
                  setFilter({
                      ...filter,
                      pageNumber: String(page),
                  });
              }
          }}
          pagination={pagination}
        />
      </div>
    </>
  );
};

export default NotificationFlow;
