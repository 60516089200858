import React from 'react';

const ContainerBaseUser = (props) =>{
    

    return(
        <>
        <section className="baseBodyUser">
            <div className="bodyUser--container-header">
                {props.header}
            </div>
            <div className="bodyUser--container-main">
                {props.main}
            </div>
            <div className="bodyUser--container-footer">
                {props.footer}
            </div> 
        </section>
        <div id="contSh" className='contSh'>
        

        <section className="baseShadowUser">
        </section>
        </div>
        </>
    );
}

export default ContainerBaseUser;