import React, { useState, useEffect, useMemo, FormEvent } from "react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import {Stack } from "@fluentui/react/lib/Stack";
import { FontIcon, Label, mergeStyles } from "@fluentui/react";

const LargeFormSelect = (props) => {
  const iconClass = mergeStyles({
    fontSize: 8,
    height: 8,
    width: 8,
    margin: '0',
    position: 'absolute',
    color: 'var(--color-primary)' 
  });
  const [selectedItem, setSelectedItem] = useState();
const [valInp, setValInp] = useState(null)
const [dropdownValue, setDropdownValue] = useState(null)
  let item = props.item;
  const controlsPage = props.props.controlsPage;
  // console.log(item)
  if (item.defaultValue) {
    if (Array.isArray(item.defaultValue)) {
      item.defaultValue.map(obj=>{
        // console.log(obj)
        
        
        
      })
    }
  }
  //#region  Buscar en un arreglo el id para habilitar y deshabilitar
  let found;
  if (props.isDisableOpt.length === 1) {
    found = props.isDisableOpt.find(
      (element) => element.key === "valueDefault"
    );
  } else {
    found = props.isDisableOpt.find((element) => element.key === item.key);
    if (found === undefined) {
      found = props.isDisableOpt.find(
        (element) => element.key === "valueDefault"
      );
    }
  }
  if (found === undefined) {
    found = props.isDisableOpt.find((element) => element.key === item.key);
  }
useEffect(() => {
  if (item.defaultValue!==null) {
    setDropdownValue(item.defaultValue)
      if(item.key=='isPrimary'&& !item.defaultValue){
        setDropdownValue(true)
      }
  }
}, [item.defaultValue])

useEffect(() => {
}, [dropdownValue])

  //#endregion
  //#region Accion onChange
  const onChange = (e, item) => {
    
    setDropdownValue(item.key?item.key:(item.value?item.value:item.text))
    //setValInp(item)
    setSelectedItem(item);
  };

  useEffect(() => {
    let arrayValueDefault = [];
    if (props.defaultSelectedKeys.length > 0) {
      props.defaultSelectedKeys.forEach((element) => {
        arrayValueDefault.push({
          key: element,
          selected: true,
          text: `${element}`,
        });
      });
    }

    let objectFinal = {
      ...(selectedItem ? selectedItem : arrayValueDefault),
      ...{ selectedItem: selectedItem },
      ...{ id: item.key, key: item.id, state: found.disable },
    };
  
    props.actionOnChange(objectFinal);
  }, [selectedItem]);
  const styleSelect = {
    errorMessage:{
      background:'#F5D4D4 0% 0% no-repeat padding-box',
      'border-radius':'4px',
      padding:'7px 7px',
      'margin-top':'2px',
      color:'var(--color-danger)',
      ':before': {
        content: "",
        position: 'absolute',
        left: '10%',
        top: '-20px',
        width: 0,
        height: 0,
        'border-left': '13px solid blue',
        'border-top': '26px solid red',
        'border-right': '13px solid green'
      }
      
    }
  }
  //#endregion
  return (
    <>
    <div className={item.className}>
      <Stack style={item.styleStackFather} id={item.id}>
      <Label>{item.label ? <div style={{position: "relative"}}> <span>{item.label}</span>{item.isRequired || item.required ? <FontIcon aria-label="requerido" iconName="SkypeCircleCheck" className={iconClass} /> : ''} </div> : ""}</Label>
        <Stack style={item.styleStack} id={item.key}>
          <Dropdown
            id={item.key}
            key={item.key}
            placeholder={item.placeholder}
            label={item.labelComponent}
            multiSelect={item.multiSelect}
            onChange={onChange}
            options={
              item.options ? item.options : props.itemsComponentInformation
            }
            disabled={item.disable}
            description={item.description}
            // errorMessage={item.errorMessage}
            selectedKey={item.selectedKey}
            errorMessage={dropdownValue || props.defaultSelectedKeys.length > 0 ? undefined : controlsPage?.slcLanguageTerms?.errorRequiredMessage }
            styles={item.style?item.style:styleSelect}
            defaultSelectedKeys={item.defaultSelectedKeys}
            {...item.defaultValue === null?{defaultSelectedKey: item.defaultValu}:{defaultSelectedKey: item.defaultValue}}
            //defaultSelectedKey={dropdownValue}
          />
        </Stack>
      </Stack>
      </div>
    </>
  );
};

export default LargeFormSelect;
