import React, {useEffect, useState, useCallback, useContext} from 'react';
import {useBoolean, useId} from '@fluentui/react-hooks';
import {DefaultButton, ActionButton, PrimaryButton} from '@fluentui/react/lib/Button';
import {Panel, PanelType} from '@fluentui/react/lib/Panel';
import {useParams} from 'react-router-dom';
import {render} from "react-dom";
import CookiesJS from "js-cookie";

import SubMenu from '../../../components/navigation/subMenu/subMenu.component';
import ListHeader from "../../../components/lists/listHeader/listHeader.component";
import DetailsListBext from '../../../components/lists/DetailsList/detailsList.component';
import ModalFluent from "../../../components/tools/modalFluent/ModalFluent";
import {
    DetailsList,
    DetailsListLayoutMode,
    IDetailsListStyles,
    IDetailsHeaderProps,
    ConstrainMode,
    IDetailsFooterProps,
    DetailsRow,
  } from '@fluentui/react/lib/DetailsList';
import {RequestFetch} from '../../../utils/Requests/requests.Controller';

import {COLUMN_TITLES, CONFIRM_MODAL_ACTIONS} from "./constants";
import SupportHistoryBsr from "./supportHistoryBsr";
import LenguajeContext from '../../../context/Lenguaje/lenguajeContext';
import { Modal } from '@fluentui/react';
import Mfa from '../../users/mfa';
import { changeLang, changeLangForModal, changeLangForm } from '../../../utils/logicRendering/changeIdiom';
import ContainerBaseLoading from '../../../components/containers/baseLoading/containerBaseLoading.component';

const SupportBsr = () => {
    const supportUsersRequest = new RequestFetch('Support/users');
    const supportRequest = new RequestFetch('Support');

    const { idClient } = JSON.parse(CookiesJS.get("dataClient"));
    const [domainForm, setDomainForm] = useState();
    const { clientName } = useParams();
    const [userSelected, setUserSelected] = useState();
    const [filtersList, setFiltersList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [controlsPage, setControlsPage] = useState({});
    const [textOnPages, setTextOnPages] = useState({})
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
    const [txtDisable, setTxtDisable] = useState('')
    const [buttondisable, setButtondisable] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [infModal, setInfModal] = useState()
    const [filter, setFilter] = useState({
        pageSize: 5,
        pageNumber: "0",
        idDomain: ""
    });
    const [pagination, setPagination] = useState(null);
    const [sesionClose, setSesionClose] = useState({})
    const [isSupportHistoryOpen, {
        setTrue: handleOpenSupportHistory,
        setFalse: handleDismissSupportHistory
    }] = useBoolean(false);
    const dataUserIdLng = JSON.parse(CookiesJS.get("idLng"));
    const user = JSON.parse(CookiesJS.get("dataUser")).user;
    const [columnTitles, setColumnTitles] = useState(COLUMN_TITLES)
    const [confirmModalActions, setConfirmModalActions] = useState(CONFIRM_MODAL_ACTIONS)

    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);

    useEffect(async() => {
        let controlsPage = {};
        let controlsPagep = {};
        if (stateLanguage.lenguaje !=0) {
            stateLanguage.lenguaje.map((pass) => {
                if (pass.path =='controls' || pass.path =='manage-mfa' || pass.path == 'support') {
                    pass.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  })    
                  setControlsPage(controlsPage)
                } 
            })
            setColumnTitles(current=>changeLang(current,controlsPage))
            setConfirmModalActions(current=>changeLangForModal(current,controlsPage))
        }
        const domainRequest = new RequestFetch(`Access/domain/${idClient}`);
        const domainData = await domainRequest.getItem({
            tenant: clientName,
            headerEx:{idLanguageUser:dataUserIdLng?dataUserIdLng:user.idLanguage}
        });
        setDomainForm(domainData.data.data);
      }, [textOnPages, stateLanguage])


    useEffect(() => {
        if(domainForm && Object.entries(controlsPage).length>0) {
            setFiltersList(changeLangForm([
                {
                    id:0,
                    key:'idDomain',
                    placeholder:'lblFilterDominio',
                    type:'select',
                    idHtmlPlaceholder: "lblFilterDominio",
                    idHtml:'lblFilterDominio',
                    options: domainForm.map(
                        domain => ({
                            id: domain.id,
                            text: domain.alias
                        })
                    )
                },
                {
                    id:1,
                    key:'searchValue',
                    label:'',
                    type:'text',
                }
            ],controlsPage));
        }
    },[domainForm])

      
    useEffect(() => {
        if(filter.pageNumber > 0) {
            requests.getSupportUsers(filter, clientName);
        }
    }, [filter])

    useEffect(() => {
        // Remover clases aplicadas anteriormente
        document.querySelector('.div-foot-rel')?.classList.remove('div-foot-rel');
        document.querySelector('.div-foot-in')?.classList.remove('div-foot-in');
      }, []);

    const _supportHistoryFooter = useCallback(
        () => (
            <div className="container--flex c--flex-we">
                <DefaultButton className="button--second" onClick={handleDismissSupportHistory} >{controlsPage?.btnBack.value}</DefaultButton>
            </div>
        ),
        [handleDismissSupportHistory,controlsPage],
    );

    // useEffect(async() => {
    //     if (Object.entries(sesionClose).length > 0 ) {
    //         sesionClose.idSupportType=2
    //         let body = sesionClose
    //         await supportRequest.createItem({
    //             body,
    //             ct: 'json',
    //             tenant: clientName,
    //             op:'b', 
    //             infoModal:{
    //             title:controlsPage?.lblSuccessfulProcess?.value,
    //             description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
    //             }
    //        });
    //        setSesionClose({})
    //     }
    // }, [sesionClose])
    
    const _defaultRenderItemColumn = (item, index, column) => {
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case 'actions':
                if (item.sessions>0 && item.isEnabled) {
                    return (
                        <ActionButton
                        text=""
                        iconProps={{iconName: 'Cancel'}}
                        onClick={() => _handleSupportActions('closeSessions', item) }
                        />
                    );
                }

            default:
                return <span>{fieldContent}</span>;
        }
    }

    const _selectUser = (items) => {
        if (items.length > 0) {
            setUserSelected(items[0]);
        } else {
            setUserSelected();
        }
    }

    const _handleSupportActions = (action, user) => {
        let title = confirmModalActions[action].title;
        let description = confirmModalActions[action].description;

        if (action === 'closeSessions') {
            description = user.fullName;
        }

        if(action === 'changeStatus') {
            const status = user.isEnabled;
            title = title.replace("{env}", status ? "INHABILITAR" : "HABILITAR");
            description = description.replace("{env}", status ? "ya no" : "");
        }
        _confirmationModal(
        {
                description: _confirmationModalBody(
                    title,
                    description
                ),
                type:'default'
            },
            () => _sendSupportRequest(action, user),
            'enable'
        )
    }

    useEffect(() => {
        if (txtDisable.length>20 && txtDisable.length<140) {
            setButtondisable(false)
        } else {
            setButtondisable(true)
        }
    }, [txtDisable])

    useEffect(() => {
        if (infModal) {
            _confirmationModal(infModal.info, infModal.confirmAction)
        }
    }, [infModal,buttondisable])
    
    

    const _sendSupportRequest = (action, user) => {
        let remark = "";
        setInfModal({
            info:{
                description: _reportDescriptionModalBody(event => {
                    remark = event.target.value;
                }),
                type:'default'
            },
            confirmAction:_ => {
                requests.createSupport({
                    idUser: user.id,
                    idSupportType: confirmModalActions[action].idSupportType,
                    remark
                });
                setButtondisable(true)
            }
            
        })
    }

    const _reportDescriptionModalBody = (onChange) => {
        return <div className="container--flex c--flex-dc c--flex-hc">
            <h3 className="mg10-h">{controlsPage?.lblEnterDescriptionWhyTheSupportWasMade?.value}</h3>
            <div className="mg10-h txt--aling-c w10">
                <textarea className="w10 h5" onChange={(e)=>{onChange(e);setTxtDisable(e.target.value)}}/>
            </div>
        </div>
    }

    const _confirmationModalBody = (title, description) => {
        return <div className="container--flex c--flex-dc c--flex-hc">
            <h3 className="mg10-h">{title}</h3>
            <h4 className="mg10-h txt--aling-c">{description}</h4>
        </div>
    }

    const _confirmationModal = (info, confirmAction,enable) => {
        render(
            <ModalFluent
                props={info}
                actionOnClick={_ => {}}
                objectRequest={confirmAction}
                disable={enable?false:buttondisable}
            />,
            document.getElementById("modal")
        );
    }
    const actionClick = () => {}
    const requests = {
        getSupportUsers: async (filters, tenant) => {
            setPagination(null);
            handleLoadingData()
            const response = await supportUsersRequest.getItem({param:filters, tenant, headerEx:{idLanguageUser:dataUserIdLng?dataUserIdLng:user.idLanguage}, actionClick});
            let idNumber = 0;
            if(response !== undefined){
            setUsersList(
                response.data.data.map(
                    user => {
                        idNumber ++;
                        return (
                            {
                                ...user,
                                isEnabledLabel: user.isEnabled ? controlsPage?.lblEnable?.value : controlsPage?.lblDisabled?.value,
                                isEnabledMfa: user.mfaWasEnabled ? controlsPage?.lblEnable?.value : controlsPage?.lblDisabled?.value,
                                associatedQuestionsLabel: user.associatedQuestions ? controlsPage?.lblYes?.value : controlsPage?.lblNo?.value,
                                actions: '',
                                idNumber
                            }
                        )
                    }
                )
            );
            const {
                currentPage,
                pageZise,
                totalCount,
                totalPages
            } = response.data.meta;
            setPagination({
                currentPage: currentPage,
                pageSize: pageZise,
                totalItems: totalCount,
                totalPages: totalPages
            });
        }else{
            setUsersList([])
        }
            handleLoadedData();
        },
        createSupport: async (body) => {
            // if (body.idSupportType == 4) {
            //     setSesionClose(body)
            // }
           await supportRequest.createItem({
                body,
                ct: 'json',
                tenant: clientName,
                op:'b',
                infoModal:{
                title:controlsPage?.lblSuccessfulProcess?.value,
                description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                }
           });
        }
    }
    const [showModal, setShowModal] = React.useState(false);

  const handleClick = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

    return (
        <>
    {Object.entries(controlsPage).length>0?
    <>
        <Modal className='modal-mfa' isOpen={showModal} onDismiss={handleClose} isBlocking={true}>
          <div>
            <Mfa infUsr={userSelected} supportAction={(opt) => _handleSupportActions(opt, userSelected)} isAdmin={true} />
            <div className={`mg10-h w10 container--flex c--flex-wc `}>
                <button 
                className='mg20-h button-simple'
                onClick={handleClose}
                >
                    {controlsPage?.smtReturn?.value}
                </button>
            </div>
          </div>
        </Modal>
            <SubMenu
                contentcontrols={
                    userSelected && [
                        {
                            key: 'CalendarAgenda',
                            cacheKey: 'cacheCalendarAgenda',
                            text:controlsPage?.OnClikChangePassword?.value,
                            iconProps:{iconName: 'CalendarAgenda'},
                            onClick:() => _handleSupportActions('changePassword', userSelected)
                        },
                        {
                            key: 'EditNote',
                            cacheKey: 'cacheEditNote',
                            text:userSelected.isEnabled ? controlsPage?.OnClickDisableStatus?.value : controlsPage?.OnClickEnableStatus?.value,
                            iconProps:{iconName: 'EditNote'},
                            onClick:() => _handleSupportActions('changeStatus', userSelected)
                        },
                        {
                            key: 'Cancel',
                            cacheKey: 'cacheCancel',
                            iconProps:{iconName: 'Cancel'},
                            text:controlsPage?.OnClickDeleteQuestions?.value,
                            onClick:() => _handleSupportActions('deleteQuestions', userSelected)
                        },
                        {
                            key: 'BulletedList',
                            cacheKey: 'cacheBulletedList',
                            iconProps:{iconName: 'BulletedList'},
                            text:controlsPage?.OnClickSupportHtories?.value,
                            onClick:() => handleOpenSupportHistory()
                        },
                        {
                            key: 'Sync',
                            cacheKey: 'cacheSync',
                            iconProps:{iconName: 'Sync'},
                            text:controlsPage?.OnClickRestoreChallenges?.value,
                            onClick:() => _handleSupportActions('restablecerDesafios', userSelected)
                        },
                        {
                            key: 'Permissions',
                            cacheKey: 'cachePermissions',
                            iconProps:{iconName: 'Permissions'},
                            text:controlsPage?.lblRestoreStatusMFA?.value,
                            onClick:() => handleClick()
                        }
                    ]
                }
                contentcontrolsTwo={
                    <>
                    <Panel isOpen={isSupportHistoryOpen}
                               closeButtonAriaLabel="Close"
                               type={PanelType.medium}
                               hasCloseButton={false}
                               onDismiss={handleDismissSupportHistory}
                               onRenderFooterContent={_supportHistoryFooter}
                               isFooterAtBottom={true}
                        >
                            <SupportHistoryBsr controlsPage={controlsPage} user={userSelected} clientName={clientName}/>
                        </Panel>
                    </>
                }
            />
            <div className="admin--body-container">
                <ListHeader
                    showReport={false}
                    filters={filtersList}
                    subRute={`${controlsPage?.lblAdministration?.value}`}
                    subRute3= {controlsPage?.lblSupportBSR?.value}
                    title={controlsPage?.lblSupportBSR?.value}
                    hideSearch={false}
                    onChange={(event) => {
                        setFilter({
                            ...filter,
                            pageSize: event.target.value,
                            pageNumber: filter.pageNumber === "0" ? "0" : "1"
                        });
                    }}
                    dataForm={({idDomain, searchValue}) => {
                        if(idDomain != undefined && searchValue != undefined){
                        setFilter({
                            ...filter,
                            pageNumber: "1",
                            idDomain,
                            search: searchValue
                        });
                    }else{
                        setUsersList([])
                        setPagination(null)
                    }
                    }}
                />
                <DetailsListBext
                    enableShimmer={isLoadingData}
                    listKey="supportBsrList"
                    selectionMode={1}
                    items={usersList}
                    renderItemColumn={_defaultRenderItemColumn}
                    columnTitles={columnTitles}
                    onSelect={_selectUser}
                    //viewport={1000}
                    layoutMode={0}
                    onPageChange={page => {
                        if(page !== +filter.pageNumber){
                            setFilter({
                                ...filter,
                                pageNumber: String(page),
                            });
                        }
                    }}
                    pagination={pagination}
                />
            </div>
            </>
            :
            <ContainerBaseLoading />}
        </>
    );
}

export default SupportBsr
