import React, {useContext, useEffect, useState} from 'react'
import {Panel, PanelType} from '@fluentui/react/lib/Panel';
import {DefaultButton} from '@fluentui/react/lib/Button';
import {useBoolean} from '@fluentui/react-hooks'
import {useParams} from 'react-router-dom';

import SubMenu from '../../../components/navigation/subMenu/subMenu.component'
import ListHeader from '../../../components/lists/listHeader/listHeader.component'
import DetailsListBext from '../../../components/lists/DetailsList/detailsList.component';
import {RequestFetch} from '../../../utils/Requests/requests.Controller'

import PanelRole from "./panelRole";
import {COLUMN_TITLES} from "./constants";
import LenguajeContext from '../../../context/Lenguaje/lenguajeContext';


const Roles = () => {
  const {clientName} = useParams();

  const [roleSelected, setRoleSelected] = useState();
  const [filter, setFilter] = useState({
    pageSize: 5,
    pageNumber: "0",
  });
  const [pagination, setPagination] = useState(null);
  const [rolesList, setRolesList] = useState([]);
  const [calendarList, setCalendarList] = useState([]);
  const [domainsList, setDomainsList] = useState([])
  const [controlsPage, setControlsPage] = useState({});
  const [textOnPages, setTextOnPages] = useState({})
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const filtersList = [{
    id: 1,
    key: 'searchValue',
    label: '',
    type: 'text',
  }];

  const [isLoadingData, {
    setTrue: handleLoadingData,
    setFalse: handleLoadedData
  }] = useBoolean(false);
  const [isOpen, {setTrue: openPanel, setFalse: dismissPanel}] = useBoolean(false)

  const Requests = new RequestFetch('Roles');
  const requestCalendar = new RequestFetch('Calendar');


  useEffect(() => {
    if (filter.pageNumber > 0) {
      methodsRequests.getItems(filter, clientName, calendarList, domainsList);
    }
  }, [filter])

  useEffect(async () => {

    const domainsMap = await methodsRequests.getDomains();
    const calendarResponse = await requestCalendar.getById({id: '', tenant: clientName});
    let calendarListMap = [];
    if (calendarResponse.data) {
      calendarListMap = calendarResponse.data.data.map(item => ({
        key: item.id,
        text: item.name,
      }));
      setCalendarList(calendarListMap);
    }
    await methodsRequests.getItems(filter, clientName, calendarListMap, domainsMap);
  }, [])
  useEffect(() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        //console.log(stateLanguage);
        stateLanguage.lenguaje.map((pass) => {
          // console.log(pass)
            if (pass.path == 'controls'|| pass.path =='domain') {
              pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })    
              setControlsPage(controlsPage)
            }              
        })          
    }
}, [stateLanguage])

  const methodsRequests = {
    getItems: async (filters, tenant, calendarListMap, domainsMap) => {
      setPagination(null);
      handleLoadingData()
      const response = await Requests.getItem({param: filters, tenant});
      let idNumber = 0;
      setRolesList(
        response.data.data.map(
          role => {
            idNumber++;
            const domains = role.domainsroles.map(
              domainRole => {
                const domain = domainsMap.find(domain => domainRole.idDomain === domain.key);
                return domain ? domain.text : null
              }
            )
              .filter(domain => !!domain)
              .toString();
            return (
              {
                ...role,
                isEnabledLabel: role.isEnable ? 'Habilitado' : 'Inhabilitado',
                calendarLabel: calendarListMap.find(calendar => calendar.key === role.idCalendar).text,
                domains,
                idNumber
              }
            )
          }
        )
      );
      const {
        currentPage,
        pageZise,
        totalCount,
        totalPages
      } = response.data.meta;
      setPagination({
        currentPage: currentPage,
        pageSize: pageZise,
        totalItems: totalCount,
        totalPages: totalPages
      });

      handleLoadedData();
    },
    getDomains: async () => {
      const domainRequest = new RequestFetch(`Domains`);
      const domainsData = await domainRequest.getItem({
        tenant: clientName
      });
      let domainsLit = [];
      if (domainsData.data.data) {
        domainsLit = domainsData.data.data.map(item => ({key: item.id, text: item.alias}));
        setDomainsList(domainsLit);
      }
      return domainsLit;
    },
    deleteItem:()=>{
      Requests.confirmRequest({id: roleSelected.id,ct:'json',tenant: clientName,option :"deleteItem",
      infoModal:{
          title:controlsPage?.lblDisabled?.value, 
          description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
        }
      })
  }
  }

  const _handleSelectRole = (items) => {
    if (items.length > 0) {
      setRoleSelected(items[0]);
    } else {
      setRoleSelected();
    }
  }
  const _defaultRenderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    switch (column.key) {
      case 'modules':
        const modules = new Set();
        item.rolesoperationspages.forEach(operation => modules.add(operation.page));
        return (
          <div className="container--flex c--flex-dc w10">
              {Array.from(modules).map(module => <div>{module}</div>)}
          </div>
        )
      default:
        return <span>{fieldContent}</span>;
    }
  }

  return (
    <>
      <SubMenu
        contentcontrols={
              roleSelected ?
                [
                  {
                    text:"Editar Rol",
                    iconProps:{iconName: 'Edit'},
                    onClick:()=>openPanel()
                  },
                  {
                    text:"Eliminar Rol",
                    iconProps:{iconName: 'delete'},
                    onClick:()=>methodsRequests.deleteItem()
                  }
                ]
                :
                [
                  {
                    text:"Nuevo Rol",
                    iconProps:{iconName: 'Add'},
                    onClick:()=>openPanel()
                  }
                ]
        }
        contentcontrolsTwo={
          <>
            <Panel isOpen={isOpen}
                   closeButtonAriaLabel="Close"
                   isHiddenOnDismiss={true}
                   type={PanelType.medium}
                   hasCloseButton={false}
            >
              {isOpen && <PanelRole
                formTitle={roleSelected ? 'Editar Rol' : 'Nuevo Rol'}
                calendarList={calendarList}
                closePanel={dismissPanel}
                roleSelected={roleSelected}
              />
              }
            </Panel>
          </>
        }
      />
      <div className="admin--body-container">
        <ListHeader
          showReport={false}
          subRute="Roles y permisos / Administración | "
          filters={filtersList}
          subRute3=" Administración de roles"
          title="Administración de roles"
          hideSearch={true}
          onChange={(event) => {
            setFilter({
              ...filter,
              pageSize: event.target.value,
              pageNumber: filter.pageNumber === "0" ? "0" : "1"
            });
          }}
          dataForm={({searchValue}) => {
            setFilter({
              ...filter,
              pageNumber: "1",
              search: searchValue
            });
          }}
        />
        <DetailsListBext
          enableShimmer={isLoadingData}
          listKey="supportBsrList"
          selectionMode={1}
          items={rolesList}
          columnTitles={COLUMN_TITLES}
          onSelect={_handleSelectRole}
          layoutMode={0}
          renderItemColumn={_defaultRenderItemColumn}
          onPageChange={page => {
            if (page !== +filter.pageNumber) {
              setFilter({
                ...filter,
                pageNumber: String(page),
              });
            }
          }}
          pagination={pagination}
        />
      </div>
    </>
  );
}

export default Roles
