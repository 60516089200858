export const COLUMN_TITLES = [{
  path: {
    name: "Nombre",
    maxWidth: 150
  },
  dailyPasswordLimit: {
    name:"Tiempo de vigencia de la clave dinámica",
    maxWidth: 150,
    isMultiline: true
  },
  dynamicKeyLength:{
    name: "Tiempo de clave dinámica",
    maxWidth: 150
  },
  appMobile:{
    name: "Teclado virtual",
    maxWidth: 180
  },
  captchaName:{
    name: "Captcha habilitado",
    maxWidth: 130
  }
}];

export const OPTIONAL_FIELDS = ["supportEmail"];

export const CLIENT_FORM = [
  {
    id: 0,
    type: "radio2",
    key: "idCaptcha",
    label: "Seleccione captcha",
    options: [],
    img: true,
    disabled: false,
    className: 'radio-capt'
  },
  {
    id: 1,
    key: "path",
    label: "Nombre cliente",
    type: "text2",
    disabled: true,
    onRenderLabel: null,
    className: 'select2',
    required: true,
    max: 12,
    min: 5,
    errorMessage: 'debe llenar este campo'
  },
  {
    id: 2,
    key: "clientLanguages",
    label: "Idioma",
    type: "select2",
    options: [],
    className: 'select2',
    placeholder: "Seleccione...",
    //disabled: isDisable,
    disabled: true,
    // isRequired: true,
    required: true
  },
  {
    id: 5,
    key: 'dynamicKeyLength',
    label: 'Carácteres mínimo 4 y máximo 6',
    type: 'slider',
    min: 4,
    max: 6,
    disabled: false,
    style: {valueLabel: {width: '2px'}},
    className: 'select2 pad-larg colorNorm'
  },
  {
    id: 4,
    key: 'dailyPasswordLimit',
    label: 'Tiempo de vigencia entre 0 y 10 minutos',
    type: 'slider',
    min: 0,
    max: 10,
    disabled: false,
    style: {valueLabel: {width: '2px'}},
    className: 'select2 pad-larg colorNorm'
  },
  {
    id: 3,
    key: 'idDynamicKeyType',
    label: 'Tipo de clave dinámica',
    type: 'select2',
    options: [],
    className: 'select2 mr-slc',
    disabled: false,
    placeholder: 'Seleccione tipo de clave',
    required: true
  },
  {
    id: 9,
    key: "supportEmail",
    typeInput: "email",
    label: "Correo de soporte",
    type: "text2",
    onRenderLabel: null,
    className: 'select2',
    required: false,
    isRequired: false
  },
  {
    id: 6,
    key: 'useVirtualKeyboard',
    label: 'Habilitar teclado virtual',
    type: 'toggle',
    onText: 'Sí',
    offText: 'No',
    disabled: false,
    className: 'mt-tgl',
    defaultValue: false
  },
  {
    id: 7,
    key: 'useApp',
    label: 'Link descarga BSR mobile',
    type: 'toggle',
    onText: 'Sí',
    offText: 'No',
    disabled: false,
    className: 'mt-tgl',
    defaultValue: false
  },
  {
    id: 8,
    key: 'validateUpperLowerCase',
    label: 'Más opciones de inicio de sesión',
    type: 'toggle',
    onText: 'Sí',
    offText: 'No',
    disabled: false,
    className: 'mt-tgl',
    defaultValue: false
  }
]
