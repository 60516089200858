import React, { useContext, useEffect, useState } from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useBoolean } from '@fluentui/react-hooks';
import { useParams } from 'react-router-dom';

//*****Componnents*****//
import SubMenu from '../../components/navigation/subMenu/subMenu.component';
import ContainerLargeForm from '../../components/containers/largeForm/containerLargeForm.component';
import ListHeader from '../../components/lists/listHeader/listHeader.component';
import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';
import { changeLang, changeLangForm } from "../../utils/logicRendering/changeIdiom";

//*****Methods*****//
import Lists from '../../utils/logicRendering/lists.controller';
import { RequestFetch } from '../../utils/Requests/requests.Controller';
import LenguajeContext from '../../context/Lenguaje/lenguajeContext';



const Queries = () => {
    //********** **********//
    //*****@arguments******//
    //********* **********//

    //**Propertise*//
    const [dataQuestion, setDataQuestion] = useState([]);
    const [arrayLanguage, setArrayLanguage] = useState([])
    const [arrayDomains, setArrayDomains] = useState([])

    //***Data Propertise***//
    const { getLenguaje, stateLanguage } = useContext(LenguajeContext);
    const [textOnPages, setTextOnPages] = useState({})
    const { clientName } = useParams();
    const [controlsPage, setControlsPage] = useState({});
    const [controlsPageValue, setControlsPageValue] = useState({});
    const [idLanguage, setIdLanguage] = useState(null);
    const [idDomain, setIdDomain] = useState(null);
    const [nameQuestionSelect, setNameQuestionSelect] = useState(null);
    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
    const [defaultValueInput, setDefaultValueInput] = useState([]);
    const [questionSelected, setQuestionSelected] = useState();
    const [pagination, setPagination] = useState(null);
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [isDisable, setDisable] = useState([
        { disable: false, key: "valueDefault" },
        { disable: true, key: "name" }
    ]);
    const [filter, setFilter] = useState({
        pageSize: 5,
        pageNumber: "0"
    });
    const Requests = new RequestFetch('SecurityQuestions');
    const RequestsDomain = new RequestFetch('Domains');
    const RequestsLanguages = new RequestFetch('Languages');
    const [currentPages, setCurrentPage] = useState(1);
    const [showElements, setShowElements] = useState(5);

    const actionOnChange = (data) => {
        if (data.id === "idLanguage") {
            if (data.selectedItem instanceof Object) {
                setIdLanguage(data.selectedItem.id);
            }
        }
        if (data.id === "idDomain") {
            if (data.selectedItem instanceof Object) {
                setIdDomain(data.selectedItem.id);
            }
        }
        if (data.id === "question") {
            if (typeof data.selectedItem === "string") {
                setNameQuestionSelect(data.selectedItem);
            }
        }
    }

    const [columnTitles, setColumnTitles] = useState([
        {
            question: {
                name: "Pregunta",
                maxWidth: 300,
                isMultiline: true,
                isResizable: true,
                idHtml: "lblTableQuestion"
            },
            domain: {
                name: "Dominio",
                minWidth: 60,
                maxWidth: 120,
                isMultiline: true,
                isResizable: true,
                idHtml: "lblTableDomain"
            },
            language: {
                name: "Lenguaje",
                minWidth: 60,
                maxWidth: 80,
                isMultiline: true,
                isResizable: true,
                idHtml: "lblTableLanguage"
            },
            creatingDate: {
                name: "Fecha de creación",
                minWidth: 100,
                isResizable: true,
                maxWidth: 200,
                idHtml: "coluCreationDate",
            },
            creatorUser: {
                name: "Creado por",
                maxWidth: 150,
                isResizable: true,
                idHtml: "coluCreatedBy",
            },
            updatingDate: {
                name: "Última Modificación",
                minWidth: 100,
                isResizable: true,
                maxWidth: 200,
                idHtml: "coluLastModification",
            },
            updateUser: {
                name: "Modificado por",
                maxWidth: 150,
                isResizable: true,
                idHtml: "lblModifiedBy"
            },
        }
    ])
    const [campForm, setCampForm] = useState([
        {
            id: 0,
            key: 'idLanguage',
            label: 'Lenguaje',
            type: 'select2',
            action: true,
            className: 'w10',
            required: 1,
            defaultValu: null,
            idHtml: 'lblQuestionLanguage'

        },
        {
            id: 1,
            key: 'idDomain',
            label: 'Dominio',
            type: 'select2',
            action: true,
            className: 'w10',
            required: 1,
            defaultValu: null,
            idHtml: 'lblQuestionDomain'
        },
        {
            id: 2,
            key: 'question',
            label: 'Pregunta',
            type: 'text2',
            placeholder: '...',
            required: true,
            min: 10,
            defaultValu: null,
            onRenderLabel: null,
            className: 'w10',
            idHtml: 'coluQuestion'
        },
    ])
    //debugger
    campForm[0] = Object.assign(campForm[0], { defaultValue: questionSelected ? questionSelected.idLanguage : '', options: arrayLanguage });
    campForm[1] = Object.assign(campForm[1], { defaultValue: questionSelected ? questionSelected.idDomain : '', options: arrayDomains });
    campForm[2] = Object.assign(campForm[2], { defaultValue: questionSelected ? questionSelected.question : '', max: controlsPageValue?.txtTableQuestion?.maxlength });

    const [campFilters, setCampFilters] = useState([])

    useEffect(() => {
        setCampFilters([
            {
                id: 0,
                key: 'idLanguage',
                label: controlsPage?.lblTableLanguage?.value,
                type: 'select',
                options: arrayLanguage
            },
            {
                id: 1,
                key: 'idDomain',
                type: 'select',
                label: controlsPage?.lblTableDomain?.value,
                options: arrayDomains
            },
        ])
    }, [arrayLanguage, arrayDomains])

    //********** **********//
    //*****@methods******//
    //********* **********//
    const methodsList = {
        getDataItem: (object) => {
            // const stateList = new Lists(dataQuestions, campForm)
            // setIdQuestionSelect(stateList.getDataItem(object, idQuestionSelect))
            // const stateCurrent = stateList.updateCurrent(idQuestionSelect)
            // setCampForm(stateCurrent.object)
            // setDeleteD(stateCurrent.delete)
            // setEdit(stateCurrent.edit)
            //setDefaultSelectedKeys()
        },
        voidCamps: () => {
            //debugger
            setDefaultSelectedKeys([])
            const stateList = new Lists(dataQuestion, campForm)
            setCampForm(stateList.clearCamps())
        }
    }

    const methosRequests = {
        crateItem: (dataForm) => {
            dataForm = Object.assign(dataForm, {
                idLanguage: idLanguage,
                idDomain: idDomain,
                question: nameQuestionSelect
            })
            Requests.createItem({
                body: dataForm, ct: 'json', tenant: clientName, op: 'b',
                infoModal: {
                    title: controlsPage?.lblSuccessfulProcess?.value,
                    description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                }
            })
        },
        updateItem: (dataForm, id) => {
            dataForm = Object.assign(dataForm, {
                idLanguage: idLanguage ? idLanguage : questionSelected.idLanguage,
                idDomain: idDomain ? idDomain : questionSelected.idDomain,
                question: nameQuestionSelect ? nameQuestionSelect : questionSelected.question
            })
            Requests.confirmRequest({
                body: dataForm, id: id, ct: 'json', tenant: clientName, option: 'updateItem', op: 'b',
                infoModal: {
                    title: controlsPage?.lblSuccessfulProcess?.value,
                    description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                }
            })
        },
        deleteItem: () => {
            Requests.confirmRequest({
                id: questionSelected.id, tenant: clientName, option: 'deleteItem', op: 'b',
                infoModal: {
                    title: controlsPage?.lblSuccessfulProcess?.value,
                    description: controlsPage?.lblItHbeenSuccessfullyDisabled?.value
                }
            })
        },
        getQuestions: async (filter, clientName) => {
            handleLoadingData()
            const promise = await Requests.getItem({ param: filter, tenant: clientName })
            setDataQuestion(promise.data.data);
            const {
                currentPage,
                pageZise,
                totalCount,
                totalPages
            } = promise.data.meta;
            setPagination({
                currentPage: currentPage,
                pageSize: pageZise,
                totalItems: totalCount,
                totalPages: totalPages
            });
            handleLoadedData();
        }
    }

    const _defaultRenderItemColumn = (item, index, column) => {
        let fieldContent = "";
        if (column.name === controlsPage?.lblQuestionDomain?.value) {
            fieldContent = item[column.fieldName].alias;
        } else if (column.name === controlsPage?.lblQuestionLanguage?.value) {
            fieldContent = item.language;
        } else if (column.name === controlsPage?.coluQuestion?.value) {
            fieldContent = item[column.fieldName];
        } else if (column.name === controlsPage?.coluCreationDate?.value) {
            fieldContent = item[column.fieldName];
        } else if (column.name === controlsPage?.coluLastModification?.value) {
            fieldContent = item[column.fieldName];
        } else if (column.name === controlsPage?.coluCreatedBy?.value) {
            fieldContent = item[column.fieldName];
        } else if (column.name === controlsPage?.lblModifiedBy?.value) {
            fieldContent = item[column.fieldName];
        }

        if (fieldContent !== "") {
            return <span>{fieldContent}</span>;
        }
    }

    const _questionSelectUser = (items) => {
        if (items.length > 0) {
            let objLng = { id: items[0].idLanguage }
            setQuestionSelected(items[0]);
        } else {
            setQuestionSelected();
        }
    }

    const onSubmitEventFormPanel = (data) => {
        return "Hola";
    };

    //********** **********//
    //*****@hooks******//
    //********* **********//

    useEffect(() => {
        if ((filter.pageNumber > 0) || (filter.search) || (filter.pageSize)) {
            methosRequests.getQuestions(filter, clientName);
        }
    }, [filter])

    useEffect(async () => {
        handleLoadingData()

        //Consulta para la tabla en la página 1
        const promise = await Requests.getItem(
            {
                param: {
                    pageSize: showElements,
                    pageNumber: currentPages
                },
                tenant: clientName
            }
        )
        setDataQuestion(promise.data.data);

        //Consultas para opciones select 'Lenguaje' y 'Dominio' en el panel
        const [lngResponse, domResponse] = await Promise.all([
            RequestsLanguages.getItem({ tenant: clientName }),
            RequestsDomain.getItem({ tenant: clientName })
        ]);
        //debugger
        const lngs = lngResponse.data.data.map(item => ({
            text: item.name,
            id: item.id,
            key: item.id
        }))
        const doms = domResponse.data.data.map(item => ({
            text: item.alias,
            id: item.id,
            key: item.id
        }))
        setArrayLanguage(lngs);
        setArrayDomains(doms);

        handleLoadedData()

    }, [])

    useEffect(() => {
        let controlsPage = {};
        let controlsPageValue = {};
        if (stateLanguage.lenguaje != 0) {
            stateLanguage.lenguaje.map((pass) => {
                if (pass.path == 'controls' || pass.path == 'domain' || pass.path == 'security-questions') {
                    pass.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                        controlsPageValue[data.idHtml] = data;

                    })
                    setControlsPage(controlsPage)
                    setControlsPageValue(controlsPageValue)
                }
            })
            setColumnTitles(current => changeLang(current, controlsPage))
            setCampForm(current => changeLangForm(current, controlsPage))
        }
    }, [textOnPages, stateLanguage])

    return (
        <>
            <SubMenu
                contentcontrols={
                    questionSelected ?
                        [
                            {
                                text: controlsPage?.lblEditQuestion?.value,
                                iconProps: { iconName: 'Edit' },
                                onClick: () => openPanel()
                            },
                            {
                                text: controlsPage?.OnClickDeleteQuestion?.value,
                                iconProps: { iconName: 'delete' },
                                onClick: () => methosRequests.deleteItem()
                            }
                        ]
                        :
                        [
                            {
                                text: controlsPage?.lblNewQuestion?.value,
                                iconProps: { iconName: 'Add' },
                                onClick: () => { methodsList.voidCamps(); openPanel(); }
                            }
                        ]
                }
                contentcontrolsTwo={
                    <Panel isOpen={isOpen}
                        closeButtonAriaLabel="Close"
                        isHiddenOnDismiss={true}
                        type={PanelType.smallFixedFar}
                        hasCloseButton={false}
                    >
                        {isOpen ? <ContainerLargeForm
                            dataForm={(e) => {
                                questionSelected ? methosRequests.updateItem(e, questionSelected.id) : methosRequests.crateItem(e)
                            }}
                            formTitle={questionSelected ? controlsPage?.lblEditQuestion?.value : controlsPage?.lblNewQuestion?.value}
                            Items={campForm}
                            actionOnChange={actionOnChange}
                            isDisableOpt={isDisable}
                            closePanel={dismissPanel}
                            defaultSelectedKeys={defaultSelectedKeys}
                            defaultValueInput={defaultValueInput}
                            onSubmitEventFormPanel={onSubmitEventFormPanel}
                            controlsPage={controlsPage}
                        /> : ''}
                    </Panel>
                }
            />
            <div className="admin--body-container">
                <ListHeader
                    filters={campFilters}
                    showReport={false}
                    subRute={controlsPage?.lblUrlQuestionBank?.value}
                    subRute3={controlsPage?.lblQuestionBank?.value}
                    title={controlsPage?.lblQuestionBank?.value}
                    dataForm={({ idLanguage, idDomain }) => {
                        setFilter({
                            ...filter,
                            pageNumber: "1",
                            idLanguage,
                            idDomain
                        });
                    }}
                    onChange={(event) => {
                        setFilter({
                            ...filter,
                            pageSize: event.target.value,
                            pageNumber: filter.pageNumber === "0" ? "0" : "1"
                        });

                    }}
                    InputChange={(e) => {

                        setFilter({
                            ...filter,
                            search: e
                        })

                    }}
                />
                <DetailsListBext
                    enableShimmer={isLoadingData}
                    listKey="questionsBsrList"
                    selectionMode={1}
                    items={dataQuestion}
                    renderItemColumn={_defaultRenderItemColumn}
                    columnTitles={columnTitles}
                    onSelect={_questionSelectUser}
                    onPageChange={page => {
                        if (page !== +filter.pageNumber) {
                            setFilter({
                                ...filter,
                                pageNumber: String(page),
                            });
                        }
                    }}
                    pagination={pagination}
                />
            </div>
        </>
    );
}

export default Queries