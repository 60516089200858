import React, { useContext, useEffect, useState } from 'react'
import CookiesJS from 'js-cookie';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks'
import { useParams } from 'react-router-dom';
//*****Componnents*****//
import SubMenu from '../../components/navigation/subMenu/subMenu.component'
import ContainerLargeForm from '../../components/containers/largeForm/containerLargeForm.component'
import ContainerBaseLoading from '../../components/containers/baseLoading/containerBaseLoading.component'
import ListHeader from '../../components/lists/listHeader/listHeader.component'
import ListBody from'../../components/lists/listBody/listBody.component'


//*****Methods*****//
import Lists from '../../utils/logicRendering/lists.controller'
import {RequestFetch} from '../../utils/Requests/requests.Controller'
import CountPages from '../../utils/logicRendering/countPages.controller'
import LenguajeContext from '../../context/Lenguaje/lenguajeContext';


const Files = ()=>{
  
    /************************
     * @arguments 
     ************************/
        //***Propertise***//
        const [edit, setEdit] = useState(false);
        const [deleteD, setDeleteD] = useState(false);
        const [domainauthenticationfactors, setdomainauthenticationfactors] =
        useState([]);
        const [idItemsSelect, setIdItemsSelect] = useState([]);
        const [currentPages, setCurrentPage]= useState(1);
        const [showElements, setShowElements]= useState(5);
        const [itemsFiles, setItemsFiles] = useState(null);
        const [itemsType, setItemsType] = useState(null);
        const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([]);
        const [textFileType, setTextFileType] = useState("file");
        const [controlsPage, setControlsPage] = useState({});
        const [textOnPages, setTextOnPages] = useState({})
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        const [defaultValueInput, seDefaultValueInput] = useState([]);
        const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
        const [responseCode, setResponseCode] = useState(null);
        const { clientName } = useParams();
        const [isDisable, setDisable] = React.useState([
          { disable: false, key: "valueDefault" },
        ]);
        //#region Variables de estado de los componentes
        const [language, setIdLanguage] = useState();
        const [fileType, setFileType] = useState();
        const [name, setName] = useState();
        const [path, setPath] = useState();
        const {idClient} = JSON.parse(CookiesJS.get('dataClient'));
        // const refresh = CookiesJS.get('refresh_token');
        // console.log(refresh)
        const [ObjectPetition, setObjectPetition] = useState();
        const [itemsComponentInformation, setItemsComponentInformation] = useState();
        //***Data Propertise***//
        const Requests = new RequestFetch("File");
        const RequestsFile = new RequestFetch("File/Upload");
        const RequestsTypes = new RequestFetch("FileTypes")
        const RequestsLanguages = new RequestFetch("Languages")
        const bodyFormData = new FormData();
    //#region Consulta de todos los lenguajes
    let arrayLanguages = [];
    const [languages, setLanguages] = useState(RequestsLanguages.getItem({tenant: clientName}));
    useEffect(() => {
        languages.then((data)=>{
        for (const [key, value] of Object.entries(data.data)) {
        if (key === "data") {
          for (let index = 0; index < value.length; index++) {
            const element = value[index];
            arrayLanguages.push({
              key: element["id"],
              text: element["name"],
            });
          }
        }
      }
        })
    }, [languages])
    //#endregion
    //#region se consultan los tipos de archivo
        let arrayTypes = [];
    const [filesTypes, setFilesTypes] = useState(RequestsTypes.getItem({tenant: clientName}));
    useEffect(() => {
        filesTypes.then((data)=>{
            
        for (const [key, value] of Object.entries(data.data)) {
        if (key === "data") {
          for (let index = 0; index < value.length; index++) {
            const element = value[index];
            arrayTypes.push({
              key: element["id"],
              text: element["name"],
              allowUrl: element["allowUrl"]
            });
          }
        }
      }
        })
    }, [filesTypes])
    //#endregion
    //#region Acciones de los componentes
    const actionOnChange = (data) => {
    //#region Se llenan los datos de los controles
    if (data.id === "idLanguage") {
      setIdLanguage(data.selectedItem);
    }
    if (data.id === "idFileType") {
        setFileType(data.selectedItem)
        if(data.allowUrl !== null){
        if(data.allowUrl === false ){
            setTextFileType("file")
            setDisable([
                { disable: false, key: "path" },
                { disable: true, key: "name" },
                { disable: false, key: "idLanguage" },
                { disable: false, key: "idFileType" },
            ])
        }else{
            setTextFileType("text")
                setDisable([
                { disable: false, key: "path" },
                { disable: false, key: "name" },
                { disable: false, key: "idLanguage" },
                { disable: false, key: "idFileType" },
            ])
        }
        }
    }
    if (data.id === "name") {
        setName(data);
    }
    if (data.id === "path") {
        setPath(data);
    }
      //#endregion
    };
    const actionOnClick = (data) => {
    };
    const onSubmitEventFormPanel = (data) => {
        let dataForm = {
            
        }
    }

    useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            //console.log(stateLanguage);
            stateLanguage.lenguaje.map((pass) => {
              // console.log(pass)
                if (pass.path == 'controls'|| pass.path =='domain') {
                  pass.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  })    
                  setControlsPage(controlsPage)
                }              
            })          
        }
    
    }, [textOnPages, stateLanguage])

    //#region Configuracion de componentes
    const SpaceTitel = ['Nombre del archivo','Tipo de documento', 'Fecha de creación', 'Creado por'];
    const OrdItems = ['name', 'type', 'creatingDate', 'creatorUser'];
    const [campForm, setCampForm] = useState([

      {
        id: 1,
        key: "idLanguage",
        label: "Idioma",
        type: "select2",
        options: arrayLanguages,
        placeholder: "Seleccione...",
        multiSelect: false,
        disabled: isDisable,
        isRequired: true,
        required: 1,
        className:'w10'
      },
      {
        id: 2,
        key: "idFileType",
        label: "Tipo de documento",
        type: "select2",
        options: arrayTypes,
        placeholder: "Seleccione...",
        multiSelect: false,
        disabled: isDisable,
        isRequired: false,
        required: 1,
        className:'w10'
      },
      {
        id: 3,
        key: "name",
        label: "Nombre del archivo",
        type: "text",
        typeInput:"text",
        placeholder: "Ingrese el nombre del archivo",
        multiSelect: false,
        disabled: isDisable,
        isRequired: false,
        required: 1,
        max:70,
        min:10,
      },
      {
        id: 6,
        key: "path",
        label: "Archivo",
        title: "Archivo",
        type: textFileType,
        typeInput:"text2",
        placeholder: "Seleccione un archivo...",
        disabled: false,
        isRequired: false,
        onRenderLabel:null,
        required: 1,
        max:70,
        min:10,
        documen:true,
        className:'w10'
      },
    ]);
    //#endregion
  

    campForm[3]={
        id: 6,
        key: "path",
        label: "Archivo",
        title: "Archivo",
        type: textFileType,
        typeInput:"text2",
        placeholder: "Seleccione un archivo...",
        disabled: false,
        isRequired: false,
        onRenderLabel:null,
        required: 1,
        max:70,
        min:10,
        documen:true,
        className:'w10'
    }


    /************************
    * @methods 
    ************************/
        const methodsList = {
            getDataItem:(object)=>{
                const stateList = new Lists(itemsType, campForm)
                setIdItemsSelect(stateList.getDataItem(object, idItemsSelect))
                const stateCurrent = stateList.updateCurrent(idItemsSelect)
                setCampForm(stateCurrent.object)
                setDeleteD(stateCurrent.delete)
                setEdit(stateCurrent.edit)
                
                if (itemsFiles.length === 1) {
                let filters = "";

                if (itemsFiles[0].extension === ".pdf") {
                  setDisable([
                    { disable: true, key: "idClient" },
                    { disable: true, key: "idLanguage" },
                    { disable: true, key: "idFileType" },
                    { disable: false, key: "name" },
                    { disable: true, key: "path" },
                    // { disable: false, key: "path2" },
                  ]);
                }
                if (itemsFiles[0].extension === "url") {
                  setDisable([
                    { disable: false, key: "idClient" },
                    { disable: false, key: "idLanguage" },
                    { disable: false, key: "idFileType" },
                    { disable: false, key: "name" },
                    { disable: false, key: "path" },
                    // { disable: true, key: "path2" },
                  ]);
                }
            }
            },
            voidCamps:()=>{
                setDefaultSelectedKeys([])
                const stateList = new Lists(itemsType, campForm)
                setCampForm(stateList.clearCamps())
            }
        }

        const methosRequests = {
            chageGetItems:(filters)=>{

                setPromiseNotifiType(RequestsTypes.getItem({param:filters}));
                setPromise(Requests.getItem({param:filters, tenant: clientName}));
            },
            crateItem:(dataForm)=>{
                
                if(textFileType === "text"){
                    dataForm = Object.assign(dataForm,{
                        idClient: idClient,
                        idLanguage:language.key,
                        idFileType:fileType.key,
                        name:dataForm.name
                    })
                    Requests.createItem({ body: dataForm, ct: "json" , tenant:clientName, 
                        infoModal:{
                      title:controlsPage?.lblSuccessfulProcess?.value,
                      description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                        }
                    })
                }else{
                    let dataFormFile = {
                        idClient: idClient,
                        idLanguage:language.key,
                        idFileType:fileType.key,
                        name:dataForm.name
                    };
                    let dataFormFileJson = JSON.stringify(dataFormFile)
                    bodyFormData.append('file', dataForm.path);
                    RequestsFile.createItem({
                        body:bodyFormData, 
                        ct: "form" , 
                        tenant:clientName, 
                        param:dataFormFileJson, 
                        infoModal:{
                      title:controlsPage?.lblSuccessfulProcess?.value,
                      description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                        }
                    })
                }
            },
            deleteItem:()=>{
                idItemsSelect.forEach(element=>Requests.confirmRequest({ id:element.id, tenant: clientName, option:'deleteItem',
                infoModal:{
                  title:controlsPage?.lblDisabled?.value, 
                  description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
                }}))
            }
        }

    /************************
     * @hooks 
    ************************/
        const [promiseType, setPromiseNotifiType] = useState(
            RequestsTypes.getItem({tenant: clientName})
        );
        const [promiseTypeDoc, setPromiseTypeDoc] = useState(RequestsTypes.getItem({
             tenant: clientName
        }))
        const [promiseLanguages, setPromiseLanguages] = useState(
            RequestsLanguages.getItem({tenant: clientName})
        );
        const [promise, setPromise]= useState(Requests.getItem({
            param: {pageSize: showElements, pageNumber: currentPages, idClient: idClient },tenant: clientName
        }))
        const [update, setUpdate] = useState(null);

        useEffect(()=>{
            promise.then(data =>{
                setItemsType(data.data);
            })
            promiseTypeDoc.then(data =>{
                setItemsFiles(data.data)
            })
        },[promise])

        useEffect(() => {
            if(update ){
            update.then(data =>{
                setResponseCode(data.status);
            })}
        }, [update]);

        if(itemsType && itemsFiles){
            itemsType.data.map(tal=>{
                itemsFiles.data.map(mas=>{
                    if(tal.idFileType == mas.id){
                        let nameType = mas.name
                        tal =Object.assign(tal,{type:nameType})
                    }
                })
            }
            )
        }

    /************************
     * @resturns 
     ************************/
        if(itemsFiles === null || itemsType === null){
            return (<ContainerBaseLoading/>)
        }
        const numberPage = CountPages(itemsType.meta.totalPages)
        return(
            <>
                <SubMenu
                contentcontrols={
                    !deleteD && !edit ?
                    [
                        { 
                            text:"Agregar Documento",
                            iconProps:{ iconName: "Add" },
                            onClick:() => {
                            methodsList.voidCamps();
                            openPanel();
                            }
                        }
                    ]
                    :
                    [
                        deleteD ?
                        {
                            text:"Inhabilitar Documento",
                            iconProps:{ iconName: "delete" },
                            onClick: ()=> methosRequests.deleteItem()
                        }:null
                    ].filter(obj => obj !== null)
                }
                contentcontrolsTwo={
                    <>
                        <Panel isOpen={isOpen}
                            closeButtonAriaLabel="Close"
                            isHiddenOnDismiss={true}
                            type={PanelType.smallFixedFar}
                            hasCloseButton={false}
                        >   
                        
                            {isOpen ?<ContainerLargeForm
                                dataForm ={(e) =>{
                                    edit ? methosRequests.updateItem(e, idItemsSelect[0].id ): methosRequests.crateItem(e)
                                }}
                                formTitle={edit?'Editar Documento':'Nuevo Documento'}
                                Items={campForm}
                                closePanel={dismissPanel}
                                actionOnChange={actionOnChange}
                                actionOnClick={actionOnClick}
                                isDisableOpt={isDisable}
                                onSubmitEventFormPanel={onSubmitEventFormPanel}
                                itemsComponentInformation={itemsComponentInformation}
                                textFileType = {textFileType}
                                defaultSelectedKeys={defaultSelectedKeys}
                                defaultValueInput={defaultValueInput}
                            />:''}
                        </Panel>
                    </>
                }
            /> 
                <div className="admin--body-container">
                    <ListHeader 
                        filters={false}
                        showReport={false}
                        subRute=" Documentos/Administración | "
                        subRute3=" Administración de Documentos"
                        title="Administración de Documentos"
                        dataForm={(e)=>methosRequests.chageGetItems({
                            pageSize:showElements, pageNumber:currentPages,
                            idDomain:e.idDomain, idLanguage:e.idLanguage
                        })}
                        onChange={(e)=>{
                            setShowElements(e.target.value); 
                            methosRequests.chageGetItems({pageSize:e.target.value, pageNumber:currentPages});
                        }}
                        inputChange={(e)=>{
                            methosRequests.chageGetItems({search:e, pageSize:setShowElements, pageNumber: currentPages });
                        }}
                    />
                    <ListBody 
                        spaceTitle={SpaceTitel}
                        Items={itemsType.data} 
                        meta={itemsType.meta}
                        numberPages={numberPage}
                        initRangeText={currentPages}
                        orditem={OrdItems}
                        finishRangeText={showElements}
                        totalRangeText={itemsType.data.length}
                        typelist='listSelect'
                        selectdata={methodsList.getDataItem}
                        paginate={(number)=>{
                            setCurrentPage(number); 
                            methosRequests.chageGetItems({pageSize:showElements, pageNumber:number});
                        }}
                    />
                </div>
            </>    
        );
}

export default Files