//****Librarys ****//
import React, { useContext, useState, useEffect } from "react";
import { render } from "react-dom";
import { Checkbox, Stack } from "@fluentui/react";
import {
  ChoiceGroup,
  IChoiceGroupOption,
} from "@fluentui/react/lib/ChoiceGroup";
import CookieJS from "js-cookie";
import { HubConnectionBuilder } from "@microsoft/signalr";

//*****Componnents*****//
import { useNavigate, useParams } from "react-router-dom";

//*****Context*****//
//*****File*****//


//*****Methods*****//
import { RequestFetch } from "../../utils/Requests/requests.Controller";
import InputFile from "../../components/Forms/inputFile/inputFile.component";
import LenguajeContext from "../../context/Lenguaje/lenguajeContext";
import ModalFluent from "../../components/tools/modalFluent/ModalFluent";
import { BSR_URI, SERVISE_URI, SSO_URI } from "../../assets/settings/settinsrouters";
import ButtonBlock from '../../components/tools/buttonBlock/buttonBlock.component'


/*****************
 *****************
 * @returns copomnent BDM-SSO
 *****************
 *****************/

const ChallengesMfa = (props) => {
  /************************
   * @arguments
   ************************/
  const [questions, setQuestions] = useState();
  const navigate = useNavigate();
  const { state, getError } = props
  const [resultResponse, setResultResponse] = useState(false);
  const { clientName } = useParams();
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const [isPass, setIsPass] = useState(true);
  const {user} = JSON.parse(CookieJS.get("dataUser"));
  let idConnection = CookieJS.get("idConnection");
  const requests = new RequestFetch(`IdentityVerification/post`);
  const strChallenge = new RequestFetch(`IdentityVerification/challenges`);
  const response = new RequestFetch(`IdentityVerification/response`);
  const enableCount = new RequestFetch("Users/manageMFAO365");
  const [dataPost, setDataPost] = useState([]);
  const [position, setPosition] = useState(0);
  const [currentChallenge, setCurrentChallenge] = useState();
  const [challengesID, setChallengesID] = useState();
  const [requiredChallenge, setRequiredChallenge] = useState([]);
  const [optionalChallenge, setOptionalChallenge] = useState([]);
  const [connection, setConnection] = useState(null);
  const [enableEmail, setEnableEmail] = useState(Boolean)
  const [idConectCok, setPrueba] = useState('')
  const [inputs, setInputs] = useState({});
  const params = new URLSearchParams({
    username: state.user,
    domain: state.roll,
    idRequest:idConnection
  }).toString();
  const [token, setToken] = useState(CookieJS.get('token'))
  const [paramsChallenges, setParamsChallenges] = useState();
  const [textOnPages, setTextOnPages] = useState({})
  const [confirmEmail, setConfirmEmail] = useState(null)
  const [enableButon, setEnableButon] = useState(false)

  // Variables implementacion de textos -----------------------------------------------------------------------------------------

  const [controlsPage, setControlsPage] = useState({});

  // ----------------------------------------------------------------------------------------------------------------------------

  /************************
   * @methods
   ************************/
   useEffect(() => {
    // const response = Requests.getById({id:'', tenant: clientName });
    // response.then(data=>{
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
      
      stateLanguage.lenguaje.map((pass) => {
        if (pass.path == 'verification-challenge' || pass.path == 'manage-mfa' || pass.path == 'controls' || pass.path == 'notifications-expiry-passwords') {
          pass.webcontrolspages.map((data) => {
            if (data.maxlength) {
              controlsPage[data.idHtml] = {...data.languagewebcontrols[0],...{maxlength: data.maxlength,minlength: data.minlength}};
            }else{
              controlsPage[data.idHtml] = data.languagewebcontrols[0];
            }
          })
          setControlsPage(controlsPage)
        }
        
      })          
    }
    // })
  }, [textOnPages, stateLanguage])


  useEffect(async () => {
    if (state.user && state.roll && confirmEmail != null) {
      const promisePost = await requests.createItemParams({
        param: params,
        tenant: clientName,
        headerEx:{idLanguageUser:props.idLng, isForManageMfa:true},
        reload:true,
        actionClick:()=>rediref(),
        hideIcon:true
      })
      setChallengesID(promisePost.data.data.id);
      setDataPost(promisePost.data.data.challenges);
    }
  }, [confirmEmail]);
  
  useEffect(async () => {
    if (state.user && state.roll) {
      if (props.isDisable == false) {
        const datP = [{idChallenge:0}]
      const challenge = await buildChallenge(0, datP, idConectCok);
      const conf = [challenge]
      setRequiredChallenge(conf);
      }
      
    }
  }, [controlsPage,]);

  const handleChange = (value,name) => { 

     // debugger
     setInputs(values => ({...values, [name+""]: value}));
}
  const methodsChallenge = {
    responseChallenge: async (form) => {
      form.preventDefault();

    const formData = new FormData(form.target)
    const data = {}

      const responseValue = {
        value: form.target[0].value,
      };
      const paramsResponse = new URLSearchParams({
        username: state.user,
        domain: state.roll,
        verification: challengesID,
        challenge: dataPost[position].id,
      }).toString();
      
      const dataResponse = await response.createItemParams({
        param: paramsResponse,
        tenant: clientName,
        headerEx:{idLanguageUser:props.idLng},
        body: responseValue,
        actionClick:()=>rediref(),
        hideIcon:true
      })
      
      setChallengesID(dataResponse.data.data.id);
      setDataPost(dataResponse.data.data.challenges);
    },
    prueba:async(form)=>{
        form.preventDefault();
        // if(form.target.length > 0){
          const formData = new FormData(form.target);
          const data = Object.fromEntries(formData.entries());
          const payload = {
            securityQuestions : Object.keys(data)
                .map(key => ({
                  id: key,
                  reply: data[key]
                }))
          }
          setParamsChallenges(payload);
        // }

        const paramsResponse = new URLSearchParams({
          username: state.user,
          domain: state.roll,
          verification: challengesID,
          challenge: dataPost[position].id,
        }).toString();
        const dataResponse = await response.createItemParams({
          param: paramsResponse,
          tenant: clientName,
          body: payload,
          headerEx:{idLanguageUser:props.idLng},
          actionClick:()=>rediref(),
          hideIcon:true
        });
        setChallengesID(dataResponse.data.data.id);
        setDataPost(dataResponse.data.data.challenges);
    },
    
    changePass: async (form) => {
      form.preventDefault();
      const responseValue = {
        value: form.target[0].value,
      };
    },

    isEmailLegal: async (form) => {
      form.preventDefault();
      var radios = document.getElementsByName('optboolean');
    for (var radio of radios)
    {
      if (radio.checked) {
        if (radio.value=='true') {
          setEnableEmail(true)
          setConfirmEmail(true)
        }else{
          setEnableEmail(false)
          setConfirmEmail(false)
        }
      }
    }      
    },
    habilitar: async(form) => {
      form.preventDefault()
      const optMfa = Object.keys(props.optMfa)
      let enab = ''

      if (props.optMfa.enableMfa != undefined) {
        if (props.optMfa.enableMfa) {
          enab = controlsPage.lblMFAEnabledSuccessfully?.value
        } else {
          enab = controlsPage.lblMFAuthenticationHasBeenDisabled?.value
        }
      }
      const txtOpt ={
        enableMfa:enab,
        disableMfaForDays: controlsPage.lblMFAHasBeenDisabledFiveADays?.value
      }
  
      const paramsResponse = new URLSearchParams({
        idUser:user.id, ...props.optMfa
      }).toString();
      const resp = await enableCount.createItemParams({
        param: paramsResponse,
        tenant: clientName,
        headerEx:{idLanguageUser:props.idLng},
        actionClick:()=>rediref(),
        hideIcon:true
      });
    if (resp.status == 200 && resp.data.data) {
      
      let info ={
        title: controlsPage.lblSuccessfulProcess?.value,
        description: txtOpt[optMfa[0]],
        type:'success'
      }
      render(
        <ModalFluent
        props={info}
        actionOnClick={()=>rediref()}
        hideIcon={true}
        objectRequest={()=>rediref()}
        />,
        document.getElementById("modal")
        );
        
    }

    },
  };

  
  const getConnectionId = () => {
    connection.invoke("Getconnectionid").then((data) => {
      
      CookieJS.set("idConnection", data);
      saveSocketConnection(data.data).then((response) => {
        console.log("save socket connection works", response);
      });
    });
  };

  const saveSocketConnection = (idConection) => {
    CookieJS.set("idConnection", idConection);
    return true;
  };

  const rediref = ()=>{
    // window.location.href = `${BSR_URI}${clientName}/user`
    window.location.reload()
  }

  /************************
   * @hooks
   ************************/

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${SERVISE_URI}identityVerificationHub/`)
      //.withAutomaticReconnect()
      .build();
    //getConnectionId()
    newConnection.onreconnected((connectionId) => {
      getConnectionId();
    });

    setConnection(newConnection);
  }, []);
  useEffect(() => {

    if (connection) {
      connection
        .start()
        .then((result) => {

          // setTimeout(() => {
          //     getConnectionId()
          // }, 2000);
          connection
            .invoke("Getconnectionid")
            .then((data) => {
             
              CookieJS.remove("idConnection");
                CookieJS.set("idConnection", data);
                setPrueba(data)
              

              // saveSocketConnection(data.data).then(response => {
              // console.log('save socket connection works', response);
              // });
            })
            .catch((error) => {
              console.log(error);
            });
          connection.on("ReceiveVerification", (data) => {
            if (!data.data.name) {
            setChallengesID(data.data.id);
            setDataPost(data.data.challenges);
            }else{
              let info ={
                title: data.data.name,
                description: data.data.code+ ' - ' +data.data.message,
                type:'danger'
            }
            render(
                <ModalFluent
                    props={info}
                    reload={true}
                    actionOnClick={()=>rediref()}
                    // confirm={actionConfirm}
                    // objectRequest={redirectPasword}
                />,
                document.getElementById("modal")
            );
            }
            
          });


        })
        .catch((e) => {alert("Connection failed: ", e)
      });
    }
  }, [connection]);

  if (connection) {
    connection.on("ReceiveVerification", (data) => {
      // console.log('ReceiveVerification', data.result);
    });
  }

  

  useEffect(async () => {
    if (dataPost.length == 1) {
      //si es igual a 1 es un desafio requerido
      let rc = [];
      if (idConectCok!='') {
        const challenge = await buildChallenge(0, dataPost, idConectCok);//primer parametro, posicion del desafio, id conectio es el identificador de la conexión,
      rc.push(challenge);
      setRequiredChallenge(rc);
      }
      
    } else if (dataPost.length > 1) {
      //Si es mayor a 1 el usuario debe elegir el desafio
      // debugger;
      let rc = [];
      let data = [{idChallenge: 4}]
      if (idConectCok!='') {
      const challenge = await buildChallenge(0, data, idConectCok);//primer parametro, posicion del desafio, id conectio es el identificador de la conexión,
      rc.push(challenge);
      setRequiredChallenge(rc);
      }
    } else {
      const optMfa = Object.keys(props.optMfa)
      let enab = ''

      if (props.optMfa.enableMfa != undefined) {
        if (props.optMfa.enableMfa) {
          enab = controlsPage.lblConfirmTheMFAEnableProcess?.value
        } else {
          enab = controlsPage.lblConfirmProcessOfDisablingMFAFinal?.value
        }
      }
      const txtOpt ={
        enableMfa:enab,
        disableMfaForDays: controlsPage.lblConfirmProcessDeactivateMFAforFiveDays?.value,
        wasDisabledPerDays: controlsPage.lblConfirmProcessDeactivateMFAforFiveDays?.value
      }
      //Finalizó exitosamente los desafios
      if (challengesID) {
        setRequiredChallenge([{challengeComponent:
          <>
          <form onSubmit={methodsChallenge.habilitar}>
            <p className="mg10-h">{txtOpt[optMfa[0]]}</p>
            
            <center>
            <button type="submit" 
            className={`${enableButon?'button--disable':'button--primary'} button--default mrg-button mg10-h`}
            onClick={()=>setEnableButon(true)}
            disabled={enableButon}
            >
            {controlsPage.smtContinue?.value}
              </button>
              </center>
          </form>
        </>
        }])
      }
    }

  }, [dataPost,idConectCok,controlsPage,enableButon]);

  const buildChallenge = async (position, dataPost, idConectCok) => {
    return {
      ...dataPost[position],
      isDone: false,
      challengeComponent: await challengesList(
        position,
        dataPost[position].idChallenge,
        idConectCok
      ),
    };
  };

  const challengesList = async (position, idChallenge, idConect) => {
    let paramsChallenges = "";
    let dataChallenge = "";

    switch (idChallenge) {
      case 0:
        // setCurrentChallenge(dataPost[position].id);
        return (
          <form onSubmit={methodsChallenge.isEmailLegal} className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse w9">
          <div className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse w9">
          <p>{controlsPage.lblAccessToEmail?.value}.</p>
          <div className="camp-radio w10 mg10-h">
              <div className="radius-opt">
                <input id="acept" type="radio" name="optboolean" defaultChecked={true} value={true} />
                <label htmlFor="acept" >{controlsPage.rbtHasAccessToEmail?.value}</label>
              </div>
              <div className="radius-opt">
                <input id="negat" type="radio" name="optboolean" value={false} />
                <label htmlFor="negat">{controlsPage.rbtWithoutAccessToEmail?.value}</label>
              </div>
            </div>
          </div>
          <button type="submit" className="button--default button--primary mg20-h">
              {controlsPage.smtContinue?.value}
          </button>
          </form>
        );
      case 1:
        setIsPass(true);
        // ingresa codigo que llega al correo electronico.


        paramsChallenges = new URLSearchParams({
          username: state.user,
          domain: state.roll,
          verification: challengesID,
          challenge: dataPost[position].id,
          idRequest: idConnection,
          sendToMainEmail:enableEmail
        }).toString();
        // console.log(paramsChallenges)
        dataChallenge = strChallenge.createItemParams({
          param: paramsChallenges,
          tenant: clientName,
          headerEx:{
            idLanguageUser:props.idLng,
            isForManageMfa:true
          },
          ct: "json",
          actionClick:()=>rediref(),
          hideIcon:true
        });

        setCurrentChallenge(dataPost[position].id);
        return (
          <form onSubmit={methodsChallenge.responseChallenge} className="top-button container--flex c--flex-wc c--flex-hc w9">
            <br />
            <div className="w10">
              <h3>{controlsPage.lblCodeVerification?.value}</h3>
            </div>
            <p>{enableEmail?
            controlsPage.lblCodeMailInstitutionalDescription?.value
            :controlsPage.lblCodeVerificationMailDescription?.value}
            </p>
            <InputFile
              name="valueResponse"
              type="password"
              style='w52'
              withOutSpace={true}
              maxLength={controlsPage.txtEntertheverificationcode.maxlength}
              minLength={controlsPage.txtEntertheverificationcode.minlength}
              // msgError={controlsPage.txtEntertheverificationcode?.infoMessage}
              onChange={() => {}}
            />
            <div className="top-button container--flex w52 c--flex-wc c--flex-hc c--flex-wsb mg20-h">
            <button className="button--default button--second"  type="button" onClick={rediref}>
              {controlsPage.smtCancel?.value}
            </button>
            <button type="submit" className="button--default button--primary">
            {controlsPage.smtSend?.value}
            </button>
            </div>
          </form>
        );
      case 2:
        //mostrar mensaje "señor usuario, se le enviará un correo electronico."
        setIsPass(true);
        paramsChallenges = new URLSearchParams({
          username: state.user,
          domain: state.roll,
          verification: challengesID,
          challenge: dataPost[position].id,
          idRequest: idConect,
          sendToMainEmail:enableEmail
        }).toString();
        dataChallenge = strChallenge.createItemParams({
          param: paramsChallenges,
          tenant: clientName,
          headerEx:{
            idLanguageUser:props.idLng,
            isForManageMfa:true
          },
          actionClick:()=>rediref(),
          hideIcon:true
        });
        dataChallenge
          .then((e) => {
            // console.log(e);
          })
          .catch((error) => {
            // console.log(error);
          });
        return (
          <div className="top-button container--flex c--flex-wc c--flex-hc w9">
            <div className="w10">
            <h3>{controlsPage.lblLinkVerification?.value}</h3>
            </div>
            <p>{enableEmail?controlsPage.lblLinkMailInstitutionalDescription?.value:controlsPage.lblLinkMailDescription?.value}</p>
            <div className="c--flex-wse">
            <button className="button--default button--second mg20-h"  type="button" onClick={rediref}>
              {controlsPage.smtCancel?.value}
            </button>
            </div>
          </div>
        );
      case 3:
        let quest = [];
        //mostrar preguntas asociadas que debe responder
        paramsChallenges = new URLSearchParams({
          username: state.user,
          domain: state.roll,
          verification: challengesID,
          challenge: dataPost[position].id,
          idRequest: idConnection,
          sendToMainEmail:enableEmail
        }).toString();
        dataChallenge = await strChallenge.createItemParams({
          param: paramsChallenges,
          headerEx:{
            idLanguageUser:props.idLng,
            isForManageMfa:true
          },
          tenant: clientName,
          reload:true,
          actionClick:()=>rediref(),
          hideIcon:true
        });
        dataChallenge.data.map((qs) => {
          quest.push(qs);
          setQuestions([questions, qs]);
        });
        return (
            // quest.map((_quest, index) => {
          <div className="top-button container--flex c--flex-wc c--flex-hc w9">
            <div className="w10">
              <h3>{controlsPage.lblQuestionVerification?.value}</h3>
            </div>
            <p>{controlsPage.lblQuestionVerificationDescript?.value}</p>
            <form className="w10" onSubmit={methodsChallenge.prueba} style={{ marginBottom: '50px' }}>
                {
                    quest.map((_quest, index) => {
                        return(
                        <>
                        <label>{_quest.question}</label>
                        <InputFile
                        name={_quest.id}
                        key={_quest.id}
                        type="password"
                        onChange={(value)=>handleChange(value,_quest.id)}
                        />
                        </>
                        )
                    })
                }
                <div className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse">
                  <button className="button--default button--second mg20-h"  type="button" onClick={rediref}>
                  {controlsPage.smtCancel?.value}
                  </button>
                    <button
                        type="submit"
                        className="button--default button--primary mg20-h"
                    >
                    {controlsPage.smtSend?.value}
                    </button>
                </div>
            </form>
          </div>
                // <h1>fdkldfjl</h1>;
            // })
        );

      case 4:
        const textChallenge={
          1:controlsPage.lblCodeVerification?.value,
          2:controlsPage.lblLinkVerification?.value,
          3:controlsPage.lblQuestionVerification?.value
        }
        
        // setCurrentChallenge(dataPost[position].id);
        return (
          <form className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse w9">
            <br />
            <div className="w10">
                <h3>{controlsPage.lblOptionalVerificationMethods?.value}</h3>
            </div>
            <p>{controlsPage.lblSelectOneOfTheFollowingVerificationMethods?.value}</p>
            <div className="w10 mgb">
              { dataPost ? 
              dataPost.map((optChallenge) => {
                  return(
                  <>
                  <div key={optChallenge.idChallenge} className="" >
            <ButtonBlock onClick={()=>setDataPost([optChallenge])}
              text=
              {textChallenge[optChallenge.idChallenge]} />
            </div>

                              
                  </>
                  )
              }) : <p>No hay desafíos desponibles</p>
              }          
            </div>
            <div className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse">
            <button className="button--default button--second"  type="button" onClick={rediref}>
              {controlsPage.smtCancel?.value}
            </button>
            </div>
          </form>
        );
        
      
      default:
        return <p>Desafios no definidos, comuniquese con soporte.</p>;
    }
  };

  useEffect(() => {
    if (requiredChallenge.length > 0) {
      // alert("tiene desafios requeridos");
      // console.log(requiredChallenge);
    } else if (optionalChallenge) {
      // alert("tiene desafios opcionales");
    }
  }, [requiredChallenge, optionalChallenge]);

  useEffect(() => {
    
    if (state.user === undefined || state.roll === undefined) {
      // navigate(`/${PATH_APPLICATION}/${clientName}/user`);
    }
  }, []);


  /************************
   * @resturns
   ************************/

  return (
    <>
      {requiredChallenge.length == 1 && (
        <>
          {/* {requiredChallenge[0].idChallenge} */}
          {requiredChallenge[0].challengeComponent}
          {/* <p>Gestor de cambio o recuperación de contraseña</p>
                    <ButtonFloat icon={<a href="/LoginForm">&#x1F511;</a>} type={1} typef="btnFloat-header" text="Opciones de inicio de sesión" />
                    <label>Seleccione al tipo de restauración para la contraseña.</label>
                    <ChoiceGroup defaultSelectedKey={0} options={itemsChoice} required={true} className="container--choicegroup"/>
                    <Checkbox label="Este proceso requiere la aceptación de términos y condiciones, conforme a la solicitud de autorización y política de tratamiento de datos personales."/> */}
          {/* <ContainerCaptcha/> */}
          {/* <section> */}
          {/* <button className={isPass?"button--default button--disabled":"button--default button--primary"} onClick={()=>{setPosition(position+1)}} disabled={isPass}>SIGUIENTE</button>  */}
          {/* <button onClick={()=>setPosition(position + 1)} className={isPass?"button--default button--disabled":"button--default button--primary"} disabled={isPass}>SIGUIENTE</button> 
                    </section>   */}
        </>
      )}
    </>
  );
};

export default ChallengesMfa;
