import React, { Fragment, useContext, useEffect, useState } from "react";
import CookiesJS from "js-cookie";
//import {DefaultButton, Panel, PanelType, } from 'office-ui-fabric-react'
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { useBoolean } from "@fluentui/react-hooks";
import { useParams } from "react-router-dom";

//*****Componnents*****//
import SubMenu from "../../components/navigation/subMenu/subMenu.component";
import ContainerLargeForm from "../../components/containers/largeForm/containerLargeForm.component";
import ContainerBaseLoading from "../../components/containers/baseLoading/containerBaseLoading.component";
import ListHeader from "../../components/lists/listHeader/listHeader.component";
import ListBody from "../../components/lists/listBody/listBody.component";
import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';

//*****Methods*****//
import Lists from "../../utils/logicRendering/lists.controller";
import { RequestFetch } from "../../utils/Requests/requests.Controller";
import CountPages from "../../utils/logicRendering/countPages.controller";
import LenguajeContext from "../../context/Lenguaje/lenguajeContext";
import { changeLang, changeLangForm } from "../../utils/logicRendering/changeIdiom";

const Domainauthenticationfactors = () => {
  /************************
   * @arguments
   ************************/

  //***Propertise***//
  const [edit, setEdit] = useState(false);
  const [deleteD, setDeleteD] = useState(false);
  const [domainauthenticationfactors, setdomainauthenticationfactors] =
    useState([]);
  // const [currentPages, setCurrentPage] = useState(1);
  // const [showElements, setShowElements] = useState(5);
  const [
    itemsDomainauthenticationfactors,
    setitemsDomainauthenticationfactors,
  ] = useState(null);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const { clientName } = useParams();
  const [isDisable, setDisable] = React.useState([
    { disable: false, key: "valueDefault" },
    { disable: true, key: "name" },
  ]);
  const [buttonDisable, setButtonDisable] = useState(true)
  const [controlsPage, setControlsPage] = useState({});
  const [domainMeta, setDomainMeta] = useState(null)
  //#region Variables de estado de los componentes
  const [selectAuthenticationFactor1, setSelectAuthenticationFactor1] =
    useState({});
  const [selectAuthenticationFactor2, setSelectAuthenticationFactor2] =
    useState({});
  const [selectAuthenticationFactor3, setSelectAuthenticationFactor3] =
    useState({});
  const [selected1, setSelected1] = useState(0)
  const [selected2, setSelected2] = useState(0)
  const [selected3, setSelected3] = useState(0)
  const [idNotifications1, setIdNotifications1] = useState([])
  const [idNotifications2, setIdNotifications2] = useState([])
  const [idNotifications3, setIdNotifications3] = useState([])
  const [arrIdNotifications1, setArrIdNotifications1] = useState([])
  const [arrIdNotifications2, setArrIdNotifications2] = useState([])
  const [arrIdNotifications3, setArrIdNotifications3] = useState([])
  const [notificationType1, setnotificationType1] =
    useState({});
  const [notificationType2, setnotificationType2] =
    useState({});
  const [notificationType3, setnotificationType3] =
    useState({});
  const [numberChallenges, setNumberChallenges] = useState({});
  const [disabledNot1, setDisabledNot1] = useState(false);
  const [disabledNot2, setDisabledNot2] = useState(false);
  const [disabledNot3, setDisabledNot3] = useState(false);
  const [checkedRequire1, setcheckedRequire1] = useState(false);
  const [checkedRequire2, setcheckedRequire2] = useState(false);
  const [checkedRequire3, setcheckedRequire3] = useState(false);
  const [require1, setRequire1] = useState(false);
  const [require2, setRequire2] = useState(false);
  const [require3, setRequire3] = useState(false);
  const [disableRequire1, setDisableRequire1] = useState(false);
  const [disableRequire2, setDisableRequire2] = useState(false);
  const [disableRequire3, setDisableRequire3] = useState(false);
  const [timeRequaredComplit, setTimeRequaredComplit] = useState(1);
  const [numberAttemps, setNumberAttemps] = useState(3);
  const [isLoadingData, {
    setTrue: handleLoadingData,
    setFalse: handleLoadedData
  }] = useBoolean(false);
  const { getLenguaje, stateLanguage } = useContext(LenguajeContext);
  const [columnTitles, setColumnTitles] = useState([{
    idNumber: {
      name: "N°",
      isRowHeader: true,
      maxWidth: 50
    },
    alias: {
      name: "Dominio",
      maxWidth: 150,
      idHtml: "coluDomain"
    },
    name: {
      name: "Desafíos Activos",
      maxWidth: 100,
      idHtml: "coluActiveChallgs",
    },
    creatingDate: {
      name: "Fecha de Creación",
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
      idHtml: "coluCreationDate",
    },
    updatingDate: {
      name: "Fecha de Modificación",
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
      idHtml: "coluLastModification",
    },
    creatorUser: {
      name: "Creado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "coluCreatedBy",
    },
    updateUser: {
      name: "Modificado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "lblModifiedBy"
    },
  }])
  const [pagination, setPagination] = useState(null);
  const [filter, setFilter] = useState({
    pageSize: 5,
    pageNumber: "0",
    idDomain: "",
    idLanguage: JSON.parse(CookiesJS.get("dataUser")).user.idLanguage
  });
  const [numberRequiredChallenges, setNumberRequiredChallenges] = useState(0);


  const [selectedKeys, setSelectedKeys] = React.useState(['A', 'D']);
  //#region Consulta de tipos de autenticación
  // const [promiseAuthenticationFactor, setPromiseAuthenticationFactor] =
  //   useState(RequestsAuthenticationFactor.getItem({ tenant: clientName }));
  let [arrayAuthenticationFactor, setArrayAuthenticationFactor] = useState([])
  let [arrayAuthenticationFactor1, setArrayAuthenticationFactor1] = useState([])
  let [arrayAuthenticationFactor2, setArrayAuthenticationFactor2] = useState([])
  let [arrayAuthenticationFactor3, setArrayAuthenticationFactor3] = useState([])
  let [arrDefaultvalue, setArrDefaultvalue] = useState([])
  const [arrNotificationType1, setArrNotificationType1] = useState([])
  const [arrNotificationType2, setArrNotificationType2] = useState([])
  const [arrNotificationType3, setArrNotificationType3] = useState([])

  const openPanel2 = () =>{
    setSelected1(domainauthenticationfactors[0].domain_AuthenticationFactor[0] ? domainauthenticationfactors[0].domain_AuthenticationFactor[0].idAuthenticationFactor : 0)
    setSelected2(domainauthenticationfactors[0].domain_AuthenticationFactor[1] ? domainauthenticationfactors[0].domain_AuthenticationFactor[1].idAuthenticationFactor : 0)
    setSelected3(domainauthenticationfactors[0].domain_AuthenticationFactor[2] ? domainauthenticationfactors[0].domain_AuthenticationFactor[2].idAuthenticationFactor : 0)
    setRequire1(domainauthenticationfactors[0].domain_AuthenticationFactor[0] ? domainauthenticationfactors[0].domain_AuthenticationFactor[0].isRequired : false)
    setRequire2(domainauthenticationfactors[0].domain_AuthenticationFactor[1] ? domainauthenticationfactors[0].domain_AuthenticationFactor[1].isRequired : false)
    setRequire3(domainauthenticationfactors[0].domain_AuthenticationFactor[2] ? domainauthenticationfactors[0].domain_AuthenticationFactor[2].isRequired : false)
    openPanel()
  } 
  const dismissPanel2 = () => {
    setSelected1()
    setSelected2()
    setSelected3()
    setRequire1(false)
    setRequire2(false)
    setRequire3(false)
    setArrNotificationType1([])
    setArrNotificationType2([])
    setArrNotificationType3([])
    setArrIdNotifications1([])
    setArrIdNotifications2([])
    setArrIdNotifications3([])
    setNumberAttemps(domainauthenticationfactors[0].challengesConfigurations[0] ? domainauthenticationfactors[0].challengesConfigurations[0].numberDailyAttempts : 5)
    setTimeRequaredComplit(domainauthenticationfactors[0].challengesConfigurations[0] ? domainauthenticationfactors[0].challengesConfigurations[0].timeAnswerChallenges : 1)
    dismissPanel()
    methodsList.updateForm(domainauthenticationfactors)
  }
  //#endregion
  const { idClient } = JSON.parse(CookiesJS.get("dataClient"));
  //const [ObjectPetition, setObjectPetition] = useState();
  //Nos ayuda a llenar de forma dinamica un componente
  const [defaultValueInput, seDefaultValueInput] = useState([]);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
  const [itemsComponentInformation, setItemsComponentInformation] = useState();
  const Requests = new RequestFetch("Domains/challenges");
  const RequestsAuthenticationFactor = new RequestFetch("AuthenticationFactor/client");
  const RequestsDomain_AuthenticationFactor = new RequestFetch(
    "Domain_AuthenticationFactor/configuration"
  );

  
  const resolvePromise = async () => {
    await RequestsAuthenticationFactor.getItem({ tenant: clientName }).then((data) => {
      for (const [key, value] of Object.entries(data.data)) {

        if (key === "data") {
          let arrayAuthenticationFactoraux = []
          for (let index = 0; index < value.length; index++) {
            const element = value[index];
            arrayAuthenticationFactoraux.push({
              key: element["id"],
              text: element["name"],
              notifications: element["notifications"]
            });
          }
          arrayAuthenticationFactoraux.push({
            key: 0,
            text: controlsPage?.slcNone?.value,
            notifications: []
          })
          setArrayAuthenticationFactor(arrayAuthenticationFactoraux)
        }
      }
    });
  }
  useEffect(() => {
    //    if ((filter.pageNumber > 0) || (filter.pageSize) || (filter.search)) {
            if (Object.entries(controlsPage).length > 0) {
              methosRequests.chageGetItems(filter);
      }
      }, [filter, controlsPage])
      useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje != 0) {
          stateLanguage.lenguaje.map((pass) => {
            if (
              pass.path == 'controls' ||
              pass.path == "challenges" 
            ) {
              pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })
              setControlsPage(controlsPage)
            }
          })
          setColumnTitles(current => changeLang(current, controlsPage))
          setCampForm(current => changeLangForm(current, controlsPage))
        }
      }, [stateLanguage])
      
  useEffect(()=>{arrayUpdate()},[arrayAuthenticationFactor])
  //#endregion
  //#region Acciones de los componentes
  const actionOnChange = (data) => {
    if (data.id === "selectAuthenticationFactor1") {
      if (data.selectedItem != undefined) {
        setSelectAuthenticationFactor1(data);
      }
    }
    if (data.id === "selectAuthenticationFactor2") {
      setSelectAuthenticationFactor2(data);
    }
    if (data.id === "selectAuthenticationFactor3") {
      setSelectAuthenticationFactor3(data);
    }
    if (data.id === "notificationType1") {
      if (data.selectedItem != undefined) {
        setnotificationType1(data);
      }
    }
    if (data.id === "notificationType2") {
      if (data.selectedItem != undefined) {
        setnotificationType2(data);
      }
    }
    if (data.id === "notificationType3") {
      if (data.selectedItem != undefined) {
        setnotificationType3(data);
      }
    }
    if (data.id === "numberChallenges") {
      setNumberChallenges(data);
    }
    if (data.id === "require1") {
      if (data.selectedItem != undefined) {
        setRequire1(data.selectedItem);
      }
    }
    if (data.id === "require2") {
      if (data.selectedItem != undefined) {
        setRequire2(data.selectedItem);
      }
    }
    if (data.id === "require3") {
      if (data.selectedItem != undefined) {
        setRequire3(data.selectedItem);
      }
    }
    if (data.id === "timeRequaredComplit") {
      if (data.selectedItem != undefined) {
        setTimeRequaredComplit(data.selectedItem);
      }
    }
    if (data.id === "numberAttemps") {
      if (data.selectedItem != undefined) {
        setNumberAttemps(data.selectedItem);
      }
    }
  };
  const actionOnClick = (data, key) => {
    if (key == "require1") {
      setcheckedRequire1(data)
      setRequire1(data)
    } else if (key == "require2") {
      setcheckedRequire2(data)
      setRequire2(data)
    } else if (key == "require3") {
      setcheckedRequire3(data)
      setRequire3(data)
    }

  };
  useEffect(() => {
    if (domainauthenticationfactors[0] != undefined) {
    if (domainauthenticationfactors[0].challengesConfigurations != undefined) {
        setArrDefaultvalue(domainauthenticationfactors[0].domain_AuthenticationFactor)
        setNumberAttemps(domainauthenticationfactors[0].challengesConfigurations[0].numberDailyAttempts)
        setTimeRequaredComplit(domainauthenticationfactors[0].challengesConfigurations[0].timeAnswerChallenges)
        setNumberRequiredChallenges(domainauthenticationfactors[0].challengesConfigurations[0].numberRequiredChallenges)
        arrayUpdate()
      }
    }
  //}
  }, [domainauthenticationfactors])
  //useEffect(() => {
  const arrayUpdate = () =>{
    setArrayAuthenticationFactor1(arrayAuthenticationFactor)
    setArrayAuthenticationFactor2(arrayAuthenticationFactor)
    setArrayAuthenticationFactor3(arrayAuthenticationFactor)
  }
  useEffect(() => {
    let index = 0
    arrDefaultvalue.map((factor) => {
      if (index == 0) {
        setSelected1(factor.idAuthenticationFactor)
        setIdNotifications1(factor.notifications[0] ? factor.notifications.map((e) => { return e.idNotification }) : [])
        setArrIdNotifications1(factor.notifications[0] ? factor.notifications.map((e) => { return { idNotification: e.idNotification } }) : [])
        setRequire1(factor.isRequired)
      } else if (index == 1) {
        setSelected2(factor.idAuthenticationFactor)
        setIdNotifications2(factor.notifications[0] ? factor.notifications.map((e) => { return e.idNotification }) : [])
        setRequire2(factor.isRequired)
        setArrIdNotifications2(factor.notifications[0] ? factor.notifications.map((e) => { return { idNotification: e.idNotification } }) : [])
      } else if (index == 2) {
        setSelected3(factor.idAuthenticationFactor)
        setIdNotifications3(factor.notifications[0] ? factor.notifications.map((e) => { return e.idNotification }) : [])
        setRequire3(factor.isRequired)
        setArrIdNotifications3(factor.notifications[0] ? factor.notifications.map((e) => { return { idNotification: e.idNotification } }) : [])
      }
      index += 1
    })
  }, [arrDefaultvalue])
  useEffect(() => {
    let arrayAux2 = arrayAuthenticationFactor.filter(factor => factor.key == (selected1 ? selected1 : "00"))
    if (arrayAux2[0] != undefined) {
      let options = arrayAux2[0].notifications.map((option) => {
        return {
          key: option.id,
          text: option.nameNotiType
        }
      })
      
      setArrNotificationType1(options)
      setDisabledNot1(options.length == 0 ? true : false)
      let arrayAux3 = selected1 != 0 ? arrayAuthenticationFactor.filter(factor => factor.key != selected1) : arrayAuthenticationFactor
      arrayAux3 = selected3 != 0 ? arrayAux3.filter(factor => factor.key != selected3) : arrayAux3
      let option = arrayAux3.find((e) => { return e.key == 0 })
      if (!option) {
        
      }
      setArrayAuthenticationFactor2(arrayAux3)
      let arrayAux4 = selected1 != 0 ? arrayAuthenticationFactor.filter(factor => factor.key != selected1) : arrayAuthenticationFactor
      arrayAux4 = selected2 != 0 ? arrayAux4.filter(factor => factor.key != selected2) : arrayAux4
      let option2 = arrayAux4.find((e) => { return e.key == 0 })
      if (!option2) {
      }
      setArrayAuthenticationFactor3(arrayAux4)
    }
    setDisableRequire1(selected1 == 0 ? true : false)
  }, [selected1])
  useEffect(() => {
    let arrayAux2 = arrayAuthenticationFactor.filter(factor => factor.key == (selected2 ? selected2 : "00"))
    if (arrayAux2[0] != undefined) {
      let options = arrayAux2[0].notifications.map((option) => {
        return {
          key: option.id,
          text: option.nameNotiType
        }
      })
      setArrNotificationType2(options)
      setDisabledNot2(options.length == 0 ? true : false)
    }
    let arrayAux3 = selected2 != 0 ? arrayAuthenticationFactor.filter(factor => factor.key != selected2) : arrayAuthenticationFactor
    arrayAux3 = selected3 != 0 ? arrayAux3.filter(factor => factor.key != selected3) : arrayAux3
    let option = arrayAux3.find((e) => { return e.key == 0 })
    if (!option) {
      arrayAux3.push({
        key: 0,
        text: controlsPage?.slcNone?.value,
        notifications: []
      })
    }
    setArrayAuthenticationFactor1(arrayAux3)
    let arrayAux4 = selected2 != 0 ? arrayAuthenticationFactor.filter(factor => factor.key != selected2) : arrayAuthenticationFactor
    arrayAux4 = selected1 != 0 ? arrayAux4.filter(factor => factor.key != selected1) : arrayAux4
    let option2 = arrayAux4.find((e) => { return e.key == 0 })
    if (!option2) {
      arrayAux4.push({
        key: 0,
        text: controlsPage?.slcNone?.value,
        notifications: []
      })
    }
    setArrayAuthenticationFactor3(arrayAux4)
    setDisableRequire2(selected2 == 0 ? true : false)

  }, [selected2])
  useEffect(() => {
    let arrayAux2 = arrayAuthenticationFactor.filter(factor => factor.key == (selected3 ? selected3 : "00"))
    if (arrayAux2[0] != undefined) {
      let options = arrayAux2[0].notifications.map((option) => {
        return {
          key: option.id,
          text: option.nameNotiType
        }
      })

      setArrNotificationType3(options)
      setDisabledNot3(options.length == 0 ? true : false)
    }
    let arrayAux3 = selected3 != 0 ? arrayAuthenticationFactor.filter(factor => factor.key != selected3) : arrayAuthenticationFactor
    arrayAux3 = selected2 != 0 ? arrayAux3.filter(factor => factor.key != selected2) : arrayAux3
    setArrayAuthenticationFactor1(arrayAux3)
    let arrayAux4 = selected3 != 0 ? arrayAuthenticationFactor.filter(factor => factor.key != selected3) : arrayAuthenticationFactor
    arrayAux4 = selected1 != 0 ? arrayAux4.filter(factor => factor.key != selected1) : arrayAux4

    setArrayAuthenticationFactor2(arrayAux4)
    setDisableRequire3(selected3 == 0 ? true : false)
  }, [selected3])

  useEffect(() => {
    if (notificationType1) {
      if (notificationType1.selected && notificationType1.selectedItem != undefined) {
        let idSel = { idNotification: notificationType1.selectedItem.key }
        setArrIdNotifications1([...arrIdNotifications1, idSel])

      } else {
        setArrIdNotifications1(arrIdNotifications1.filter(item => item.idNotification != notificationType1.selectedItem.key))
      }
    }
  }, [notificationType1])
  useEffect(() => {
    if (notificationType2) {
      if (notificationType2.selected && notificationType2.selectedItem != undefined) {
        let idSel = { idNotification: notificationType2.selectedItem.key }
        setArrIdNotifications2([...arrIdNotifications2, idSel])

      } else {
        setArrIdNotifications2(arrIdNotifications2.filter(item => item.idNotification != notificationType2.selectedItem.key))
      }
    }
  }, [notificationType2])
  useEffect(() => {
    if (notificationType3) {
      if (notificationType3.selected && notificationType3.selectedItem != undefined) {
        let idSel = { idNotification: notificationType3.selectedItem.key }
        setArrIdNotifications3([...arrIdNotifications3, idSel])

      } else {
        setArrIdNotifications3(arrIdNotifications3.filter(item => item.idNotification != notificationType3.selectedItem.key))
      }
    }
  }, [notificationType3])
  const onSubmitEventFormPanel = (data) => {
    let dataForm = {
      idDomain: domainauthenticationfactors[0].id,
      configuration: {
        idDomain: domainauthenticationfactors[0].id,
        numberRequiredChallenges: 0,
        ...(timeRequaredComplit !== null
          ? { timeAnswerChallenges: timeRequaredComplit }
          : ""),
        ...(numberAttemps !== null
          ? { numberDailyAttempts: numberAttemps }
          : ""),
      },
      authenticationFactors: [],
    };

    if (require1 || require2 || require3) {

      dataForm.configuration.numberRequiredChallenges = require1 && selected1 != 0 ? dataForm.configuration.numberRequiredChallenges + require1 : dataForm.configuration.numberRequiredChallenges
      dataForm.configuration.numberRequiredChallenges = require2 && selected2 != 0 ? dataForm.configuration.numberRequiredChallenges + require2 : dataForm.configuration.numberRequiredChallenges
      dataForm.configuration.numberRequiredChallenges = require3 && selected3 != 0 ? dataForm.configuration.numberRequiredChallenges + require3 : dataForm.configuration.numberRequiredChallenges
    }
    if (selected1 !== null && selected1 !== 0) {
      //let arrayNotifications1 = idNotifications1.map((id) => { if (id != "selectAll") return { "idNotification": id } }).filter(notUndefined => notUndefined !== undefined)
      dataForm.authenticationFactors.push({
        idDomain: domainauthenticationfactors[0].id,
        idAuthenticationFactor: selected1,
        isRequired: require1,
        notifications: arrIdNotifications1
      });
    }

    if (selected2 !== null && selected2 !== 0) {
      //const arrayNotifications2 = notificationType2.map((id) => { if (id != "selectAll") return { "idNotification": id } }).filter(notUndefined => notUndefined !== undefined)
      dataForm.authenticationFactors.push({
        idDomain: domainauthenticationfactors[0].id,
        idAuthenticationFactor: selected2,
        isRequired: require2,
        notifications: arrIdNotifications2

      });
    }
    if (selected3 !== null && selected3 !== 0) {
      //let arrayNotifications3 = notificationType3.map((id) => { if (id != "selectAll") return { "idNotification": id } }).filter(notUndefined => notUndefined !== undefined)
      dataForm.authenticationFactors.push({
        idDomain: domainauthenticationfactors[0].id,
        idAuthenticationFactor: selected3,
        isRequired: require3,
        notifications: arrIdNotifications3
      });
    }
    return dataForm;
  };

  //#endregion
  //#region Configuracion de componentes
  const SpaceTitel = ["Dominio", "Desafíos Activos", "Fecha", "Creado por"];
  const OrdItems = [
    "alias",
    "numberOfChallenges",
    "creatingDate",
    "creatorUser",
  ];
  const [campForm, setCampForm] = useState([
    {
      id: 1,
      key: "alias",
      label: "Nombre del Dominio",
      idHtml: "lblDomainName",
      ariaLabel: null,
      type: "text2",
      placeholder: '',
      defaultValu: null,
      multiline: false,
      resizable: false,
      typeInput: "text",
      description: "",
      onRenderLabel: null,
      isRequired: true,
      className: 'w10',
      readOnly: true,
      disable:true,
      style: {color:"black",},
      styleStack: {
        marginBottom: "1rem"
      },
      required: 0,
    },
    {
      id: 18,
      key: "label1",
      label: "Factor de autenticación ",
      defaultValue: "Factor de autenticación ",
      idHtml: "lblAuthenticationFactor",
      type: "label",
      styleStack: {
        width: "17rem",
        position: "center",
      },
    styleStackFather: { float: "right", "padding-right": "0rem" },
    },
    {
      id: 19,
      key: "label2",
      defaultValue: "Tipo canal de Envío  ",
      label: "Tipo canal de Envío",
      idHtml: "slcChallgsFactor",
      type: "label",
      styleStack: {
        width: "17rem",
        position: "center",
        //"margin-left": "-3.8rem"

      },
    styleStackFather: { float: "right", "padding-right": "0rem" },
    },
    {
      id: 20,
      key: "label3",
      label: "Requerido",
      idHtml: "lblRequired",
      type: "label",
      styleStack: {
        width: "8rem",
        position: "center",
        //"margin-left": "5rem"
      },
    styleStackFather: { float: "right", "padding-right": "0rem" },
    },
    {
      id: 2,
      key: "selectAuthenticationFactor1",
      label: "Factor de autenticación ",
      type: "select2",
      idHtml:"lblAuthenticationFactor",
      options: arrayAuthenticationFactor1,
      placeholder: controlsPage?.slcSelect?.value,
      multiSelect: false,
      disabled: isDisable,
      defaultValue: ' ',
      isRequired: false,
      styleStackFather: { float: "right" },
      styleStack: {
        width: "11rem",
        position: "center",
        "marginBottom": "1rem"
      },
      required: 0,
    },
    {
      id: 11,
      key: "notificationType1",
      //label: "Tipo canal de Envío",
      idHtml: "slcChallgsFactor",
      type: "select2",
      options: arrNotificationType1,
      placeholder: controlsPage?.slcSelect?.value,
      defaultSelectedKeys: idNotifications1,
      defaultValue: idNotifications1,
      disable: disabledNot1,
      multiSelect: true,
      isRequired: false,
      defaultR: idNotifications1,
      styleStackFather: { float: "right", "padding-right": "0rem", },
      styleStack: {
        width: "18rem",
        position: "center",
        marginBottom: "1rem",
      },
      required: 0,
    },
    {
      id: 6,
      key: "require1",
      idHtml: "lblRequired",
      type: "toggle",
      defaultValue: false,
      onText: controlsPage?.lblYes?.value,
      offText: controlsPage?.lblNo?.value,
      styleStackFather: { float: "right", "padding-right": "4rem" },
      styleStack: {
          width: "6rem",
          position: "center",
          "marginTop": "1rem",
          "background": "red"
        },  
      required: 0,
    },
    {
      id: 14,
      key: "line1",
      type: "line",
      className: "w9_5",
      color: "#a8b0b0"
    },
    {
      id: 21,
      key: "label4",
      label: "Factor de autenticación ",
      defaultValue: "Factor de autenticación ",
      idHtml: "lblAuthenticationFactor",
      type: "label",
      styleStack: {
        width: "18rem",
        position: "center",
      },
    styleStackFather: { float: "right", "padding-right": "0rem" },
    },
    {
      id: 22,
      key: "label5",
      defaultValue: "Tipo canal de Envío  ",
      label: "Tipo canal de Envío",
      idHtml: "slcChallgsFactor",
      type: "label",
      styleStack: {
        width: "17rem",
        position: "center",
        //"margin-left": "-3.8rem"

      },
    styleStackFather: { float: "right", "padding-right": "0rem" },
    },
    {
      id: 23,
      key: "label6",
      label: "Requerido",
      idHtml: "lblRequired",
      type: "label",
      styleStack: {
        width: "8rem",
        position: "center",
        //"margin-right": "-8rem"
      },
    styleStackFather: { float: "right", "padding-right": "0rem" },
    },
    {
      id: 3,
      key: "selectAuthenticationFactor2",
      idHtml: "lblAuthenticationFactor",
      type: "select2",
      options: arrayAuthenticationFactor1,
      placeholder: controlsPage?.slcSelect?.value,
      multiSelect: false,
      disabled: isDisable,
      defaultValue: selected2 > 0 ? selected2 : ' ',
      isRequired: false,
      styleStackFather: { float: "right", "padding-right": "10rem" },
      required: 0,
      styleStack: {
        width: "15rem",
        position: "center",
      },
    },
    {
      id: 12,
      key: "notificationType2",
      idHtml: "slcChallgsFactor",
      type: "select2",
      options: arrNotificationType2,
      placeholder: controlsPage?.slcSelect?.value,
      multiSelect: false,
      disable: disabledNot2,
      defaultValue: " ",
      isRequired: true,
      styleStackFather: { float: "right", "padding-right": "10rem" },
      styleStack: {
        width: "15rem",
        position: "center",
      },
      required: 0,
    },
    {
      id: 7,
      key: "require2",
      idHtml: "lblRequired",
      type: "toggle",
      disabled: true,
      defaultValue: " ",
      onText: controlsPage?.lblYes?.value,
      offText: controlsPage?.lblNo?.value,
      styleStackFather: { float: "right", "padding-right": "3rem" },
      styleStack: {
        width: "5rem",
        position: "center",
      },
    },
    {
      id: 15,
      key: "line2",
      type: "line",
      className: "w9_5",
      color: "#a8b0b0"
    },
    {
      id: 24,
      key: "label7",
      label: "Factor de autenticación ",
      defaultValue: "Factor de autenticación ",
      idHtml: "lblAuthenticationFactor",
      type: "label",
      styleStack: {
        width: "18rem",
        position: "center",
      },
    styleStackFather: { float: "right", "padding-right": "0rem" },
    },
    {
      id: 25,
      key: "label8",
      defaultValue: "Tipo canal de Envío  ",
      label: "Tipo canal de Envío",
      idHtml: "slcChallgsFactor",
      type: "label",
      styleStack: {
        width: "17rem",
        position: "center",
        //"margin-left": "-3.8rem"

      },
    styleStackFather: { float: "right", "padding-right": "0rem" },
    },
    {
      id: 26,
      key: "label9",
      label: "Requerido",
      idHtml: "lblRequired",
      type: "label",
      styleStack: {
        width: "8rem",
        position: "center",
       // "margin-right": "-8rem"
      },
    styleStackFather: { float: "right", "padding-right": "0rem" },
    },
    {
      id: 4,
      key: "selectAuthenticationFactor3",
      idHtml: "lblAuthenticationFactor",
      type: "select2",
      defaultValue: selected3 > 0 ? selected3 : ' ',
      options: arrayAuthenticationFactor3,
      placeholder: controlsPage?.slcSelect?.value,
      multiSelect: false,
      disabled: isDisable,
      required: false,
      isRequired: false,
      styleStackFather: { float: "right", "padding-right": "10rem" },
      styleStack: {
        width: "15rem",
        position: "center",
      },
    },
    {
      id: 13,
      key: "notificationType3",
      idHtml: "slcChallgsFactor",
      type: "select2",
      options: arrNotificationType3,
      placeholder: controlsPage?.slcSelect?.value,
      multiSelect: false,
      disable: disabledNot3,
      defaultValue: idNotifications3,
      isRequired: false,
      styleStackFather: { float: "right", "padding-right": "10rem" },
      styleStack: {
        width: "15rem",
        position: "center",
      },
      required: 0,
    },
    {
      id: 8,
      key: "require3",
      idHtml: "lblRequired",
      type: "toggle",
      disabled: disableRequire3,
      defaultValue: false,
      onText: controlsPage?.lblYes?.value,
      offText: controlsPage?.lblNo?.value,
      styleStackFather: { float: "right", "padding-right": "3rem" },
      styleStack: {
        width: "5rem",
        position: "center",
      },
    },
    {
      id: 16,
      key: "line3",
      type: "line",
      className: "w9_5",
      color: "#a8b0b0"
    },
    // {
    //   id: 27,
    //   key: "label10",
    //   label: "Desafíos Solicitados para ser realizados",
    //   idHtml: "lblTheChallengesToBeDone",
    //   defaultValue: "Desafíos Solicitados para ser realizados ",
    //   type: "label",
    //   styleStack: {
    //     width: "80%",
    //     position: "center",
    //   },
    // styleStackFather: { float: "right", "padding-right": "0rem" },
    // },
    // {
    //   id: 28,
    //   key: "label11",
    //   defaultValue: "Tiempo para completar los desafíos",
    //   label: "Tiempo para completar los desafíos",
    //   idHtml: "lblTimeCompleteChallges",
    //   type: "label",
    //   styleStack: {
    //     width: "80%",
    //     position: "center",
    //     //"margin-left": "-2rem"

    //   },
    // styleStackFather: { float: "right", "padding-right": "0rem" },
    // },
    // {
    //   id: 29,
    //   key: "label12",
    //   label: "Número de intentos",
    //   idHtml: "lblNumberOfAttemps",
    //   type: "label",
    //   styleStack: {
    //     width: "80%",
    //     position: "center",
    //     //"margin-right": "-8rem"
    //   },
    // styleStackFather: { float: "right", "padding-right": "0rem" },
    // },
    {
      id: 5,
      key: "numberChallenges",
      label: "Desafíos Solicitados para ser realizados",
      idHtml: "lblTheChallengesToBeDone",
      type: "slider",
      disabled: isDisable,
      min: 0,
      max: 3,
      defaultValue: numberRequiredChallenges,
      value: numberRequiredChallenges,
      divStyle: { width: "300px", margin: "0 auto", "margin-top": "1rem" },
      className: 'colorNorm'
    },
    {
      id: 9,
      key: "timeRequaredComplit",
      label: "Tiempo para completar los desafíos",
      idHtml: "lblTimeCompleteChallges",
      type: "slider",
      disabled: isDisable,
      min: 1,
      max: 10,
      defaultValue: timeRequaredComplit,
      value: timeRequaredComplit,
      divStyle: { width: "325px", margin: "0 auto", "margin-top": "1rem" },
      className: 'colorNorm',
      format: "min"
    },
    {
      id: 10,
      key: "numberAttemps",
      label: "Número de intentos",
      idHtml: "lblNumberOfAttemps",
      type: "slider",
      disabled: isDisable,
      min: 3,
      max: 10,
      defaultValue: numberAttemps ? numberAttemps : 3,
      value: numberAttemps ? numberAttemps : 3,
      divStyle: { width: "262px", margin: "0", "margin-top": "1rem" },
    },
  ]);
  useEffect(() => {
    let arr = [require1, require2, require3]
    let result = arr.reduce((acu, value) => acu + value)
    setNumberRequiredChallenges(result)
    if (result == 0) {
      setButtonDisable(true)
    } else {
      setButtonDisable(false)
    }
    setcheckedRequire1(arr[0])
    setcheckedRequire2(arr[1])
    setcheckedRequire3(arr[2])
  }, [require1, require2, require3])

  // useEffect(() => {
  //   let arr = [require1, require2, require3]
  //   let result = arr.reduce((acu, value) => acu + value)
  //   setNumberRequiredChallenges(result)
  // }, [require2])

  campForm[4] = {
    id: 2,
    key: "selectAuthenticationFactor1",
    //label: "Factor de autenticación",
    idHtml: "lblAuthenticationFactor",  
    type: "select2",
    //defaultSelectedKeys: defaultSelectedKeys,
    options: arrayAuthenticationFactor1,
    placeholder: controlsPage?.slcSelect?.value,
    defaultValue: selected1 > 0 ? selected1 : ' ',
    multiSelect: false,
    disabled: isDisable,
    required: true,
    isRequired: false,
    //className: "w8",
    styleStackFather: { float: "right", "padding-right": "0rem" },
    styleStack: {
      width: "22rem",
      position: "center",
      marginBottom: "1rem"
    },
    required: 0,
  }
  campForm[5] = {
    id: 11,
    key: "notificationType1",
    //label: "Tipo canal de Envío",
    idHtml: "slcChallgsFactor",
    type: "select2",
    options: arrNotificationType1,
    placeholder: controlsPage?.slcSelect?.value,
    defaultSelectedKeys: idNotifications1,
    defaultValue: idNotifications1,
    disable: disabledNot1,
    multiSelect: true,
    isRequired: false,
    defaultR: idNotifications1,
    styleStackFather: { float: "right", "padding-right": "0rem", },
    styleStack: {
      width: "22rem",
      position: "left",
      marginBottom: "1rem",
    },
    required: 0,
  }
  campForm[6] = {
    id: 6,
    key: "require1",
    idHtml: "lblRequired",
    type: "toggle",
    checked: checkedRequire1,
    disable: disableRequire1,
    defaultValue: require1,
    onText: controlsPage?.lblYes?.value,
    offText: controlsPage?.lblNo?.value,
    styleStackFather: { float: "right", "padding-right": "3rem" },
    styleStack: {
        width: "5rem",
        position: "center",
        "marginTop": "0.8rem",
      },
    required: 0,
  }
  campForm[11] = {
    id: 3,
    key: "selectAuthenticationFactor2",
    idHtml: "lblAuthenticationFactor",  
    type: "select2",
    defaultValue: selected2 > 0 ? selected2 : ' ',
    options: arrayAuthenticationFactor2,
    placeholder: controlsPage?.slcSelect?.value,
    multiSelect: false,
    disabled: isDisable,
    required: true,
    isRequired: false,
    styleStackFather: { float: "right", "padding-right": "0rem" },
    styleStack: {
      width: "22rem",
      position: "center",
      marginBottom: "1rem"
    },
    required: 0,
  }
  campForm[12] = {
    id: 12,
    key: "notificationType2",
    idHtml: "slcChallgsFactor",
    type: "select2",
    options: arrNotificationType2,
    selectedKeys: ["38"],
    placeholder: controlsPage?.slcSelect?.value,
    defaultValue: " ",
    defaultSelectedKeys: idNotifications2,
    multiSelect: true,
    disable: disabledNot2,
    isRequired: false,
    styleStackFather: { float: "right", "padding-right": "0rem" },
    styleStack: {
      width: "22rem",
      position: "center",
      marginBottom: "1rem"
    },
    required: 0,
  }
  campForm[13] = {
    id: 7,
    key: "require2",
    idHtml: "lblRequired",
    type: "toggle",
    checked: checkedRequire2,
    disable: disableRequire2,
    defaultValue: require2,
    onText: controlsPage?.lblYes?.value,
    offText: controlsPage?.lblNo?.value,
    styleStackFather: { float: "right", "padding-right": "3rem" },
    styleStack: {
      width: "5rem",
      position: "center",
      "marginTop": "0.8rem",
    },
  }
  campForm[18] = {
    id: 4,
    key: "selectAuthenticationFactor3",
    idHtml: "lblAuthenticationFactor",  
    type: "select2",
    defaultValue: selected3 > 0 ? selected3 : ' ',
    options: arrayAuthenticationFactor3,
    placeholder: controlsPage?.slcSelect?.value,
    multiSelect: false,
    disabled: isDisable,
    required: false,
    isRequired: false,
    styleStackFather: { float: "right", "padding-right": "0rem" },
    styleStack: {
      width: "22rem",
      position: "center",
      marginBottom: "1rem"
    },
  }
  campForm[19] = {
    id: 13,
    key: "notificationType3",
    idHtml: "slcChallgsFactor",
    type: "select2",
    options: arrNotificationType3,
    placeholder: controlsPage?.slcSelect?.value,
    defaultSelectedKeys: idNotifications3,
    defaultValue: " ",
    disable: disabledNot3,
    multiSelect: true,
    isRequired: false,
    defaultR: " ",
    styleStackFather: { float: "right", "padding-right": "0rem", },
    styleStack: {
      width: "22rem",
      position: "center",
      marginBottom: "1rem",
    },
    required: 0,
  }
  campForm[20] = {
    id: 8,
    key: "require3",
    idHtml: "lblRequired",
    type: "toggle",
    checked: checkedRequire3,
    disable: disableRequire3,
    defaultValue: require3,
    onText: controlsPage?.lblYes?.value,
    offText: controlsPage?.lblNo?.value,
    styleStackFather: { float: "right", "padding-right": "3rem" },
    styleStack: {
      width: "5rem",
      position: "center",
      "marginTop": "0.8rem",
    },
  }
  campForm[22] = Object.assign(campForm[22], { value: numberRequiredChallenges, defaultValue: numberRequiredChallenges })
  campForm[23] = Object.assign(campForm[23], { value: timeRequaredComplit, defaultValue: timeRequaredComplit })
  campForm[24] = Object.assign(campForm[24], { value: numberAttemps, defaultValue: numberAttemps })

  // campForm[25] = {
  //   id: 5,
  //   key: "numberChallenges",
  //   // label: "Desafíos Solicitados para ser realizados",
  //   // idHtml: "lblTheChallengesToBeDone",
  //   type: "slider",
  //   disabled: isDisable,
  //   min: 0,
  //   max: 3,
  //   defaultValue: numberRequiredChallenges,
  //   value: numberRequiredChallenges,
  //   divStyle: { width: "250px", margin: "0 auto", "margin-top": "1rem" },
  //   className: 'colorNorm'
  // }
  // campForm[26] = {
  //   id: 9,
  //   key: "timeRequaredComplit",
  //   // label: "Tiempo para completar los desafíos",
  //   // idHtml: "lblTimeCompleteChallges",
  //   type: "slider",
  //   disabled: isDisable,
  //   min: 1,
  //   max: 10,
  //   defaultValue: timeRequaredComplit,
  //   value: timeRequaredComplit,
  //   divStyle: { width: "250px", margin: "0 auto", "margin-top": "1rem" },
  //   className: 'colorNorm',
  //   format: "min"
  // // }

  // campForm[27] = {
  //   id: 10,
  //   key: "numberAttemps",
  //   // label: "Número de intentos",
  //   // idHtml: "lblNumberOfAttemps",
  //   type: "slider",
  //   disabled: isDisable,
  //   min: 3,
  //   max: 10,
  //   defaultValue: numberAttemps ? numberAttemps : 3,
  //   value: numberAttemps ? numberAttemps : 3,
  //   divStyle: { width: "250px", margin: "0 auto", "margin-top": "1rem" },
  // }

  const [campFilters, setCampFilters] = useState([]);
  //#endregion
  /************************
   * @methods
   ************************/
  const methodsList = {
    getDataItem: (object) => {
      const stateList = new Lists(itemsDomainauthenticationfactors, campForm);
      setdomainauthenticationfactors(object);
      const stateCurrent = stateList.updateCurrent(object);
      setCampForm(stateCurrent.object);
      setDeleteD(stateCurrent.delete);
      setEdit(stateCurrent.edit);
      //#region Habilitar Deshabilitar Componente
      setDisable([
        { disable: true, key: "alias2" },
        { disable: true, key: "alias" },
        { disable: false, key: "selectAuthenticationFactor1" },
        { disable: false, key: "selectAuthenticationFactor2" },
        { disable: false, key: "selectAuthenticationFactor3" },
        { disable: false, key: "notificationType1" },
        { disable: false, key: "notificationType2" },
        { disable: false, key: "notificationType3" },
        { disable: false, key: "numberChallenges" },
        { disable: false, key: "require1" },
        { disable: false, key: "require2" },
        { disable: false, key: "require3" },
        { disable: false, key: "timeRequaredComplit" },
        { disable: false, key: "numberAttemps" },
      ]);
      //#endregion
    },
    voidCamps: () => {
      const stateList = new Lists(itemsDomainauthenticationfactors, campForm);
      setCampForm(stateList.clearCamps());
    },
    updateForm: (object) => {
      const stateList = new Lists(itemsDomainauthenticationfactors, campForm);
      setdomainauthenticationfactors(object);
      const stateCurrent = stateList.updateCurrent(domainauthenticationfactors);
      setCampForm(stateCurrent.object);

    }
  };

  const methosRequests = {
    chageGetItems: async (filters) => {
      handleLoadingData()
      resolvePromise()
      const response = await Requests.getItem({ param: filters, tenant: clientName })
      let idNumber = 0;
      let aux = response.data
      const {
        currentPage,
        pageZise,
        totalCount,
        totalPages
      } = response.data.meta;
      if (currentPage > 1) {
        idNumber = (currentPage - 1) * pageZise
      }
      setPagination({
        currentPage: currentPage,
        pageSize: pageZise,
        totalItems: totalCount,
        totalPages: totalPages
      });
      // let domains = response.data.data.map(
      //   domain => {
      //     idNumber++;
      //     return (
      //       {
      //         ...domain,
      //         idNumber
      //       }
      //     )
      //   }
      // )
      // aux.data = domains
      setDomainMeta(response.data.meta)
      setitemsDomainauthenticationfactors(
        response.data.data.map(
          domain => {
            idNumber++;
            return (
              {
                ...domain,
                idNumber
              }
            )
          }
        )
      );
      handleLoadedData()
    
    },
    createItem: (dataForm, id) => {
      RequestsDomain_AuthenticationFactor.createItem({
        body: onSubmitEventFormPanel(),
        ct: "json",
        //id:id,
        //option: 'createItem',
        tenant: clientName,
        infoModal: {
          title: controlsPage?.lblSuccessfulProcess?.value,
          description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
        },
        op: "b",
      });
    },
    //updateItem: (dataForm, id) => { },
    deleteItem: () => {
      domainauthenticationfactors.forEach((element) =>
        RequestsAuthenticationFactor.deleteItem({
          id: element.id,
          tenant: clientName,
          infoModal: {
            title: controlsPage?.lblDisabled?.value,
            description: controlsPage?.lblItHbeenSuccessfullyDisabled?.value
          }
        })
      );
      window.location.reload();
    },
  };

  /************************
   * @hooks
   ************************/
  // const [promise, setPromise] = useState(
  //   Requests.getItem({
  //     param: { pageSize: showElements, pageNumber: currentPages },
  //     tenant: clientName,
  //   })
  // );
  
  useEffect(() => {
    if (selectAuthenticationFactor1.selectedItem) {
      let options = selectAuthenticationFactor1.selectedItem.notifications.map((option) => {
        return {
          key: option.id,
          text: option.nameNotiType
        }
      })
      setArrNotificationType1(options)
      setSelected1(selectAuthenticationFactor1.selectedItem.key)
      setDisabledNot1(options.length == 0 ? true : false)
      if (selectAuthenticationFactor1.selectedItem.key == 0) {
        setDisableRequire1(true)
        setcheckedRequire1(false)
        setRequire1(false)
      } else {
        setDisableRequire1(false)
        setRequire1(true)
        setcheckedRequire1(true)
      }
    }
  }, [selectAuthenticationFactor1])

  useEffect(() => {
    if (selectAuthenticationFactor2.selectedItem) {
      let options = selectAuthenticationFactor2.selectedItem.notifications.map((option) => {
        return {
          key: option.id,
          text: option.nameNotiType
        }
      })
      setArrNotificationType2(options)
      setDisabledNot2(options.length == 0 ? true : false)
      setSelected2(selectAuthenticationFactor2.selectedItem.key)
      if (selectAuthenticationFactor2.selectedItem.key == 0) {
        setDisableRequire2(true)
        setcheckedRequire2(false)
        setRequire2(false)
      } else {
        setDisableRequire2(false)
        setRequire2(true)
        setcheckedRequire2(true)
      }
    }
  }, [selectAuthenticationFactor2])
  useEffect(() => {

    if (selectAuthenticationFactor3.selectedItem) {
      let options = selectAuthenticationFactor3.selectedItem.notifications.map((option) => {
        return {
          key: option.id,
          text: option.nameNotiType
        }
      })
      setArrNotificationType3(options)
      setDisabledNot3(options.length == 0 ? true : false)
      setSelected3(selectAuthenticationFactor3.selectedItem.key)
      if (selectAuthenticationFactor3.selectedItem.key == 0) {
        setDisableRequire3(true)
        setRequire3(false)
        setcheckedRequire3(false)
      } else {
        setDisableRequire3(false)
        setRequire3(true)
        setcheckedRequire3(true)
      }
    }
  }, [selectAuthenticationFactor3])
  const _defaultRenderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    if (column.fieldName == "name") {

      return <div style={{
        listStyle: 'none',
        //"text-align":"center",
        marginLeft: "20%",
      }}> <span >{item.domain_AuthenticationFactor.length}</span></div>;
    } 
    else if (column.fieldName == "updatingDate"){
      return <span>{item.challengesConfigurations[0].updatingDate}</span>
    } 
    else if (column.fieldName == "updateUser"){
      return <span>{item.challengesConfigurations[0].updateUser}</span>
    }
    else {
      return <span>{fieldContent}</span>;
    }
  }
  /************************
   * @resturns
   ************************/
  if (itemsDomainauthenticationfactors === null) {
    return <ContainerBaseLoading />;
  }
  // const numberPage = CountPages(
  //   itemsDomainauthenticationfactors.meta.totalPages
  // );
  const numberPage = CountPages(domainMeta?.totalPages)

  return (
    <>
      <SubMenu
        contentcontrols={
          edit &&
          ([
            { 
              text:controlsPage?.lblChallengesConfig.value,
              iconProps:{ iconName: "Edit" },
              onClick:() => openPanel2()
            }
          ])
        }
        contentcontrolsTwo={
          <>
            <Panel
              isOpen={isOpen}
              closeButtonAriaLabel="Close"
              isHiddenOnDismiss={true}
              type={PanelType.custom}
              customWidth={'970px'}
              hasCloseButton={false}
            >
              {isOpen ? (
                <ContainerLargeForm
                  dataForm={(e) => {
                    methosRequests.createItem(e, domainauthenticationfactors[0].id)
                  }}
                  formTitle={edit ? controlsPage?.lblChallengesConfig.value  : ""}
                  closePanel={dismissPanel2}
                  Items={campForm}
                  case="notificationFlow"
                  actionOnChange={actionOnChange}
                  actionOnClick={actionOnClick}
                  isDisableOpt={isDisable}
                  onSubmitEventFormPanel={onSubmitEventFormPanel}
                  itemsComponentInformation={itemsComponentInformation}
                  defaultValueInput={defaultValueInput}
                  defaultSelectedKeys={defaultSelectedKeys}
                  buttonDisable={buttonDisable}
                  controlsPage={controlsPage} 
                  />
              ) : (
                ""
              )}
            </Panel>
          </>
        }
      />
      <div className="admin--body-container">
        <ListHeader
          showReport={false}
          subRute={controlsPage?.lblUrlAdminChallengesConfig?.value}
          subRute3={controlsPage?.lblManagementChallanges?.value}
          title={controlsPage?.lblManagementChallanges?.value}
          hideSearch={true}
          onChange={(e) => {
            setFilter({
              ...filter,
              pageSize: e.target.value,
              pageNumber: filter.pageNumber === "0" ? "0" : "1"
            });
          }}
          InputChange={(e) => {
            setFilter({
              ...filter,
              search: e,
              pageNumber: filter.pageNumber === "0" ? "0" : "1"
            })
          }}
        />
        <DetailsListBext
          enableShimmer={isLoadingData}
          listKey="listSelect"
          selectionMode={1}
          items={itemsDomainauthenticationfactors}
          renderItemColumn={_defaultRenderItemColumn}
          columnTitles={columnTitles}
          onSelect={methodsList.getDataItem}
          onPageChange={page => {
            if (page !== +filter.pageNumber) {
              setFilter({
                ...filter,
                pageNumber: String(page),
              });
            }
          }}
          pagination={pagination}
        />
      </div>
    </>
  );
};
export default Domainauthenticationfactors;
