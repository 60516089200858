import axios from "axios";
import CookieJS from "js-cookie";
import ContrlModel from "./models.Controller";
import { BSR_URI, PATH_APPLICATION, PATH_SSO, SERVISE_URI, SSO_URI } from "../../assets/settings/settinsrouters";
import { render } from "react-dom";
import React from "react"
import ModalFluent from "../../components/tools/modalFluent/ModalFluent";
import fileDownload from 'js-file-download'

//import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
const url = `${SERVISE_URI}api`;
const token = CookieJS.get("token");
const nameClient = CookieJS.get("nameClient");
const idLng = CookieJS.get("idLng")?JSON.parse(CookieJS.get("idLng")):JSON.parse(CookieJS.get("dataUser")).user.idLanguage;

const contentType = {
    'encode': 'application/x-www-form-urlencoded',
    'json': 'application/json;charset=UTF-8',
    'form': 'multipart/form-data'
}

const GetRequest = async ({category, method, param, body, id, tenant, headerEx, idLanguageUser}) =>{
    
    const data = await axios(ContrlModel({
            id: id,
            method: method, 
            params: param, 
            body: body, 
            category: category,
            token: token,
            tenant: tenant,
            headerEx,
            idLanguageUser:idLanguageUser
        })
        ).then((res)=>{
            return res.data
        }).catch(error=>{
            if (error.response) {
                return error.response.data
            }
        })

    return data    
}

export const actualiza =(db,data) =>{
  const transaction = db?.transaction(['textF'],'readwrite')
  const objectStore = transaction?.objectStore('textF')
  const request = objectStore?.put(data)
  request.onsuccess = () =>{
      console.log('funciona act')
  }
}

export const consulta = (db) => {
  const transaction = db.transaction(['textF'], 'readonly')
      const objectStore = transaction.objectStore('textF')
      const conexion = objectStore.openCursor()
      conexion.onsuccess = (e) => {
          const cursor = e.target.result
          if (cursor) {
          }
      }
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['textF'], 'readonly')
      const objectStore = transaction.objectStore('textF')
      const conexion = objectStore.openCursor()
      conexion.onsuccess = (e) => {
          const cursor = e.target.result
          if (cursor) {
              resolve(cursor.value)
          }
      }
  })
}

const removeCookiesAndRedirect = () => {
  CookieJS.remove('token');
  CookieJS.remove('dataUser');
  CookieJS.remove('dataToken');
  CookieJS.remove('dataClient');
  CookieJS.remove('refresh_token');
  window.location.href = `${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${nameClient}/home`;
}

const GetRequestFile = async ({category, method, param, body, id, tenant, filename}) =>{
    
  const data = await axios(ContrlModel({
          id: id,
          method: method, 
          params: param, 
          body: body, 
          category: category,
          token: token,
          tenant: tenant,
      })
      ).then((res)=>{
        fileDownload(res.data,filename)
          return res.data
      }).catch(error=>{
          if (error.response) {
              return error.response.data
          }
      })

  return data    
}

export const FetchGet = ({category, method, param, body, id, tenant, headerEx, idLanguageUser})=>{
    return Promise.all([
        GetRequest({category:category, method:method, param:param, body:body, id:id, tenant: tenant, headerEx, idLanguageUser:idLanguageUser})
    ]).then(([data])=>{
        return{data};
    });
}

export const FetchGetFile = ({category, method, param, body, id, tenant, filename})=>{
  return Promise.all([
    GetRequestFile({category:category, method:method, param:param, body:body, id:id, tenant: tenant,filename:filename})
  ]).then(([data])=>{
      return{data};
  });
}
  
const actionOnClick =(data) => {
  if(data){
    if(data.option === 'createItem'){
        RequestFetch.prototype.createItem(data)
    }else if(data.option === 'updateItem'){
        RequestFetch.prototype.updateItem(data)
    }else if(data.option === 'patchItem'){
        RequestFetch.prototype.patchItem(data)
    }else if(data.option === 'deleteItem'){
        RequestFetch.prototype.deleteItem(data)
    }
  }
}
const optClick = (data) =>{
  if (data.op == 'b') {
    window.location.reload()
  }
  if (data.op == 'a') {
    window.history.back()
  }
  if(data.op == "c"){
    window.location.href = `${BSR_URI}${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/${data.tenant}/administration`
  }
  if(data.op == "d"){
    window.location.href = `${BSR_URI}${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/${data.tenant}/user`
  }
}
const controlsPage = {}

export const getText =(text)=>{
  text.map((spd) =>{
    if (spd.path == 'controls') {
      spd.webcontrolspages.map((data) => {
        controlsPage[data.idHtml] = data.languagewebcontrols[0];
      })
    } 
  })
}
export class RequestFetch {
    constructor(page){
        this.page = page
    }

   confirmRequest({body, id, ct, tenant, option,op, hideIcon,headerEx,infoModal}){
    const {smtAccept,smtConfirm,lblAreYouSureToPerformThisAction,smtClose} =controlsPage
    console.log('dffdfd',controlsPage)
      let objectRequest ={
        body:body, 
        id:id, 
        ct:ct, 
        tenant:tenant,
        option:option,
        page:this.page,
        op:op,
        hideIcon:hideIcon,
        headerEx:headerEx,
        infoModal:infoModal
      }
      let info ={
        title:smtConfirm?.value,
        description:lblAreYouSureToPerformThisAction?.value,
        type:'warning',
      }
      render(<ModalFluent props={info}
        actionOnClick={actionOnClick} 
        objectRequest={objectRequest}
        text={{smtAccept, smtClose}}
        />,
        document.getElementById("modal")
      );

    }
// confirmRequest({body, id, ct, tenant, option,acceptTermsConditions}){

    //   let objectRequest ={
    //     body:body, 
    //     id:id, 
    //     ct:ct, 
    //     tenant:tenant,
    //     option:option,
    //     page:this.page
    //   }
    //   let info ={
    //     title:'confirmar',
    //     description:'¿Está seguro de realizar esta acción?',
    //     type:'warning',
    //   }

    //   if(acceptTermsConditions === undefined || acceptTermsConditions === null){        
    //     render(<ModalFluent props={info} 
    //       actionOnClick={actionOnClick} 
    //       objectRequest={objectRequest}
    //       />,
    //       document.getElementById("modal")
    //     );
    //   }
    //   if(acceptTermsConditions && acceptTermsConditions !== undefined){
    //     render(<ModalFluent props={info} 
    //       actionOnClick={actionOnClick} 
    //       objectRequest={objectRequest}
    //       />,
    //       document.getElementById("modal")
    //     );
    //   }else if(acceptTermsConditions === false && acceptTermsConditions !== undefined){
    //     info.title = 'confirmar'
    //     info.description = "¿Terminos y condiciones?"
    //     info.type = 'danger'
    //     render(<ModalFluent props={info} 
    //       actionOnClick={actionOnClick} 
    //       objectRequest={objectRequest}
    //       />,
    //       document.getElementById("modal")
    //     );
    //   }

    // }

    async getItem({param, id, tenant, headerEx,actionClick}){
      const {smtAccept,smtConfirm,lblAreYouSureToPerformThisAction,smtClose} =controlsPage
        const resp = await FetchGet({
            category: this.page,
            method: 'get', 
            param: param,
            id: id,
            tenant: tenant,
            headerEx,
            idLanguageUser:JSON.parse(CookieJS.get("dataUser")).user.idLanguage
        })
        
        if (!resp.data.errors) {
          
          return resp
         
        }else{
          let error =resp.data.errors
          let info ={
            title:error[0].title,
            description:error[0].code+" - "+error[0].detail,
            type:'danger',
            errorGet:!error[0].code == 'AG-2204'
          }
          render(<ModalFluent props={info} 
            actionOnClick={actionClick?()=>actionClick():()=>{}}
            func={error[0].status == '401'? 
            (e)=>{
              removeCookiesAndRedirect();
            }:actionClick}
            errToken={error[0].status == '401'? true:false}
            text={{smtAccept, smtClose}}
            />,
            document.getElementById("modal")
          );
        }
        
    }

    getItemById({param, id, tenant}){
        return( FetchGet({
            category: this.page,
            method: 'getid',
            param: param,
            id: id,
            tenant: tenant
        }))
    }

    async getFile({param, id, tenant,filename}){
      return( FetchGetFile({
        category: this.page,
        method: 'get', 
        param: param,
        id: id,
        tenant: tenant,
        filename:filename
    }))
    }

    async getById({id, tenant, ct}){
      return await axios.get(`${url}/${this.page}/${id}`, {
          headers: {
            'content-type': contentType[ct], 
            'Authorization': `Bearer ${token}`,
            'tenant': tenant
          }
        })
    }

    async createItem({body, ct, tenant,param, tr, op, headerEx,infoModal,objectRequest}){
      const {smtAccept,smtConfirm,lblAreYouSureToPerformThisAction,smtClose} =controlsPage
      if (ct === 'encode') {
        if(tr === 'revoke'){
          return axios.post(`${url}/${this.page}`, body)
        }else{
          console.log(contentType[ct])
          return axios.post(`${url}/${this.page}`, body,
          {
            headers: {
              'content-type': contentType[ct],
                  'Authorization': `Bearer ${token}`,
                  'tenant': tenant,
                  'idLanguageUser':JSON.parse(CookieJS.get("dataUser")).user.idLanguage,
                  ...headerEx
            }
          });
        }
      }else if(ct === 'json'){
       return await axios.post(`${url}/${this.page}`, body, {
            headers: {
                'content-type': contentType[ct], 
                'Authorization': `Bearer ${token}`,
                'tenant': tenant,
                'Model':param,
                'idLanguageUser':JSON.parse(CookieJS.get("dataUser")).user.idLanguage,
                ...headerEx
            }
        }).then(e=>{
            let infopax ={
                title:infoModal.title,
                description:infoModal.description,
                type:'success',
              }
              render(<ModalFluent props={infopax} 
                actionOnClick={optClick} 
                op={{op:op,tenant:tenant}}    
                objectRequest={objectRequest}   
                text={{smtAccept, smtClose}}    
                />,
                document.getElementById("modal")
              );
        }).catch(error=>{
            if (error.response) {
                let description = ''
                let code = ''
                let title = ''
                if (Array.isArray(error.response.data.errors)) {
                for (const property in error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                      description = error.response.data.errors[i].detail;
                      code = error.response.data.errors[i].code;
                      title = error.response.data.errors[i].title;
                    }
                }
                } else if (typeof error.response.data.errors === 'object') {
                  for (const property in error.response.data.errors) {
                      for (let i = 0; i < error.response.data.errors[property].length; i++) {
                        description = `${description}\n${error.response.data.errors[property][i]}`;
                        
                      }
                  }              
                }
                let info ={
                  title:title,
                  description:`${code} - ${description}\n`,
                  type:'danger'
                }
                render(<ModalFluent props={info} 
                  actionOnClick={actionOnClick}  
                  func={error.response.status == '401'?
                  (e)=>{
                    removeCookiesAndRedirect();
                  }:()=>{}}
                  errToken={error.response.status == '401'? true:false}
                  text={{smtAccept, smtClose}}              
                  />,
                  document.getElementById("modal")
                );
              }
        })
      }else if(ct === 'form'){
        return await axios({
          method: "post",
          url: `${url}/${this.page}`,
          data: body,
          headers: { 
            'content-type': contentType[ct], 
            'Authorization': `Bearer ${token}`,
            'tenant': tenant,
            'idLanguageUser':JSON.parse(CookieJS.get("dataUser")).user.idLanguage,
            'Model':param
          },
         }).then(e=>{
             let infopax ={
                 title:infoModal.title,
                 description:infoModal.description,
                 type:'success',
               }
               render(<ModalFluent props={infopax} 
                 actionOnClick={actionOnClick}
                 text={{smtAccept, smtClose}}
                 />,
                 document.getElementById("modal")
               );
         }).catch(error=>{
             if (error.response) {
                 let description = ''
                 if (Array.isArray(error.response.data.errors)) {
                 for (const property in error.response.data.errors) {
                     for (let i = 0; i < error.response.data.errors.length; i++) {
                       description = error.response.data.errors[i].detail;
                     }
                 }
                 } else if (typeof error.response.data.errors === 'object') {
                   for (const property in error.response.data.errors) {
                       for (let i = 0; i < error.response.data.errors[property].length; i++) {
                         description = `${description}\n${error.response.data.errors[property][i]}`;
                         
                       }
                   }              
                 }
                 let info ={
                   title:error.response.data.title,
                   description:`${description}\n`,
                   type:'danger',
                 }
                 render(<ModalFluent props={info}
                   actionOnClick={actionOnClick}
                   func={error.response.status == '401'?
                  (e)=>{
                    removeCookiesAndRedirect();
                  }:()=>{}}
                  errToken={error.response.status == '401'? true:false}
                  text={{smtAccept, smtClose}}
                   />,
                   document.getElementById("modal")
                 );
               }
         })
       }
    }
    async createItemParams({body, ct, param, tenant, headerEx, validator, hideIcon, actionClick}){
      const {smtAccept,smtConfirm,lblAreYouSureToPerformThisAction,smtClose} =controlsPage
      return await axios.post(`${url}/${this.page}?${param}`,body,{
          headers: {
              'content-type': contentType[ct],
              'Authorization': `Bearer ${token}`,
              'tenant': tenant,
              'idLanguageUser':JSON.parse(CookieJS.get("dataUser")).user.idLanguage,
              ...headerEx
          }
      })/* .then(e=>{
          alert('si')
          let infopax ={
              title:'Actualización',
              description:'Se ha registrado la información de forma correcta',
              type:'success',
            }
            render(<ModalFluent props={infopax} 
              actionOnClick={actionOnClick}                
              />,
              document.getElementById("modal")
            );
      }) */
      .catch(error=>{
          if (error.response) {
              let description = ''
              let title = ''
              if (Array.isArray(error.response.data.errors)) {
                  title=error.response.data.errors[0].title
              for (const property in error.response.data.errors) {
                  for (let i = 0; i < error.response.data.errors.length; i++) {
                    description =error.response.data.errors[i].code + ' - ' + error.response.data.errors[i].detail;
                  }
              }
              } else if (typeof error.response.data.errors === 'object') {
                  title=error.response.data.title

                for (const property in error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors[property].length; i++) {
                      description = `${description}\n${error.response.data.errors[property][i]}`;
                      
                    }
                }              
              }
              let info ={
                title:title,
                description:description,
                type:'danger',
              }
              render(<ModalFluent props={info} 
                actionOnClick={(e)=>actionClick(e)}
                hideIcon={hideIcon}
                redirect={validator?validator:false}
                text={{smtAccept, smtClose}}
                />,
                document.getElementById("modal")
              );
          }
      }) 
  }
  async updateItem({body, id, ct, tenant, page,infoModal,op}){
    const {smtAccept,smtConfirm,lblAreYouSureToPerformThisAction,smtClose} =controlsPage

        await axios.put(`${url}/${page}/${id}`, body, {
          
            headers: {
                'content-type': contentType[ct], 
                'Authorization': `Bearer ${token}`,
                'idLanguageUser':JSON.parse(CookieJS.get("dataUser")).user.idLanguage,
                'tenant': tenant
            }
        }).then(e=>{
            let infopax ={
              title:infoModal.title,
              description:infoModal.description,
              type:'success',
            }
            render(<ModalFluent props={infopax} 
              actionOnClick={optClick}
              op={{op:op,tenant:tenant}}             
              text={{smtAccept, smtClose}}            
              />,
              document.getElementById("modal")
            );
        }).catch(error=>{
            if (error.response) {
              let description = ''
              if (Array.isArray(error.response.data.errors)) {
                for (const property in error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                      description = error.response.data.errors[i].detail;
                    }
                }
              } else if (typeof error.response.data.errors === 'object') {
                for (const property in error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors[property].length; i++) {
                      description = `${description}\n${error.response.data.errors[property][i]}`;
                      
                    }
                }              
              }
                
              let info ={
                title:error.response.data.title,
                description:`${description}\n`,
                type:'danger',
              }
              render(<ModalFluent props={info} 
                actionOnClick={actionOnClick}
                func={error.response.status == '401'?
                (e)=>{
                  removeCookiesAndRedirect();
                }:()=>{}}
                errToken={error.response.status == '401'? true:false}
                text={{smtAccept, smtClose}}             
                />,
                document.getElementById("modal")
              );
            }
        })
    }
    async deleteItem({id, tenant, page,op,infoModal}){
      const {smtAccept,smtConfirm,lblAreYouSureToPerformThisAction,smtClose} =controlsPage
        await axios.delete(`${url}/${page}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'idLanguageUser':JSON.parse(CookieJS.get("dataUser")).user.idLanguage,
                'tenant': tenant
            }
        }).then(e=>{
            let infopax ={
                title:infoModal.title,
                description:infoModal.description,
                type:'success',
              }
              render(<ModalFluent props={infopax} 
                actionOnClick={optClick}
                op={{op:op,tenant:tenant}}
                text={{smtAccept, smtClose}}
                />,
                document.getElementById("modal")
              );
        }).catch(error=>{
            if (error.response) {
              let description = ''
              let code = ''
              if (Array.isArray(error.response.data.errors)) {
                for (const property in error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                      description = error.response.data.errors[i].detail;
                      code = error.response.data.errors[i].code;
                    }
                }
              } else if (typeof error.response.data.errors === 'object') {
                for (const property in error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors[property].length; i++) {
                      description = `${description}\n${error.response.data.errors[property][i]}`;
                      code = error.response.data.errors[i].code;
                      
                    }
                }              
              }
              let info ={
                title:error.response.data.title,
                description:`${code} - ${description}\n`,
                type:'danger',
              }
              render(<ModalFluent props={info} 
                actionOnClick={actionOnClick}
                text={{smtAccept, smtClose}}              
                />,
                document.getElementById("modal")
              );
            }
        })
    }
    async patchItem({ body, id, ct, tenant,page, op,hideIcon, headerEx,infoModal }) {
      const {smtAccept,smtClose} =controlsPage
      return await axios.patch(`${url}/${page}/${id}`, body, {
        headers: {
          "content-type": contentType[ct],
          Authorization: `Bearer ${token}`,
          "tenant": tenant,
          'idLanguageUser':JSON.parse(CookieJS.get("dataUser")).user.idLanguage,
          ...headerEx
        },
      })
      .then(e=>{
        const path = window.location.pathname.split('/').pop()
        let infopax ={
            title:infoModal.title,
            description:infoModal.description,
            type:'success',
            path: path,
            'tenant': tenant
          }
          render(<ModalFluent props={infopax} 
            hideIcon={hideIcon}
            actionOnClick={optClick}
            op={{op:op,tenant:tenant}} 
            text={{smtAccept,smtClose}}   
            />,
            document.getElementById("modal")
          );
      })
      .catch(error=>{
        let description = ''
        let code = ''
        let title = ''
          if (Array.isArray(error.response.data.errors)) {
            for (const property in error.response.data.errors) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  description = error.response.data.errors[i].detail;
                  code = error.response.data.errors[i].code;
                  title = error.response.data.errors[i].title;
                }
            }
          } else if (typeof error.response.data.errors === 'object') {
            for (const property in error.response.data.errors) {
                for (let i = 0; i < error.response.data.errors[property].length; i++) {
                  description = `${description}\n${error.response.data.errors[property][i]}`;
                }
            }              
          }
          let info ={
            title:title,
            description:`${code} - ${description}\n`,
            type:'danger',
          }
          render(<ModalFluent props={info} 
            actionOnClick={actionOnClick}
            func={error.response.status == '401'? 
            (e)=>{
              removeCookiesAndRedirect();
            }:()=>{}}
            errToken={error.response.status == '401'? true:false}
            text={{smtAccept, smtClose}}
            />,
            document.getElementById("modal")
          );
      })
    }
} 
