import React, { useContext, useEffect, useState } from 'react'
import CookiesJS from 'js-cookie';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks'
import { useParams } from 'react-router-dom';
//*****Componnents*****//
    import SubMenu from '../../components/navigation/subMenu/subMenu.component'
    import ContainerLargeForm from '../../components/containers/largeForm/containerLargeForm.component'
    import ContainerBaseLoading from '../../components/containers/baseLoading/containerBaseLoading.component'
    import ListHeader from '../../components/lists/listHeader/listHeader.component'
    import ListBody from'../../components/lists/listBody/listBody.component'
    import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';


//*****Methods*****//
    import Lists from '../../utils/logicRendering/lists.controller'
    import {RequestFetch} from '../../utils/Requests/requests.Controller'
    import CountPages from '../../utils/logicRendering/countPages.controller'
    import LenguajeContext from '../../context/Lenguaje/lenguajeContext';
    import { changeLang, changeLangForm } from '../../utils/logicRendering/changeIdiom';

const NotificationTemplate = ()=>{

  
    /************************
     * @arguments 
     ************************/
        //***Propertise***//
        const [edit, setEdit] = useState(false);
        const [deleteD, setDeleteD] = useState(false);
        const [idTemplateSelect, setIdTemplateSelect] = useState([]);
        const [currentPages, setCurrentPage]= useState(1);
        const [showElements, setShowElements]= useState(5);
        const [itemsType, setItemsType] = useState(null);
        const [itemsNotificacion, setItemsNotificacion] = useState(null);
        const [itemsTemplate, setItemsTemplate] = useState(null);
        const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
        const [responseCode, setResponseCode] = useState(null);
        const { clientName } = useParams();
        const {idClient} = JSON.parse(CookiesJS.get('dataClient'));

        //***Data Propertise***//
        const Requests = new RequestFetch('NotificationTemplate');
        const ResquetsNotifiType = new RequestFetch("Notification/types");
        const ResquetsNotification = new RequestFetch("Notification");
        const language = CookiesJS.get('languages');
        const [controlsPage, setControlsPage] = useState({});
        const [textOnPages, setTextOnPages] = useState({})
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        const lng = JSON.parse(language);
        const SpaceTitel = ['Notificación','Detalles','Tipo','Idioma']
        const OrdItems = ['notification','subject','nameNotiType','nameLanguage']
        let selectLng = [];
        let lngFilter = [];
        const COLUMN_TITLES = [{
            notification: {
                name: "Notificación",
                minWidth: 50,
                isResizable: true,
                //idHtml: "coluDomain",
                maxWidth: 100
            },
            subject: {
                name: "Detalles",
                minWidth: 50,
                isResizable: true,
                //idHtml: "coluEmail",
                maxWidth: 150
            },
            type: {
                name: "Tipo",
                minWidth: 50,
                isResizable: true,
                //idHtml: "coluState",
                maxWidth: 150
            },
            language: {
                name: "Idioma",
                minWidth: 50,
                isResizable: true,
                //idHtml: "coluState",
                maxWidth: 150
            },
            creatingDate: {
                name: "Fecha de creación",
                minWidth: 50,
                isResizable: true,
                maxWidth: 150,
                idHtml: "coluCreationDate",
            },
            updatingDate: {
                name: "Última Modificación",
                minWidth: 50,
                isResizable: true,
                maxWidth: 150,
                idHtml: "coluLastModification",
            },
            creatorUser: {
              name: "Creado por",
              maxWidth: 150,
              isResizable: true,
              idHtml: "coluCreatedBy",
            },
            updateUser: {
              name: "Modificado por",
              maxWidth: 150,
              isResizable: true,
              idHtml: "lblModifiedBy"
            },
        }];
        const [columnTitles, setColumnTitles] = useState(COLUMN_TITLES)
        const [filter, setFilter] = useState({
            pageSize: 5,
            pageNumber: "0",
            idDomain: "",
            idLanguage: JSON.parse(CookiesJS.get("dataUser")).user.idLanguage
        });
        const [pagination, setPagination] = useState(null);
        const [isLoadingData, {
            setTrue: handleLoadingData,
            setFalse: handleLoadedData
        }] = useBoolean(false);
    
        const [campForm, setCampForm] = useState([

            {  
                id:0, 
                key: 'idNotification',
                label: 'Notificación',
                type: 'select',
                required: true,
                options: [
                ],
            },
            {  
                id:1, 
                key:'idLanguage', 
                label: 'Idioma', 
                type:'select',
                required: true, 
                options: selectLng,
            },
            {  
                id:2, 
                key: 'subject',
                label: 'Asunto',
                type: 'text',
                placeholder: '...',
                required: true,
                defaultValue: '',
            },
            {  
                id:3, 
                key: 'notificationtemplate_domain',
                label: 'Dominio a Asociar',
                type: 'select',
                required: true,
                options: [
                ],
            },
        ])
        const campFilters = [
            {
                id:0, 
                key:'idLanguage', 
                label:'Lenguaje', 
                required: true,
                type:'select', 
                options: lngFilter
            }
        ]

        useEffect(() => {
            let controlsPage = {};
            if (stateLanguage.lenguaje !=0) {
                //console.log(stateLanguage);
                stateLanguage.lenguaje.map((pass) => {
                  // console.log(pass)
                    if (pass.path == 'controls'|| pass.path =='domain') {
                      pass.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                      })    
                      setControlsPage(controlsPage)
                    }              
                })          
                setColumnTitles(current => changeLang(current, controlsPage))
            }
        
        }, [textOnPages, stateLanguage])
    
        

    /************************
    * @methods 
    ************************/
        const methodsList = {
            getDataItem:(object)=>{
                const stateList = new Lists(itemsTemplate, campForm)
                setIdTemplateSelect(stateList.getDataItem(object, idTemplateSelect))
                const stateCurrent = stateList.updateCurrent(idTemplateSelect)
                setCampForm(stateCurrent.object)
                setDeleteD(stateCurrent.delete)
                setEdit(stateCurrent.edit)
            },
            voidCamps:()=>{
                const stateList = new Lists(itemsTemplate, campForm)
                setCampForm(stateList.clearCamps())
            }
        }

        const methosRequests = {
            chageGetItems:(filters)=>{
                handleLoadingData()
                setPromiseNotification(ResquetsNotification.getItem({param:filters}));
                setItemsType(ResquetsNotifiType.getItem({param:filters}));
                setPromise(Requests.getItem({param:filters, tenant: clientName}));
                handleLoadedData();
            },
            crateItem:(dataForm)=>{
                dataForm = Object.assign(dataForm, {
                    idClient: idClient,
                });
                Requests.createItem({ body: dataForm, ct: "json" , tenant:clientName, 
                    infoModal:{
                    title:controlsPage?.lblSuccessfulProcess?.value,
                    description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                    }
                })
            },
            updateItem:(dataForm, id)=>{
                Requests.confirmRequest({ body: dataForm, id: id, ct: "json", tenant: clientName, option:'updateItem',
                    infoModal:{
                    title:controlsPage?.lblSuccessfulProcess?.value,
                    description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
                    }
                })
            },
            deleteItem:()=>{
                idTemplateSelect.forEach(element=>Requests.confirmRequest({ id:element.id, tenant: clientName, option:'deleteItem',
                infoModal:{
                    title:controlsPage?.lblDisabled?.value, 
                    description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
                }
                }))
            }
        }

    /************************
     * @hooks 
    ************************/
        const [promiseNotification, setPromiseNotification] = useState(
            ResquetsNotification.getItem({pageSize: showElements, pageNumber: currentPages, tenant: clientName})
        );
        const [promiseType, setPromiseType] = useState(ResquetsNotification.getItem({
            param: {pageSize: showElements, pageNumber: currentPages}, tenant: clientName
        }))
        const [promise, setPromise]= useState(Requests.getItem({
            param: {pageSize: showElements, pageNumber: currentPages, idClient: idClient },tenant: clientName
        }))
        const [update, setUpdate] = useState(null);

        useEffect(()=>{
            promise.then(data =>{
                setItemsTemplate(data.data);
                const {
                    currentPage,
                    pageZise,
                    totalCount,
                    totalPages
                } = data.data.meta;
                setPagination({
                    currentPage: currentPage,
                    pageSize: pageZise,
                    totalItems: totalCount,
                    totalPages: totalPages
                })
            })
            promiseType.then(data =>{
                setItemsType(data.data)
            })
            promiseNotification.then(data =>{
                setItemsNotificacion(data.data)
            })
        },[promise])

        /*useEffect(()=>{
            promiseNotification.then(data => {
                data.data.data.map(data =>{
                    campForm[0].options.push({value: data.id, text: data.name})
                })
                setCampForm(campForm)
            })
        },[promiseNotification])
        
        useEffect(() => {
            if(update ){
            update.then(data =>{
                setResponseCode(data.status);
            })}
        }, [update]);

        if(itemsTemplate && itemsNotificacion){
            itemsTemplate.data.map(tal=>{
                itemsNotificacion.data.map(mas=>{
                    if(tal.idNotification == mas.id){
                        let nameType = mas.name;
                        tal =Object.assign(tal,{notification:nameType})
                    }
                })
            }
            )
        }
        if(itemsTemplate && itemsType){
            itemsTemplate.data.map(tal=>{
                itemsType.data.map(mas=>{
                    if(tal.idNotification == mas.id){
                        let nameNotiType = mas.nameNotiType
                        tal =Object.assign(tal,{nameNotiType:nameNotiType})
                    }
                })
            }
            )
        }
        for(let i = 0; i<lng.length; i++){
            let lngArray = {value: lng[i].id, text: lng[i].name}
            let lngArray2 = {id: lng[i].id, text: lng[i].name}
            selectLng.push(lngArray);
            lngFilter.push(lngArray2);
        }*/
        
    /************************
     * @resturns 
     ************************/
        if(itemsType === null || itemsTemplate === null){
            return (<ContainerBaseLoading/>)
        }
        const numberPage = CountPages(itemsTemplate.meta.totalPages)
        return(
            <>
                <SubMenu
                    contentcontrols={
                        !deleteD && !edit ? [
                            {
                                text:"Nuevo Plantilla",
                                iconProps:{ iconName: "Add" },
                                onClick:() => {
                                    methodsList.voidCamps();
                                    openPanel();
                                }
                            }
                        ]
                        :
                        [
                            edit?{
                                text:"Editar Plantilla",
                                iconProps:{ iconName: "Edit" },
                                onClick:()=>openPanel()
                            }:null,
                            {
                                text:"Inhabilitar Plantilla",
                                iconProps:{ iconName: "delete" },
                                onClick:()=>methosRequests.deleteItem()
                            }
                        ].filter(obj => obj !== null)
                    }
                    contentcontrolsTwo={
                        <>
                            <Panel isOpen={isOpen}
                                closeButtonAriaLabel="Close"
                                isHiddenOnDismiss={true}
                                onDismiss={dismissPanel}
                                type={PanelType.smallFixedFar}
                            >    
                                {isOpen ?<ContainerLargeForm
                                    dataForm ={(e) =>{
                                        edit ? methosRequests.updateItem(e, idTemplateSelect[0].id ): methosRequests.crateItem(e)
                                    }}
                                    formTitle={edit?'Editar Plantilla':'Nuevo Plantilla'}
                                    Items={campForm}
                                    closePanel={dismissPanel}
                                />:''}
                            </Panel>
                        </>
                    }
                /> 
                <div className="admin--body-container">
                    <ListHeader 
                        filters={campFilters}
                        showReport={false}
                        subRute="Notificaciones/Administración | "
                        subRute3 = " Configuración de Servicios Externos"
                        title="Banco de Notificaciones"
                        dataForm={(e)=>methosRequests.chageGetItems({
                            pageSize:showElements, pageNumber:currentPages,
                            idDomain:e.idDomain, idLanguage:e.idLanguage
                        })}
                        onChange={(e)=>{
                            setShowElements(e.target.value); 
                            methosRequests.chageGetItems({pageSize:e.target.value, pageNumber:currentPages});
                        }}
                        inputChange={(e)=>{
                            methosRequests.chageGetItems({search:e, pageSize:setShowElements, pageNumber: currentPages });
                        }}
                    />
                    {/* <ListBody 
                        spaceTitle={SpaceTitel}
                        Items={itemsTemplate.data} 
                        meta={itemsTemplate.meta}
                        numberPages={numberPage}
                        initRangeText={currentPages}
                        orditem={OrdItems}
                        finishRangeText={showElements}
                        totalRangeText={itemsTemplate.data.length}
                        typelist='listSelect'
                        selectdata={methodsList.getDataItem}
                        paginate={(number)=>{
                            setCurrentPage(number); 
                            methosRequests.chageGetItems({pageSize:showElements, pageNumber:number});
                        }}
                    /> */}
                    <DetailsListBext
                        enableShimmer={isLoadingData}
                        listKey="listSelect"
                        selectionMode={1}
                        items={itemsTemplate.data}
                        //renderItemColumn={_defaultRenderItemColumn}
                        columnTitles={columnTitles}
                        onSelect={methodsList.getDataItem}
                        onPageChange={page => {
                            if (page !== +filter.pageNumber) {
                                setFilter({
                                    ...filter,
                                    pageNumber: String(page),
                                });
                            }
                        }}
                        pagination={pagination}
                    />
                </div>
            </>    
        );
}

export default NotificationTemplate