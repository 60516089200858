import CookiesJS from 'js-cookie';

export const validateRoute = async() => {
    const path = JSON.parse(CookiesJS.get('pages'));
    const currentLocation = window.location.href.split('/').pop()
    let isValid = path.includes(currentLocation)
    // console.log(path)
    // console.log(currentLocation)
    // console.log(isValid)
    if (path.some(palabra => window.location.href.includes(palabra))) {
        // console.log("Se encontró una palabra clave en el texto.");
        isValid=true
      } else {
        // console.log("No se encontró ninguna palabra clave en el texto.");
        isValid = false
    }
    return isValid;
}

