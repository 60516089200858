import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { MaskedTextField } from "@fluentui/react/lib/TextField";
import { Stack } from '@fluentui/react/lib/Stack';
import LargeFormSelect from "./LargeFormSelect";

const LargeFormMaskedTextField = (props) => {
  LargeFormMaskedTextField.item = {
    label: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  };

  const [selectedItem, setSelectedItem] = useState();
  let item = props.item;

  //#region Buscar en un arreglo el id para habilitar y deshabilitar
  let found;
  if (props.isDisableOpt.length === 1) {
    found = props.isDisableOpt.find(
      (element) => element.key === "valueDefault"
    );
  } else {
    found = props.isDisableOpt.find((element) => element.key === item.key);
    if (found == undefined) {
      found = props.isDisableOpt.find(
        (element) => element.key === "valueDefault"
      );
    }
  }
  if (found == undefined) {
    found = props.isDisableOpt.find((element) => element.key === item.key);
  }
  //#endregion
  //#region Accion onChange
  const onChange = (e, item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    let objectFinal = {
      ...selectedItem,
      ...{ id: item.key, state: found.disable },
    };
    props.actionOnChange(objectFinal);
  }, [selectedItem, found]);
  //#endregion

  return (
      <>
    <label> {item.label}</label>
      <Stack id={item.id} style={item.style}>
        <MaskedTextField
          id={item.key}
          mask={item.mask}
          title={item.title}
          className={"" && item.className}
          defaultValue={item.defaultValue}
          disabled={found.disable}
          iconitem={{ iconName: item.iconitem }}
          onChange={onChange}
          //styles={item.style}
          type={item.typeInput}
          required={item.isRequired}
        />
      </Stack>
      </>
  );
};
export default LargeFormMaskedTextField;
