import { Label } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react'
import { ReactComponent as IconDobleFactor } from "../../assets/img/ico-doblefactor.svg";
import ContainerBaseLoading from '../../components/containers/baseLoading/containerBaseLoading.component';
import LargeFormInput from '../../components/containers/largeForm/components/LargeFormInput';
import HeaderForm from '../../components/Forms/headerForm/headerForm.component';
import LenguajeContext from '../../context/Lenguaje/lenguajeContext';
import CookiesJS from "js-cookie";
import { RequestFetch } from '../../utils/Requests/requests.Controller';
import { useNavigate, useParams } from 'react-router-dom';
import ChallengesMfa from './challengesMfa';
import { render } from 'react-dom';
import ModalFluent from '../../components/tools/modalFluent/ModalFluent';
import { PATH_APPLICATION } from '../../assets/settings/settinsrouters';
const Mfa = (props) => {


const requestsUser = new RequestFetch("Users/stateMFAOffice365");
// const enableCount = new RequestFetch(`Access/enableAccount/${2}/${'jhonbe'}`);
const isAdmin = props.isAdmin
const user = isAdmin? props.infUsr : JSON.parse(CookiesJS.get("dataUser")).user ;
const showAdmin = isAdmin? props.infUsr : JSON.parse(CookiesJS.get("dataUser")).showAdmin ;
const dataUserIdLng = JSON.parse(CookiesJS.get("idLng"));
const [name, setName] = useState('')
const [emailCorp, setEmailCorp] = useState('')
const { clientName } = useParams();
const [defaultValueInput, seDefaultValueInput] = useState([]);
const [textOnPages, setTextOnPages] = useState({})
const [controlsPage, setControlsPage] = useState({});
const [renderCase, setRenderCase] = useState(null)
const navigate = useNavigate();
const [statusMfa, setStatusMfa] = useState('')

const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
const [colorFond, setColorFond] = useState('var(--color-warning-fond)')
const [colorTxt, setColorTxt] = useState('#107c10')

const [isDisable, setDisable] = React.useState([
    { disable: false, key: "valueDefault" }
]);


useEffect(() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        stateLanguage.lenguaje.map((pass) => {
            if (pass.path == 'manage-mfa' || pass.path == 'controls') {
                pass.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                })
                setControlsPage(controlsPage)
            }              
        })
    }
}, [textOnPages, stateLanguage])

useEffect(() => {
    if (!isAdmin) {
        const footerStyle = document.querySelector('.admin--container-footer');
        footerStyle?.classList.add('div-foot-rel');
        props.onChange('div-foot-rel')
    }
  }, [props])

useEffect(async() => {
    if (Object.entries(controlsPage).length>0) {
    const resp = await requestsUser.getItem({
        tenant: clientName, 
        param:{idUser:user.id}, 
        headerEx: {idLanguageUser:dataUserIdLng?dataUserIdLng:user.idLanguage},
        actionClick:()=>{navigate(isAdmin?window.location.reload():`${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/${clientName}/user/`)}
    })
    const {
        firstName, 
        secondName, lastName, 
        secondSurname, 
        institutionalMail,
        wasEnabled,
        wasDisabledPerDays
    } = resp.data.data
    const nombre = [firstName, secondName, lastName, secondSurname]
    // setName()
    // setEmailCorp(institutionalMail)
    let statusMfa = ''
    let colorTxt = '#4b4b4b'
    optRender()
    
    if (wasEnabled != undefined || wasDisabledPerDays != undefined) {
        if (wasEnabled) {
            colorTxt = '#107c10'
            statusMfa = controlsPage?.lblEnable.value
        }else{
            colorTxt = '#CC0000'
            statusMfa = controlsPage?.lblDisabled.value
        }

        if (wasDisabledPerDays) {
            colorTxt = '#F37807'
            statusMfa = controlsPage?.lblDisqualifiedForFiveDays.value
        }
    }
    if (!props.hide) {
        setRenderCase(
            <>
            <div style={{marginTop:'0px', marginBottom:'10px'}} className= {`${showAdmin?'w9 ml2-mfa':'w10 ml2'} mra`}>
                <LargeFormInput 
                item={{
                        id: 'name',
                        key: 'name',
                        label: controlsPage?.lblUserNames.value,
                        ariaLabel: null,
                        defaultValu: name,
                        disabled: true,
                        disable:true,
                        resizable: false,
                        // typeInput: inp.typeInput,
                        description: "",
                        title: null,
                        onRenderLabel: null,
                        isRequired: false,
                        onGetErrorMessage: null,
                        style:{color:"black"},
                        errorMsg:true,
                        className: showAdmin ? 'w10-mfa' :'w10_5' ,
                        value:nombre.join(' ') 
    
                    }}
                    isDisableOpt={isDisable} 
                    label="Disabled:" 
                    actionOnChange={actionOnChange}
                    defaultValueInput={defaultValueInput}
                    />
            </div>
            <div className={ `${showAdmin?'w9 ml2-mfa':'w10 ml2'} mra mg10-h`}>
                <LargeFormInput 
                    item={{
                        id: 'emailCorp',
                        key: 'email-corp',
                        label:controlsPage?.lblCorporateEmail.value,
                        ariaLabel: null,
                        defaultValu: name,
                        disabled: true,
                        disable:true,
                        resizable: false,
                        // typeInput: inp.typeInput,
                        description: "",
                        title: null,
                        onRenderLabel: null,
                        isRequired: false,
                        onGetErrorMessage: null,
                        errorMsg:true,
                        style:{color:"black"},
                        className: showAdmin ? 'w10-mfa' :'w10_5' ,
                        value:institutionalMail 
    
                    }}
                    isDisableOpt={isDisable} 
                    label="Disabled:" 
                    actionOnChange={actionOnChange}
                    defaultValueInput={defaultValueInput}
                    />
            </div>
            <div style={{background:colorFond}} className={`${isAdmin?'mg30-h pg10':'mg20-h'} w6 c--flex-wc mra mla container--flex h3 c--flex-hc`}>
                <Label className='label-mfa'>{controlsPage?.lblMFAStatus.value}</Label>
                <Label style={{color:colorTxt}} className='label-mfa mg4-w'>{statusMfa}</Label>
            </div>
            <div style={isAdmin?null:{padding:'0px 8px'}} className={`mg10-h w10 container--flex ${isAdmin?'c--flex-wse':'c--flex-wsa'}`}>
                <button type="submit" 
                    style={{width:'144px', borderRadius:'5px'}}
                    onClick={()=>{submitEnableMfa({enableMfa:true})}}
                    className={`button--default pg10 ${wasEnabled?'button--disable':'button--primary'} smallS w8-resp`} 
                    disabled={wasEnabled}>
                    {controlsPage?.smtEnableMFA?.value}
                </button>
                <button type="submit" 
                    style={{width:'144px', borderRadius:'5px'}}
                    onClick={()=>{submitEnableMfa({disableMfaForDays:true})}}
                    className={`button--default pg10 mg-resp ${!wasEnabled?'button--disable':'button--primary'} smallS w8-resp`} 
                    disabled={!wasEnabled}>
                    {controlsPage?.smtDisableMFAForFiveDays?.value}
                </button>
                {isAdmin?
                <button type="submit" 
                    style={{width:'144px', borderRadius:'5px'}}
                    onClick={()=>{submitEnableMfa({enableMfa:false})}}
                    className={`button--default pg10 mg-resp ${!wasEnabled?'button--disable':'button--primary'} smallS w8-resp`} 
                    disabled={!wasEnabled}>
                    {controlsPage?.smtDisableUltimateMFA?.value}
                </button>
                    :''
                }
            </div>
            </>
        )
    }
    
    }
    
}, [controlsPage])

const actionOnChange = (data)=>{}

const optRender = () =>{
    // if (emailCorp != '') {
        
    // }
}

const submitEnableMfa = async(opt) => {
    if (isAdmin) {
        if (opt.enableMfa) {
            props.supportAction('enableMfa')
        }else{
            if (opt.disableMfaForDays) {
                props.supportAction('disableMfaForDays')
            }else{
                props.supportAction('disableMfa')
            }
        }

    } else {
        if (opt.disableMfaForDays) {
            let infor ={
                title: controlsPage?.smtConfirm.value,
                description: controlsPage?.lblWarningDisableMFA.value,
                type: 'warning'
            }
              render(<ModalFluent
                hideIcon={true}
                props={infor}
                actionOnClick={()=>{}}
                objectRequest={()=>{
                setRenderCase(
                    <>
                        {props.renderAdmin?
                        <div className="just w10">
                            <ChallengesMfa 
                            optMfa={opt} 
                            isDisable={false} 
                            idLng={dataUserIdLng?dataUserIdLng:user.idLanguage}
                            state={{user:user.username, roll:user.idDomain}}
                            />
                        </div>
                        :<ChallengesMfa 
                        optMfa={opt} 
                        isDisable={false} 
                        idLng={dataUserIdLng?dataUserIdLng:user.idLanguage}
                        state={{user:user.username, roll:user.idDomain}}
                        />
                        }
                    </>
                )}}
                />,
                document.getElementById("modal"))
        }else{
            setRenderCase(
                <>
                {props.renderAdmin?
                <div className="just w10">
                <ChallengesMfa 
                    optMfa={opt} 
                    isDisable={false} 
                    idLng={dataUserIdLng?dataUserIdLng:user.idLanguage}
                    state={{user:user.username, roll:user.idDomain}}
                    />
                </div>
                :
                <ChallengesMfa 
                    optMfa={opt} 
                    isDisable={false} 
                    idLng={dataUserIdLng?dataUserIdLng:user.idLanguage}
                    state={{user:user.username, roll:user.idDomain}}
                    />
                }
                    
                </>
            )
        }
    }
}

if (Object.entries(controlsPage).length > 0 && renderCase != null) {
    return (
      <>
      <div className="modal"></div>
  <div className={isAdmin?'w9':'w10'}>
            <HeaderForm
                iconurl={<IconDobleFactor fill="var(--color-primary)" className="claso"/>}
                title={controlsPage?.lblManageMFAOffice365.value}
                subtitle={controlsPage?.lblResetMFAOffice365.value}
                className={"w83"}
            />
          </div>
         {renderCase && (
          renderCase
         )}
      </>
    )
}else{
    return <ContainerBaseLoading />;
       }
}

export default Mfa