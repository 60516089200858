//****Librarys ****//
import React, { Fragment, useEffect, useState } from 'react';

//*****Componnents*****//

//*****Layouts*****//

//**Methods**//


/**
 *@author Yeison Quiroga(15-04-2021)
 *@version 1.0
 *@lastCommit 
 */

const NotAuthorization = () =>{          
           
    /************************
     * @arguments 
     ************************/
        
    /************************
     * @methods 
     ************************/
    
    /************************
     * @hooks 
     ************************/
        
    /************************
     * @resturns 
     ************************/
        return(
            <div><h1>Not Authorization</h1></div>
        );
}

export default NotAuthorization;