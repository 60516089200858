export const COLUMN_TITLES = [{
    idNumber: {
        name: "Nº",
        maxWidth: 30,
        idHtml:'coluNumber'
    },
    fullName: {
        name:"Nombre Completo",
        idHtml:'coluFullName',
        maxWidth: 200,
        isMultiline: true
    },
    // TODO: pendiente por agregar en la respuesta del servicio
    // idNumber: {
    //     name:"Número de Identificación",
    //     maxWidth: 120
    // },
    institutionalEmail: {
        name: "Correo Institucional",
        maxWidth: 180,
        idHtml:'coluInstitutionalMail'
    },
    personalEmail: {
        name: "Correo Alterno",
        maxWidth: 180,
        idHtml:'coluAlternateMail'
    },
    phoneNumber: {
        name: "Teléfono",
        maxWidth: 80,
        idHtml:'coluPhone'
    },
    sessions: {
        name:"Sesiones Iniciadas",
        maxWidth: 60,
        idHtml:'coluStartedSessions'
    },
    isEnabledLabel: {
        name:"Estado de la Cuenta",
        maxWidth: 100,
        idHtml:'coluAccountStatus'
    },
    associatedQuestionsLabel: {
        name:"Tiene Preguntas asociadas",
        maxWidth: 100,
        // isMultiline: true,
        idHtml:'coluAssociatedQuestions'
    },
    // TODO: pendiente por agregar en la respuesta del servicio
    // supports: {
    //     name:"Información Soportes",
    //     isMultiline: true
    // }
    isEnabledMfa: {
        name:"Estado MFA",
        maxWidth: 80,
        idHtml:'lblMFAStatus'
    },
    actions: {
        name: "Cerrar Sesión",
        maxWidth: 80,
        idHtml:'coluSignOff'
    }
}];
// lblProcess Confirmation ---confirmar proceso titulo modal
export const CONFIRM_MODAL_ACTIONS = {
    'changeStatus' : {
        title : `¿Desea CONFIRMAR proceso de {env} ESTADO?`,
        description: `Tenga en cuenta que al editar el estado del usuario, este {env} tendrá acceso a los aplicativos.`,
        idSupportType: 3,
        idHtmlForTitle:'lblYouWantToConfirmDisableStatus',
        idHtmlForDesc:'lblWhenEditingUserWillNotHaveAccessApplication'
    },
    'changePassword': {
        title : `¿Desea CONFIRMAR proceso de CAMBIAR CONTRASEÑA?`,
        description: `Tenga en cuenta que se enviará un enlace al usuario para que él mismo realice el proceso de cambio de contraseña.`,
        idSupportType: 4,
        idHtmlForTitle:'lblYouWantToConfirmPasswordChange',
        idHtmlForDesc:'lblALinkToChangePasswordWillBeSent'
    },
    'deleteQuestions': {
        title : `¿Desea CONFIRMAR proceso de ELIMINAR PREGUNTAS?`,
        description: `Tenga en cuenta que las preguntas que el usuario tiene asociadas a la cuenta quedarán eliminadas y que al ingresar nuevamente a BSR, el sistema solicitará de nuevo la asociación de estas.`,
        idSupportType: 1,
        idHtmlForTitle:'lblYouWantToConfirmDeleteQuestions',
        idHtmlForDesc:'lblQuestionsThatUserHasAssociatedWillDeleted'
    },
    'closeSessions': {
        title : `¿Desea CONFIRMAR proceso de CERRAR SESIONES?`,
        description: `Tenga en cuenta que se cerrará la sesión que el usuario tenga abierta.`,
        idSupportType: 2,
        idHtmlForTitle:'lblYouWantToConfirmLogout',
        idHtmlForDesc:'coluFullName'
    },
    'restablecerDesafios': {
        title : `¿Desea CONFIRMAR proceso de RESTABLECER DESAFÍOS?`,
        description: `Al restablecer los desafíos, el usuario disponible dispondra nuevamente del número máximo de intentos permitidos, para poder resolverlos`,
        idSupportType: 5,
        idHtmlForTitle:'lblYouWantConfirmRestoreChallenges',
        idHtmlForDesc:'lblRestartingChllgesWillHaveMaximumNumAttempts'
    },
    'enableMfa': {
        title : `¿Desea CONFIRMAR proceso de RESTABLECER DESAFÍOS?`,
        description: `Al restablecer los desafíos, el usuario disponible dispondra nuevamente del número máximo de intentos permitidos, para poder resolverlos`,
        idSupportType: 6,
        idHtmlForTitle:'lblConfirmTheMFAEnableProcess',
        idHtmlForDesc:'lblTakeIntoAccountLinkWillBeSentToUser'
    },
    'disableMfaForDays': {
        title : `¿Desea CONFIRMAR proceso de DESACTIVAR MFA por 5 días?`,
        description: `Al restablecer los desafíos, el usuario disponible dispondra nuevamente del número máximo de intentos permitidos, para poder resolverlos`,
        idSupportType: 7,
        idHtmlForTitle:'lblConfirmProcessDeactivateMFAforFiveDays',
        idHtmlForDesc:'lblTakeIntoAccountLinkWillBeSentToUser'
    },
    'disableMfa': {
        title : `¿Desea CONFIRMAR proceso de DESACTIVAR MFA definitivamente?`,
        description: `Al restablecer los desafíos, el usuario disponible dispondra nuevamente del número máximo de intentos permitidos, para poder resolverlos`,
        idSupportType: 8,
        idHtmlForTitle:'lblConfirmProcessOfDisablingMFAFinal',
        idHtmlForDesc:'lblTakeIntoAccountLinkWillBeSentToUser'
    }
}

export const SUPPORT_HISTORY_COLUMN_TITLES = [{
    creatingDate: {
        name: "Fecha",
        maxWidth: 150,
        isMultiline: true,
        idHtml:'coluDate'
    },
    userEmail: {
        name: "Usuario de soporte",
        maxWidth: 150,
        isMultiline: true,
        idHtml:'coluSupportUser'
    },
    remark: {
        name: "Descripción del soporte",
        isMultiline: true,
        idHtml:'coluDescription'
    }
}];

