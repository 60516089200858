import React, { useState, useEffect } from "react";
import { FontIcon, initializeIcons, Stack } from "@fluentui/react";

import {
  DocumentCard
} from "@fluentui/react/lib/DocumentCard";
import { mergeStyles } from "@fluentui/react/lib/Styling";


const ComponentCard = ({
  label, 
  colorSquare, 
  icon, 
  text,
  value}) => {
  // initializeIcons();
  //EditNote
  return (
    <Stack className="pdg-resp w5-resp">
      <DocumentCard >
        <div className="component-card">
          <div className="label-card class-target">
            <label>
              {label}
            </label>
              <FontIcon aria-label={icon} iconName={icon} className={colorSquare} />
          </div>
          <div className='card-num'>
            <div
              style={{
                backgroundColor: colorSquare,
              }}
              className={`num-card ${colorSquare}`}
            >
              <label style={{padding: "5px"}}>{text}</label>
              <br />
              <label style={{ fontSize: "25px", textAlign: "rigth", float: "right",padding: "0px 10px"}}>{value}</label>
            </div>
          </div>
        </div>
      </DocumentCard>
    </Stack>
  );
};
export default ComponentCard;
