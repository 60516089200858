//*****Library*****//
    import React, { useEffect, useContext} from 'react'
    import {BrowserRouter as Router, Switch, Redirect, Route, Routes} from 'react-router-dom'
    //import {initializeIcons} from 'office-ui-fabric-react'
    import { initializeIcons } from '@fluentui/react/lib/Icons';
    import CookiesJS from 'js-cookie';

//*****Components*****//
    import RouterPrivate from '../components/routers/private/privateRouter.component'
    import RouterAdminPrivate from '../components/routers/privateAdmin/privateAdminRouter.component'
    import RouterPublic from '../components/routers/public/publicRouter.component'
    import AvisoError from '../components/containers/containerError/avisoError'
//*****Layouts*****//
    import Main from '../layouts/main'
    import NotAuthorization from '../layouts/notAuthorization'
    //**Admin**//
        import Admin from '../layouts/admin/admin'
    //**User**//
        import User from '../layouts/users/user'
    

//*****Context*****//
    import CredentialsState from '../context/credentials/credentrialsState'
    import CredentialsContext from '../context/credentials/credentialsContext'
import FetchOAuth from '../utils/validations/oauth.controller';
import { PATH_APPLICATION, PATH_SSO, SSO_URI } from '../assets/settings/settinsrouters';
import LenguajeState from '../context/Lenguaje/lenguajeState';


/*****************
 *****************
 * @returns copomnent Routers
 *****************
 *****************/

const RouterViews = () => {      
     /************************
     * @arguments 
     ************************/
      const token = CookiesJS.get('token')
      const {showAdmin} =JSON.parse(CookiesJS.get('dataUser'));
        let dataClient = CookiesJS.get('dataClient');
        const client = CookiesJS.get('nameClient')
        let theme = JSON.parse(dataClient);
        const style = document.documentElement.style;
        if(!showAdmin){
        style.setProperty('--background-image', `url(${theme.imageBackgroundPath})`);
        style.setProperty('--color-primary', `#${theme.primaryColor}`);
        style.setProperty('--color-light', `#${theme.ligthColor}`);
        style.setProperty('--color-semilight', `#${theme.semiligthColor}`);
        style.setProperty('--color-semidark', `#${theme.semidarkColor}`);
        style.setProperty('--color-dark', `#${theme.darkColor}`);
        style.setProperty('--color-hover', `#${theme.darkColor}`);
        style.setProperty('--font-primary', `${theme.primaryFont}`);
}
    /************************
     * @methods 
     ************************/
    
    /************************
     * @hooks 
     ************************/
    //  useEffect(async () => {
    //     let isvalid = await FetchOAuth({
    //         accessToken: token,
    //         type: 'introspect',
    //         tenant: client
    //     })
    //     if(isvalid.data.data.data.active == 'false') {
    //         CookiesJS.remove('token')
    //         window.location.href = `${SSO_URI}${client}/home`
    //     }
    // },[window.location.href])

    useEffect(() => {
      initializeIcons();
    }, [])
    
    
    /************************
     * @resturns 
     ************************/
        return (
            <CredentialsState>
                <LenguajeState>
                <Router>
                    <Routes>
                        {/* <Route path="/:clientName/" element={<Main />}/> */}
                        <Route path={`${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/:clientName/user/*`} element={<User />} />
                        <Route path={`${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/:clientName/administration/*`} element={<Admin />} />
                        <Route path="*" element={<AvisoError />} />
                        <Route path={`${PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION}/:clientName/NotAuthorization`} element={<NotAuthorization />} />
                    </Routes>
                </Router>
                </LenguajeState>
            </CredentialsState>
        );
}


export default RouterViews;