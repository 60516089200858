import { CommandBar } from '@fluentui/react/lib/CommandBar';
import CookiesJS from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import React, { useState, useHisto, useContext } from 'react';
//import {usenavigate} from 'react-router-dom';
//import {CommandBar} from 'office-ui-fabric-react';
import { PATH_APPLICATION, PATH_SSO, SSO_URI } from '../../../assets/settings/settinsrouters.js'
import { useParams } from "react-router-dom";
import { appKey } from '../../../utils/Requests/models.Controller.js';
import { useBoolean } from '@fluentui/react-hooks';

//**Components**//
import SubMenu from '../../navigation/subMenu/subMenu.component';
import ContainerPanelFloat from '../panelFloat/containerPanelFloat.component';
import NoticeAlert from '../../tools/noticeAlert/noticeAlert.component.jsx';
import { RequestFetch } from '../../../utils/Requests/requests.Controller.js';
import { useEffect } from 'react';
import ContainerBaseLoading from '../baseLoading/containerBaseLoading.component.jsx';
import { ClassContainerBody } from '../../../utils/logicRendering/classContainerBody.js';
import { Modal, IconButton, Panel } from "@fluentui/react";
import LenguajeContext from '../../../context/Lenguaje/lenguajeContext.js';

//***Methods***//


/**
 *@author Yeison Quiroga(14-04-2021)
 *@version 1.0
 *@lastCommit 
 */

const ContainerBaseAdmin = (props) => {
    const { client, style } = props;
    /************************
     * @arguments 
     ************************/
    const [dataUser, setDataUser] = useState(CookiesJS.get('dataUser'));
    const [dataToken, setDataToken] = useState(CookiesJS.get('dataToken'));
    const requestTyC = new RequestFetch('TermCondition')
    const nameClient = CookiesJS.get('nameClient');
    const [itemTyC, setItemTyC] = useState([])
    const [commandItems, setComandItems] = useState();
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
    const [controlsPage, setControlsPage] = useState({});
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const token = CookiesJS.get('token');
    //const [showNotice, setShowNotice] = useState(true);
    const Requests = new RequestFetch("OAuth/revoke");
    const { clientName } = useParams();
    const DU = JSON.parse(dataUser);
    const DT = JSON.parse(dataToken);
    const fullName = DT.firstName + ' ' + DT.lastName;
    const navigate = useNavigate();
    const [className, setClassName] = useState(null);
    const [typeClass,setTypeClass] = useState("")
    const PATH_APP = PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION
    useEffect(() => {
        const URLcurrent = window.location.href
        const URLused = URLcurrent.split("/")
        const { className } = ClassContainerBody(URLused)
        setClassName(className)
    }, [window.location.href])
    useEffect(()=>{
        if(DT.colorLevel == "CriticalIndicator"){
            setTypeClass("criticalIndicatorStyle noticealert--container--select--criticalIndicator")
        }
        else if(DT.colorLevel == "AverageIndicator"){
            setTypeClass("warning noticealert--container--select")
        }
        else if(DT.colorLevel == "MildIndicator"){
            setTypeClass("mildIndicatorStyle noticealert--container--select--mildIndicator")
        }
    },[])
    useEffect(async() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            stateLanguage.lenguaje.map((pass) => {
                if (pass.path == 'notifications-expiry-passwords'|| pass.path =='controls'|| pass.path == 'administration'|| pass.path == 'manage-mfa') {
                    pass.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  })    
                  setControlsPage(controlsPage)
                } 
            })
        }
        setComandItems([
            {
                key: 'Profile',
                text: fullName,
                subMenuProps: {
                    styles: buttonStyles,
                    items: [
                        {
                            key: 'fdfddf',
                            text: <NoticeAlert
                                typeClass={typeClass}
                                //typeClass="warning noticealert--container--select"
                                date={userPasswordExpiryTime[0]}
                                days={DT.expirationDays}
                                msg={controlsPage?.lblTDateUser?.value}
                                //onClose={() => setShowNotice(false)}
                                menu={true}
                            />,
                            disabled: true,
                            className: 'buttonOptStyle'
    
                        },
                        {
                            canCheck: true,
                            key: 'Asociar preguntas',
                            text: controlsPage?.imgAssociateQuestion?.value,
                            onClick: () => { navigate(`${PATH_APP}/${client}/administration/AssosiateQuestions`) },
                        },
                        {
                            key: 'Cambiar contraseña',
                            text: controlsPage?.lblChangePswd?.value,
                            onClick: () => { navigate(`${PATH_APP}/${client}/administration/PasswordChange`) }
                        },
                        {
                            key: 'Consultar mis datos',
                            text: controlsPage?.lblCheckMyData?.value,
                            onClick: () => { navigate(`${PATH_APP}/${client}/administration/DataUserAdmin`) }
                        },
                        {
                            key: 'Gestionar MFA',
                            text: controlsPage?.lblManageMFAOffice365?.value,
                            onClick: () => { navigate(`${PATH_APP}/${client}/administration/manage-mfa`) }
                        },
                        {
                            key: 'logout',
                            text: controlsPage?.smtGoOut?.value,
                            onClick: () => { logout() },
                        },
    
                    ]
                }
            }
        ])
    }, [stateLanguage])    
    let year = new Date();
    let date = year.getFullYear();


    const getTyC = async () => {
        // const res = await requestTyC.getItem({tenant:nameClient,param:{idClient:userData.client,idLanguage:userData.idLanguage}})
        const res = await requestTyC.getItem({ tenant: nameClient })
        setItemTyC(res.data.data)
    }
    const iconButtonStyles = {
        root: {
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },

    };
    let userPasswordExpiryTime =
        DT.userPasswordExpiryTime.split("T");

    const buttonStyles = {

        root: {
            //background:'aqua'
            button: { height: 'auto' },
            i: { width: '0px' }
        },
        rootHovered: {

        },

    }



    const redirect = () => {
        navigate(`${PATH_APP}/${client}/administration`);
    }

    /************************
     * @methods 
     ************************/
    const logout = () => {

        const params = new URLSearchParams();
        params.append('client', `${appKey}`);
        params.append('tenant', `${clientName}`);
        params.append('domain', `${DU.user.idDomain}`);
        params.append('token', `${token}`);


        const revoke = Requests.createItem({ body: params, ct: "encode", tr: "revoke" })
            .then(resp => {
                CookiesJS.remove('token')
                const DBDeleteRequest = window.indexedDB.deleteDatabase("textListApp");

                DBDeleteRequest.onerror = (event) => {
                console.error("Error deleting database.");
                };

                DBDeleteRequest.onsuccess = (event) => {
                console.log("Database deleted successfully");
                };
                window.location.href = `${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`
            })

    }

    /************************
     * @hooks 
     ************************/


    /************************
     * @resturns 
     ************************/

    return (
        <div className="admin--container-base">
            <div className="admin--container-header">
                <ContainerPanelFloat client={client} />
                <div className="header--title dsp-none">
                <h2><a onClick={() => redirect()}>{controlsPage?.lblBSRAdmon?.value}</a></h2>
                </div>

                <div className="header--profile w8-resp">
                    <div className="header--butto-profile">
                        <CommandBar items={commandItems} className="session--client" />

                    </div>
                    {/* <div className="header--icon">
                        <a>{controlsPage?.lblConnectionIP?.value}:{DU.publicIP}</a>
                    </div> */}
                </div>
            </div>
            <div className={className}>
                {props.content}
            </div>
            <div className={`admin--container-footer ${style}`}>
                <div>
                    <h6>BSR&#169; {date}  - {controlsPage?.lblAllRightsReserved?.value}</h6>
                </div>
                <div>
                    <h6 href="#" onClick={() => { getTyC(); showModal() }}
                        className="hipertext hipertext--second">  {controlsPage?.urlTermsCondition?.value}
                    </h6>
                    <Panel
                        isOpen={isModalOpen}
                        onDismiss={hideModal}
                        isBlocking={false}
                        headerText={controlsPage?.urlTermsCondition?.value}
                    >
                        <div className="pl1">
                            {itemTyC.map(opt =>
                                <>
                                    <ul>
                                        <li className="listStyle" key={opt.name}>
                                            <a href={opt.url} className="hipertext  clr--light" target='_blank'>{opt.name}</a>
                                        </li>
                                    </ul>
                                </>
                            )
                            }
                        </div>
                    </Panel>
                </div>
            </div>
        </div>
    );
}

export default ContainerBaseAdmin;