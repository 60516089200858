import { FontIcon } from '@fluentui/react'
import React, { useState } from 'react'

const NoticeAlert = (props)=>{
    // const [msg, setMsg] = useState([])
    const message = props.msg?.split("/")
    return(
        props.msg? (
        <div className={`container--flex ${props.typeClass}`}>
            {props.menu?
            <div className="">
            <p>{message[0]}<span>{message[1]}</span></p>
            <p>{message[2]}<span>{message[3]}</span>{message[4]}</p>
            {/* <p className='w97'>{message[0]}<span></span>{message[2]}<span>{message[3]}</span>{message[4]}</p> */}
            </div>
            :
            <p className='w97'>{message[0]}<span>{message[1]}</span>{message[2]}<span>{message[3]}</span>{message[4]}</p>
            
            }
            {props.cancel?<a onClick={()=>props.onClose()} className='stron-weight'><FontIcon aria-label="Cancel" iconName="Cancel"/></a>:''}
        </div>)
        :<></>
        
    )
}

export default NoticeAlert