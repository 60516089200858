export default (state, action)=>{
    const {payload, type} = action

    const TYPES_OPT = {
        'GET_CREDENTIALS_TOKEN': {
            ...state, 
            token: payload
        }
    }

    return TYPES_OPT[type]
}