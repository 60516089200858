//****Librarys ****//
import { DefaultButton, FontIcon, Label, mergeStyles, TeachingBubble } from '@fluentui/react';
import React, { useState, useEffect ,useContext} from 'react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import LenguajeContext from '../../../context/Lenguaje/lenguajeContext';
//*****Componnents*****//

//**Methods**//

/**
 *@author Sebastian Ocampo(14-05-2022)
 *@version 1.0
 *@lastCommit
 */

const InputFile = (props) => {
  
  /************************
   * @arguments
   ************************/
  const [stateType, setStateType] = useState(true);
  const buttonId = useId('targetButton');
  const [controlsPage, setControlsPage] = useState({});
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(true);
  const [count, setCount] = useState(0)
  const [score, setScore] = useState(1)
  const [unfit, setUnfit]= useState(false)
const [bot, setBot] = useState()
const [entenD, setEntenD] = useState('')
const [messageError, setMessageError] = useState(false)
const [txtValue, setTxtValue] = useState('')
const [waitInput, setWaitInput] = useState(false);

  /************************
   * @methods
   ************************/

  useEffect(() => {
  
    if (props.listPolicies) {
      if (score ==props.listPolicies.length) {
        setBot(false)
      }else{
        setBot(true)
      }
    }
    
  if(score < 1 || score > (props.listPolicies ? props.listPolicies[score]:"")){
    setScore(1)
    setBot(true)
  }
  }, [score])
  
   const iconClass = mergeStyles({
    fontSize: 8,
    height: 8,
    width: 8,
    margin: '0',
    position: 'absolute',
    color: 'var(--color-primary)' 
  });

  useEffect(async() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        stateLanguage.lenguaje.map((pass) => {
            if ( pass.path == 'controls' || pass.path == 'associate-security-questions')  {
              pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })  
            }              
        })          
      setControlsPage(controlsPage)
    }
  }, [stateLanguage])
  /************************
   * @hooks
   ************************/
  //  const PrimaryButtonProps= React.useMemo( 
  //   () => ({
  //     children: 'Anterior',
  //     onClick: ()=>{setScore(score-1);},
  //   }),
  // );
  useEffect(() => {
    setEntenD(controlsPage.lblUnderstood?.value)
  }, [controlsPage])
  
   const dismissButtonProps = React.useMemo(
    () => ({
      children: entenD,
      onClick:()=>{toggleTeachingBubbleVisible();props.setDisable(false)},
    }),
    [toggleTeachingBubbleVisible,entenD],
  );
  const SecondaryButtonProps = React.useMemo(
    () => ({
      children: 'Siguiente',
      onClick: ()=>{setScore(score==5);},
    }),
    
  );

  const limitTxt = (e)=>{
    let newValue = props.withOutSpace?e.target.value.trim():e.target.value
    if(props.maxLength){
      if (newValue.length > props.maxLength) {
        // props.onMessage(true)
        setMessageError(true)
      }else if(newValue.length == props.maxLength){
        setTxtValue(newValue.slice(0, props.maxLength));
        // props.onMessage(true)
        setMessageError(true)
      }else{
        setTxtValue(newValue.slice(0, props.maxLength));
        // props.onMessage(false)
        setMessageError(false)
      }
    }else{
      setTxtValue(newValue)
    }
  }

  const validateTxt = ()=>{
    if(props.id !== undefined){
      let input = document.getElementById("input" + props.id);
      if(input !== null){
        if (input.value.length < props.min || input.value.length > props.max) {
          input.setCustomValidity(controlsPage?.txtAnswer?.infoMessage);
        } else {
          input.setCustomValidity("");
        }
      }
    }
  }
 
  const firstInfo = () =>{
    if (count === 0 && props.politics) {
       // toggleTeachingBubbleVisible()
      setCount(1)
    }
  }
  
  useEffect(() => {
    if(teachingBubbleVisible == false){
      setScore(0)
    }
    
  }, [teachingBubbleVisible])

useEffect(() => {
  setTimeout(() => {
    setWaitInput(true)
  }, 2000);
}, [])

  /************************
   * @resturns
   ************************/
  if (props.type == "password") {
    return (
      <>
        <div className={`inputFile--container ${props.style}`}>
          <div className="est">
          {props.label ? <div className="content">
          {props.label ? <Label>{props.label ? <div style={{position: "relative"}}> <span>{props.label}</span>{props.isRequired || props.required ? <FontIcon aria-label="requerido" iconName="SkypeCircleCheck" className={iconClass} /> : ''} </div> : ""}</Label> : undefined}
            </div> : undefined}
          {props.politics ? <div className="content"><a id={buttonId} onClick={toggleTeachingBubbleVisible} className='politics'>{props.politics}</a> </div>: undefined}
          </div>
          <div className="inputFielt--container-input">
            <input
              id={"input" + props.id}
              onClick={firstInfo}
              name={props.name}
              type={stateType ? "password" : "text"}
              placeholder={props.placeholder}
              disabled={props.disable ? true : false}
              autoComplete="off"
              onChange={(e)=>{props.onChange(e);limitTxt(e)}}
              defaultValue={props.defaultValue}
              required
              maxLength={props.max}
              minLength={props.min}
              value={txtValue}
              oninput={validateTxt()}
            />
            {props.listPolicies && props.listPolicies.length>0 ?
            waitInput && (
            teachingBubbleVisible && (
                  <TeachingBubble
                    target={`#${buttonId}`}
                    // primaryButtonProps={
                    //   props.listPolicies && props.listPolicies.length>0 && score != 1
                    //   ?PrimaryButtonProps:''}
                    secondaryButtonProps={
                      props.listPolicies && props.listPolicies.length>0 
                      ?(bot?dismissButtonProps:dismissButtonProps):dismissButtonProps}
                    // footerContent={props.listPolicies && props.listPolicies.length>0 ?`${score} de ${props.listPolicies ? props.listPolicies.length:""}`:''}
                    //onDismiss={toggleTeachingBubbleVisible}
                    headline={controlsPage.lblPasswordPolicies?.value}
                    className="teching-bubble"
                  >
                    {props.listPolicies && props.listPolicies.length>0 ?
                     <ul className='polits'>
                      {/* <li>{props.listPolicies[(score==props.listPolicies.length ? 0:score )].description}</li>
                      {props.listPolicies[(score==props.listPolicies.length ? 0:score )].values.map(item=>{
                      if(item.idSecurityPolicyType != 6 ){lblPasswordPolicies
                        const ultiPos = props.listPolicies[(score==props.listPolicies.length ? 0:score )].values.length-1
                        const ultiPolitc = props.listPolicies[(score==props.listPolicies.length ? 0:score )].values[ultiPos]
                        if (ultiPolitc == item) {
                          return(<a>{item.value} </a>)
                        }
                        return(<a>{item.value} , </a>)
                      }

                    })} */}
                    {props.listPolicies.map(items =>{
                        let valuepolicies=""
                        const ultiPos = items.values.length-1
                          const ultiPolitc = items.values[ultiPos]
                          items.values.map(value=>{
                            if(value.idSecurityPolicyType != 6 && value.idSecurityPolicyType != 5 ){
                              if (ultiPolitc == value) {
                                valuepolicies += value.value 
                              }else{
                                valuepolicies += (value.value != ''?value.value + ", ":'')
                              }
                            }
                          })
                        return(
                          <div><li>{items.description}</li>
                          <a>{valuepolicies}</a></div>
                        )
                    })}
                     </ul>
                     :<p></p>}
                  </TeachingBubble>
              ))
              :''}
              {messageError && props.msgError?
                    <div className='message-error'>
                        <p className='paragraf-error'>{props.msgError}</p>
                    </div>
                    :''}
            <span
              onClick={() => {
                stateType ? setStateType(false) : setStateType(true);
              }}
            >
            <FontIcon aria-label="ChevronLeftSmall"
              iconName={stateType ? 'RedEye': 'VisuallyImpaired'}
            />
            </span>
          </div>
        </div>
      </>
    );
  } else if (props.type != "password") {
    if (props.required == true) {
      return (
        <>
          <div className={`inputFile--container ${props.style}`}>
            {props.label ? <Label>{props.label ? <div style={{position: "relative"}}> <span>{props.label}</span>{props.isRequired || props.required ? <FontIcon aria-label="requerido" iconName="SkypeCircleCheck" className={iconClass} /> : ''} </div> : ""}</Label> : undefined}
            <input
              name={props.name}
              type={props.type}
              placeholder={props.placeholder}
              disabled={props.disable ? true : false}
              autoComplete="off"
              onChange={(e)=>{props.onChange(e);limitTxt(e)}}
              defaultValue={props.defaultValue}
              maxLength={props.maxLength}
              minLength={props.minLength}
              required
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={`inputFile--container ${props.style}`}>
            { <Label>{props.label}</Label> }
            <input
              name={props.name}
              type={props.type}
              placeholder={props.placeholder}
              disabled={props.disable ? true : false}
              autoComplete="off"
              onChange={props.onChange}
              defaultValue={props.defaultValue}
              maxLength={props.maxLength}
              minLength={props.minLength}
            />
          </div>
        </>
      );
    }
  }
};

export default InputFile;