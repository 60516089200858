import React, { Fragment, useContext, useEffect, useState } from "react";
import CookiesJS from "js-cookie";
//import {DefaultButton, Panel, PanelType, } from 'office-ui-fabric-react'
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { useBoolean } from "@fluentui/react-hooks";
import { useParams } from "react-router-dom";
//import fileDownload from 'js-file-download'

//*****Componnents*****//
import SubMenu from "../../components/navigation/subMenu/subMenu.component";
import ContainerLargeForm from "../../components/containers/largeForm/containerLargeForm.component";
import ContainerBaseLoading from "../../components/containers/baseLoading/containerBaseLoading.component";
import ListHeader from "../../components/lists/listHeader/listHeader.component";
import ListBody from "../../components/lists/listBody/listBody.component";
import DetailsListBext from '../../components/lists/DetailsList/detailsList.component';

//*****Methods*****//
import Lists from "../../utils/logicRendering/lists.controller";
import { RequestFetch } from "../../utils/Requests/requests.Controller";
import CountPages from "../../utils/logicRendering/countPages.controller";
import LenguajeContext from "../../context/Lenguaje/lenguajeContext";

const Contentforlanguage = () => {
  /************************
   * @arguments
   ************************/

  //***Propertise***//
  const [edit, setEdit] = useState(false);
  const [deleteD, setDeleteD] = useState(false);
  const [idPageSelect, setIdPageSelect] = useState([]);
  const [currentPages, setCurrentPage] = useState(1);
  const [showElements, setShowElements] = useState(5);
  const [itemsNotification, setitemsPages] = useState(null);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const { clientName } = useParams();
  const [isDisable, setDisable] = useState([
    { disable: false, key: "valueDefault" },
    { disable: true, key: "name" },
    { disable: true, key: "selectWebControl" },
    { disable: true, key: "errorPatternMessage" },
    { disable: true, key: "errorRequiredMessage" },
    { disable: true, key: "infoMessage" },
    { disable: true, key: "placeholder" },
    { disable: true, key: "succesMessage" },
    { disable: true, key: "title" },
    { disable: true, key: "label" },
    { disable: true, key: "fileBulkLoad" },
    { disable: true, key: "fileDownload" },
    { disable: true, key: "value" },
    { disable: true, key: "alt" },
  ]);

  const [selectPanel, seSelectPanel] = useState(true);
  const languages = JSON.parse(CookiesJS.get("languages"));
  const [itemsComponentInformation, setItemsComponentInformation] = useState(
    []
  );
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([]);
  const [defaultValueInput, seDefaultValueInput] = useState([]);
  const [webcontrolspages, setWebcontrolspages] = useState([]);
  //#region Variables de formulario carga masiva
  const [selectLanguageBulkLoad, setSelectLanguageBulkLoad] = useState();
  const [fileBulkLoad, setFileBulkLoad] = useState();
  const [selectLanguageDownload, setSelectLanguageDownload] = useState();
  const [fileDownload, setFileDownload] = useState();
  //#endregion
  //#region Variables de formulario configurar modulo
  const [selectLanguage, setSelectLanguage] = useState();
  const [selectWebControl, setSelectWebControl] = useState();
  const [errorPatternMessage, setErrorPatternMessagel] = useState();
  const [errorRequiredMessage, setErrorRequiredMessage] = useState();
  const [infoMessage, setInfoMessage] = useState();
  const [label, setLabel] = useState();
  const [placeholderText, setPlaceholderText] = useState();
  const [succesMessage, setSuccesMessage] = useState();
  const [titleText, setTitleText] = useState();
  const [controlsPage, setControlsPage] = useState({});
  const [textOnPages, setTextOnPages] = useState({})
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const [alt, setAlt] = useState();
  const [valueText, setValueText] = useState();
  const [idLanguageWebControl, setIdLanguageWebControl] = useState();
  const [selectedWebcontrolspages, setSelectedWebcontrolspages] = useState();
  //#endregion
  
  const [arrayLanguage, setArrayLanguage] = useState([])

  const [filter, setFilter] = useState({
    pageSize: 5,
    pageNumber: "0",
    idDomain: "",
    idLanguage: JSON.parse(CookiesJS.get("dataUser")).user.idLanguage
  });
  const [pagination, setPagination] = useState(null);
  const [isLoadingData, {
      setTrue: handleLoadingData,
      setFalse: handleLoadedData
  }] = useBoolean(false);

  const Requests = new RequestFetch("Pages");
  const Requestspagesage = new RequestFetch("Pages/page");
  const RequestsLanguageWebControlsmetadata = new RequestFetch(
    "LanguageWebControls/metadata"
  );
  const RequestsLanguageWebControlsPost = new RequestFetch(
    "LanguageWebControls/Post"
  );
  const RequestsLanguageWebControls = new RequestFetch("LanguageWebControls");
  const RequestsLanguages = new RequestFetch('Languages');
  useEffect( async () => {
    handleLoadingData()

    //Consultas para opciones select 'Lenguaje' y 'Dominio' en el panel
    const [lngResponse] = await Promise.all([
        RequestsLanguages.getItem({ tenant: clientName }),
    ]);
    //debugger
    const lngs = lngResponse.data.data.map(item => ({
        text: item.name,
        id: item.id,
        key: item.id
    }))
    setArrayLanguage(lngs);

    handleLoadedData()

},[])
  //#region Acciones de los componentes
  const actionOnChange = (data) => {
    //#region Se llenan los datos de los controles del formulario Carga Masiva idioma administracion
    if (data.id === "selectLanguageDownload") {
      setDisable([
        { disable: false, key: "valueDefault" },
        { disable: true, key: "fileBulkLoad" },
      ]);
    }
    if (data.id === "selectLanguageBulkLoad") {
      setDisable([
        { disable: false, key: "valueDefault" },
        { disable: true, key: "fileDownload" },
      ]);
    }
    if (data.id === "selectLanguageBulkLoad") {
      setSelectLanguageBulkLoad(data);
    }
    if (data.id === "fileBulkLoad") {
      setFileBulkLoad(data);
    }
    if (data.id === "selectLanguageDownload") {
      setSelectLanguageDownload(data);
    }
    //#endregion
    //#region Se llenan los datos de los controles del formulario Configurar Modulo
    if (data.id === "selectLanguage") {
      setSelectLanguage(data.selectedItem);
      setItemsComponentInformation([]);
      if (data.text !== undefined) {
        setDisable([
          { disable: false, key: "valueDefault" },
          { disable: true, key: "name" },
          { disable: false, key: "selectWebControl" },
          { disable: true, key: "errorPatternMessage" },
          { disable: true, key: "errorRequiredMessage" },
          { disable: true, key: "infoMessage" },
          { disable: true, key: "placeholder" },
          { disable: true, key: "succesMessage" },
          { disable: true, key: "title" },
          { disable: true, key: "label" },
          { disable: true, key: "value" },
          { disable: true, key: "alt" },
        ]);
        Requestspagesage.getItem(
          JSON.parse(
            `{"param" : {"id" : "${idPageSelect[0].id}","idLanguage" : "${data.selectedItem.key}"},"tenant" : "${clientName}"}`
          )
        ).then((data) => {
          setWebcontrolspages(data.data.data.webcontrolspages);
          setItemsComponentInformation(
            data.data.data.webcontrolspages.map(({ id, name }) => ({
              key: id,
              text: name,
            }))
          );
        });
        seDefaultValueInput([
          {
            key: "errorPatternMessage",
            text: "",
          },
          {
            key: "errorRequiredMessage",
            text: "",
          },
          {
            key: "infoMessage",
            text: "",
          },
          {
            key: "label",
            text: "",
          },
          {
            key: "placeholder",
            text: "",
          },
          {
            key: "succesMessage",
            text: "",
          },
          {
            key: "title",
            text: "",
          },
          {
            key: "alt",
            text: "",
          },
          {
            key: "value",
            text: "",
          },
        ]);
      } else {
        setDisable([
          { disable: false, key: "valueDefault" },
          { disable: true, key: "name" },
          { disable: true, key: "selectWebControl" },
          { disable: true, key: "errorPatternMessage" },
          { disable: true, key: "errorRequiredMessage" },
          { disable: true, key: "infoMessage" },
          { disable: true, key: "placeholder" },
          { disable: true, key: "succesMessage" },
          { disable: true, key: "title" },
          { disable: true, key: "label" },
          { disable: true, key: "value" },
          { disable: true, key: "alt" },
        ]);
      }
    }

    if (data.id === "selectWebControl") {
      setSelectWebControl(data);
      if (data.text !== undefined) {
        setDisable([
          { disable: false, key: "valueDefault" },
          { disable: true, key: "name" },
          { disable: false, key: "selectWebControl" },
          { disable: false, key: "errorPatternMessage" },
          { disable: false, key: "errorRequiredMessage" },
          { disable: false, key: "infoMessage" },
          { disable: false, key: "placeholder" },
          { disable: false, key: "succesMessage" },
          { disable: false, key: "title" },
          { disable: false, key: "label" },
          { disable: false, key: "value" },
          { disable: false, key: "alt" },
        ]);
        let selectedWebcontrolspages = webcontrolspages.filter(
          (element) => element.id === data.selectedItem.key
        );
        setIdLanguageWebControl(
          selectedWebcontrolspages[0].languagewebcontrols[0].id
        );
        seDefaultValueInput([
          {
            key: "errorPatternMessage",
            text: selectedWebcontrolspages[0].languagewebcontrols[0]
              .errorPatternMessage,
          },
          {
            key: "errorRequiredMessage",
            text: selectedWebcontrolspages[0].languagewebcontrols[0]
              .errorRequiredMessage,
          },
          {
            key: "infoMessage",
            text: selectedWebcontrolspages[0].languagewebcontrols[0]
              .infoMessage,
          },
          {
            key: "label",
            text: selectedWebcontrolspages[0].languagewebcontrols[0].label,
          },
          {
            key: "placeholder",
            text: selectedWebcontrolspages[0].languagewebcontrols[0]
              .placeholder,
          },
          {
            key: "succesMessage",
            text: selectedWebcontrolspages[0].languagewebcontrols[0]
              .succesMessage,
          },
          {
            key: "title",
            text: selectedWebcontrolspages[0].languagewebcontrols[0].title,
          },
          {
            key: "alt",
            text: selectedWebcontrolspages[0].languagewebcontrols[0].title,
          },
          {
            key: "value",
            text: selectedWebcontrolspages[0].languagewebcontrols[0].title,
          },
        ]);
        setSelectedWebcontrolspages(selectedWebcontrolspages);
      } else {
        setDisable([
          { disable: false, key: "valueDefault" },
          { disable: true, key: "name" },
          { disable: true, key: "selectWebControl" },
          { disable: true, key: "errorPatternMessage" },
          { disable: true, key: "errorRequiredMessage" },
          { disable: true, key: "infoMessage" },
          { disable: true, key: "placeholder" },
          { disable: true, key: "succesMessage" },
          { disable: true, key: "title" },
          { disable: true, key: "label" },
          { disable: true, key: "value" },
          { disable: true, key: "alt" },
        ]);
      }
    }
    if (data.id === "errorPatternMessage") {
      setErrorPatternMessagel(data);
    }
    if (data.id === "errorRequiredMessage") {
      setErrorRequiredMessage(data);
    }
    if (data.id === "placeholder") {
      setPlaceholderText(data);
    }
    if (data.id === "succesMessage") {
      setSuccesMessage(data);
    }
    if (data.id === "infoMessage") {
      setInfoMessage(data);
    }
    if (data.id === "label") {
      setLabel(data);
    }
    if (data.id === "title") {
      setTitleText(data);
    }
    if (data.id === "alt") {
      setAlt(data);
    }
    if (data.id === "value") {
      setValueText(data);
    }
    //#endregion
  };
  const actionOnClick = (data) => {
    if(data.id === "fileDownload" && data.target !== undefined){      
      RequestsLanguageWebControlsmetadata.getFile(
        JSON.parse(
          `{"param" : {"id" : "${selectLanguageDownload.selectedItem.key}","state":"true"},"filename":"DataPage.xlsx","tenant" : "${clientName}"}`
        )
      )
    }
  };
  useEffect(() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        //console.log(stateLanguage);
        stateLanguage.lenguaje.map((pass) => {
          // console.log(pass)
            if (pass.path == 'controls'|| pass.path =='domain') {
              pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })    
              setControlsPage(controlsPage)
            }              
        })          
    }

}, [textOnPages, stateLanguage])

  const onSubmitEventFormPanel = (data, typeOperation) => {
    let dataForm;
    if (typeOperation === "create") {
      let fileMetaData = new FormData([]);
      if (data.target !== undefined) {
        for (let entry of data.target) {
          if (entry.localName === "input") {
            let file = new File();
            console.log(file);
            //fileMetaData.append("fileMetaData", entry.files[0]);
          }
        }
      }
      console.log(fileMetaData);
      dataForm.push({
        idLanguage: selectLanguageBulkLoad.selectedItem.key,
        fileMetaData: fileMetaData,
      });
    }
    if (typeOperation === "update") {
      dataForm = {
        idWebControlPage: selectedWebcontrolspages[0].idWebControl,
        idLanguage: selectLanguage.key,
        label: label.selectedItem,
        placeholder: placeholderText.selectedItem,
        value: alt.selectedItem,
        title: titleText.selectedItem,
        alt: valueText.selectedItem,
        infoMessage: infoMessage.selectedItem,
        succesMessage: succesMessage.selectedItem,
        errorRequiredMessage: errorRequiredMessage.selectedItem,
        errorPatternMessage: errorPatternMessage.selectedItem,
      };
    }
    return dataForm;
  };

  //#endregion
  //#region Configuracion de componentes
  const SpaceTitel = ["Módulos", "Lenguaje personalizado por usuario", "Fecha"];
  const OrdItems = ["name", "idLanguage", "creatorUser", "creatingDate"];
  const COLUMN_TITLES = [{
    name: {
        name: "Módulos",
        minWidth: 100,
        isResizable: true,
        //idHtml: "coluDomain",
        maxWidth: 300
    },
    language: {
        name: "Lenguaje personalizado por usuario",
        minWidth: 50,
        isResizable: true,
        idHtml: "coluEmail",
        maxWidth: 150
    },
    creatingDate: {
        name: "Fecha de creación",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
        idHtml: "coluCreationDate",
    },
    updatingDate: {
        name: "Última Modificación",
        minWidth: 50,
        isResizable: true,
        maxWidth: 150,
        idHtml: "coluLastModification",
    },
    creatorUser: {
      name: "Creado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "coluCreatedBy",
    },
    updateUser: {
      name: "Modificado por",
      maxWidth: 150,
      isResizable: true,
      idHtml: "lblModifiedBy"
    },
}];
const [columnTitles, setColumnTitles] = useState(COLUMN_TITLES)

  const [bulkLoad, setBulkLoad] = useState([
    {
      id: 1,
      key: "selectLanguageBulkLoad",
      label: "Lenguaje",
      type: "select2",
      options: languages.map(({ id, name }) => ({ key: id, text: name })),
      placeholder: "Seleccione...",
      multiSelect: false,
      disabled: false,
      required: true,
      isRequired: false,
      styleStackFather: {
        "min-width": "100%",
        margin: "0 auto",
        "padding-top": "1rem",
      },
      styleStack: {
        "min-width": "100%",
        "padding-right": "2rem",
      },
    },
    {
      id: 2,
      key: "fileBulkLoad",
      label: "Cargar archivo",
      // labelComponent: "Cargar archivo",
      type: "text2",
      onRenderLabel:null,
      defaultValu: null,
      disabled: true,
      multiline: false,
      required: true,
      resizable: false,
      typeInput: "file",
      description: "",
      title: null,
      isRequired: false,
      onGetErrorMessage: null,
    },
    {
      id: 3,
      key: "selectLanguageDownload",
      label: "Lenguaje",
      type: "select2",
      options: languages.map(({ id, name }) => ({ key: id, text: name })),
      placeholder: "Seleccione...",
      multiSelect: false,
      disabled: false,
      required: true,
      styleStackFather: {
        "min-width": "100%",
        margin: "0 auto",
        "padding-top": "3rem",
      },
      styleStack: {
        "min-width": "100%",
        "padding-right": "2rem",
      },
    },
    {
      id: 4,
      key: "fileDownload",
      type: "button2",
      isPrimary: false,
      text: "Descargar",
      disable: false,
      ariaDescription: "Este boton descarga el formatos",
      styleStackFather: {
        "min-width": "100%",
        "padding-top": "1rem",
        "text-align": "center",
      },
      styleStack: {
        margin: "0 auto",
      },
    },
  ]);

  const [campForm, setCampForm] = useState([
    {
      id: 1,
      key: "name",
      label: "",
      ariaLabel: null,
      type: "text2",
      defaultValu: null,
      disabled: true,
      multiline: true,
      resizable: false,
      required: true,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: {
        "min-width": "100%",
        margin: "0 auto",
        "padding-top": "3rem",
        "text-align": "center",
      },
      styleStack: {
        margin: "0px auto",
      },
      title: null,
      onRenderLabel: "Dominio al cual se le va a configurar los desafíos",
      isRequired: false,
      onGetErrorMessage: null,
    },
    {
      id: 2,
      key: "selectLanguage",
      label: "Lenguaje",
      type: "select2",
      options: languages.map(({ id, name }) => ({ key: id, text: name })),
      placeholder: "Seleccione...",
      multiSelect: false,
      disabled: false,
      required: true,
      isRequired: true,
      styleStackFather: {
        "min-width": "50%",
        margin: "0 auto",
        "padding-top": "3rem",
      },
      styleStack: {
        "min-width": "100%",
        "padding-right": "2rem",
      },
    },
    {
      id: 3,
      key: "selectWebControl",
      label: "Controles",
      type: "select2",
      options: null,
      itemsComponentInformation: itemsComponentInformation,
      placeholder: "Seleccione...",
      multiSelect: false,
      disabled: isDisable,
      required: true,
      isRequired: true,
      styleStackFather: {
        "min-width": "50%",
        margin: "0 auto",
        "padding-top": "3rem",
      },
      styleStack: {
        "min-width": "100%",
        "padding-right": "2rem",
      },
    },
    {
      id: 4,
      key: "errorPatternMessage",
      label: "errorPatternMessage",
      ariaLabel: null,
      type: "text2",
      defaultValu: null,
      disabled: isDisable,
      multiline: true,
      resizable: false,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: {},
      styleStack: {
        width: "100%",
        position: "center",
      },
      suffix: null,
      title: null,
      onRenderLabel: "Error message",
      isRequired: true,
      onGetErrorMessage: null,
    },
    {
      id: 5,
      key: "errorRequiredMessage",
      label: "errorRequiredMessage",
      ariaLabel: null,
      type: "text2",
      defaultValu: null,
      disabled: isDisable,
      multiline: true,
      resizable: false,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: {},
      styleStack: {
        width: "100%",
        position: "center",
      },
      suffix: null,
      title: null,
      onRenderLabel: "Error required message",
      isRequired: true,
      onGetErrorMessage: null,
    },
    {
      id: 6,
      key: "infoMessage",
      label: "infoMessage",
      ariaLabel: null,
      type: "text2",
      defaultValu: null,
      disabled: isDisable,
      multiline: true,
      resizable: false,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: {},
      styleStack: {
        width: "100%",
        position: "center",
      },
      suffix: null,
      title: null,
      onRenderLabel: "Info Message",
      isRequired: true,
      onGetErrorMessage: null,
    },
    {
      id: 7,
      key: "label",
      label: "label",
      ariaLabel: null,
      type: "text2",
      defaultValu: null,
      disabled: isDisable,
      multiline: true,
      resizable: false,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: {},
      styleStack: {
        width: "100%",
        position: "center",
      },
      suffix: null,
      title: null,
      onRenderLabel: "label",
      isRequired: true,
      onGetErrorMessage: null,
    },
    {
      id: 8,
      key: "placeholder",
      label: "placeholder",
      ariaLabel: null,
      type: "text2",
      defaultValu: null,
      disabled: isDisable,
      multiline: true,
      resizable: false,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: {},
      styleStack: {
        width: "100%",
        position: "center",
      },
      suffix: null,
      title: null,
      onRenderLabel: "placeholder",
      isRequired: true,
      onGetErrorMessage: null,
    },
    {
      id: 9,
      key: "succesMessage",
      label: "succesMessage",
      ariaLabel: null,
      type: "text2",
      defaultValu: null,
      disabled: isDisable,
      multiline: true,
      resizable: false,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: {},
      styleStack: {
        width: "100%",
        position: "center",
      },
      suffix: null,
      title: null,
      onRenderLabel: "Succes message",
      isRequired: true,
      onGetErrorMessage: null,
    },
    {
      id: 10,
      key: "title",
      label: "title",
      ariaLabel: null,
      type: "text2",
      defaultValu: null,
      disabled: isDisable,
      multiline: true,
      resizable: false,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: {},
      styleStack: {
        width: "100%",
        position: "center",
      },
      suffix: null,
      title: null,
      onRenderLabel: "Title",
      isRequired: true,
      onGetErrorMessage: null,
    },
    {
      id: 11,
      key: "alt",
      label: "alt",
      ariaLabel: null,
      type: "text2",
      defaultValu: null,
      disabled: isDisable,
      multiline: true,
      resizable: false,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: {},
      styleStack: {
        width: "100%",
        position: "center",
      },
      suffix: null,
      title: null,
      onRenderLabel: "alt",
      isRequired: true,
      onGetErrorMessage: null,
    },
    {
      id: 12,
      key: "value",
      label: "value",
      ariaLabel: null,
      type: "text2",
      defaultValu: null,
      disabled: isDisable,
      multiline: true,
      resizable: false,
      typeInput: "text",
      description: "",
      style: { color: "black" },
      styleStackFather: {},
      styleStack: {
        width: "100%",
        position: "center",
      },
      suffix: null,
      title: null,
      onRenderLabel: "value",
      isRequired: true,
      onGetErrorMessage: null,
    },
  ]);

  const [campFilters, setCampFilters] = useState([
    /*{
      id: 6,
      key: "isToAdmin",
      label: "Es administrador",
      type: "select",
      options: [
        { id: 0, text: "true" },
        { id: 1, text: "false" },
      ],
    },
    {
      id: 7,
      key: "idNotificationType",
      label: "Tipo de notificacion",
      type: "select",
      options: arrayNotificationTypes,
    },*/
  ]);
  
  useEffect(() => {
    setCampFilters([
        {
            id:0, 
            key:'idLanguage', 
            label: controlsPage?.lblTableLanguage?.value, 
            type:'select', 
            options: arrayLanguage
        },
    ])
},[arrayLanguage])
  //#endregion
  /************************
   * @methods
   ************************/
  const methodsList = {
    getDataItem: (object) => {
      const stateList = new Lists(itemsNotification, campForm);
      setIdPageSelect(stateList.getDataItem(object, idPageSelect));
      const stateCurrent = stateList.updateCurrent(idPageSelect);
      setCampForm(stateCurrent.object);
      setDeleteD(stateCurrent.delete);
      setEdit(stateCurrent.edit);
    },
    voidCamps: () => {
      const stateList = new Lists(itemsNotification, campForm);
      console.log(stateList);
      setCampForm(stateList.clearCamps());
    },
  };

  const methosRequests = {
    chageGetItems: async (filters) => {
      handleLoadingData()
      const response = await Requests.getItem({ param: filters, tenant: clientName })
      setitemsPages(response.data);
      const {
          currentPage,
          pageZise,
          totalCount,
          totalPages
      } = response.data.meta;
      setPagination({
          currentPage: currentPage,
          pageSize: pageZise,
          totalItems: totalCount,
          totalPages: totalPages
      })
      handleLoadedData();
    },
    crateItem: (dataForm) => {
      RequestsLanguageWebControlsPost.createItem({
        body: onSubmitEventFormPanel(dataForm, "create"),
        ct: "json",
        tenant: clientName,
        infoModal:{
          title:controlsPage?.lblSuccessfulProcess?.value,
          description:controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
        }
      });
    },
    updateItem: (dataForm, id) => {
      RequestsLanguageWebControls.confirmRequest({
        body: onSubmitEventFormPanel(dataForm, "update"),
        id: idLanguageWebControl,
        ct: "json",
        tenant: clientName,
        option: "updateItem",
        infoModal:{
          title:controlsPage?.lblUpdate?.value,
          description:controlsPage?.lblTheUpdateHasBeenSuccessful?.value
          }
      });
    },
    deleteItem: () => {
      idPageSelect.forEach((element) =>
        Requests.deleteItem({
          id: element.id,
          tenant: clientName,
          infoModal:{
            title:controlsPage?.lblDisabled?.value, 
            description:controlsPage?.lblItHbeenSuccessfullyDisabled?.value
          }
        })
      );
      window.location.reload();
    },
  };

  /************************
   * @hooks
   ************************/
  const [promise, setPromise] = useState(
    Requests.getItem({
      param: { pageSize: showElements, pageNumber: currentPages },
      tenant: clientName,
    })
  );

  // useEffect(() => {
  //   promise.then((data) => {
  //     setitemsPages(data.data);
  //   });
  // }, [promise]);
  useEffect(() => {
    if (Object.entries(controlsPage).length > 0) {
        methosRequests.chageGetItems(filter)
    }
}, [filter, controlsPage])



  /************************
   * @resturns
   ************************/
  if (itemsNotification === null) {
    return <ContainerBaseLoading />;
  }
  const numberPage = CountPages(itemsNotification.meta.totalPages);
  return (
    <>
      <SubMenu
        contentcontrols={
            !deleteD && !edit ? 
            [
              {
                text:"Carga masiva idioma administracíon",
                iconProps:{ iconName: "BulkUpload" },
                onClick:() => {
                  seSelectPanel(false);
                  methodsList.voidCamps();
                  openPanel();
                }
              }
            ]
            :
            [
              edit ?{
                text:"Configurar Modulo",
                iconProps:{ iconName: "CircleAddition" },
                onClick:() => {
                  openPanel();
                  seSelectPanel(true);
                }
              }:null
            ].filter(obj => obj !== null)
        }
        contentcontrolsTwo={
          <>
            <Panel
              isOpen={isOpen}
              closeButtonAriaLabel="Close"
              isHiddenOnDismiss={true}
              type={selectPanel ? PanelType.medium : PanelType.smallFixedFar}
              hasCloseButton={false}
            >
              {isOpen ? (
                <ContainerLargeForm
                  dataForm={(e) => {
                    console.log(edit);
                    edit
                      ? methosRequests.updateItem(e, idPageSelect[0].id)
                      : methosRequests.crateItem(e, idPageSelect);
                  }}
                  formTitle={edit ? "Configurar" : "Configurar"}
                  closePanel={dismissPanel}
                  Items={selectPanel ? campForm : bulkLoad}
                  case="notificationFlow"
                  actionOnChange={actionOnChange}
                  actionOnClick={actionOnClick}
                  isDisableOpt={isDisable}
                  onSubmitEventFormPanel={onSubmitEventFormPanel}
                  itemsComponentInformation={itemsComponentInformation}
                  defaultValueInput={defaultValueInput}
                  defaultSelectedKeys={defaultSelectedKeys}
                />
              ) : (
                ""
              )}
            </Panel>
          </>
        }
      />
      <div className="admin--body-container">
        <ListHeader
          filters={campFilters}
          showReport={false}
          subRute="Inicio / Administración | "
          subRute3= " Configuracíon de contenido por idioma"
          title="Configuracíon de contenido por idioma"
          dataForm={(e) =>
            methosRequests.chageGetItems({
              pageSize: showElements,
              pageNumber: currentPages,
              idDomain: e.idDomain,
            })
          }
          onChange={(e) => {
            setFilter({
                ...filter,
                pageSize: e.target.value,
                pageNumber: filter.pageNumber === "0" ? "0" : "1"
            });
          }}
          InputChange={(e) => {
              setFilter({
                  ...filter,
                  search: e,
                  pageNumber: filter.pageNumber === "0" ? "0" : "1"
              })
          }}
        />
        {/* <ListBody
          spaceTitle={SpaceTitel}
          Items={itemsNotification.data}
          meta={itemsNotification.meta}
          numberPages={numberPage}
          initRangeText={currentPages}
          orditem={OrdItems}
          finishRangeText={showElements}
          totalRangeText={itemsNotification.data.length}
          typelist="listSelect"
          selectdata={methodsList.getDataItem}
          paginate={(number) => {
            setCurrentPage(number);
            methosRequests.chageGetItems({
              pageSize: showElements,
              pageNumber: number,
            });
          }}
          /> */}
          <DetailsListBext
            enableShimmer={isLoadingData}
            listKey="listSelect"
            selectionMode={1}
            items={itemsNotification.data}
            //renderItemColumn={_defaultRenderItemColumn}
            columnTitles={columnTitles}
            onSelect={methodsList.getDataItem}
            onPageChange={page => {
                if (page !== +filter.pageNumber) {
                    setFilter({
                        ...filter,
                        pageNumber: String(page),
                    });
                }
            }}
            pagination={pagination}
          />
      </div>
    </>
  );
};

export default Contentforlanguage;
