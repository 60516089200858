import React, {useEffect, useState} from "react";
import CookiesJS from "js-cookie";
import {Panel, PanelType} from "@fluentui/react/lib/Panel";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {useBoolean} from "@fluentui/react-hooks";
import {useParams} from "react-router-dom";

import SubMenu from "../../../components/navigation/subMenu/subMenu.component";
import ListHeader from "../../../components/lists/listHeader/listHeader.component";
import PanelClient from "./panelClient";
import logo from '../../../assets/img/images'
import {RequestFetch} from "../../../utils/Requests/requests.Controller";
import DetailsListBext from "../../../components/lists/DetailsList/detailsList.component";
import {COLUMN_TITLES} from "./constants";

const Client = () => {
  const {idClient} = JSON.parse(CookiesJS.get("dataClient"));
  const {clientName} = useParams();

  const [captchaList, setCaptchaList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [dynamicKeyList, setDynamicKeyList] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [clientsItems, setClientsItems] = useState([])

  const requestsClients = new RequestFetch("Client");
  const RequestsCaptcha = new RequestFetch("Captcha");
  const RequestKeyType = new RequestFetch('DynamicKeyType');
  const RequestsLanguages = new RequestFetch("Languages")
  const [clientSelected, setClientSelected] = useState()

  const [filter, setFilter] = useState({
    pageSize: 5,
    pageNumber: "0",
    idDomain: ""
  });
  const [isLoadedOptionsData, {
    setTrue: handleLoadedOptionsData
  }] = useBoolean(false);

  const [isLoadingData, {
    setTrue: handleLoadingData,
    setFalse: handleLoadedData
  }] = useBoolean(true);
  const [isOpen, {
    setTrue: openPanel,
    setFalse: dismissPanel
  }] = useBoolean(false);

  useEffect(() => {
    async function onFilterChange(){
      if (filter.pageNumber > 0) {
        await executeClientsQuery();
      }
    }
    onFilterChange();
  }, [filter])

  useEffect( () => {
    async function onInit(){
      const [captchaResponse] = await Promise.all([
        methodsRequests.loadCaptchaList(clientName),
        methodsRequests.loadDynamicKeyList(clientName),
        methodsRequests.loadLanguagesList(clientName, idClient)
      ]);
      handleLoadedOptionsData();
      await executeClientsQuery(captchaResponse);
    }
    onInit();
  }, [])

  const executeClientsQuery = async (captchaElements = captchaList) => {
    await methodsRequests.getClients(filter, clientName, captchaElements);
  }

  const _defaultRenderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];
    return <span>{fieldContent}</span>;
  }

  const buildCaptchaOptions = () => {
    return captchaList.map(
      captcha => ({
        key: captcha.id,
        text: captcha.name,
        img: true,
        imageSrc: logo[captcha.name],
        imageSize: {width: "160px", height: "50px"},
        selectedImageSrc: logo[captcha.name]
      })
    )
  }

  const campFilters = [
    {
      id: 1,
      key: 'searchValue',
      label: '',
      type: 'text',
    }
  ]

  const methodsRequests = {
    loadCaptchaList: async (tenant) => {
      const captcha = await RequestsCaptcha.getItem({tenant});
      if (captcha.data.data) {
        setCaptchaList(captcha.data.data)
      }
      return captcha?.data?.data || [];
    },
    loadLanguagesList: async (tenant, idClient) => {
      const languages = await RequestsLanguages.getItem({tenant, param: {idClient}})
      if (languages.data.data) {
        setLanguageList(
          languages.data.data.map(element => ({
              key: element?.id,
              text: element?.name,
            })
          )
        )
      }
      return languages?.data?.data || [];
    },
    loadDynamicKeyList: async (tenant, idClient) => {
      const dynamicKeys = await RequestKeyType.getItem({tenant});
      if (dynamicKeys.data.data) {
        setDynamicKeyList(
          dynamicKeys.data.data.map(element => ({
              key: element?.id,
              text: element?.name,
            })
          )
        )
      }
      return dynamicKeys?.data?.data || [];
    },
    getClients: async (filters, tenant, captchaMap) => {
      handleLoadingData();
      setPagination(null);
      const response = await requestsClients.getItem({param: filters, tenant});
      if (response.data.data) {
        setClientsItems(
          response.data.data.map(client => {
            let clientMap = {
              ...client,
              appMobile: client.useVirtualKeyboard ? 'Habilitado' : 'Deshabilitado',
              captchaName: "Ninguno"
            }
            if (captchaMap) {
              const captchaName = captchaMap.find(captcha => captcha.id === client.idCaptcha)?.name;
              if (captchaName) {
                clientMap = {
                  ...clientMap,
                  captchaName
                }
              }
            }
            return clientMap
          })
        )
      }
      const {
        currentPage,
        pageZise,
        totalCount,
        totalPages
      } = response.data.meta;
      setPagination({
        currentPage: currentPage,
        pageSize: pageZise,
        totalItems: totalCount,
        totalPages: totalPages
      });

      handleLoadedData();
    },
  };

  const _handleSelectClient = (items) => {
    if (items.length > 0) {
      setClientSelected(items[0]);
    } else {
      setClientSelected(null);
    }
  }

  return (
    <>
      <SubMenu
        contentcontrols={
          isLoadedOptionsData ?
            clientSelected ?
            [
              {
                text:'Configurar Cliente',
                iconProps:{iconName: 'Edit'},
                onClick:() => openPanel()
              }
            ]
              :
            [
              {
                text:'Nuevo Cliente',
                iconProps:{iconName: 'Add'},
                onClick: () => openPanel()
              }
            ]
          :[]
        }
        contentcontrolsTwo={
          <>
            <Panel
              isOpen={isOpen}
              closeButtonAriaLabel="Close"
              isHiddenOnDismiss={true}
              type={PanelType.medium}
              hasCloseButton={false}
            >
              {isOpen && (
                <PanelClient
                  clientSelected={clientSelected}
                  onClosePanel={async(actionExecuted = false) => {
                    dismissPanel();
                    if(actionExecuted){
                      await executeClientsQuery();
                    }
                  }}
                  optionsList={{
                    captcha: buildCaptchaOptions(),
                    languages: languageList,
                    dynamicKeys: dynamicKeyList
                  }}
                />
              )}
            </Panel>
          </>
        }
      />
      <div className="admin--body-container">
        <ListHeader
          filters={campFilters}
          showReport={false}
          hideSearch={true}
          subRute="Administración / Clientes | "
          subRute3=" Configuración de clientes"
          title="Configuración de clientes"
          onChange={(event) => {
            setFilter({
              ...filter,
              pageSize: event.target.value,
              pageNumber: filter.pageNumber === "0" ? "0" : "1"
            });
          }}
          dataForm={({searchValue}) => {
            setFilter({
              ...filter,
              pageNumber: "1",
              search: searchValue
            });
          }}
        />
        <DetailsListBext
          enableShimmer={isLoadingData}
          listKey="supportBsrList"
          selectionMode={1}
          items={clientsItems}
          renderItemColumn={_defaultRenderItemColumn}
          columnTitles={COLUMN_TITLES}
          onSelect={_handleSelectClient}
          onPageChange={page => {
            if (page !== +filter.pageNumber) {
              setFilter({
                ...filter,
                pageNumber: String(page),
              });
            }
          }}
          pagination={pagination}
        />
      </div>
    </>
  );
};
export default Client;
