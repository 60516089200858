import React, {useContext, useEffect, useState} from "react";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {Panel, PanelType} from "@fluentui/react/lib/Panel";
import {useBoolean} from "@fluentui/react-hooks";
import {useParams} from "react-router-dom";

import SubMenu from "../../../components/navigation/subMenu/subMenu.component";
import ListHeader from "../../../components/lists/listHeader/listHeader.component";
import DetailsListBext from "../../../components/lists/DetailsList/detailsList.component";
import {RequestFetch} from "../../../utils/Requests/requests.Controller";
import {COLUMN_TITLES} from "./constants";
import RoleAssignment from "./roleAssignment";
import LenguajeContext from "../../../context/Lenguaje/lenguajeContext";
import { changeLang, changeLangForm } from "../../../utils/logicRendering/changeIdiom";
import ContainerBaseLoading from "../../../components/containers/baseLoading/containerBaseLoading.component";


const UsersRoleAssignment = () => {
    const domainRequest = new RequestFetch('Domains');
    const rolesRequest = new RequestFetch('Roles/assigned');

    const {clientName} = useParams();
    const [userOrRoleSelected, setUserOrRoleSelected] = useState(null);
    const [filtersList, setFiltersList] = useState([]);
    const [domainForm, setDomainForm] = useState();
    const [rolesAssignedList, setRolesAssignedList] = useState([]);
    const { getLenguaje, stateLanguage } = useContext(LenguajeContext);
    const [controlsPage, setControlsPage] = useState({})
    const [columnTitles, setColumnTitles] = useState(COLUMN_TITLES)
    const [pagination, setPagination] = useState(null);
    const [filter, setFilter] = useState({
        pageSize: 5,
        pageNumber: "0",
        alias: "",
        search: ""
    });

    const [isPanelOpen, {
        setTrue: handleOpenPanel,
        setFalse: handleDismissPanel
    }] = useBoolean(false);

    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);

    useEffect(async () => {
        let controlsPage = {};
        if (stateLanguage.lenguaje != 0) {
            stateLanguage.lenguaje.map((pass) => {
                if (pass.path == 'controls' || 
                pass.path == 'domain' || 
                pass.path == 'assign-roles') {
                    pass.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                    })
                }
            })
            setControlsPage(controlsPage)
            setColumnTitles(current => changeLang(current, controlsPage))
            // setCampForm(current => changeLangForm(current, controlsPage))
            requests.getRolesAssigned(filter, clientName);
        }
        const domainData = await domainRequest.getItem({
            tenant: clientName
        })
        
        setDomainForm(domainData.data.data);

    }, [stateLanguage])

    useEffect(() => {
        if(filter.pageNumber > 0) {
            requests.getRolesAssigned(filter, clientName);
        }
    }, [filter])

    useEffect(() => {
        if (domainForm) {
            setFiltersList(changeLangForm([
                {
                    id: "search-value",
                    key: 'searchValue',
                    type: 'text',
                    label: '',
                    // placeholder: "Buscar"
                },
                {
                    id: "domain-select",
                    key: 'idDomain',
                    label: '',
                    type: 'select',
                    placeholder: "slcSelectDomain",
                    idHtmlPlaceholder: "slcSelectDomain",
                    idHtml:'slcSelectDomain',
                    options: domainForm.map(
                        domain => ({
                            id: domain.alias,
                            text: domain.alias
                        })
                    )
                }
            ],controlsPage));
        }
    }, [domainForm])

    const _selectUser = (items) => {
        if (items.length > 0) {
            setUserOrRoleSelected(items[0]);
        } else {
            setUserOrRoleSelected();
        }
    }

    const _handleClosePanel = () => {
        handleDismissPanel();
        setUserOrRoleSelected(null);
    }

    const getLastModificationDate = (role) => {
        return role.isAssignedToUser ?
            role.userRole.updatingDate || role.userRole.creatingDate :
            role.domainRole.updatingDate || role.domainRole.creatingDate
    }

    const requests = {
        getRolesAssigned: async (filters, tenant) => {
            setPagination(null);
            handleLoadingData()
            const response = await rolesRequest.getItem({param:filters, tenant});
            let idNumber = 0;
            setRolesAssignedList(
                response.data.data.map(
                    role => {
                        idNumber ++;
                        const roleAssigned = role.isAssignedToUser ? role.userRole : role.domainRole;
                        return (
                            {
                                assignedTo: role.isAssignedToUser ? "Usuario" : "Dominio",
                                updatingDate: getLastModificationDate(role),
                                domain: roleAssigned.domain,
                                rol: roleAssigned.name,
                                username: role.isAssignedToUser ? roleAssigned.userName : roleAssigned.domain,
                                idUser: roleAssigned.idUser,
                                idDomain: roleAssigned.idDomain,
                                isAssignedToUser: role.isAssignedToUser,
                                idNumber
                            }
                        )
                    }
                )
            );
            const {
                currentPage,
                pageZise,
                totalCount,
                totalPages
            } = response.data.meta;
            setPagination({
                currentPage: currentPage,
                pageSize: pageZise,
                totalItems: totalCount,
                totalPages: totalPages
            });
            handleLoadedData();
        }
    }

    return (
        <>
            {Object.entries(controlsPage).length>0?
            <>
                <SubMenu
                    contentcontrols={
                        [
                            {
                                text:controlsPage?.OnClickAssignRoleUserAccount.value,
                                iconProps:{iconName: 'AddTo'},
                                onClick: ()=>handleOpenPanel()
                            },
                            userOrRoleSelected?
                            {
                                text:controlsPage?.OnclickEditRoleUserAccount.value,
                                iconProps:{iconName: 'EditNote'},
                                onClick: ()=>handleOpenPanel()
                            }:null
                        ].filter(obj => obj !== null)
                    }
                    contentcontrolsTwo={
                        <>
                            <Panel isOpen={isPanelOpen}
                                closeButtonAriaLabel="Close"
                                type={PanelType.large}
                                hasCloseButton={false}
                                onDismiss={handleDismissPanel}
                                isFooterAtBottom={true}
                            >
                                <RoleAssignment domains={domainForm} onCancel={_handleClosePanel} userOrRoleSelected={userOrRoleSelected} controlsPage={controlsPage}/>
                            </Panel>
                        </>
                    }
                />
                <div className="admin--body-container">
                    <ListHeader
                        showReport={false}
                        filters={filtersList}
                        subRute={controlsPage?.lblUrlAssignmentRolesUserAccounts.value}
                        subRute3={controlsPage?.lblAssignmentRolesUserAccounts.value}
                        title={controlsPage?.lblAssignmentRolesUserAccounts.value}
                        hideSearch={true}
                        onChange={(event) => {
                            setFilter({
                                ...filter,
                                pageSize: event.target.value,
                                pageNumber: filter.pageNumber === "0" ? "0" : "1"
                            });
                        }}
                        dataForm={({idDomain, searchValue}) => {
                            setFilter({
                                ...filter,
                                pageNumber: "1",
                                alias: idDomain,
                                search: searchValue
                            });
                        }}
                    />
                    <DetailsListBext
                        enableShimmer={isLoadingData}
                        listKey="supportBsrList"
                        selectionMode={1}
                        items={rolesAssignedList}
                        columnTitles={columnTitles}
                        onSelect={_selectUser}
                        onPageChange={page => {
                            if (page !== +filter.pageNumber) {
                                setFilter({
                                    ...filter,
                                    pageNumber: String(page),
                                });
                            }
                        }}
                        pagination={pagination}
                    />
                </div>
            </>
            :
            <ContainerBaseLoading />
            }
        </>
    );
}

export default UsersRoleAssignment;
