import React, { useContext, useEffect, useState } from 'react';
import { Nav } from '@fluentui/react/lib/Nav';
import { Panel, PanelType } from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import LenguajeContext from '../../../context/Lenguaje/lenguajeContext';
import ContainerBaseLoading from '../baseLoading/containerBaseLoading.component';
import { useNavigate } from 'react-router-dom';
import { PATH_APPLICATION, URL_REPORTS } from '../../../assets/settings/settinsrouters';

//***Components***//


//***Methods***//


/**
 *@author Yeison Quiroga(15-04-2021)
 *@version 1.0
 *@lastCommit 
 */

const ContainerPanelFloat =(props)=>{
    const { client } = props;
    /************************
     * @arguments 
     ************************/
        const [showContent, setShowContent]= useState(false);
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        const [controlsPage, setControlsPage] = useState({});
        const [navLinkGroups, setNavLinkGroups] = useState([])
        const  navigate = useNavigate()
        const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

        const PATH_APP = PATH_APPLICATION==''?PATH_APPLICATION:'/'+PATH_APPLICATION
        useEffect(async() => {
            let controlsPage = {};
            if (stateLanguage.lenguaje !=0) {
                stateLanguage.lenguaje.map((pass) => {
                    if (pass.path == 'roles-permissions'|| pass.path == 'controls'|| pass.path == 'administration' ) {
                        pass.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                      })
                      setControlsPage(controlsPage)
                    } 
                })
                setNavLinkGroups([
                    {
                      links: [
                        {
                            key: 'home',
                            name: controlsPage?.OnClickStart?.value,
                            // url: `/${client}/administration/`
                            onClick:()=>{navigate(`${PATH_APP}/${client}/administration/`);dismissPanel();}
                        },
                        {
                            key: 'Personalización',
                            name: controlsPage?.OnClickPersonalization?.value,
                            links:[
                                // {
                                //     key: 'contentLanguage',
                                //     name: controlsPage?.OnClickContentByLanguage?.value,
                                //     // url: `/${client}/administration/language-content`,
                                //     onClick:()=>{navigate(`${PATH_APP}/${client}/administration/language-content`);dismissPanel();}
                                    
                                // },
                                {
                                    key: 'Themes',
                                    name: controlsPage?.OnClickAppearance?.value,
                                    // url: `/${client}/administration/themes`,
                                    onClick:()=>{navigate(`${PATH_APP}/${client}/administration/themes`);dismissPanel();}
                                }
                            ]
                        },
                        {
                            key: 'Notificaciones',
                            name: controlsPage?.OnClickNotifications?.value,
                            links:[
                                // {
                                //     key: 'errorException',
                                //     name: controlsPage?.OnClickErrorExceptionMessages?.value,
                                //     // url: `/${client}/administration/messages-error`,
                                //     onClick:()=>{navigate(`${PATH_APP}/${client}/administration/messages-error`);dismissPanel();}
                                // },
                                // {
                                //     key: 'NotificationTemplate',
                                //     name: controlsPage?.OnClickNotificationTemplate?.value,
                                //     // url: `/${client}/administration/notification-template`
                                //     onClick:()=>{navigate(`${PATH_APP}/${client}/administration/notification-template`);dismissPanel();}
                                // },
                                // {
                                //     key: 'notificationFlow',
                                //     name: controlsPage?.OnClickNotificationFlow?.value,
                                //     // url: `/${client}/administration/notification-flow`
                                //     onClick:()=>{navigate(`${PATH_APP}/${client}/administration/notification-flow`);dismissPanel();}
                                // },
                                {
                                    key: 'passwordExpire',
                                    name: controlsPage?.OnClickPasswordToExpire?.value,
                                    // url: `/${client}/administration/PasswordExpirationNotifications`
                                    onClick:()=>{navigate(`${PATH_APP}/${client}/administration/PasswordExpirationNotifications`);dismissPanel();}
                                }
                            ]
                        },
                        // {
                        //     key: 'Administración',
                        //     name: controlsPage?.OnClickAdministration?.value,
                        //     links:[
                        //         {
                        //             key: 'domainGroup',
                        //             name: controlsPage?.OnClickDomain?.value,
                        //             links:[
                        //                 {
                        //                     key: 'domain',
                        //                     name: controlsPage?.OnClickDomain?.value,
                        //                     // url: `/${client}/administration/domain`
                        //                     onClick:()=>{navigate(`${PATH_APP}/${client}/administration/domain`);dismissPanel();}
                        //                 },
                        //                 {
                        //                     key: 'securityPolicy',
                        //                     name: controlsPage?.OnClickPasswordPoliciesDomain?.value,
                        //                     // url: `/${client}/administration/password-policies`
                        //                     onClick:()=>{navigate(`${PATH_APP}/${client}/administration/password-policies`);dismissPanel();}
                        //                 },
                        //                 {
                        //                     key: 'attributes',
                        //                     name: controlsPage?.OnClickAttributes?.value,
                        //                     // url: `/${client}/administration/attributes`
                        //                     onClick:()=>{navigate(`${PATH_APP}/${client}/administration/attributes`);dismissPanel();}
                        //                 },
                        //                 {
                        //                     key: 'schemes',
                        //                     name: controlsPage?.OnClickSchemes?.value,
                        //                     // url: `/${client}/administration/schemas`
                        //                     onClick:()=>{navigate(`${PATH_APP}/${client}/administration/schemas`);dismissPanel();}
                        //                 }
                        //             ]
                        //         },
                        //         {
                        //             key: 'rolesPermissionsGroup',
                        //             name: controlsPage?.OnClickRolesAndPermissions?.value,
                        //             links:[
                        //                 {
                        //                     key: 'rolesPermissions',
                        //                     name: controlsPage?.OnClickRolesAndPermissions?.value,
                        //                     // url: `/${client}/administration/roles-permissions`
                        //                     onClick:()=>{navigate(`${PATH_APP}/${client}/administration/roles-permissions`);dismissPanel();}
                        //                 },
                        //                 {
                        //                     key: 'assignRoles',
                        //                     name: controlsPage?.OnClickAssignRolesUserAccounts?.value,
                        //                     // url: `/${client}/administration/user-role-assignments`
                        //                     onClick:()=>{navigate(`${PATH_APP}/${client}/administration/user-role-assignments`);dismissPanel();}
                        //                 }
                        //             ]
                        //         },
                        //         {
                        //             key: 'securityQuestions',
                        //             name: controlsPage?.OnClickSecurityQuestions?.value,
                        //             // url: `/${client}/administration/security-questions/`
                        //             onClick:()=>{navigate(`${PATH_APP}/${client}/administration/security-questions`);dismissPanel();}
                        //         },
                        //         {
                        //             key: 'AuthenticationFactorConfig',
                        //             name: controlsPage?.OnClickChallenges?.value,
                        //             // url: `/${client}/administration/challenges`
                        //             onClick:()=>{navigate(`${PATH_APP}/${client}/administration/challenges`);dismissPanel();}
                        //         },
                        //         {
                        //             key: 'channels',
                        //             name: controlsPage?.OnClickShippingChannels?.value,
                        //             // url: `/${client}/administration/delivery-channels`
                        //             onClick:()=>{navigate(`${PATH_APP}/${client}/administration/delivery-channels`);dismissPanel();}
                        //         },
                        //         {
                        //             key: 'customers',
                        //             name: controlsPage?.OnClickCustomers?.value,
                        //             // url: `/${client}/administration/client`
                        //             onClick:()=>{navigate(`${PATH_APP}/${client}/administration/client`);dismissPanel();}

                        //         },
                        //         {
                        //             key: 'IP',
                        //             name: controlsPage?.OnClickIPAddressRestriction?.value,
                        //             // url: `/${client}/administration/ip-restriction`
                        //             onClick:()=>{navigate(`${PATH_APP}/${client}/administration/ip-restriction`);dismissPanel();}

                        //         },
                        //         {
                        //             key: 'accessTimes',
                        //             name: controlsPage?.OnClickAccessHours?.value,
                        //             // url: `/${client}/administration/access-hours`
                        //             onClick:()=>{navigate(`${PATH_APP}/${client}/administration/access-hours`);dismissPanel();}
                        //         },
                        //     ]
                        // },
                        // {
                        //     key: 'Documentos',
                        //     name: controlsPage?.OnClickDocuments?.value,
                        //     links:[
                        //         { 
                        //             key: 'documentUpload',
                        //             name: controlsPage?.OnClickDocumentUpload?.value,
                        //             // url: `/${client}/administration/upload-files`
                        //             onClick:()=>{navigate(`${PATH_APP}/${client}/administration/upload-files`);dismissPanel();}
                                    
                        //         },
                        //         {
                        //             key: 'fileType',
                        //             name: controlsPage?.OnClickFileTypeCategorization?.value,
                        //             // url: `/${client}/administration/type-file`
                        //             onClick:()=>{navigate(`${PATH_APP}/${client}/administration/type-file`);dismissPanel();}
                        //         }
                        //     ]
                        // },
                        // {
                        //     key: 'license',
                        //     name: controlsPage?.OnClickLicense?.value,
                        //     links:[
                        //         {
                        //             key: 'commercialLicense',
                        //             name: controlsPage?.OnClickCommercialLicense?.value,
                        //             // url: `/${client}/administration/commercial-license`
                        //             onClick:()=>{navigate(`${PATH_APP}/${client}/administration/commercial-license`);dismissPanel();}
                        //         },
                        //         {
                        //             key: 'userLicenses',
                        //             name: controlsPage?.OnClickUserLicense?.value,
                        //             // url: `/${client}/administration/user-licences`
                        //             onClick:()=>{navigate(`${PATH_APP}/${client}/administration/user-licences`);dismissPanel();}

                        //         }
                        //     ]
                        // },
                        {
                            key: 'Soporte BSR',
                            name: controlsPage?.OnClickSupportBSR?.value,
                            // url: `/${client}/administration/support`,
                            onClick:()=>{navigate(`${PATH_APP}/${client}/administration/support`);dismissPanel();}
                            
                        },
                        // {
                        //     key: 'Reportes',
                        //     name: controlsPage?.OnClickReports?.value,
                        //     // url: `/${client}/administration/reports`,
                        //     onClick:()=>{navigate(`${PATH_APP}/${client}/administration/reports`);dismissPanel();}
                        // },
                        {
                            key: 'TyC',
                            name: controlsPage?.OnClickTermsAndConditions?.value,
                            // url: `/${client}/administration/terms-conditions`,
                            onClick:()=>{navigate(`${PATH_APP}/${client}/administration/terms-conditions`);dismissPanel();}
                        }
                      ],
                    },
                ])
            }
            }, [stateLanguage])

    /************************
     * @methods 
     ************************/
    const panel ={
        
        commands:{
            padding: 0,
            marginTop: 0
        },
        content:{
            'padding-left': 0,
            'padding-right': 0
        }
    }

    /************************
     * @hooks 
     ************************/


    /************************
     * @resturns 
     ************************/
        
            return(
                <>
            {Object.entries(controlsPage).length>0?
            <>
                 <div className="header--button-nav" onClick={()=>{setShowContent(true);openPanel();}}>&#9776;</div>
                    
                    <Panel
                        isLightDismiss
                        isOpen={isOpen}
                        onDismiss={dismissPanel}
                        closeButtonAriaLabel="Close"
                        //className='panel'
                        styles={panel}
                        isOnRightSide={false}
                        hasCloseButton={false}
                        type={PanelType.smallFixedNear}
                    >
                        <div className="panel--header">
                            <div className="panel--header-title">
                                <i onClick={()=>{dismissPanel()}}>V</i> 
                                <h3>{controlsPage?.lblBSRAdmon?.value}</h3>
                            </div>
                        </div>
                        <div className="panel--body">
                            <Nav groups={navLinkGroups}/>
                        </div>

                    </Panel>
                    </>:
            <ContainerBaseLoading />
        }
        </>
            );
        
}

export default ContainerPanelFloat;
