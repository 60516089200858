import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { Stack } from "@fluentui/react";

const LargeFormButton = (props) => {
  const [selectedItem, setSelectedItem] = useState();
  let item = props.item;

  const onClick = (e) => {
    setSelectedItem(e);
  };

  useEffect(() => {
    let objectFinal = {
      ...selectedItem,
      ...{ id: item.key },
    };
    props.actionOnClick(objectFinal);
  }, [selectedItem]);

  //Buscar en un arreglo el id para habilitar y deshabilitar
  let found;
  if (props.isDisableOpt.length === 1) {
    found = props.isDisableOpt.find(
      (element) => element.key === "valueDefault"
    );
  } else {
    found = props.isDisableOpt.find((element) => element.key === item.key);
    if (found === undefined) {
      found = props.isDisableOpt.find(
        (element) => element.key === "valueDefault"
      );
    }
  }
  if (found === undefined) {
    found = props.isDisableOpt.find((element) => element.key === item.key);
  }

  LargeFormButton.item = {
    text: PropTypes.string.isRequired,
    key: PropTypes.isRequired,
    id: PropTypes.isRequired,
    isPrimary: PropTypes.isRequired,
  };
  return (
    <div key={props.id}>
      <Stack style={item.styleStackFather} id={item.id}>
        <Stack style={item.styleStack} id={item.key}>
          {item.isPrimary === false ? (
            <DefaultButton
              id={item.key}
              text={item.text}
              onClick={onClick}
              allowDisabledFocus
              disabled={found.disable}
              checked={item.checked}
              className={"" && item.className}
              ariaDescription={item.ariaDescription}
            />
          ) : (
            <PrimaryButton
              id={item.key}
              text={item.text}
              onClick={onClick}
              allowDisabledFocus
              disabled={found.disable}
              checked={item.checked}
              className={"" && item.className}
              ariaDescription={item.ariaDescription}
            />
          )}
        </Stack>
      </Stack>
    </div>
  );
};
export default LargeFormButton;
