import React, {useEffect, useState} from "react";
import {useBoolean} from "@fluentui/react-hooks";
import {useParams} from "react-router-dom";
import {DefaultButton, PrimaryButton} from "@fluentui/react/lib/Button";

import {RequestFetch} from "../../../utils/Requests/requests.Controller";
import ListHeader from "../../../components/lists/listHeader/listHeader.component";
import DetailsListBext from "../../../components/lists/DetailsList/detailsList.component";
import {INDIVIDUAL_ASSIGNMENT_COLUMN_TITLES} from "./constants";

const IndividualAssignment = ({
    domains,
    onAssignLicense,
    onCancel
}) => {

    const usersRequest = new RequestFetch('Users');

    const {clientName} = useParams();
    const [filterList, setFilterList] = useState([])
    const [usersList, setUsersList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [filter, setFilter] = useState({
        pageSize: 5,
        pageNumber: "0",
        idDomain: ""
    });

    const [isLoadingData, {
        setTrue: handleLoadingData,
        setFalse: handleLoadedData
    }] = useBoolean(false);


    useEffect(() => {
        if (filter.pageNumber > 0) {
            requests.getUsers(filter, clientName);
        }
    }, [filter])

    useEffect(() => {
        if(domains){
            setFilterList([
                {
                    id: "search-value",
                    key: 'searchValue',
                    type: 'text',
                    label: '',
                    placeholder: "Nombre de Usuario"
                },
                {
                    id: "domain-select",
                    key: 'idDomain',
                    label: '',
                    type: 'select',
                    placeholder: "Seleccione dominio",
                    options: domains.map(
                        domain => ({
                            id: domain.id,
                            text: domain.alias
                        })
                    )
                }
            ]);
        }
    }, [domains])

    const _assignLicenceToUser = (user) => {
        onAssignLicense(user.id, true);
    }

    const _defaultRenderItemColumn = (item, index, column) => {
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case 'actions':
                return (

                    <PrimaryButton text="Asignar Licencia"
                       className="button--default button--primary"
                       onClick={() => _assignLicenceToUser(item)}
                   />
                );

            default:
                return <span>{fieldContent}</span>;
        }
    }


    const requests = {
        getUsers: async (filters, tenant) => {
            setPagination(null);
            handleLoadingData()
            const response = await usersRequest.getItem({param: filters, tenant});
            let idNumber = 0;
            setUsersList(
                response.data.data.map(
                    user => {
                        idNumber++;
                        return (
                            {
                                ...user,
                                idNumber,
                                domain: domains.find(domain => domain.id === user.idDomain).alias
                            }
                        )
                    }
                )
            );
            const {
                currentPage,
                pageZise,
                totalCount,
                totalPages
            } = response.data.meta;
            setPagination({
                currentPage: currentPage,
                pageSize: pageZise,
                totalItems: totalCount,
                totalPages: totalPages
            });
            handleLoadedData();
        }
    }

    return (
        <>

            <div className="admin--body-container">
                <ListHeader
                    showReport={false}
                    filters={filterList}
                    subRutes=""
                    title="Asignación individual de licencia"
                    hideSearch={true}
                    onChange={(event) => {
                        setFilter({
                            ...filter,
                            pageSize: event.target.value,
                            pageNumber: filter.pageNumber === "0" ? "0" : "1"
                        });
                    }}
                    dataForm={({idDomain, searchValue}) => {
                        setFilter({
                            ...filter,
                            pageNumber: "1",
                            idDomain,
                            search: searchValue
                        });
                    }}
                />
                <DetailsListBext
                    enableShimmer={isLoadingData}
                    listKey="userLicencesList"
                    selectionMode={0}
                    items={usersList}
                    columnTitles={INDIVIDUAL_ASSIGNMENT_COLUMN_TITLES}
                    renderItemColumn={_defaultRenderItemColumn}
                    onPageChange={page => {
                        if (page !== +filter.pageNumber) {
                            setFilter({
                                ...filter,
                                pageNumber: String(page),
                            });
                        }
                    }}
                    pagination={pagination}
                />
            </div>
            <div className="admin--container-footer">
                <DefaultButton className="button--second w3 mg10-w" onClick={onCancel}>CANCELAR</DefaultButton>
            </div>
        </>
    );
}

export default IndividualAssignment;
