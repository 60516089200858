import React, { Fragment, useContext, useEffect, useState } from "react";
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useBoolean } from "@fluentui/react-hooks";
import CookiesJS from 'js-cookie';

//*****Componnents*****//
import SubMenu from "../../components/navigation/subMenu/subMenu.component";
import ContainerLargeForm from "../../components/containers/largeForm/containerLargeForm.component";
import ContainerBaseLoading from "../../components/containers/baseLoading/containerBaseLoading.component";
import ListHeader from "../../components/lists/listHeader/listHeader.component";
import ListBody from "../../components/lists/listBody/listBody.component";
import { changeLang, changeLangForm } from "../../utils/logicRendering/changeIdiom";

//*****Methods*****//
import Lists from "../../utils/logicRendering/lists.controller";
import { RequestFetch } from "../../utils/Requests/requests.Controller";
import CountPages from "../../utils/logicRendering/countPages.controller";
import Modal from "../../components/tools/modal/modal.component";
import { useParams } from "react-router";
import DetailsListBext from "../../components/lists/DetailsList/detailsList.component";
import LenguajeContext from "../../context/Lenguaje/lenguajeContext";

/**
 *@author Jhon Beltran(26-05-2022)
 *@version 1.0
 *@lastCommit 
 */
const Schemas = () => {
  /************************
   * @arguments
   ************************/
  //***Propertise***//
  const [edit, setEdit] = useState(false);
  const [deleteD, setDeleteD] = useState(false);
  const [idSchemaSelect, setIdSchemaSelect] = useState(null);
  const [currentPages, setCurrentPage] = useState(1);
  const [showElements, setShowElements] = useState(5);
  const [itemsSchemas, setItemsSchemas] = useState(null);
  const [itemsTypeSchemas, setItemsTypeSchemas] = useState(null);
  const [schemasList, setSchemasList] = useState([])
  const [controlsPage, setControlsPage] = useState({});
  const [controlsPageValue, setControlsPageValue] = useState({});
  const [textOnPages, setTextOnPages] = useState({})
  const { getLenguaje, stateLanguage } = useContext(LenguajeContext);
  const [pagination, setPagination] = useState(null);
  const { clientName } = useParams();
  const { idClient } = JSON.parse(CookiesJS.get('dataClient'));
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [isDisable, setDisable] = useState([]);

  const [nomEsq, setNomEsq] = useState('')
  const [tipEsq, setTipEsq] = useState(null)
  const [tenId, setTenId] = useState(null)
  const [endPo, setendPo] = useState('')
  const [isActSsl, setIsActSsl] = useState(false)

  const [isLoadingData, {
    setTrue: handleLoadingData,
    setFalse: handleLoadedData
  }] = useBoolean(false);

  const [filter, setFilter] = useState({
    pageSize: 5,
    pageNumber: "0",
    idDomain: ""
  });

  const selectedKey = (key) => {
    if (key == 2) {
      setCampForm(
        [{
          id: 0,
          key: "domainAddress",
          label: "Nombre de Esquema",
          type: "",
          placeholder: "Esquema",
          required: 1,
          max: controlsPageValue?.txtSchemaName?.maxlength,
          defaultValue: nomEsq

        },
        {
          id: 1,
          key: "idSchemaType",
          label: "Tipo de Esquema",
          type: "select",
          options: datOpt,
          required: true,
          action: true,
          defaultValue: tipEsq
        },

        {
          id: 2,
          key: "endpoint",
          label: "Endpoint",
          type: "text",
          placeholder: "...",
          required: 1,
          max: 400,
          min: 10,
          defaultValue: endPo
        },
        {
          id: 3,
          key: "ssl",
          type: "checkbox",
          label: "Usa SSL",
          required: 1,
          defaultValue: isActSsl
        },
        {
          id: 7,
          key: "tenantId",
          label: "Tenant Id",
          type: "",
          placeholder: "Tenant",
          required: 1,
          max: 70,
          min: 10,
          defaultValue: tenId
        },
        ]
      )
    } else {
      setCampForm(
        [{
          id: 0,
          key: "domainAddress",
          label: "Nombre de Esquema",
          type: "",
          placeholder: "Esquema",
          required: 1,
          max: controlsPageValue?.txtSchemaName?.maxlength,
        },
        {
          id: 1,
          key: "idSchemaType",
          label: "Tipo de Esquema",
          type: "select",
          options: datOpt,
          required: 1,
          action: true
        },
        {
          id: 2,
          key: "endpoint",
          label: "Endpoint",
          type: "text",
          placeholder: "...",
          required: 1,
          max: 400,
          min: 10,
        },
        {
          id: 3,
          key: "ssl",
          type: "checkbox",
          label: "Usa SSL",
          required: 1,
        },]
      )
    }

  }
  let datOpt = [];
  if (itemsSchemas && itemsTypeSchemas) {
    itemsSchemas.data.map((tal) => {
      itemsTypeSchemas.data.map((mas) => {
        if (tal.idSchemaType == mas.id) {
          let nameType = mas.name;
          let itemSsl = tal.ssl === true ? ("Sí") : ('No')
          tal = Object.assign(tal, { type: nameType, itemSsl: itemSsl });
        }
      });
    });
    itemsTypeSchemas.data.map((opt) => {
      datOpt.push({ value: opt.id, text: opt.name });
    });
  }
  //***Data Propertise***//
  const Requests = new RequestFetch("Schemas");
  const RequestsTypesSchema = new RequestFetch("Schemas/types");
  const RequestsUserSchema = new RequestFetch("users");

  const SpaceTitel = ["Nombre de Esquema", "Tipo de Esquema", "SSL", "Fecha de creación", "Creado por"];


  const [columnTitles, setColumnTitles] = useState([
    {
      domainAddress: {
        name: "Nombre de esquema",
        maxWidth: 150,
        idHtml: "coluSchemaName",
      },
      type: {
        name: "Tipo de Esquema",
        maxWidth: 150,
        isMultiline: true,
        idHtml: "coluSchemaType",
      },
      itemSsl: {
        name: "SSL",
        maxWidth: 50
      },
      creatingDate: {
        name: "Fecha de creación",
        minWidth: 100,
        isResizable: true,
        maxWidth: 200,
        idHtml: "coluCreationDate",
      },
      creatorUser: {
        name: "Creado por",
        maxWidth: 150,
        isResizable: true,
        idHtml: "coluCreatedBy",
      },
      updatingDate: {
        name: "Última Modificación",
        minWidth: 100,
        isResizable: true,
        maxWidth: 200,
        idHtml: "coluLastModification",
      },
      updateUser: {
        name: "Modificado por",
        maxWidth: 150,
        isResizable: true,
        idHtml: "lblModifiedBy"
      },
    }
  ])

  const selectSchema = (items) => {

    if (items.length > 0) {
      setIdSchemaSelect(items[0]);

      setNomEsq(items[0].domainAddress)
      setTipEsq(items[0].idSchemaType)
      setTenId(items[0].tenantId)
      setendPo(items[0].endpoint)
      setIsActSsl(items[0].ssl)

      if (items[0].idSchemaType == 2) {
        campForm[4] = {
          id: 7,
          key: "tenantId",
          label: "Tenant Id",
          type: "",
          placeholder: "Tenant",
          max: 70,
          min: 10,
          defaultValue: items[0].tenantId
        }
      }


    } else {
      setIdSchemaSelect();

      setNomEsq('')
      setTipEsq(null)
      setTenId('')
      setendPo('false')
      setIsActSsl(false)
    }
  }
  const OrdItems = ["domainAddress", "type", "itemSsl", "creatingDate", "creatorUser"];
  const [campForm, setCampForm] = useState([
    {
      id: 0,
      key: "domainAddress",
      label: "Nombre de Esquema",
      type: "text",
      placeholder: "Esquema",
      required: 1,
      max: controlsPageValue?.txtSchemaName?.maxlength,
      disabled: false,
      isRequired: false,
      defaultValue: nomEsq,
      idHtml: "lblSchemaName"
    },
    {
      id: 1,
      key: "idSchemaType",
      label: "Tipo de Esquema",
      type: "select",
      required: 1,
      options: datOpt,
      action: true,
      defaultValue: tipEsq,
      idHtml: "lblSchemaType"
    },
    {
      id: 2,
      key: "endpoint",
      label: "Endpoint",
      type: "text",
      placeholder: "...",
      required: 1,
      max: 400,
      min: 10,
      defaultValue: endPo,
      idHtml: "lblEndPoint"
    },
    {
      id: 3,
      key: "ssl",
      type: "checkbox",
      label: "Usa SSL",
      defaultValue: isActSsl,
      idHtml: "lblUsaSSL"
    },
  ]);
  campForm[0] = Object.assign(campForm[0], { defaultValue: idSchemaSelect ? nomEsq : '', max: controlsPageValue?.txtSchemaName?.maxlength })
  campForm[1] = Object.assign(campForm[1], { defaultValue: idSchemaSelect ? tipEsq : '', options: datOpt })
  campForm[2] = Object.assign(campForm[2], { defaultValue: idSchemaSelect ? endPo : '', max: controlsPageValue?.txtEndPoint?.maxlength })
  campForm[3] = Object.assign(campForm[3], { defaultValue: idSchemaSelect ? isActSsl : '' })


  useEffect(() => {
    let controlsPage = {};
    let controlsPageValue = {};
    if (stateLanguage.lenguaje != 0) {
      stateLanguage.lenguaje.map((pass) => {
        if (pass.path == 'controls' || pass.path == 'domain' || pass.path == 'schemas') {
          pass.webcontrolspages.map((data) => {
            controlsPage[data.idHtml] = data.languagewebcontrols[0];
            controlsPageValue[data.idHtml] = data;
          })
          setControlsPage(controlsPage)
          setControlsPageValue(controlsPageValue)
        }
      })
      setColumnTitles(current => changeLang(current, controlsPage))
      setCampForm(current => changeLangForm(current, controlsPage))
    }
  }, [textOnPages, stateLanguage])

  useEffect(() => {
    if (Object.entries(controlsPage).length > 0) {
      methosRequests.getSupportUsers(filter, clientName);
    }
  }, [filter, controlsPage])

  /************************
   * @methods
   ************************/
  const methodsList = {
    getDataItem: (object) => {
      const stateList = new Lists(itemsSchemas, campForm);
      setIdSchemaSelect(stateList.getDataItem(object, idSchemaSelect));
      const stateCurrent = stateList.updateCurrent(idSchemaSelect);
      setCampForm(stateCurrent.object);
      setDeleteD(stateCurrent.delete);
      setEdit(stateCurrent.edit);
    },
    voidCamps: () => {
      const stateList = new Lists(itemsSchemas, campForm);
      setCampForm(stateList.clearCamps());
    },
  };

  const _defaultRenderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];

    return <span>{fieldContent}</span>;
  }
  const methosRequests = {
    chageGetItems: (filters) => {
      setPromise(Requests.getItem({ param: filters, tenant: clientName }));
      setPromiseDomainSchema(RequestsTypesSchema.getItem({ param: filters, tenant: clientName }));
    },
    crateItem: (dataForm) => {
      dataForm = Object.assign(dataForm, { idClient: idClient });
      Requests.createItem({
        body: dataForm, ct: "json", tenant: clientName, op: 'b',
        infoModal: {
          title: controlsPage?.lblSuccessfulProcess?.value,
          description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
        }
      })

    },
    updateItem: (dataForm, id) => {
      dataForm = Object.assign(dataForm, { idClient: idClient, })
      dataForm.ssl == null ? (dataForm.ssl = false) : (dataForm.ssl = true);
      Requests.confirmRequest({
        body: dataForm, id: id, ct: "json", tenant: clientName, option: 'updateItem', op: 'b',
        infoModal: {
          title: controlsPage?.lblSuccessfulProcess?.value,
          description: controlsPage?.lblTheInformationHBeenRegisteredCorrectly?.value
        }
      })
    },
    deleteItem: () => {
      Requests.confirmRequest({
        id: idSchemaSelect.id, tenant: clientName, option: 'deleteItem', op: 'b',
        infoModal: {
          title: controlsPage?.lblDisabled?.value,
          description: controlsPage?.lblItHbeenSuccessfullyDisabled?.value
        }
      })
    },
    getSupportUsers: async (filters, tenant) => {
      setPagination(null);
      handleLoadingData()
      const response = await Requests.getItem({ param: filters, tenant });

      setSchemasList(
        response.data.data.map(user => {
          itemsTypeSchemas.data.map((mas) => {
            if (user.idSchemaType == mas.id) {
              let nameType = mas.name;
              user = Object.assign(user, { type: nameType });
            }
          })
          return (
            {
              ...user,
              itemSsl: user.ssl ? 'Sí' : 'No'
            }
          )
        }
        )
      );
      const {
        currentPage,
        pageZise,
        totalCount,
        totalPages
      } = response.data.meta;
      setPagination({
        currentPage: currentPage,
        pageSize: pageZise,
        totalItems: totalCount,
        totalPages: totalPages
      });

      handleLoadedData();
    },
  };
  const onSubmitEventFormPanel = (data) => {

  }

  /************************
   * @hooks
   ************************/
  const [promise, setPromise] = useState(
    Requests.getItem({
      param: { pageSize: showElements, pageNumber: currentPages }, tenant: clientName
    })
  );

  useEffect(() => {
    promise.then((data) => {
      handleLoadingData()
      setItemsSchemas(data.data);
      setSchemasList(data.data.data)
      const {
        currentPage,
        pageZise,
        totalCount,
        totalPages
      } = data.data.meta;
      setPagination({
        currentPage: currentPage,
        pageSize: pageZise,
        totalItems: totalCount,
        totalPages: totalPages
      });
      handleLoadedData();
    });
  }, [promise]);

  const [promiseDomain, setPromiseDomainSchema] = useState(
    RequestsTypesSchema.getItem({
      param: { pageSize: showElements, pageNumber: currentPages }, tenant: clientName
    })
  );

  useEffect(() => {
    promiseDomain.then((data) => {
      setItemsTypeSchemas(data.data);
    });
  }, [promiseDomain]);

  /************************
   * @resturns
   ************************/
  if (itemsSchemas === null) {
    return <ContainerBaseLoading />;
  }
  const numberPage = CountPages(itemsSchemas.meta.totalPages);

  return (
    <>
      <SubMenu
        contentcontrols={
          idSchemaSelect ? [
            {
              text: controlsPage?.lblEditSchemas?.value,
              iconProps: { iconName: "Edit" },
              onClick: () => openPanel()
            },
            {
              text: controlsPage?.OnClickDeleteSchema?.value,
              iconProps: { iconName: "delete" },
              onClick: () => methosRequests.deleteItem()
            }
          ]
            :
            [
              {
                text: controlsPage?.lblNewSchema?.value,
                iconProps: { iconName: "Add" },
                onClick: () => {
                  methodsList.voidCamps();
                  openPanel();
                }
              }
            ]
        }
        contentcontrolsTwo={
          <>
            <Panel
              isOpen={isOpen}
              closeButtonAriaLabel="Close"
              isHiddenOnDismiss={true}
              type={PanelType.smallFixedFar}
              hasCloseButton={false}
            >
              {isOpen ? (
                <ContainerLargeForm
                  dataForm={(e) => {
                    idSchemaSelect
                      ? methosRequests.updateItem(e, idSchemaSelect.id)
                      : methosRequests.crateItem(e);
                  }}
                  formTitle={idSchemaSelect ? controlsPage?.lblEditSchemas?.value : controlsPage?.lblNewSchema?.value}
                  Items={campForm}
                  closePanel={dismissPanel}
                  selectedKey={selectedKey}
                  isDisableOpt={isDisable}
                  onSubmitEventFormPanel={onSubmitEventFormPanel}
                  controlsPage={controlsPage}
                />
              ) : (
                ""
              )}
            </Panel>
          </>
        }
      />
      <div className="admin--body-container">
        <ListHeader
          showReport={false}
          subRute={controlsPage?.lblUrlAdminSchemaManagement?.value}
          subRute3={controlsPage?.lblAdminSchemaManagement?.value}
          title={controlsPage?.lblAdminSchemaManagement?.value}
          dataForm={(e) =>
            methosRequests.chageGetItems({
              pageSize: showElements,
              pageNumber: currentPages,
            })
          }
          onChange={(e) => {
            setShowElements(e.target.value);
            methosRequests.chageGetItems({
              pageSize: e.target.value,
              pageNumber: currentPages,
            });
          }}
          InputChange={(e) => {
            methosRequests.chageGetItems({
              search: e,
              pageSize: showElements,
              pageNumber: currentPages,
            });
          }}
        />
        <DetailsListBext
          enableShimmer={isLoadingData}
          listKey="supportBsrList"
          selectionMode={1}
          items={schemasList}
          renderItemColumn={_defaultRenderItemColumn}
          columnTitles={columnTitles}
          onSelect={selectSchema}
          onPageChange={page => {
            if (page !== +filter.pageNumber) {
              setFilter({
                ...filter,
                pageNumber: String(page),
              });
            }
          }}
          pagination={pagination}
        />
      </div>
    </>
  );
};

export default Schemas;
