import React, { useState, useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { TextField, MaskedTextField } from "@fluentui/react/lib/TextField";
import { Label } from '@fluentui/react/lib/Label';
import { useBoolean, useId } from "@fluentui/react-hooks";
import { IStackTokens, Stack, IStackStyles } from "@fluentui/react/lib/Stack";
import { DefaultButton, IconButton, IButtonStyles } from "@fluentui/react/lib/Button";
import { Callout } from "@fluentui/react/lib/Callout";
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import LenguajeContext from "../../../../context/Lenguaje/lenguajeContext";


const LargeFormInput = (props) => {

  const [selectedItem, setSelectedItem] = useState();
  const [dropdownValue, setDropdownValue] = useState(null)
  const { stateLanguage } = useContext(LenguajeContext);
  const [controlsPage, setControlsPage] = useState({});
  const [msgError, setMsgError] = useState('')
  let item = props.item;
  const iconClass = mergeStyles({
    fontSize: 8,
    height: 8,
    width: 8,
    margin: '0',
    position: 'absolute',
    color: 'var(--color-primary)' 
  });
  useEffect(async() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        stateLanguage.lenguaje.map((pass) => {
            if (pass.path == 'ip-restriction'|| pass.path =='controls') {
                pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })    
              setControlsPage(controlsPage)
            } 
        })
        setMsgError(props.item?.isRequired || props.item?.required ? controlsPage?.txtEnterIp?.infoMessage: '')
    }
  }, [stateLanguage])
  //#region Buscar en un arreglo el id para habilitar y deshabilitar
  let found;
  if (props.isDisableOpt.length === 1) {
    found = props.isDisableOpt.find(
      (element) => element.key === "valueDefault"
    );
  } else {
    found = props.isDisableOpt.find((element) => element.key === item.key);
    if (found == undefined) {
      found = props.isDisableOpt.find(
        (element) => element.key === "valueDefault"
      );
    }
  }
  if (found == undefined) {
    found = props.isDisableOpt.find((element) => element.key === item.key);
  }
  //#endregion
  //#region Accion onChange
  const inputNumber = (e) =>{
    const regex = /^[0-9]+$/;
    return regex.test(e);
  }
  const onChange = (e, item) => {
    if (props.item.min) {
      if (item.length >= props.item.min) {
        if (props.item.max) {
          if (item.length<=props.item.max) {
            setDropdownValue(item.length)
          } else {
            setMsgError('Excede el número de caracteres permitido')
            setDropdownValue()
          }
        } else {
          setDropdownValue(item.length)
        }
      }else{
        setMsgError('Debe tener más caracteres')
        setDropdownValue()
      }
    }else{
      if (item.length > 0) {
        setDropdownValue(item.length)
      }else{
        setDropdownValue()
      }
    }
    
    setSelectedItem(item);
  };
  const handleChangeOnPaste = (event)=>{
      if (["mailPort","requestSecurityQuestions","numberSecurityQuestions","sessionTime","dailyUserRecoveryLimit"].indexOf(item.key) !== -1){
        event.preventDefault()
      }
  }
  const handleKeyPress = (event) => {
    if(item.isNumeric){
      if (!inputNumber(event.key)) {
        event.preventDefault();
        }    
      }
    }
  useEffect(() => {
    if (item.defaultValue) {
      setDropdownValue(item.defaultValue)
    }else if(item.defaultValu){
      setDropdownValue(item.defaultValu)
    }
  }, [item])
  const styleInput = {
    errorMessage:{
      background:'#F5D4D4 0% 0% no-repeat padding-box',
      'border-radius':'4px',
      padding:'7px 7px',
      'margin-top':'2px',
      color:'var(--color-danger)',
      ':before': {
        content: "",
        position: 'absolute',
        left: '10%',
        top: '-20px',
        width: 0,
        height: 0,
        'border-left': '13px solid blue',
        'border-top': '26px solid red',
        'border-right': '13px solid green'
      }
    }
  }

  /*  useEffect(() => {
     if (item.defaultValue) {
       setDropdownValue(item.defaultValue)
     }
   }, [item]) */

  useEffect(() => {
    let objectFinal = {
      ...(selectedItem ? {selectedItem : selectedItem}: {selectedItem :item.defaultValue}),
      ...{selectedItem : selectedItem,defaulvalue: item.defaultValue},
      ...{ id: item.key, state: found.disable },
    };
    props.actionOnChange(objectFinal);
  }, [selectedItem, found]);
  //#endregion

  const getErrorMessage = (value) => {
    //Validar cantidad
    if (value.length > item.sizeInput) {
      return item.onGetErrorMessage[0].messageValidateSize;
    }
  };
  //#region Componente de ayuda
  const stackTokens = {
    childrenGap: 4,
    maxWidth: 300,
  };
  const iconProps = { iconName: "Info" };
  const labelCalloutStackStyles = { root: { padding: 20 } };
  const CustomLabel = () => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
      useBoolean(false);
    const descriptionId = useId("description");
    const iconButtonId = useId("iconButton");
    return (
      <>
        <Stack horizontal verticalAlign="center" tokens={stackTokens}>
          <span id={item.id}>{item.labelComponent}</span>
          <IconButton
            id={iconButtonId}
            iconProps={iconProps}
            title="Info"
            ariaLabel="Info"
            onClick={toggleIsCalloutVisible}
            styles={iconButtonId}
          />
        </Stack>
        {isCalloutVisible && (
          <Callout
            target={"#" + iconButtonId}
            setInitialFocus
            onDismiss={toggleIsCalloutVisible}
            ariaDescribedBy={descriptionId}
            role="alertdialog"
          >
            <Stack
              tokens={stackTokens}
              horizontalAlign="start"
              styles={labelCalloutStackStyles}
            >
              <span id={descriptionId}>
                <p>{item.onRenderLabel}</p>
              </span>
              <DefaultButton onClick={toggleIsCalloutVisible}>
                Close
              </DefaultButton>
            </Stack>
          </Callout>
        )}
      </>
    );
  };
  useEffect(()=>{
    if(item.value!=null && item.value!= '' ){
      setSelectedItem(item.value)
    }
  },[item.value])
  //#endregion
  //#region Buscar en el arreglo props.defaultValueInput   
  let defaultValueElement;
  const onRenderDescription = (e,i ,o )=>{
    if(item.key =="criticalIndicator"){
      return(
        <>
        <p style={{"color":"#CC0000", margin: "5px", fontSize:"13px"}}>{e.description}</p>
        </>
      )
    }
    if(item.key =="averageIndicator"){
      return(
        <>
        <p style={{"color":"#F37807", margin: "5px", fontSize:"13px"}}>{e.description}</p>
        </>
      )
    }
    if(item.key =="mildIndicator"){
      return(
        <>
        <p style={{"color":"#0C5A0C", margin: "5px", fontSize:"13px"}}>{e.description}</p>
        </>
      )
    }
    if(item.key =="hour"){
      return(
        <>
        <p style={{ paddingTop: "1px", fontSize:"13px"}}>{e.description}</p>
        </>
      )
    }
    return e.description
  } 
  useEffect(() => {
    if (props.defaultValueInput) {
      defaultValueElement = props.defaultValueInput.find((element) => element.key === item.key);
    }
  }, [props.defaultValueInput]);
  //#endregion.
  const onRenderLabel = () => <CustomLabel id={item.key + 1} {...item} />;
  return (
    <>
      <div className={item.className}>
        <Stack style={item.styleStackFather} id={item.id}>
          <Label>{item.label ? <div style={{ position: "relative" }}> <span>{item.label}</span>{item.isRequired || item.required ? <FontIcon aria-label="requerido" iconName="SkypeCircleCheck" className={iconClass} /> : ''} </div> : ""}</Label>
          <Stack style={item.styleStack} id={item.key}>
            <TextField
              id={item.key}
              ariaLabel={item.ariaLabel}
              autoAdjustHeight={item.autoAdjustHeight}
              autoComplete={item.autoComplete}
              canRevealPassword={item.canRevealPassword}
              className={"" && item.className}
              {...item.defaultValu === null ? { defaultValue: item.defaultValue } : { defaultValue: item.defaultValu }}
              //defaultValue = {defaultValueElement.text}
              description={item.description}
              disabled={item.disable}
              iconitem={{ iconName: item.iconitem }}
              multiline={item.multiline}
              resizable={item.resizable}
              onChange={onChange}
              //onInput={"validity.valid||(value='');"}
              onKeyPress={handleKeyPress}
              maxLength={item.max}
              label={item.labelComponent}
              prefix={item.prefix}
              readOnly={item.readOnly}
              errorMessage={item.errorMsg ? (undefined) : (dropdownValue ? undefined : msgError)}
              style={item.style ? item.style : styleInput}
              value={selectedItem}
              onPaste={handleChangeOnPaste}
              onRenderDescription={onRenderDescription}
              // type={item.typeInput}
              type={item.typeInput ? item.typeInput : props.textFileType}
              suffix={item.suffix}
              required={item?.required || false}
              {...(item.isRequired === true ? { required: onRenderLabel } : "")}
              {...(item.onGetErrorMessage !== null
                ? { onGetErrorMessage: getErrorMessage }
                : "")}
              {...(item.onRenderLabel !== null ? { onRenderLabel } : "")}
              oninput="validity.valid||(value='');"
            />
          </Stack>
        </Stack>
      </div>
    </>
  );
};
export default LargeFormInput;
