import React, { useState, useEffect, useContext } from "react";
import { useBoolean } from '@fluentui/react-hooks';
import { w3cwebsocket as W3Cwebsocket } from "websocket";
import CookiesJS from "js-cookie";

//**Components**//
import SubMenu from "../../components/navigation/subMenu/subMenu.component";
import ComponentCard from "../../components/containers/largeForm/components/ComponentCard"


import { useParams } from "react-router-dom";
//**Methods**//


import { HubConnectionBuilder } from "@microsoft/signalr";
import { ActionButton } from "@fluentui/react";
import { render } from "@testing-library/react";
import ModalFluent from "../../components/tools/modalFluent/ModalFluent";
import CountPages from "../../utils/logicRendering/countPages.controller";
import NoticeAlert from "../../components/tools/noticeAlert/noticeAlert.component";
import ListHeader from "../../components/lists/listHeader/listHeader.component";
import ListBody from "../../components/lists/listBody/listBody.component";
import DetailsListBext from "../../components/lists/DetailsList/detailsList.component";
import { SERVISE_URI } from "../../assets/settings/settinsrouters";
import LenguajeContext from "../../context/Lenguaje/lenguajeContext";

//**Constants**//
// import {COLUMN_TITLES, CONFIRM_MODAL_ACTIONS} from "./constants";

/**
 *@author Sebastian Ocampo Roa(25-05-2022)
 *@version 1.0
 *@lastCommit
 */

const Inbox = () => {
  const Client = CookiesJS.get("dataUser");
  const dataToken = JSON.parse(CookiesJS.get("dataToken"));
  const dataClient = JSON.parse(Client);
  const [idClient, setIdClient] = useState(dataClient.idClient);
  const [ connection, setConnection ] = useState(null);
  const [dataT, setDataT] = useState(CookiesJS.get("dataToken"));
  const dataUserFormat = JSON.parse(dataT);
  const [userSelected, setUserSelected] = useState();
  const [usersList, setUsersList] = useState([]);
  const [newSession, setNewSession] = useState()
  const [listUsers, setListUsers] = useState([])
  const [typeClass,setTypeClass] = useState("")
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const [controlsPage, setControlsPage] = useState({});
  const [filter, setFilter] = useState({
    pageSize: 5
  });
  const [pagination, setPagination] = useState(null);
  const [isLoadingData, {
    setTrue: handleLoadingData,
    setFalse: handleLoadedData
  }] = useBoolean(false);
  const _confirmationModal = (info, confirmAction) => {
    render(
        <ModalFluent
            props={info}
            actionOnClick={_ => {}}
            objectRequest={confirmAction}
        />,
        document.getElementById("modal")
    );
  }
  useEffect(async() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
        stateLanguage.lenguaje.map((pass) => {
            if (pass.path == 'notifications-expiry-passwords'|| pass.path =='controls'|| pass.path =='administration'|| pass.path=='commercial-license') {
                pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = data.languagewebcontrols[0];
              })    
              setControlsPage(controlsPage)
            } 
        })
    }
  }, [stateLanguage])

  
  useEffect(()=>{
    if(dataToken.colorLevel == "CriticalIndicator"){
      setTypeClass("criticalIndicatorStyle noticealert--container--criticalIndicator")
    }
    else if(dataToken.colorLevel == "AverageIndicator"){
      setTypeClass("warning noticealert--container")
    }
    else if(dataToken.colorLevel == "MildIndicator"){
      setTypeClass("mildIndicatorStyle noticealert--container--mildIndicator")
    }
},[])
  /************************
   * @arguments
   ************************/
   const COLUMN_TITLES = [{
    user: {
        name:controlsPage?.coluUser?.value,
        maxWidth: 250,
        isMultiline: true
    },
    mail: {
        name: controlsPage?.coluEmail?.value,
        maxWidth: 250
    },
    ip: {
        name: controlsPage?.coluIdPublic?.value,
        maxWidth: 250
    },
    date: {
        name:controlsPage?.coluLoginDate?.value,
        maxWidth: 250
    },
    actions: {
        name: controlsPage?.coluSignOff?.value
    }
}];
   const CONFIRM_MODAL_ACTIONS = {
    'changeStatus' : {
        title : `¿Desea CONFIRMAR proceso de {env} ESTADO?`,
        description: `Tenga en cuenta que al editar el estado del usuario, este {env} tendrá acceso a los aplicativos.`,
        idSupportType: 3
    },
    'changePassword': {
        title : `¿Desea CONFIRMAR proceso de CAMBIAR CONTRASEÑA?`,
        description: `Tenga en cuenta que se enviará un enlace al usuario para que él mismo realice el proceso de cambio de contraseña.`,
        idSupportType: 4
    },
    'deleteQuestions': {
        title : `¿Desea CONFIRMAR proceso de ELIMINAR PREGUNTAS?`,
        description: `Tenga en cuenta que las preguntas que el usuario tiene asociadas a la cuenta quedarán eliminadas y que al ingresar nuevamente a BSR, el sistema solicitará de nuevo la asociación de estas.`,
        idSupportType: 1
    },
    'closeSessions': {
        title : `¿Desea CONFIRMAR proceso de CERRAR SESIONES?`,
        description: `Tenga en cuenta que se cerrará la sesión que el usuario tenga abierta.`,
        idSupportType: 2
    }
  }
  const _confirmationModalBody = (title, description) => {
    return <div className="container--flex c--flex-dc c--flex-hc">
        <h3 className="mg10-h">{title}</h3>
        <h4 className="mg10-h txt--aling-c">{description}</h4>
    </div>
  }
  const _reportDescriptionModalBody = (onChange) => {
    return <div className="container--flex c--flex-dc c--flex-hc">
        <h3 className="mg10-h">Ingrese una breve descripción del porqué se realizó el soporte</h3>
        <div className="mg10-h txt--aling-c w10">
            <textarea className="w10 h5" onChange={onChange}/>
        </div>
    </div>
  }
  const _sendSupportRequest = (action, user) => {
    let remark = "";
    _confirmationModal(
        {
            description: _reportDescriptionModalBody(event => {
                remark = event.target.value;
            }),
            type:'default'
        },
        _ => {
            requests.createSupport({
                idUser: user.id,
                idSupportType: CONFIRM_MODAL_ACTIONS[action].idSupportType,
                remark
            })
        }
    )
  }
  const requests = {
    getSupportUsers: async (filters, tenant) => {
        setPagination(null);
        handleLoadingData()
        // const response = await supportUsersRequest.getItem({param:filters, tenant});
        let idNumber = 0;
        // setUsersList(
        //     response.data.data.map(
        //         user => {
        //             idNumber ++;
        //             return (
        //                 {
        //                     ...user,
        //                     isEnabledLabel: user.isEnabled ? 'Habilitada' : 'Inhabilitada',
        //                     associatedQuestionsLabel: user.associatedQuestions ? 'Si' : 'No',
        //                     actions: '',
        //                     idNumber
        //                 }
        //             )
        //         }
        //     )
        // );
        // const {
        //     currentPage,
        //     pageZise,
        //     totalCount,
        //     totalPages
        // } = response.data.meta;
        // setPagination({
        //     currentPage: currentPage,
        //     pageSize: pageZise,
        //     totalItems: totalCount,
        //     totalPages: totalPages
        // });

        handleLoadedData();
    },
    // createSupport: async (body) => {
    //    await supportRequest.createItem({
    //         body,
    //         tenant: clientName
    //    });
    // }
  }

  const [showNotice, setShowNotice] = useState(true);
  const [currentPages, setCurrentPage] = useState(1);
  const [showElements, setShowElements] = useState(5);

  /************************
   * @methods
   ************************/
   const getConnectionId = () => {
    connection.invoke('Getconnectionid').then(
      (data) => {
        // console.log(data);
        // saveSocketConnection(data.data).then(response => {
        //   console.log(response);
        // });
      }
    );
  }

  /************************
   * @hooks
   ************************/
  const paginate = (number) => setCurrentPage(number);

  const saveSocketConnection = (idConection) =>{
    CookiesJS.set('idConnection', idConection);
    return true;
}   

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
    .withUrl(`${SERVISE_URI}sessionsHub/`)
    //.withAutomaticReconnect()
    .build();
    newConnection.onreconnected(connectionId => {
        getConnectionId()
    });

setConnection(newConnection);
}, [])

useEffect(() => {
    if (connection) {
        connection.start()
            .then(result => {
                getConnectionId()
                connection.on('listenNewSession', (data) => {
                    // console.log('listenNewSession');
                    // console.log(data);
                    // {
                    //   id: 1,
                    //   user: "usuario2",
                    //   mail: "usuario@gmail.com",
                    //   ip: "123.145.23.1",
                    //   date: "02/03/2021",
                    // }
                    setNewSession(
                        {
                          ...data,
                          user: data.user.username,
                          mail: data.user.institutionalEmail,
                          ip: data.user.publicIP,
                          date: data.user.time.split('T')[0]
                        }
                    )
                });
            })
            .catch(e => console.log('Connection failed: ', e));
    }
}, [connection])

useEffect(() => {
  if(newSession){
    // console.log(listUsers)
    let users = listUsers
    let _users = users.push(newSession)
    const sessionsFliter = listUsers.filter((data)=> data.user)
    console.log(sessionsFliter)
    console.log(newSession)
    setListUsers([listUsers.length > 0 ? newSession: _users])
    // console.log(listUsers)
  }
}, [newSession])

const _defaultRenderItemColumn = (item, index, column) => {
  const fieldContent = item[column.fieldName];
  switch (column.key) {
      case 'actions':
          return (
              <ActionButton
                  text=""
                  iconProps={{iconName: 'Cancel'}}
                  onClick={() => _handleSupportActions('closeSessions', item) }
              />
          );

      default:
          return <span>{fieldContent}</span>;
  }
}

const _handleSupportActions = (action, user) => {
  let title = CONFIRM_MODAL_ACTIONS[action].title;
  let description = CONFIRM_MODAL_ACTIONS[action].description;
  if(action === 'changeStatus') {
      const status = user.isEnabled;
      title = title.replace("{env}", status ? "INHABILITAR" : "HABILITAR");
      description = description.replace("{env}", status ? "ya no" : "");
  }
  _confirmationModal(
  {
          description: _confirmationModalBody(
              title,
              description
          ),
          type:'default'
      },
      () => _sendSupportRequest(action, user)
  )
}

  // useEffect(() => {
  //   if(filter.pageNumber > 0) {
  //       // requests.getSupportUsers(filter, clientName);
  //   }
  // }, [filter])

  /************************
   * @resturns
   ************************/
  let userPasswordExpiryTime =
    dataUserFormat.userPasswordExpiryTime.split("T");
  return (
    <>
      <SubMenu />
      {showNotice ? (
        <>
        
          <NoticeAlert
            typeClass={typeClass}
            //typeClass="warning noticealert--container "
            date={userPasswordExpiryTime[0]}
            days={dataUserFormat.expirationDays}
            cancel={true}
            onClose={() => setShowNotice(false)}
            msg={controlsPage.lblTDateUser?.value}
          />
        </>
      ) : undefined}
      <div className="admin--body-container">
        <ListHeader
          showReport={true}
          subRute={controlsPage?.OnClickStart?.value}
          subRute3="Modulo Administrador"
          title={controlsPage?.lblInbox?.value}
          onChange={(e) => setShowElements(e.target.value)}
          targets={
            <div className="container-access">
              <ComponentCard
                label={controlsPage?.lblActiveSessions?.value} 
                colorSquare="bkg-green" 
                icon="Accept"
                text={controlsPage?.lblActive?.value}
                value={dataToken.indicator.sessions}
              />
              <ComponentCard
                label={controlsPage?.lblViewsLicensesUse?.value} 
                colorSquare="bkg-orange" 
                icon="InboxCheck"
                text={controlsPage?.lblUse?.value}
                value={dataToken.indicator.licenses}
              />
              <ComponentCard 
                label={controlsPage?.lblPriorityAttention?.value} 
                colorSquare="bkg-yellow" 
                icon="EditNote"
                text={controlsPage?.lblTotal?.value}
                value={dataToken.indicator.expiredPassword}
              />
              <ComponentCard 
                label={controlsPage?.lblInputError?.value} 
                colorSquare="bkg-red" 
                icon="Unsubscribe"
                text={controlsPage?.lblErrors?.value}
                value={dataToken.indicator.failedLogins}
              />
            </div>
          }
        />
        
        <DetailsListBext
          enableShimmer={isLoadingData}
          listKey="mainList"
          selectionMode={0}
          items={listUsers}
          renderItemColumn={_defaultRenderItemColumn}
          columnTitles={COLUMN_TITLES}
          onPageChange={page => {
              if(page !== +filter.pageNumber){
                  setFilter({
                      ...filter,
                      pageNumber: String(page),
                  });
              }
          }}
          pagination={pagination}
        />
      </div>
    </>
  );
};

export default Inbox;
