//****Librarys ****//
import React, { Fragment, useEffect, useState } from 'react';

//*****Componnents*****//

//**Methods**//

/**
 *@author Yeison Quiroga(15-04-2021)
 *@version 1.0
 *@lastCommit 
 */

const HeaderForm = (props) =>{          
           
    /************************
     * @arguments 
     ************************/
        
    /************************
     * @methods 
     ************************/
    
    /************************
     * @hooks 
     ************************/
        
    /************************
     * @resturns 
     ************************/
        return(
            <div className="headerForm--container">
                <div className="headerForm--icon">
                    {props.iconurl}
                </div>
                <div className={`headerForm--title ${props.className}`}>
                    <h2>{props.title}</h2>
                    <h4>{props.subtitle}</h4>
                </div>
            </div>
        );
}

export default HeaderForm;